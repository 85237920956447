import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./market-statistics.module.scss";
import Proptypes from "prop-types";
import { LocalizationContext } from "services/localizationContext";
import { useTheme } from "@mui/material/styles";

export default function MarketStatistics({
  capitalization,
  volume,
  volumeChange,
  buys,
  sells,
}) {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  return (
    <Box className={`${styles.accordionWrap} ${styles.blockWrap}`}>
      <Box className={styles.accordion}>
        <Box id="market-stat" mb={2}>
          <Typography variant="h6">{t("discover.marketStatistics")}</Typography>
        </Box>
        <Box className={styles.infoWrap}>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.totalMarketValue")}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.accent}
            >
              ${capitalization}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.sevenDayVolume")}
            </Typography>
            <Box className={styles.volumeWrap}>
              <Typography
                variant="subtitle1"
                component="span"
                className={styles.volumeChange}
                style={{
                  color:
                    volumeChange >= 0
                      ? theme.palette.tertiary.main
                      : theme.palette.coral.main,
                }}
              >
                {volumeChange}%
                <span
                  className={styles.diffTriangle}
                  style={{
                    borderWidth:
                      volumeChange > 0 ? "0 2px 8px 2px" : "8px 2px 0 2px",
                    borderColor:
                      volumeChange >= 0
                        ? `transparent transparent ${theme.palette.tertiary.main} transparent`
                        : `${theme.palette.coral.main} transparent transparent transparent`,
                  }}
                ></span>
              </Typography>
              <Typography
                variant="subtitle1"
                component="span"
                className={styles.accent}
              >
                ${volume}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.infoItem}>
            <Typography
              className={styles.subText}
              variant="subtitle1"
              component="span"
            >
              {t("discover.buy")}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.accent}
            >
              {buys || "0"}%
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography
              className={styles.subText}
              variant="subtitle1"
              component="span"
            >
              {t("discover.sell")}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.accent}
            >
              {sells || "0"}%
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

MarketStatistics.defaultProps = {
  capitalization: 0,
  volume: 0,
  volumeChange: 0,
  buys: 0,
  sells: 0,
};

MarketStatistics.propTypes = {
  capitalization: Proptypes.number,
  volume: Proptypes.number,
  volumeChange: Proptypes.number,
  buys: Proptypes.number,
  sells: Proptypes.number,
};
