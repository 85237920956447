import { celebrityDomain, mainAppDomain, PATHS } from "../constants";
import { setLoader } from "../redux/appSlice";
import { API_AWS_AUTH_URL, googleApi } from "../services/api.service";
import {
  setAccessToken,
  setIdToken,
  setRefreshToken,
} from "../services/auth.service";

export const setPayloadToDashboard = (data) => {
  const lang = localStorage.getItem("selected-language");
  let url = `${celebrityDomain}/signin?refreshToken=${data.refreshToken.token}&username=${data.accessToken.payload.username}`;
  if (lang) {
    url += `&lang=${lang}`;
  }
  window.location.href = url;
};

export const googleAuth = ({ dispatch, navigate, isMainApp }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (code) {
    dispatch(setLoader(true));
    const params = new URLSearchParams();
    const redirect_uri = isMainApp
      ? `${mainAppDomain}/google-signup`
      : `${celebrityDomain}/google-signup`;
    params.append("grant_type", "authorization_code");
    params.append(
      "client_id",
      `${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}`
    );
    params.append("code", code);
    params.append("redirect_uri", redirect_uri);
    googleApi
      .post(`${API_AWS_AUTH_URL}/oauth2/token`, params)
      .then(function (response) {
        const { access_token, refresh_token, id_token } = response?.data || {};
        setAccessToken(access_token);
        setIdToken(id_token);
        setRefreshToken(refresh_token);

        dispatch(setLoader(false));
        navigate(PATHS.HOME);
      })
      .catch(function (error) {
        console.log(error);
        navigate(PATHS.LOGIN);
      });
  }
};
