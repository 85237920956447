import { Box, Typography } from "@mui/material";
import PriceChart from "components/PriceChart/lineChart";
import PriceTitle from "components/PriceTitle";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./GraphBlock.module.scss";

export const GraphBlock = ({ influencerData }) => {
  const { t } = useContext(LocalizationContext);
  const { influencerPrices = [], currencySymbol = "" } = influencerData || {};

  return (
    <Box className={styles.graphBlock}>
      <Box className={styles.graphTitleWrap}>
        <Box className={styles.priceWrap}>
          <Box className={styles.priceContainer}>
            <Typography className={styles.priceWrapTitle} variant="body3">
              {t("default.price")}
            </Typography>
            <Box className={styles.priceBlock}>
              <PriceTitle {...influencerData} />
            </Box>
          </Box>
        </Box>
      </Box>
      {influencerPrices?.length ? (
        <Box className={styles.graph}>
          <PriceChart data={influencerPrices} currencySymbol={currencySymbol} />
        </Box>
      ) : null}
    </Box>
  );
};
