import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getMyConnections,
  getProfile,
  getUserEdition,
  getUserEditionLoader,
  getUsersMe,
  getUsersMeLoading,
  getUserWallet,
} from "redux/selectors/usersSelectors";
import { LocalizationContext } from "services/localizationContext";

import { ANALYTICS_EVENTS } from "../../constants";
import { fetchUserEditions } from "../../redux/usersSlice";
import Analytics from "../../services/Analytics.service";
import { getAppUserPosts } from "../../services/api/feedApi";
import { getInfluencerIdByHandle } from "../../services/influencerApi";
// import { DepositCardsIcon } from "./icons";
import { NewProfile } from "./NewProfile";
import styles from "./NewProfileWrapper.module.scss";

const NewProfileWrapper = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const location = useLocation();

  const me = useSelector(getUsersMe);
  const profile = useSelector(getProfile);
  const meLoader = useSelector(getUsersMeLoading);
  const editions = useSelector(getUserEdition);
  const userWallet = useSelector(getUserWallet);
  const connections = useSelector(getMyConnections);
  const editionLoader = useSelector(getUserEditionLoader);

  const [postsData, setPostsData] = useState({
    posts: {
      left: [],
      right: [],
      all: [],
    },
    total: 0,
  });
  const [postsLoader, setPostsLoader] = useState(false);
  const [postsPage, setPostsPage] = useState(1);

  const sendAnalytics = () => {
    Analytics.page(location.pathname, {
      event: ANALYTICS_EVENTS.VIEW_PROFILE,
      reward_points: me.rewardPoints || 0,
      cards: userWallet?.cardsNumber || 0,
      connections: connections.total,
    });
  };

  const hasMorePosts =
    postsData.total >
    postsData.posts.left.length + postsData.posts.right.length;

  const loadMorePosts = () => {
    setPostsPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (profile.appUserID) {
      setPostsLoader(true);
      getAppUserPosts({
        appUserID: profile.appUserID,
        params: {
          limit: 20,
          page: 1,
        },
      })
        .then((res) => {
          const params = {
            total: res.total,
            posts: {
              left: [],
              right: [],
              all: res.posts,
            },
          };
          res.posts.forEach((item, index) => {
            if (index % 2 === 0) {
              params.posts.left.push(item);
            } else {
              params.posts.right.push(item);
            }
          });
          setPostsData(params);
        })
        .finally(() => setPostsLoader(false));
    }
  }, [profile.appUserID]);

  useEffect(() => {
    if (postsPage > 1) {
      getAppUserPosts({
        appUserID: profile.appUserID,
        params: {
          limit: 20,
          page: postsPage,
        },
      }).then((res) => {
        const posts = {
          left: [],
          right: [],
        };
        res.posts.forEach((item, index) => {
          if (index % 2 === 0) {
            posts.left.push(item);
          } else {
            posts.right.push(item);
          }
        });
        setPostsData((prevState) => ({
          ...prevState,
          posts: {
            left: [...prevState.posts.left, ...posts.left],
            right: [...prevState.posts.right, ...posts.right],
            all: [...prevState.posts.all, ...res.posts],
          },
        }));
      });
    }
  }, [postsPage]);

  useEffect(() => {
    dispatch(fetchUserEditions());
    sendAnalytics();
  }, []);

  return (
    <NewProfile
      title={
        <Box className={styles.header}>
          <Typography variant="h2">{t("default.profilePageTitle")}</Typography>
          {/*<Button variant="outlined" className={styles.headerBtn}>*/}
          {/*  <DepositCardsIcon />*/}
          {/*  <Typography variant="body1">*/}
          {/*    {t("default.depositCardTitle")}*/}
          {/*  </Typography>*/}
          {/*</Button>*/}
        </Box>
      }
      me={me}
      profile={profile}
      loading={meLoader}
      postsData={postsData}
      editions={editions}
      editionLoader={editionLoader}
      loadMorePosts={loadMorePosts}
      hasMorePosts={hasMorePosts}
      postsLoader={postsLoader}
    />
  );
};

export default NewProfileWrapper;
