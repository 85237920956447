import { Box, Button, Typography } from "@mui/material";

import styles from "./EmptyState.module.scss";

export const EmptyState = ({ icon, title, subTitle, buttonText, action }) => {
  const clickHandler = () => {
    action();
  };

  return (
    <Box className={styles.empty}>
      <Box className={styles.emptyIcon}>{icon}</Box>
      <Typography className={styles.title} variant="h5">
        {title}
      </Typography>
      <Typography className={`${styles.subTitle} opacity_07`} variant="body2">
        {subTitle}
      </Typography>
      <Button onClick={clickHandler} variant="contained">
        {buttonText}
      </Button>
    </Box>
  );
};
