import { Box, Typography } from "@mui/material";
import Countdown from "components/Countdown";
import { differenceInSeconds, intervalToDuration, isValid } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

const CountdownDate = ({
  children,
  endDate,
  className,
  onEnd,
  hoursStyling,
  isAbbr = true,
}) => {
  const { timerLocalize: localize } = useContext(LocalizationContext);
  const getFormattedValue = () => {
    if (!endDate) return "";
    const end =
      differenceInSeconds(new Date(endDate), new Date()) > 0
        ? new Date(endDate)
        : new Date();
    const duration = intervalToDuration({
      start: new Date(),
      end,
    });
    const hoursLeft = duration.days * 24 + duration.hours;

    const hoursPart =
      hoursLeft > 0 ? `${hoursLeft}${isAbbr ? localize.hours : ""} : ` : "";
    const minutesPart =
      hoursLeft > 0 || duration.minutes > 0
        ? `${
            duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes
          }${isAbbr ? localize.minutes : ""} : `
        : "00 : ";
    const secondsPart =
      duration.seconds > 0
        ? `${
            duration.seconds < 10 ? `0${duration.seconds}` : duration.seconds
          }${isAbbr ? localize.seconds : ""}`
        : "00";
    return (
      <Box>
        <Box
          component={hoursStyling ? Typography : "span"}
          variant={hoursStyling?.variant}
          color={hoursStyling?.color?.main}
        >
          {hoursPart}
        </Box>
        {minutesPart}
        {secondsPart}
      </Box>
    );
  };

  const onSetTimer = () => {
    setSecondsLeft(differenceInSeconds(new Date(endDate), new Date()));
    setTimeFormatted(getFormattedValue);

    if (onEnd && differenceInSeconds(new Date(endDate), new Date()) <= 0) {
      onEnd();
      setIsEnd(true);
    }
  };
  const [secondsLeft, setSecondsLeft] = useState(
    differenceInSeconds(new Date(endDate), new Date())
  );
  const [timeFormatted, setTimeFormatted] = useState(getFormattedValue);
  const [isEnd, setIsEnd] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!endDate) return;
    let isSubscribed = true;
    isSubscribed &&
      setIsEnd(differenceInSeconds(new Date(endDate), new Date()) <= 0);

    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (isSubscribed) {
      onSetTimer();
    }
    return () => (isSubscribed = false);
  }, [endDate]);

  if (isEnd) return <>- : -</>;

  return (
    <Countdown
      value={secondsLeft}
      onSetValue={onSetTimer}
      className={className}
    >
      {timeFormatted}
      {children}
    </Countdown>
  );
};
const validDate = (props, propName, componentName) => {
  if (!isValid(new Date(props[propName])))
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}. Expected a valid Date.`
    );
};
CountdownDate.propTypes = {
  endDate: validDate,
};

export default CountdownDate;
