import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NoContent } from "components/Feed/EmptyState/NoContent";
import Post from "components/Feed/Post/Post";
import { ModalPostCreator } from "components/ModalPostCreator/ModalPostCreator";
import SystemNewCard from "components/SystemNewCard/SystemNewCard";
import { TutorialTooltip } from "components/TutorialTooltip/TutorialTooltip";
import { PATHS, PLAYING_FEED_VIDEO, TUTORIALS_PROPS } from "constants/index";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "redux/appSlice";
import {
  fetchMorePosts,
  fetchReactions,
  newPost,
  prevPostId as prev,
  resetFeed,
} from "redux/feedSlice";
import { getUserSeenTutorials } from "redux/selectors/usersSelectors";
import { LocalizationContext } from "services/localizationContext";
import { LEVEL_GROUP } from "utils/getFilteredInfluencers";
import useDelayedExecution from "utils/hooks/useDelayedExecution";

import {
  updateUserEntities,
  updateUserProfile,
} from "../../../../redux/usersSlice";
import { AUTO_HIDE_TIMEOUT } from "../../../Post/utils";
import { CommunityInfo } from "../CommunityInfo/CommunityInfo";
import NoRenewalInfo from "../CommunityInfo/NoRenewalInfo";
import { FakePostForm } from "./components/FakePostForm/FakePostForm";
import { ReviewPosts } from "./components/ReviewPosts/ReviewPosts";
import styles from "./feed.module.scss";

export const FeedTab = ({
  influencerId,
  isFollowing,
  community,
  level,
  isAllowedToPost,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isMd = useMediaQuery("(max-width:840px)");

  const { stopExecute, startExecute } = useDelayedExecution(AUTO_HIDE_TIMEOUT);

  const data = useSelector((state) => state.feed.entities);
  const pinnedPost = useSelector((state) => state.feed.pinnedPost);
  const hasMore = useSelector((state) => state.feed.hasMore);
  const loading = useSelector((state) => state.feed.loading);
  const influencerData = useSelector((state) => state.influencer.entities);
  const postInReviewTotal = useSelector(
    (state) => state.feed.hasPostInReviewTotal
  );
  const prevPostId = useSelector((state) => prev(state));
  const appUserID = useSelector((state) => state.users.entities?.appUserID);
  const seenTutorials = useSelector(getUserSeenTutorials);

  const { isSubscribed, subscriptionData } = influencerData || {};
  const noPosts = !loading && !data.length;
  const showNoRenewalWarn =
    isSubscribed &&
    subscriptionData?.isCancelled &&
    influencerData?.community?.subscriptionPrice;
  const systemType = "system-new-card";

  const [isOpenModal, setOpenModal] = useState(false);
  const [isHide, setHide] = useState(true);
  const [isSnackbarCreate, setSnackbarCreate] = useState(false);
  const [loaderPostInReview, setLoaderPostInReview] = useState(false);

  const undoCreate = () => {
    stopExecute();
    setSnackbarCreate(false);
    setLoaderPostInReview(false);
  };

  const createPost = (payload) => {
    if (community?.postsPendingReview) {
      setOpenModal(false);
      setSnackbarCreate(true);
      setLoaderPostInReview(true);
      return startExecute(() =>
        dispatch(newPost(payload))
          .then(() => {
            dispatch(
              setSnackbar({
                message: t("default.newPostSuccess"),
                open: true,
                right: "20px",
                left: "unset",
              })
            );
            setOpenModal(false);
            setLoaderPostInReview(false);
          })
          .catch((e) => {
            setLoaderPostInReview(false);
            dispatch(
              setSnackbar({
                open: true,
                message: e?.message || t("default.postErrorPublish"),
                right: "20px",
                left: "unset",
              })
            );
          })
      );
    } else {
      return dispatch(newPost(payload))
        .then(() => {
          dispatch(
            setSnackbar({
              message: t("default.newPostSuccess"),
              open: true,
              right: "20px",
              left: "unset",
            })
          );
          setOpenModal(false);
        })
        .catch((e) => {
          dispatch(
            setSnackbar({
              open: true,
              message: e?.message || t("default.postErrorPublish"),
              right: "20px",
              left: "unset",
            })
          );
        });
    }
  };

  const pathToProfile = (post) => {
    return appUserID === post?.appUser?.id
      ? PATHS.PROFILE
      : post.appUser?.username
      ? `/public/user/${post.appUser?.username}`
      : null;
  };

  const closeTutorialTooltip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.GROUP_ID_TUTORIAL_1]: true,
      },
    };
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  useEffect(() => {
    sessionStorage.removeItem(PLAYING_FEED_VIDEO);

    return () => {
      dispatch(resetFeed());
    };
  }, []);

  const LoadMore = () => {
    dispatch(
      fetchMorePosts({
        id: influencerId,
        params: { limit: 20, prev: prevPostId },
      })
    )
      .unwrap()
      .catch((err) => {
        console.error("Cannot get influencer feed", err);
      });
  };

  const renderPosts = useMemo(
    () =>
      data.map((item, index) => {
        if (item?.type === systemType) {
          return <SystemNewCard {...item.data} key={item?.id} />;
        } else {
          return pinnedPost?.id !== item?.id ? (
            <Post
              {...item}
              key={item?.id}
              userName={influencerData.name}
              postIndex={index}
              originalMessage={item?.message}
              level={level}
              pathToProfile={pathToProfile(item)}
            />
          ) : null;
        }
      }),
    [data, influencerData.name, pinnedPost?.id]
  );

  return (
    <Box>
      {showNoRenewalWarn && <NoRenewalInfo influencerData={influencerData} />}
      <CommunityInfo
        isFollowing={isFollowing}
        community={community}
        level={level}
      />

      {noPosts ? (
        <NoContent />
      ) : (
        <Box className={styles.postWrapper}>
          <Box className={styles.posts}>
            {isFollowing && level === LEVEL_GROUP && isAllowedToPost ? (
              <TutorialTooltip
                isOpen={!seenTutorials?.[TUTORIALS_PROPS.GROUP_ID_TUTORIAL_1]}
                setOpen={closeTutorialTooltip}
                placement={isMd ? "top" : "left"}
                title={t("default.groupPostFormTutorialTitle")}
                subTitle={t("default.groupPostFormSubTutorialTitle")}
              >
                <FakePostForm setOpenModal={setOpenModal} />
              </TutorialTooltip>
            ) : null}
            <Box className={styles.postWrapperInReview}>
              {loaderPostInReview ? (
                <Box className={styles.loaderSection}>
                  <CircularProgress />
                </Box>
              ) : null}
              <ReviewPosts
                isHide={isHide}
                setHide={setHide}
                isFollowing={isFollowing}
                influencerId={influencerId}
                level={level}
              />
            </Box>
            {postInReviewTotal > 1 && !noPosts ? (
              <Typography variant="textMediumBold" className={styles.feedTitle}>
                {t("default.groupFeedTitle")}
              </Typography>
            ) : null}
            <>
              {pinnedPost && (
                <Post
                  key={pinnedPost.id}
                  {...pinnedPost}
                  pinned
                  userName={influencerData.name}
                  postIndex="-1"
                  originalMessage={pinnedPost.message}
                  level={level}
                  pathToProfile={pathToProfile(pinnedPost)}
                />
              )}
              {renderPosts}
            </>
          </Box>

          {hasMore && (
            <LoadingButton
              loading={loading}
              variant="outlined"
              size="small"
              onClick={LoadMore}
              className={styles.loadMoreBtn}
            >
              {t("discover.loadMore")}
            </LoadingButton>
          )}
        </Box>
      )}
      <ModalPostCreator
        isOpen={isOpenModal}
        setOpenModal={setOpenModal}
        title={t("default.createNewPost")}
        onSubmitHandler={(payload) => createPost(payload)}
        influencerId={influencerId}
      />
      <Snackbar
        open={isSnackbarCreate}
        message={t("default.successNotificationPostForReview")}
        autoHideDuration={AUTO_HIDE_TIMEOUT}
        severity={"success"}
        onClose={() => setSnackbarCreate(false)}
        classes={{ root: styles.snackbar }}
        action={
          <Button className="snackbar-undo-button" onClick={undoCreate}>
            {t("discover.undo")}
            <svg
              width="28"
              height="12"
              viewBox="0 0 28 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 10.75V7.25C27 3.93629 24.3137 1.25 21 1.25H1.5"
                stroke="#111322"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </Button>
        }
      />
    </Box>
  );
};
