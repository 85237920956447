import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import styles from "./ModalChooseSwapCards.module.scss";
import EditionCard from "components/Card/Edition/EditionCard";
import { useIsMobile } from "utils/hooks";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import CollectionsEmptyPlacaholder from "components/CollectionsEmptyPlacaholder";
import { ReactComponent as CheckedIcon } from "assets/svg/success-icon.svg";
import { SWAP_CARDS_LIMIT } from "constants/";

const ModalChooseSwapCards = ({
  open,
  cards,
  userName,
  handleCardClick,
  handleClose,
  selectedCardsIds,
}) => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();

  const handleClick = (id) => {
    handleCardClick(id);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={styles.modalWrap}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: 695,
          background: "#111322",
          outline: "none",
          overflowY: "auto",
        }}
      >
        <Box className={styles.titleWrap}>
          <Typography className={styles.title} variant="h5">
            {t("swap.usersEditionsAvailableForBarter", {
              userName: userName,
            })}
          </Typography>
        </Box>
        {cards?.length > 0 ? (
          <Box className={styles.cardsContainer}>
            {cards?.map((card) => {
              const isSelected = selectedCardsIds.includes(card.id);
              const disabled =
                selectedCardsIds.length >= SWAP_CARDS_LIMIT && !isSelected;
              return (
                <Box
                  className={styles.modalCardWrapper}
                  style={{ pointerEvents: disabled ? "none" : "all" }}
                  key={`swap-${card.id}`}
                  onClick={() => handleClick(card.id)}
                >
                  <EditionCard
                    {...card}
                    className={styles.modalCard}
                    cardImage={card.imageUrl}
                    editionsDropped={card.series}
                    wrapClassName={styles.cardWrap}
                  />
                  {isSelected && (
                    <Box className={styles.overlay}>
                      <Box className={styles.pin}>
                        <CheckedIcon />
                      </Box>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box className={styles.emptyWrap}>
            <CollectionsEmptyPlacaholder
              title={t("swap.userHasNoCardsAvailableForBarter", {
                userName: userName,
              })}
              showBtn={false}
            />
          </Box>
        )}
        <IconButton className={styles.iconClose} onClick={handleClose}>
          <IconClose />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default ModalChooseSwapCards;
