import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./BanxaDisclaimer.module.scss";

export const BanxaDisclaimer = ({
  isOpen,
  setOpen,
  cardName,
  submitHandler,
}) => {
  const { t } = useContext(LocalizationContext);

  const handleClose = () => setOpen(false);

  if (!isOpen) {
    return <></>;
  }

  return (
    <Box className={styles.modal}>
      <Button onClick={handleClose} className={styles.close} variant="text">
        <CloseIcon />
      </Button>
      <Typography className={styles.title} variant="h4_24">
        {t("drops.banxaDisclaimerTitle", { cardName })}
      </Typography>
      <Button
        variant="outlined"
        onClick={() => {
          submitHandler();
          handleClose();
        }}
      >
        {t("drops.yesProceed")}
      </Button>
    </Box>
  );
};
