export const HomeIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7489 12.2266V15.7284C15.7489 16.9791 14.7291 17.9989 13.4784 17.9989H5.51263C4.26196 17.9989 3.24219 16.9791 3.24219 15.7284V12.2266"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M0.835938 9.34337L8.84983 1.32947C9.20579 0.97351 9.79265 0.97351 10.1486 1.32947L18.1625 9.34337"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const DiscoverIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3361 17.0872C12.1837 17.671 10.8803 18 9.5 18C4.80558 18 1 14.1944 1 9.5C1 4.80558 4.80558 1 9.5 1C14.1944 1 18 4.80558 18 9.5C18 11.2331 17.4813 12.845 16.5907 14.1891"
      stroke="white"
      strokeWidth="1.5"
    />
    <path
      d="M7.21377 13.7988L8.08956 8.71978L12.0503 5.42179"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
  </svg>
);

export const MarketIcon = () => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.44833 6.73438H2.31533C1.8038 6.73438 1.39844 7.14939 1.39844 7.65127L1.39844 18.0846C1.39844 18.5961 1.81345 19.0015 2.31533 19.0015H9.67947C10.191 19.0015 10.5964 18.5865 10.5964 18.0846V16.55"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.744 14.7827L6.63085 12.8813C6.13862 12.7462 5.84907 12.2443 5.97454 11.7521L8.67698 1.68553C8.8121 1.19331 9.31398 0.903759 9.80621 1.02923L16.9194 2.93058C17.4116 3.06571 17.7012 3.56759 17.5757 4.05982L14.8829 14.136C14.7381 14.6283 14.2363 14.9178 13.744 14.7827Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.5157 8.06538L10.5312 7.59245L11.6219 7.65036L12.0948 6.65625L12.0369 7.75653L13.0213 8.22945L11.9307 8.17154L11.4578 9.156L11.5157 8.06538Z"
      fill="white"
    />
    <path
      d="M12.567 4.78125L11.3895 7.25205L8.64844 7.10727L11.1192 8.29441L10.9745 11.0355L12.1616 8.56466L14.9026 8.69978L12.4318 7.52229L12.567 4.78125Z"
      fill="white"
    />
  </svg>
);

export const ChallengesIcon = () => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.3713 6.39498L2.8713 5.39498C2.1313 5.09498 1.3613 5.74498 1.5213 6.52498L2.5413 11.455C2.7313 12.385 3.5513 13.045 4.5013 13.045H6.9513"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.6291 6.40279L18.1291 5.40279C18.8691 5.10279 19.6391 5.75279 19.4791 6.53279L18.4591 11.4628C18.2691 12.3928 17.4491 13.0528 16.4991 13.0528H14.2891"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M7.07031 18H13.7503"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M6.63469 12.9519C5.91469 12.0819 5.47469 10.9719 5.47469 9.75187V6.15188C5.47469 5.48188 5.13469 4.84187 4.57469 4.48187C4.47469 4.42187 4.38469 4.35187 4.29469 4.26187C3.99469 3.96187 3.80469 3.54188 3.80469 3.08188C3.80469 2.62188 3.99469 2.20188 4.29469 1.90188C4.60469 1.61188 5.01469 1.42188 5.47469 1.42188H15.4647C15.9247 1.42188 16.3447 1.61187 16.6447 1.91188C16.9447 2.21188 17.1347 2.63188 17.1347 3.09188C17.1347 3.55188 16.9447 3.97187 16.6447 4.27187C16.5647 4.35187 16.4647 4.43187 16.3747 4.49187C15.8047 4.86187 15.4747 5.49187 15.4747 6.16187V9.62187C15.4747 12.3719 13.2247 14.7319 10.4747 14.7519V18.0019"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const ProfileIcon = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M11.4996 9.14024C10.9896 9.65024 10.2813 9.97135 9.4974 9.97135C7.92962 9.97135 6.66406 8.7058 6.66406 7.13802C6.66406 5.57024 7.92962 4.30469 9.4974 4.30469C11.0652 4.30469 12.3307 5.57024 12.3307 7.13802"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M4.77527 16.5845C2.41416 9.97334 6.89082 12.8067 9.49749 12.8067C12.1042 12.8067 16.5808 9.97334 14.2197 16.5845"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
