import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Container, Skeleton, Typography } from "@mui/material";
import { ReactComponent as SwapIcon } from "assets/svg/swap-icon.svg";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { SWAP_STATUSES } from "constants/";
import { SWAP_MODAL_TYPES } from "constants/";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setError } from "redux/appSlice";
import { openModal } from "redux/modalSlice";
import {
  fetchSwapApprove,
  fetchSwapById,
  fetchSwapCancel,
  fetchSwapReject,
} from "redux/swapsSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";

import SwapCard from "../SwapCard";
import SwapModal from "../SwapModal";
import styles from "./SwapInnerPage.module.scss";

const SLOTS_NUMBER = 3;

const SwapInnerPage = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [swapData, setSwapData] = useState(null);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const user = useSelector((state) => state.users.entities);

  useEffect(() => {
    if (!id) return;
    let unmounted = false;

    dispatch(fetchSwapById({ id: id }))
      .unwrap()
      .then((d) => {
        !unmounted && setSwapData(d);
      })
      .catch((err) => {
        console.log("Cannot get barter by id", err);
      });

    return () => {
      unmounted = true;
    };
  }, [id]);

  const getIncomingStatusTitle = (status) => {
    switch (status) {
      case SWAP_STATUSES.pending:
        return (
          <PageSubtitle
            name={userName}
            title={t("swap.hasOfferedYouTheExchange")}
          />
        );
      case SWAP_STATUSES.cancelled:
        return <PageSubtitle title={t("swap.senderCanceledThisRequest")} />;
      case SWAP_STATUSES.approved:
        return <PageSubtitle title={t("swap.youApprovedThisExchange")} />;
      case SWAP_STATUSES.rejected:
        return <PageSubtitle title={t("swap.youRejectedThisExchange")} />;
      default:
        return "";
    }
  };

  const getSentStatusTitle = (status) => {
    switch (status) {
      case SWAP_STATUSES.pending:
        return (
          <PageSubtitle
            name={userName}
            title={t("swap.needsToApproveThisExchange")}
          />
        );
      case SWAP_STATUSES.cancelled:
        return <PageSubtitle title={t("swap.youCanceledThisRequest")} />;
      case SWAP_STATUSES.approved:
        return (
          <PageSubtitle
            name={userName}
            title={t("swap.hasApprovedThisExchange")}
          />
        );
      case SWAP_STATUSES.rejected:
        return (
          <PageSubtitle
            name={userName}
            title={t("swap.hasRejectedThisExchange")}
          />
        );
      default:
        return "";
    }
  };

  const PageSubtitle = ({ title, name }) => (
    <Typography className={styles.pageDesc} component="span" variant="h6">
      {name ? (
        <>
          {t("swap.user")}{" "}
          <Typography className={styles.userName} component="span">
            {userName}{" "}
          </Typography>
        </>
      ) : null}
      {title}
    </Typography>
  );

  const openApproveModal = () => {
    showModal(
      t("swap.offerApproved"),
      t("swap.youApprovedThisExchange"),
      SWAP_MODAL_TYPES.success
    );
  };

  const openRejectModal = () => {
    showModal(
      t("swap.offerRejected"),
      t("swap.youRejectedThisExchange"),
      SWAP_MODAL_TYPES.error
    );
  };

  const openCanceledModal = () => {
    showModal(
      t("swap.offerCanceled"),
      t("swap.youCanceledThisRequest"),
      SWAP_MODAL_TYPES.error
    );
  };

  const showModal = (title, subtitle, type) => {
    dispatch(
      openModal({
        children: (
          <SwapModal
            title={title}
            description={subtitle}
            btnTitle={t("swap.goBack")}
            type={type}
            onBtnClick={() => navigate(-1)}
          />
        ),
      })
    );
  };

  const handleCancel = () => {
    setCancelLoading(true);
    dispatch(fetchSwapCancel({ swapId: id })).then(() => {
      setCancelLoading(false);
      openCanceledModal();
    });
  };

  const handleApprove = () => {
    setApproveLoading(true);
    dispatch(fetchSwapApprove({ swapId: id }))
      .unwrap()
      .then(() => {
        openApproveModal();
      })
      .catch((err) => {
        dispatch(
          setError({
            open: true,
            title: t("default.oops"),
            subtitle: errorResponseMessages(err, t),
          })
        );
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };

  const handleReject = () => {
    setRejectLoading(true);
    dispatch(fetchSwapReject({ swapId: id })).then(() => {
      setRejectLoading(false);
      openRejectModal();
    });
  };

  const CardsSlots = ({ cards }) => {
    return (
      <Box className={styles.slotsWrap}>
        {Array.from(new Array(SLOTS_NUMBER)).map((_, i) => (
          <Box className={styles.card}>
            {cards[i] && (
              <SwapCard
                key={`key-${i}}`}
                disabled
                className={styles.card}
                {...cards[i]}
              />
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const isIncoming = user.appUserID === swapData?.toAppUser?.id;
  const isMyRequest =
    user.appUserID === swapData?.fromAppUser?.id ||
    user.appUserID === swapData?.toAppUser?.id;
  const userName = isIncoming
    ? swapData?.fromAppUser.username
    : swapData?.toAppUser.username;
  const status = swapData?.status;

  const offeredCardsTitle = `${
    !isIncoming && isMyRequest ? t("swap.you") : swapData?.fromAppUser?.username
  } ${t("swap.offered")}`;
  const featuredCardTitle = `${
    isIncoming ? t("swap.you") : swapData?.toAppUser?.username
  } ${t("swap.offered")}`;
  const title = isMyRequest
    ? isIncoming
      ? t("swap.incomingBarter")
      : t("swap.sentBarter")
    : t("swap.barterRequest");
  return (
    <Container className={styles.container}>
      <Box className={styles.header}>
        <BackBtn replace={false} className={styles.backBtn} title={title} />
        {swapData ? (
          <Box className={styles.pageTitleWrap}>
            <Typography className={styles.pageDesc} variant="h6">
              {isMyRequest
                ? isIncoming
                  ? getIncomingStatusTitle(status)
                  : getSentStatusTitle(status)
                : ""}
            </Typography>
          </Box>
        ) : (
          <Skeleton className={styles.skeletonTitle} variant="rectangular" />
        )}
      </Box>
      <Box className={`${styles.content} ${styles[status]}`}>
        <Box className={styles.featuredCardWrap}>
          {swapData ? (
            <Typography className={styles.title} variant="h6">
              {featuredCardTitle}
            </Typography>
          ) : (
            <Skeleton
              className={styles.skeletonSubtitle}
              variant="rectangular"
            />
          )}
          <CardsSlots cards={swapData?.requesting || []} />
        </Box>
        <Box className={styles.iconWrap}>
          <SwapIcon />
        </Box>
        <Box className={styles.offeredWrap}>
          {swapData ? (
            <Typography className={styles.title} variant="h6">
              {offeredCardsTitle}
            </Typography>
          ) : (
            <Skeleton
              className={styles.skeletonSubtitle}
              variant="rectangular"
            />
          )}
          <CardsSlots cards={swapData?.offering || []} />
        </Box>
      </Box>
      <Box className={styles.btnsWrap}>
        {isMyRequest && isIncoming && status === SWAP_STATUSES.pending && (
          <>
            <LoadingButton
              variant="outlined"
              className={`${styles.btn} ${styles.rejectBtn}`}
              onClick={handleReject}
              loading={rejectLoading}
            >
              {t("swap.reject")}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              className={`${styles.btn} ${styles.approveBtn}`}
              onClick={handleApprove}
              loading={approveLoading}
            >
              {t("swap.approve")}
            </LoadingButton>
          </>
        )}
        {isMyRequest && !isIncoming && status === SWAP_STATUSES.pending && (
          <LoadingButton
            variant="outlined"
            className={`${styles.btn} ${styles.cancelBtn}`}
            onClick={handleCancel}
            loading={cancelLoading}
          >
            {t("swap.cancelBarterRequest")}
          </LoadingButton>
        )}
      </Box>
    </Container>
  );
};

export default SwapInnerPage;
