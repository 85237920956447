import { MEDIA_TYPES, MEDIA_URLS_PROP } from "constants/index";
import { api } from "services/api.service";

const API_URL = process.env.REACT_APP_API_HOST;

export const generatePostS3Url = async (data) => {
  const params = {
    ...data,
    uploadType: "userpicOriginal",
  };
  try {
    return api.post(`${API_URL}/posts/generate-upload-s3-url`, params);
  } catch (error) {
    console.error(error);
  }
};

const getConvertedFiles = async ({ blobFiles, mediaType, influencerId }) => {
  const promises = blobFiles.map(async (item) => {
    const s3Response = await generatePostS3Url({
      influencerId,
      type: mediaType,
      ContentType: item.file.type,
      extension: item.file.type.split("/")[1],
    });
    await fetch(s3Response.data.presignedUploadUrl, {
      method: "PUT",
      body: item.file,
    });

    return s3Response.data.presignedUrl;
  });

  const result = await Promise.all(promises);
  return result;
};

const getParsedFiles = (files, urlType) => {
  return files.reduce(
    (prev, acc) => {
      if (acc.file) {
        prev.blobFiles.push(acc);
      } else {
        prev.urls.push(acc[urlType]);
      }
      return prev;
    },
    {
      blobFiles: [],
      urls: [],
    }
  );
};

const getConvertedPostPayload = ({
  message,
  allowComments,
  type,
  files,
  youtubeUrl,
  createdAt,
}) => {
  return {
    message,
    allowComments,
    type: type || MEDIA_TYPES.TEXT,
    ...(type === MEDIA_TYPES.IMAGE && {
      images: files.map((imageUrl, index) => ({
        index,
        imageUrl,
      })),
    }),
    ...(type === MEDIA_TYPES.VIDEO && {
      videos: [
        {
          index: 0,
          videoUrl: files[0],
        },
      ],
    }),
    ...(type === MEDIA_TYPES.AUDIO && {
      audios: files.map((audioUrl, index) => ({
        index,
        audioUrl,
      })),
    }),
    ...(youtubeUrl && { youtubeUrl }),
    ...(createdAt && { createdAt }),
  };
};

export const createPost = async ({
  message,
  allowComments,
  files = [],
  mediaType,
  youtubeUrl,
  influencerId,
}) => {
  try {
    const result = await getConvertedFiles({
      blobFiles: files,
      mediaType,
      influencerId,
    });
    const payload = getConvertedPostPayload({
      message,
      allowComments,
      type: mediaType,
      files: result,
      youtubeUrl,
    });

    const response = await api.post(`${API_URL}/posts`, {
      influencerId,
      ...payload,
    });

    return response.data;
  } catch (e) {
    throw new Error(e.data.message);
  }
};

export const updatePost = async ({
  postId,
  message,
  allowComments,
  files,
  mediaType,
  youtubeUrl,
  createdAt,
  influencerId,
}) => {
  try {
    const urlType =
      mediaType === MEDIA_TYPES.IMAGE
        ? MEDIA_URLS_PROP.imageUrl
        : mediaType === MEDIA_TYPES.AUDIO
        ? MEDIA_URLS_PROP.audioUrl
        : MEDIA_TYPES.VIDEO
        ? MEDIA_URLS_PROP.videoUrl
        : null;
    const { blobFiles, urls } = getParsedFiles(files, urlType);
    const result = await getConvertedFiles({
      blobFiles,
      mediaType,
      influencerId,
    });
    const allFiles = [...result, ...urls];

    const payload = getConvertedPostPayload({
      message,
      allowComments,
      type: mediaType,
      files: allFiles,
      youtubeUrl,
      createdAt,
    });
    const response = await api.put(`${API_URL}/posts/${postId}`, {
      influencerId,
      ...payload,
    });
    return response.data;
  } catch (e) {
    throw new Error(e.data[0].message);
  }
};

export const deletePost = async ({ postId }) => {
  try {
    const response = await api.delete(`${API_URL}/posts/${postId}`);
    return response.data;
  } catch (e) {
    throw new Error(e.data[0].message);
  }
};

export const getPosts = async ({ id, params, friendId }) => {
  const url = id
    ? `${API_URL}/influencer/${id}/posts`
    : friendId
    ? `${API_URL}/discover/posts?friendId=${friendId}`
    : `${API_URL}/discover/posts`;

  const response = await api.get(url, {
    params,
  });

  return response.data;
};

export const getAppUserPosts = async ({ appUserID, params }) => {
  const url = `${API_URL}/app-users/${appUserID}/posts`;

  const response = await api.get(url, {
    params,
  });

  return response.data;
};

export const postTranslate = (params) => {
  return api.post(`${API_URL}/translate`, params).then((res) => res.data);
};

export const postComment = ({ id, params }) => {
  try {
    return api.post(`${API_URL}/posts/${id}/comments`, params);
  } catch (err) {
    console.log(err);
  }
};

export const postVote = ({ id, params }) => {
  try {
    return api.post(`${API_URL}/posts/${id}/vote`, params);
  } catch (err) {
    console.log(err);
  }
};

export const getComments = async ({ id, params, isAuth }) => {
  let url = `${API_URL}/posts/${id}/comments`;
  if (!isAuth) {
    url = `${API_URL}/public/posts/${id}/comments`;
  }
  const response = await api.get(url, {
    params: params,
  });

  return response.data;
};

export const deleteComment = async ({ id, commentId }) => {
  const response = await api.delete(
    `${API_URL}/posts/${id}/comments/${commentId}`
  );

  return response.data;
};

export const updateComment = async ({ id, commentId, message }) => {
  const response = await api.patch(
    `${API_URL}/posts/${id}/comments/${commentId}`,
    { message }
  );

  return response.data;
};

export const getPost = async (id, isAuth) => {
  let url = `${API_URL}/posts/${id}`;
  if (!isAuth) {
    url = `${API_URL}/public/posts/${id}`;
  }
  const response = await api.get(url);
  return response.data;
};

export const getReactions = async () => {
  try {
    const response = await api.get(`${API_URL}/reactions`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const sendPostReaction = async (postId, reactionId) => {
  try {
    return api.post(`${API_URL}/posts/${postId}/reactions`, { reactionId });
  } catch (e) {
    console.error(e);
  }
};

export const deletePostReaction = async (postId, reactionId) => {
  try {
    const response = await api.delete(
      `${API_URL}/posts/${postId}/reactions/${reactionId}`
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const postCommentLike = async (id) => {
  try {
    const response = await api.post(`${API_URL}/comments/${id}/like`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
export const deleteCommentLike = async (id) => {
  try {
    const response = await api.delete(`${API_URL}/comments/${id}/like`);
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const postReport = async ({ type, recordId, contentType }) => {
  try {
    const response = await api.post(`${API_URL}/reports`, {
      contentType,
      type,
      recordId,
    });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
