import {
  Box,
  Button,
  Container,
  IconButton,
  Popover,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as AddFriendIcon } from "assets/svg/add-friend-icon.svg";
import { ReactComponent as BlockedIcon } from "assets/svg/blocked-icon.svg";
import { ReactComponent as CheckedIcon } from "assets/svg/checked-icon.svg";
import { ReactComponent as ShareIcon } from "assets/svg/copy-share-icon.svg";
import { ReactComponent as EmptyPlaceholder } from "assets/svg/empty-tabs-placeholder.svg";
import { ReactComponent as FriendIcon } from "assets/svg/friend-icon.svg";
import { ReactComponent as Kebab } from "assets/svg/kebab-icon.svg";
import { ReactComponent as MessageIcon } from "assets/svg/message-icon.svg";
import EditionCard from "components/Card/Edition/EditionCard";
import UnfriendModal from "components/Modal/UnfriendModal";
import ProfileConnection from "components/ProfileConnection";
import { ViewAll } from "components/ViewAll/ViewAll";
import {
  CHAT_CATEGORIES,
  CONNECTION_REQUEST_TYPE,
  CONNECTIONS_LOAD_LIMIT,
  MODAL_TYPE,
  PATHS,
} from "constants/index";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { setLoader, setSnackbar } from "redux/appSlice";
import { setSelectedUser } from "redux/messengerSlice";
import { closeModal, openModal } from "redux/modalSlice";
import {
  acceptFriendRequest,
  blockUser,
  fetchBlacklist,
  fetchFollowingByUserId,
  fetchFriendsById,
  fetchPublicUser,
  fetchUserConnections,
  fetchUserFriendRequests,
  rejectFriendRequest,
  searchUser,
  selectConnectionById,
  sendConnectionRequest,
  unblockUser,
  unfriendUser,
} from "redux/usersSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { getInfluencerProfileLink } from "utils";
import { useIsMobile } from "utils/hooks";
import { getUsernameFromPathname } from "utils/usernameHelper";

import styles from "./user-profile.module.scss";

const SNACKBAR_DURATION = 4000;

const PublicUserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const publicUser = useSelector((state) => state.users.publicUser.entities);
  const userMe = useSelector((state) => state.users.me.entities);
  const friends = useSelector((state) => state.users.connections.friends);
  const following = useSelector((state) => state.users.following.entities);

  const connection = useSelector((state) =>
    selectConnectionById(state, publicUser?.id)
  );

  const publicUserIsLoading = useSelector(
    (state) => state.users.publicUser.loading
  );

  const editions = publicUser?.cards;
  const isMobile = useIsMobile();
  const location = useLocation();
  const params = useParams();
  const username =
    params?.username || getUsernameFromPathname(location.pathname);

  const [trophyCard, setTrophyCard] = useState(null);

  const [outgoingFriendRequests, setOutgoingFriendRequests] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);

  const [blacklist, setBlacklist] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const actionsMenuIsOpen = Boolean(anchorEl);

  const isMyProfile = publicUser?.id === userMe?.id;
  const isTabletWidth = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const profileFriendsLimit = (() => {
    if (isTabletWidth && !isMobileWidth) {
      return 6;
    }
    if (isMobileWidth) {
      return 3;
    }
    return 5;
  })();

  const isFriend = !!connection;
  const isSent =
    outgoingFriendRequests.findIndex((r) => r.friend.id === publicUser.id) >= 0;

  const isBlocked = useMemo(
    () => blacklist?.findIndex((user) => user.id === publicUser.id) >= 0,
    [blacklist, publicUser.id]
  );

  useEffect(() => {
    if (!username) {
      return;
    }

    dispatch(searchUser(username))
      .unwrap()
      .then((user) => {
        dispatch(fetchPublicUser(user?.id));
      })
      .catch((err) => {
        console.log("Cannot get public user", err);
      });
  }, [username]);

  useEffect(() => {
    setTrophyCard(
      editions?.find((card) => card.imageUrl === publicUser?.trophyCardURL)
    );
  }, [editions, publicUser?.trophyCardURL]);

  useEffect(() => {
    let unmounted = false;
    dispatch(
      fetchUserConnections({
        limit: CONNECTIONS_LOAD_LIMIT,
        page: 1,
      })
    );

    if (!_.isEmpty(publicUser) || publicUser?.id) {
      dispatch(fetchFriendsById({ id: publicUser?.id }));
      dispatch(fetchFollowingByUserId({ id: publicUser?.id }));
    }
    dispatch(
      fetchUserFriendRequests({ type: CONNECTION_REQUEST_TYPE.incoming })
    )
      .unwrap()
      .then((d) => !unmounted && setFriendRequests(d));
    dispatch(
      fetchUserFriendRequests({ type: CONNECTION_REQUEST_TYPE.outgoing })
    )
      .unwrap()
      .then((d) => !unmounted && setOutgoingFriendRequests(d));

    dispatch(fetchBlacklist())
      .unwrap()
      .then((d) => !unmounted && setBlacklist(d.blacklist || []));

    return () => {
      unmounted = true;
    };
  }, [publicUser?.id]);

  const publicLink = `${window.origin}/public/user/${publicUser.username}`;

  const copyPublicShareLink = () => {
    dispatch(
      setSnackbar({
        open: true,
        message: t("drops.copied"),
      })
    );
    navigator.clipboard.writeText(publicLink);
  };

  const handleCollectionViewAll = useCallback(() => {
    navigate("collections/passes", {
      state: {
        id: publicUser?.id,
        username: publicUser.username,
      },
    });
  }, [publicUser?.id, publicUser.username]);

  const handleFriendsViewAll = useCallback(
    (shouldActivateFollowingTab) => {
      navigate(`/public/user/${publicUser.username}/connections`, {
        state: {
          isPublicUser: true,
          id: publicUser?.id,
          activeTab: shouldActivateFollowingTab ? 1 : 0,
        },
      });
    },
    [publicUser.username]
  );

  const swapEdition = (cardId) => {
    navigate("/swaps/create", {
      state: { cardId: cardId, userId: publicUser.id },
    });
  };

  const handleSendRequest = async () => {
    if (_.isEmpty(publicUser) || !publicUser?.id) return;
    dispatch(setLoader(true));
    await dispatch(sendConnectionRequest(publicUser?.id));
    dispatch(
      fetchUserFriendRequests({ type: CONNECTION_REQUEST_TYPE.outgoing })
    )
      .unwrap()
      .then((d) => setOutgoingFriendRequests(d));
    dispatch(setLoader(false));
  };

  const handleUnfriend = () => {
    if (!connection) return;

    const handleConfirm = () => {
      dispatch(setLoader(true));
      dispatch(unfriendUser(connection.id));

      dispatch(setLoader(false));
      dispatch(closeModal());
    };

    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={`Do you want to unfriend ${connection.friend.username}?`}
            description={`If you unfriend ${connection.friend.username} now you will have to send request to the user to become friends again`}
            onConfirm={handleConfirm}
            onCancel={() => dispatch(closeModal())}
          />
        ),
      })
    );
  };

  const handleBlock = () => {
    dispatch(setLoader(true));

    dispatch(closeModal());

    dispatch(blockUser(publicUser.id))
      .unwrap()
      .then(() =>
        dispatch(fetchBlacklist())
          .unwrap()
          .then((d) => {
            setBlacklist(d.blacklist || []);

            dispatch(
              setSnackbar({
                open: true,
                autoHideDuration: SNACKBAR_DURATION,
                message: t("account.youBlocked", {
                  username: publicUser.username,
                }),
              })
            );
          })
          .finally(() => dispatch(setLoader(false)))
      );
  };

  const handleUnblock = () => {
    dispatch(setLoader(true));
    dispatch(unblockUser(publicUser.id))
      .unwrap()
      .then(() =>
        dispatch(fetchBlacklist())
          .unwrap()
          .then((d) => {
            setBlacklist(d.blacklist || []);

            dispatch(setLoader(false));
          })
      );
  };

  const showReportModal = (e) => {
    handleClose(e);

    dispatch(
      openModal({
        type: MODAL_TYPE.REPORT_MODAL,
        recordId: publicUser?.id,
        contentType: "user",
      })
    );
  };

  const showBlockModal = (e) => {
    handleClose(e);

    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={t("account.blockModalTitle")}
            description={t("account.blockModalText")}
            onConfirm={handleBlock}
            onCancel={() => dispatch(closeModal())}
            confirmBtnText={t("account.blockUser")}
            cancelBtnText={t("default.goBack")}
          />
        ),
      })
    );
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation(event);
    setAnchorEl(null);
  };

  const onUnblockClick = (event) => {
    handleClose(event);
    handleUnblock();
  };

  const goToMessenger = () => {
    dispatch(setSelectedUser(publicUser));
    navigate({
      pathname: PATHS.CHAT_DIRECT.replace(":userId", publicUser.id),
      search: createSearchParams({
        category: CHAT_CATEGORIES.GENERAL,
      }).toString(),
    });
  };

  const handleAcceptRequest = useCallback(
    (connectionId) => {
      if (!connectionId) return;

      dispatch(setLoader(true));
      dispatch(acceptFriendRequest(connectionId)).then(() => {
        dispatch(
          fetchUserConnections({
            limit: CONNECTIONS_LOAD_LIMIT,
            page: 1,
          })
        );
        setFriendRequests([
          ...friendRequests.filter((c) => c.id !== connectionId),
        ]);
      });
      dispatch(setLoader(false));
    },
    [friendRequests]
  );

  const handleRejectRequest = useCallback(
    (connectionId) => {
      if (!connectionId) return;

      dispatch(setLoader(true));
      dispatch(rejectFriendRequest(connectionId)).then(() => {
        setFriendRequests([
          ...friendRequests.filter((c) => c.id !== connectionId),
        ]);
      });
      dispatch(setLoader(false));
    },
    [friendRequests]
  );

  const renderFriendButtons = () => {
    const isRequest = friendRequests?.find(
      (request) => request.friend.id === publicUser.id
    );
    if (isRequest) {
      return (
        <Box className={styles.requestFriendButtons}>
          <Button
            className={styles.requestBtn}
            onClick={() => handleRejectRequest(isRequest.id)}
            variant="outlined"
          >
            <Typography variant="body1" className={styles.friendTitle}>
              {t("account.remove")}
            </Typography>
          </Button>

          <Button
            variant="contained"
            className={`${styles.requestBtn} ${styles.acceptRequestBtn}`}
            onClick={() => handleAcceptRequest(isRequest.id)}
          >
            <Typography variant="body1" className={styles.friendTitle}>
              {t("account.acceptAsFriend")}
            </Typography>
          </Button>
        </Box>
      );
    }
    return (
      <IconButton className={styles.addFriendBtn} onClick={handleSendRequest}>
        <AddFriendIcon className={styles.friendIcon} />
        <Typography variant="body1" className={styles.friendTitle}>
          {t("account.addFriend")}
        </Typography>
      </IconButton>
    );
  };

  const FriendBlock = () =>
    !!userMe?.id &&
    !isMyProfile && (
      <>
        {isBlocked ? (
          <Button
            variant="contained"
            className={styles.unblockBtn}
            onClick={handleUnblock}
          >
            {t("account.unblock")}
          </Button>
        ) : (
          <Box className={styles.friendBlock}>
            {isFriend ? (
              <Box className={styles.btnsWrap}>
                <IconButton
                  className={`${styles.smallBtn} ${styles.outlined}`}
                  onClick={handleUnfriend}
                >
                  <FriendIcon className={styles.friendIcon} />
                  <Typography variant="body1" className={styles.friendTitle}>
                    <Box className={styles.friendTitleText}>
                      {t("account.friend")}
                    </Box>
                    <Box className={styles.friendTitleTextHover}>
                      {t("account.unfriend")}
                    </Box>
                  </Typography>
                </IconButton>
                <IconButton
                  className={`${styles.smallBtn} ${styles.outlined}`}
                  onClick={goToMessenger}
                  style={{ textTransform: "capitalize" }}
                >
                  <MessageIcon className={styles.friendIcon} />
                  <Typography variant="body1" className={styles.friendTitle}>
                    {t("messages.message")}
                  </Typography>
                </IconButton>
              </Box>
            ) : !isSent ? (
              renderFriendButtons()
            ) : (
              <Box className={styles.friendRequestSent}>
                <CheckedIcon className={styles.friendRequestSentIcon} />
                <Typography
                  className={styles.friendRequestSentText}
                  variant="body1"
                >
                  {t("account.friendRequestWasSent")}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </>
    );
  const navToInfluencerProfile = (item) => {
    const influencerProfileLink = getInfluencerProfileLink({
      id: item.influencer.id,
      handle: item.influencer.handle,
    });
    navigate(influencerProfileLink, {
      state: { from: location.pathname },
    });
  };
  return (
    <Container className={styles.container}>
      <Box className={styles.content}>
        <Box className={styles.leftContainer}>
          {publicUserIsLoading ? (
            <Skeleton
              component="div"
              className={`${styles.profileContainer} ${styles.skeletonCard}`}
              variant="rectangular"
              sx={{ height: isMobile ? 85 : 225 }}
            />
          ) : (
            <Box className={styles.profileContainer}>
              <Box sx={{ position: "relative" }}>
                <img
                  className={styles.profileImage}
                  src={publicUser?.profileImageUrl}
                  alt={publicUser?.username}
                />
              </Box>
              <Box className={styles.rightBlock}>
                <Typography
                  className={styles.textUsername}
                  variant="textMediumBold"
                >
                  {publicUser?.username}
                </Typography>
                <Box className={styles.actionsWrap}>
                  <Box className={styles.copyInputWrap}>
                    <TextField
                      className={styles.copyLinkInput}
                      value={publicUser.username ? publicLink : ""}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <IconButton
                      className={`${styles.copyBtn} ${styles.btnTitle}`}
                      onClick={copyPublicShareLink}
                    >
                      <ShareIcon className={styles.copyBtnIcon} />
                    </IconButton>
                  </Box>
                  {!isMyProfile && isAuthenticated() ? (
                    <Box className={styles.actionsBtnWrap}>
                      <IconButton
                        className={styles.actionsBtn}
                        onClick={handleClick}
                      >
                        <Kebab width={24} height={24} />
                      </IconButton>
                      <Popover
                        id={"user-actions-popover"}
                        open={actionsMenuIsOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        className={styles.optsPaper}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {isBlocked ? (
                          <Button
                            onClick={onUnblockClick}
                            variant="text"
                            className={styles.popoverBtn}
                          >
                            <Typography
                              variant="body2"
                              className={styles.popoverBtnText}
                            >
                              {t("account.unblock")}
                            </Typography>
                          </Button>
                        ) : (
                          <Button
                            onClick={showBlockModal}
                            variant="text"
                            className={styles.popoverBtn}
                          >
                            <Typography
                              variant="body2"
                              className={`${styles.popoverBtnText} ${styles.destructiveBtnText}`}
                            >
                              {t("account.block")}
                            </Typography>
                          </Button>
                        )}
                        <Button
                          onClick={showReportModal}
                          variant="text"
                          className={styles.popoverBtn}
                        >
                          <Typography
                            variant="body2"
                            className={`${styles.popoverBtnText} ${styles.destructiveBtnText}`}
                          >
                            {t("account.report")}
                          </Typography>
                        </Button>
                      </Popover>
                    </Box>
                  ) : null}
                </Box>
                {!isMobile && <FriendBlock />}
              </Box>
            </Box>
          )}
          {isMobile &&
            (publicUserIsLoading ? (
              <Skeleton
                component="div"
                className={`${styles.friendsBlock} ${styles.skeletonCard}`}
                variant="rectangular"
                sx={{ height: 39 }}
              />
            ) : (
              <FriendBlock />
            ))}
          {publicUserIsLoading ? (
            <Skeleton
              component="div"
              className={`${styles.friendsBlock} ${styles.skeletonCard}`}
              variant="rectangular"
              sx={{ height: isMobile ? 179 : 196 }}
            />
          ) : (
            !isBlocked &&
            (!!friends?.rows?.length || !!following.length) && (
              <Box className={styles.friendsBlock}>
                <Box className={styles.friendsBlockHeader}>
                  <Typography className={styles.friendsBlockTitle} variant="h4">
                    {!!friends.length
                      ? t("account.connections")
                      : t("account.following")}
                  </Typography>
                  <ViewAll
                    handler={() =>
                      handleFriendsViewAll(
                        !friends.length && !!following.length
                      )
                    }
                  />
                </Box>
                <Box className={styles.friendsList}>
                  {!!friends.length && !!friends?.rows?.length ? (
                    friends?.rows
                      .slice(0, profileFriendsLimit)
                      .map((connection) => (
                        <ProfileConnection
                          key={connection.id}
                          connection={connection}
                        />
                      ))
                  ) : (
                    <>
                      {!!following.length &&
                        following.map((item) => (
                          <Box
                            key={item.id}
                            className={styles.friend}
                            onClick={() => navToInfluencerProfile(item)}
                          >
                            <Box className={styles.friendImageWrap}>
                              <img
                                className={styles.friendImage}
                                src={item.influencer.profileImageUrl}
                                alt={item.influencer.name}
                              />
                            </Box>
                            <Typography
                              className={styles.friendName}
                              variant="body1"
                            >
                              {item.influencer.name}
                            </Typography>
                          </Box>
                        ))}
                    </>
                  )}
                </Box>
              </Box>
            )
          )}
          {publicUserIsLoading ? (
            <Skeleton
              component="div"
              className={`${styles.statisticBlock} ${styles.skeletonCard}`}
              variant="rectangular"
              sx={{ height: isMobile ? 179 : 196 }}
            />
          ) : (
            <Box className={styles.statisticBlock}>
              <Typography className={styles.statisticBlockTitle} variant="h4">
                {t("account.statistics")}
              </Typography>
              <Box className={styles.statisticBlockRow}>
                <Typography className={styles.statisticBlockLabel} variant="h6">
                  {t("account.cardsCollected")}
                </Typography>
                <Typography className={styles.statisticBlockValue} variant="h6">
                  {publicUser?.stats?.cards}
                </Typography>
              </Box>
              <Box className={styles.statisticBlockRow}>
                <Typography className={styles.statisticBlockLabel} variant="h6">
                  {t("account.appPoints")}
                </Typography>
                <Typography className={styles.statisticBlockValue} variant="h6">
                  {publicUser?.stats?.rewardPoints}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {publicUserIsLoading ? (
          <Skeleton
            component="div"
            className={`${styles.rightContainer} ${styles.skeletonCard}`}
            variant="rectangular"
            sx={{ height: 797 }}
          />
        ) : (
          <Box className={styles.rightContainer}>
            {isBlocked ? (
              <Box className={styles.blockedBlock}>
                <BlockedIcon className={styles.blockedIcon} />
                <Typography variant="h5" className={styles.blockedText}>
                  {t("account.youBlockedThisUser")}
                </Typography>
              </Box>
            ) : (
              <Box className={styles.collectionContainer}>
                <Box className={styles.collectionHeader}>
                  <Typography variant="h4" className={styles.textCapitalize}>
                    {publicUser?.username
                      ? t("account.collections", {
                          username: publicUser.username,
                        })
                      : ""}
                  </Typography>
                  <ViewAll handler={handleCollectionViewAll} />
                </Box>

                <Box className={styles.collections}>
                  <Typography
                    className={styles.collectionsTitle}
                    variant="textNormal"
                  >
                    {t("editions")}
                  </Typography>
                  {editions?.length > 0 ? (
                    <Box>
                      <Box className={styles.cards}>
                        {trophyCard && (
                          <EditionCard
                            key={`edition-${trophyCard.id}`}
                            className={styles.editionCard}
                            {...trophyCard}
                            id={trophyCard.id}
                            cardImage={trophyCard.imageUrl}
                            isTrophy
                            editionsDropped={trophyCard.series}
                            swapEditionCb={() => swapEdition(trophyCard.id)}
                            showOptsBtn
                            isPublicUser
                            barterDisabled={
                              isMyProfile ||
                              !trophyCard?.isSwappable ||
                              !trophyCard?.availableForBarter
                            }
                            isSwappable={trophyCard?.isSwappable}
                            inBarter={false}
                          />
                        )}

                        {editions
                          .filter((card) => card.id !== trophyCard?.id)
                          .slice(0, !!trophyCard ? 3 : 4)
                          .map((card) => (
                            <EditionCard
                              className={styles.editionCard}
                              key={`edition-${card.id}`}
                              {...card}
                              id={card.id}
                              cardImage={card.imageUrl}
                              editionsDropped={card.series}
                              onClick={() => navigate(`/pass/${card.id}`)}
                              swapEditionCb={() => swapEdition(card.id)}
                              showOptsBtn
                              isSwappable={card?.isSwappable}
                              isPublicUser
                              barterDisabled={
                                isMyProfile ||
                                !card?.isSwappable ||
                                !card?.availableForBarter
                              }
                              inBarter={false}
                            />
                          ))}
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      className={[
                        styles.collections,
                        styles.empty,
                        styles.emptyEditions,
                      ]}
                    >
                      <EmptyPlaceholder />
                      <Typography
                        className={styles.placeholderText}
                        variant="h5"
                      >
                        {t("account.noEditions")}
                      </Typography>
                      <Button component={Link} variant="white" to="/discover">
                        {t("account.beginCollectingEditions")}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};
export default PublicUserProfile;
