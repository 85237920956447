import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { LocalizationContext } from "services/localizationContext";
import styles from "./SwapCard.module.scss";
import { ReactComponent as CheckedIcon } from "assets/svg/success-icon.svg";

export const SwapCard = ({
  imageUrl,
  influencerName,
  series,
  className,
  isSmall,
  isSelected,
  disabled,
  onClick,
  assetHoldings,
  showAssetHoldings,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box
      onClick={onClick}
      className={`${styles.card} ${isSmall ? styles.smallCard : ""} ${
        disabled ? styles.disabled : ""
      } ${className ? className : ""}`}
    >
      <img className={styles.image} src={imageUrl} alt={influencerName} />
      <Box className={styles.imageOverlay} />
      <Box className={styles.titleWrap}>
        <Typography variant="body2" className={styles.edition}>
          {t("default.editionNumber", { number: series })}
        </Typography>
        <Typography
          variant={isSmall ? "textNormal" : "body2"}
          component="span"
          className={styles.name}
        >
          {influencerName}
        </Typography>
      </Box>
      {isSelected && (
        <Box className={styles.overlay}>
          <Box className={styles.pin}>
            <CheckedIcon />
          </Box>
        </Box>
      )}
      {!isSelected && showAssetHoldings && (
        <Box className={styles.pin}>
          <Typography variant="body2" className={styles.assetHoldingsText}>
            {assetHoldings}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SwapCard;
