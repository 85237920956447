import { Box, Button, IconButton, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import { ReactComponent as PencilIcon } from "assets/svg/icon-pencil.svg";
import { ReactComponent as UploadIcon } from "assets/svg/upload-icon.svg";
import { Level } from "components/Level/Level";
import ModalUploadPhoto from "components/Modal/ModalUploadPhoto";
import { ShareModal } from "components/Modal/ShareModal/ShareModal";
import { ACCOUNT_STATES, celebrityDomain, PATHS } from "constants/index";
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import { openModal } from "redux/modalSlice";
import {
  getUserWallet,
  getUserWalletLoading,
} from "redux/selectors/usersSelectors";
import { updateUserProfile, uploadPhoto } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import { CustomTooltip } from "../../../../components/CustomTooltip/CustomTooltip";
import styles from "./ProfileInfo.module.scss";

const getActiveColor = (value) => {
  let color = "#fff";
  if (value >= 6 && value <= 10) {
    color = "#70e8fc";
  } else if (value >= 11 && value <= 20) {
    color = "#27e7c4";
  } else if (value >= 21 && value <= 40) {
    color = "#f1fc70";
  } else if (value > 40) {
    color = "#FF707E";
  }
  return color;
};

export const ProfileInfo = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isS = useMediaQuery("(max-width:960px)");
  const { t } = useContext(LocalizationContext);

  const levelColor = getActiveColor(data.level);
  const userWallet = useSelector(getUserWallet);
  const userWalletLoading = useSelector(getUserWalletLoading);

  const [openUploadPhoto, setOpenUploadPhoto] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleNavigate = () => {
    if (data?.appUser?.isInfluencer) {
      window.open(`${celebrityDomain}/profile`);
    } else {
      navigate(PATHS.SETTINGS_ACCOUNTS, {
        state: {
          accountState: ACCOUNT_STATES.username,
          from: location.pathname,
        },
      });
    }
  };

  const goToCompleteProfile = () => {
    localStorage.setItem("completeProfileBackRoute", location.pathname);
    navigate(PATHS.COMPLETE_PROFILE);
  };

  const handleUploadPhotoOpen = () => {
    if (data?.appUser?.isInfluencer) {
      window.open(`${celebrityDomain}/profile`);
    } else {
      setOpenUploadPhoto(true);
    }
  };

  const handleUploadPhotoClose = () => setOpenUploadPhoto(false);

  const handleUploadPhoto = (payload) => {
    dispatch(uploadPhoto(payload)).then(() => {
      handleUploadPhotoClose();
    });
  };

  const handleRemovePhoto = () => {
    dispatch(setLoader(true));
    dispatch(
      updateUserProfile({ userPicCroppedUrl: null, userPicOriginalUrl: null })
    )
      .then(() => setOpenUploadPhoto(false))
      .finally(() => dispatch(setLoader(false)));
  };

  const handlerNavigateToBadges = () => {
    if (data.badgesAmount) {
      navigate(PATHS.BADGES);
    } else {
      navigate(PATHS.CHALLENGES);
    }
  };

  const friendInviteLink = `${window.origin}${PATHS.SIGN_UP}/${data.userInviteCode}`;

  const friendsHandler = () => {
    if (data.friendsAmount) {
      navigate(PATHS.CONNECTIONS);
    } else {
      dispatch(
        openModal({
          children: (
            <ShareModal
              url={friendInviteLink}
              title={t("default.profileFriendsEmptyStateAction")}
            />
          ),
          styles: {
            width: "100%",
            maxWidth: isS ? "280px" : "352px",
            padding: isS ? "16px 16px 16px 16px" : "44px 48px 48px 48px",
          },
        })
      );
    }
  };

  const clubsHandler = () => navigate(PATHS.DISCOVER);

  const profileShare = () => {
    const publicLink = data?.appUser?.isInfluencer
      ? `${window.origin}/${data.influencerHandle || data.username}`
      : `${window.origin}/public/user/${data.username}`;

    dispatch(
      openModal({
        children: <ShareModal url={publicLink} title={t("edition.share")} />,
        styles: {
          width: "100%",
          maxWidth: isS ? "280px" : "352px",
          padding: isS ? "16px 16px 16px 16px" : "44px 48px 48px 48px",
        },
      })
    );
  };

  return (
    <Box className={styles.profile}>
      <CustomTooltip
        open={openTooltip}
        setOpen={setOpenTooltip}
        isClick={true}
        placement="bottom-end"
        title={
          <Box className={styles.tooltip}>
            <Typography
              variant="body2"
              className={styles.tooltipItem}
              onClick={profileShare}
            >
              {t("edition.share")}
            </Typography>
          </Box>
        }
      >
        <Button variant="contained" className={styles.profileActionBtn}>
          <DotsEditIcon />
        </Button>
      </CustomTooltip>
      <Box className={styles.profileAvatar} onClick={handleUploadPhotoOpen}>
        {data.profileImageUrl ? (
          <img src={data.profileImageUrl} alt="avatar" />
        ) : null}
        {!data?.appUser?.isInfluencer ? (
          <Box className={styles.profileImageOverlay}>
            <UploadIcon width={24} height={24} />
          </Box>
        ) : null}
        <Box className={styles.profileProgress}>
          <CircularProgressbar
            value={data.level}
            strokeWidth={5}
            styles={buildStyles({ pathColor: levelColor })}
          />
        </Box>
        <Level
          level={data.level}
          className={styles.profileLevel}
          overrideStyles={{ borderColor: levelColor, background: levelColor }}
          text={t("drops.level")}
        />
      </Box>
      <Box className={styles.profileMain}>
        <Box className={styles.profileNameWrapper}>
          <Typography className={styles.profileName} variant="body4">
            {data.username}
          </Typography>
          <IconButton onClick={handleNavigate}>
            <PencilIcon />
          </IconButton>
        </Box>
        <Box
          className={`${styles.profileInfo} ${
            isEmpty(userWallet) && !userWalletLoading
              ? styles.profileInfoColumn
              : ""
          }`}
        >
          {!isS ? (
            <Box className={styles.profileInfoRow}>
              <Box
                className={`${styles.profileInfoCol} ${styles.profileInfoColUnhover}`}
              >
                <Typography
                  variant="body2"
                  className={`opacity_05 ${styles.profileInfoTitle}`}
                >
                  {t("discover.commentsTitle")}
                </Typography>
                <Typography variant="body4">{data.stats?.comments}</Typography>
              </Box>
              <Box
                className={`${styles.profileInfoCol} ${styles.profileInfoColUnhover}`}
              >
                <Typography
                  variant="body2"
                  className={`opacity_05 ${styles.profileInfoTitle}`}
                >
                  {t("default.reactions")}
                </Typography>
                <Typography variant="body4">{data.stats?.reactions}</Typography>
              </Box>
            </Box>
          ) : null}
          {isS ? (
            <Box className={styles.profileInfoRow}>
              <Box
                className={`${styles.profileInfoCol} ${styles.profileInfoColUnhover}`}
                // onClick={clubsHandler}
                style={{
                  // cursor: "pointer",
                  pointerEvents: "none",
                }}
              >
                <Typography
                  variant="body2"
                  className={`opacity_05 ${styles.profileInfoTitle}`}
                >
                  {t("default.clubs")}
                </Typography>
                <Typography variant="body4">{data.clubsAmount}</Typography>
              </Box>
              <Box
                className={styles.profileInfoCol}
                onClick={friendsHandler}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  variant="body2"
                  className={`opacity_05 ${styles.profileInfoTitle}`}
                >
                  {t("default.friends")}
                </Typography>
                <Typography variant="body4">{data.friendsAmount}</Typography>
              </Box>
            </Box>
          ) : null}
          {!isS ? (
            <Box className={styles.profileInfoRow}>
              <Box
                className={`${styles.profileInfoCol}`}
                onClick={() => navigate(PATHS.REWARDS)}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  variant="body2"
                  className={`opacity_05 ${styles.profileInfoTitle}`}
                >
                  {t("account.zoopPoints")}
                </Typography>
                <Typography variant="body4">{data.rewardPoints}</Typography>
              </Box>
              <Box
                className={styles.profileInfoCol}
                onClick={handlerNavigateToBadges}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  variant="body2"
                  className={`opacity_05 ${styles.profileInfoTitle}`}
                >
                  {t("account.badges")}
                </Typography>
                <Typography variant="body4">{data.badgesAmount}</Typography>
              </Box>
            </Box>
          ) : null}
          <Box className={styles.profileInfoRow}>
            {/*{isEmpty(userWallet) && !isS ? (*/}
            {/*  !userWalletLoading ? (*/}
            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      className={styles.walletBtn}*/}
            {/*      size="small"*/}
            {/*      onClick={goToCompleteProfile}*/}
            {/*    >*/}
            {/*      {t("account.initialiseBalance")}*/}
            {/*    </Button>*/}
            {/*  ) : (*/}
            {/*    <Skeleton*/}
            {/*      variant="rectangular"*/}
            {/*      style={{ height: "44px", width: "100%" }}*/}
            {/*    />*/}
            {/*  )*/}
            {/*) : (*/}
            {/*  <Box*/}
            {/*    className={`${styles.profileInfoCol} ${*/}
            {/*      !isS ? styles.profileInfoColFull : styles.profileInfoColThird*/}
            {/*    }`}*/}
            {/*    style={{ cursor: "pointer" }}*/}
            {/*    onClick={() => navigate(PATHS.WALLET)}*/}
            {/*  >*/}
            {/*    <Typography*/}
            {/*      variant="body2"*/}
            {/*      className={`opacity_05 ${styles.profileInfoTitle}`}*/}
            {/*    >*/}
            {/*      {t("account.wallet")}*/}
            {/*    </Typography>*/}
            {/*    <Typography variant="body4">*/}
            {/*      {isEmpty(userWallet) ? (*/}
            {/*        "-"*/}
            {/*      ) : (*/}
            {/*        <NumberFormat*/}
            {/*          value={roundToTwo(userWallet?.cashAvailable || 0)}*/}
            {/*          displayType={"text"}*/}
            {/*          thousandSeparator={true}*/}
            {/*          prefix={userWallet?.currencySymbol}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}
            {/*)}*/}
            {isS ? (
              <Box
                // className={`${styles.profileInfoCol} ${
                //   !isS ? styles.profileInfoColFull : styles.profileInfoColThird
                // }`}
                className={`${styles.profileInfoCol} ${
                  !isS ? styles.profileInfoColFull : ""
                }`}
                onClick={() => navigate(PATHS.REWARDS)}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  variant="body2"
                  className={`opacity_05 ${styles.profileInfoTitle}`}
                >
                  {t("account.zoopPoints")}
                </Typography>
                <Typography variant="body4">{data.rewardPoints}</Typography>
              </Box>
            ) : null}
            {isS ? (
              <Box
                // className={`${styles.profileInfoCol} ${
                //   !isS ? styles.profileInfoColFull : styles.profileInfoColThird
                // }`}
                className={`${styles.profileInfoCol} ${
                  !isS ? styles.profileInfoColFull : ""
                }`}
                onClick={handlerNavigateToBadges}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  variant="body2"
                  className={`opacity_05 ${styles.profileInfoTitle}`}
                >
                  {t("account.badges")}
                </Typography>
                <Typography variant="body4">{data.badgesAmount}</Typography>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <ModalUploadPhoto
        open={openUploadPhoto}
        handleClose={handleUploadPhotoClose}
        handleClick={handleUploadPhoto}
        handleRemove={handleRemovePhoto}
      />
    </Box>
  );
};
