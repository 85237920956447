import { PATHS } from "constants/index";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { goToInfluencer } from "../../../utils";
import { EmptyStateFriendsIcon } from "../icons";
import styles from "./ClubsWrapper.module.scss";
import { SidebarBox } from "./SidebarBox/SidebarBox";

export const ClubsWrapper = ({ clubsCommunity, clubsLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useContext(LocalizationContext);

  const onItemClick = (data) => {
    goToInfluencer({
      id: data.influencer?.id,
      handle: data.influencer?.handle,
      navigate,
      location,
    })();
  };

  return (
    <SidebarBox
      title={t("default.clubs")}
      amount={clubsCommunity?.length}
      path={PATHS.CLUBS}
      data={clubsCommunity?.map((item) => ({
        ...item,
        picture: item?.influencer?.profileImageUrl,
        title: item?.influencer?.name,
        id: item?.id,
      }))}
      loading={clubsLoading}
      onItemClick={onItemClick}
      emptyStateIcon={<EmptyStateFriendsIcon />}
      emptyStateTitle={t("default.profileClubsEmptyStateTitle")}
      emptyStateActionText={t("default.profileClubsEmptyStateAction")}
      emptyStateAction={() => navigate(PATHS.DISCOVER)}
      itemClass={styles.item}
    />
  );
};
