import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as FiltersIcon } from "assets/svg/filter.svg";
import { ReactComponent as OrderIcon } from "assets/svg/order.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./MainHeader.module.scss";

export const MainHeader = ({
  setShowFilters,
  handleSort,
  sortAscending,
  title,
  isControls = true,
  wrapperClass,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={`${styles.heading} ${wrapperClass ? wrapperClass : ""}`}>
      <h1 className={`MainTitle ${styles.title}`}>{title}</h1>
      {isControls ? (
        <Box className={styles.controls}>
          <IconButton
            aria-label="Change Order"
            onClick={handleSort}
            className={styles.controlBtn}
          >
            <OrderIcon className={!sortAscending ? styles.reverseIcon : null} />
          </IconButton>
          <IconButton
            className={`${styles.controlBtn} ${styles.controlBtnText}`}
            aria-label="Open filters"
            sx={{ p: 0 }}
            onClick={() => setShowFilters(true)}
          >
            <FiltersIcon />
            <Typography
              variant="body1"
              component="span"
              className={styles.controlsText}
            >
              {t("default.filter")}
            </Typography>
          </IconButton>
        </Box>
      ) : null}
      <Box className={styles.break} />
    </Box>
  );
};
