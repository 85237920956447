import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";

import BackBtn from "../../components/Layout/BackBtn/BackBtn";
import styles from "./PageLayout.module.scss";
export const PageLayout = ({
  title,
  children,
  isBack,
  pageContentClassName,
  pageClassName,
  headerClassName,
}) => {
  const pageClass = classNames(styles.page, {
    [pageClassName]: pageClassName,
  });
  const pageContentClass = classNames(styles.pageContent, {
    [pageContentClassName]: pageContentClassName,
  });

  return (
    <Box className={pageClass}>
      {isBack ? (
        <BackBtn
          title={title ? title : null}
          className={headerClassName ? headerClassName : ""}
        />
      ) : (
        <Typography
          variant="h1"
          className={`${styles.pageTitle} ${
            headerClassName ? headerClassName : ""
          }`}
        >
          {title}
        </Typography>
      )}
      <Box className={pageContentClass}>{children}</Box>
    </Box>
  );
};
