import "styles/slider/index.scss";

import { Box, Button, Container, Tab, Tabs, Typography } from "@mui/material";
import { ReactComponent as NoFriendsIcon } from "assets/svg/no-connections-icon.svg";
import { ReactComponent as NoFollowingIcon } from "assets/svg/no-following-icon.svg";
import ConnectionItem from "components/ConnectionItem";
import FriendRequestItem from "components/FriendRequestItem";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import UnfriendModal from "components/Modal/UnfriendModal";
import { NextArrow, PrevArrow } from "components/Slider/Arrow";
import TabPanel from "components/TabPanel";
import {
  CONNECTION_REQUEST_TYPE,
  CONNECTIONS_LOAD_LIMIT,
  SCROLL_THRESHOLD,
} from "constants/";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { setLoader, setSnackbar } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import {
  acceptFriendRequest,
  clearPublicFollowers,
  clearPublicUser,
  fetchFollowingByUserId,
  fetchFriendRequestsIndicator,
  fetchFriendsById,
  fetchMyFollows,
  fetchPublicUser,
  fetchUserFriendRequests,
  rejectFriendRequest,
  searchUser,
  unfriendUser,
} from "redux/usersSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";
import { getUsernameFromPathname } from "utils/usernameHelper";

import styles from "./connections.module.scss";

const REQUESTS_PAGE_LIMIT = 7;
const REQUESTS_PAGE_LIMIT_MOBILE = 2;

const responsiveSliderSettings = (items) => ({
  dots: false,
  infinite: false,
  slidesToShow: REQUESTS_PAGE_LIMIT,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: items.length >= 6 ? 6 : items.length,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: items.length >= 5 ? 5 : items.length,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: items.length >= 4 ? 4 : items.length,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: items.length >= 3 ? 3 : items.length,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: items.length >= 2 ? 2 : items.length,
      },
    },
  ],
});

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const Placeholder = ({ text, buttonText, onBtnClick, icon }) => {
  return (
    <Box className={styles.placeholder}>
      {icon}
      <Typography
        variant="h5"
        component="h5"
        className={styles.placeholderText}
      >
        {text}
      </Typography>
      <Button variant="contained" onClick={onBtnClick}>
        {buttonText}
      </Button>
    </Box>
  );
};

const Connections = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const params = useParams();
  const [friends, setFriends] = useState(null);
  const publicUser = useSelector((state) => state.users.publicUser.entities);

  const [friendsOffset, setFriendsOffset] = useState(0);
  const [followingOffset, setFollowingOffset] = useState(0);
  const isLastPage = useSelector((state) => state.users.connections.isLastPage);
  const [friendRequests, setFriendRequests] = useState([]);
  const [tabsValue, setTabsValue] = useState(location.state?.activeTab || 0);
  const following = useSelector((state) => state.users.following.entities);
  const isFollowingLoading = useSelector(
    (state) => state.users.following.loading
  );
  // console.log(isFollowingLoading);

  const username =
    params?.username || getUsernameFromPathname(location.pathname);

  const showFriendRequestsIndicator = useSelector(
    (state) => state.users.connections.requests.showIndicator
  );
  const requestsSliderRef = useRef(null);
  const [requestsSlideIndex, setRequestsSlideIndex] = useState(0);

  const isPublicUser = location.state?.isPublicUser;

  const requestItemsLimit = useMemo(() => {
    if (!requestsSliderRef?.current) {
      return isMobile ? REQUESTS_PAGE_LIMIT_MOBILE : REQUESTS_PAGE_LIMIT;
    }

    const bp = requestsSliderRef.current?.state.breakpoint;

    const currentSettings = Object.values(
      requestsSliderRef.current?.props.responsive
    ).find((item) => item.breakpoint === bp);

    if (currentSettings?.settings) {
      return currentSettings.settings.slidesToShow;
    } else {
      return isMobile ? REQUESTS_PAGE_LIMIT_MOBILE : REQUESTS_PAGE_LIMIT;
    }
  }, [isMobile, requestsSliderRef.current?.state.breakpoint]);

  const requestsSliderSettings = useMemo(() => {
    return {
      ...responsiveSliderSettings(friendRequests),
      prevArrow:
        requestsSlideIndex !== 0 ? (
          <PrevArrow onClick={() => requestsSliderRef?.current.slickPrev()} />
        ) : (
          <></>
        ),
      nextArrow:
        requestsSlideIndex + requestItemsLimit < friendRequests?.length ? (
          <NextArrow onClick={() => requestsSliderRef?.current.slickNext()} />
        ) : (
          <></>
        ),
      beforeChange: (_current, next) => setRequestsSlideIndex(next),
    };
  }, [friendRequests, requestItemsLimit, requestsSlideIndex]);
  //TODO::loaders
  const getFriendsData = (offsetParam) => {
    // console.log("getFriendsData", publicUser);
    setFriendsOffset(offsetParam);

    const payload = {
      offset: 0,
      limit: offsetParam + CONNECTIONS_LOAD_LIMIT,
    };

    if (!_.isEmpty(publicUser) || publicUser?.id) {
      dispatch(fetchFriendsById({ id: publicUser?.id }))
        .unwrap()
        .then((d) => setFriends(d))
        .catch((e) => console.error(e));
      dispatch(fetchFollowingByUserId({ id: publicUser?.id }));
    }
  };
  //TODO::loader

  const getFollowingData = (offsetParam) => {
    // console.log("getFollowingData");

    setFollowingOffset(offsetParam);

    const payload = {
      offset: 0,
      limit: offsetParam + CONNECTIONS_LOAD_LIMIT,
    };

    dispatch(fetchMyFollows(payload))
      .unwrap()
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (!username) {
      return;
    }

    dispatch(searchUser(username))
      .unwrap()
      .then((user) => {
        dispatch(fetchPublicUser(user?.id));
      })
      .catch((err) => {
        console.log("Cannot get public user", err);
      });
    return () => {
      clearPublicFollowers();
    };
  }, [username]);

  useEffect(() => {
    let unmounted = false;

    !unmounted && getFriendsData(0);
    !unmounted && getFollowingData(0);
    dispatch(
      fetchUserFriendRequests({ type: CONNECTION_REQUEST_TYPE.incoming })
    )
      .unwrap()
      .then((d) => !unmounted && setFriendRequests(d));
    dispatch(fetchFriendRequestsIndicator());

    return () => {
      // console.log("unmounted");
      unmounted = true;
      dispatch(clearPublicUser());
    };
  }, [publicUser?.id]);

  const handleUnfriend = (connection) => {
    if (!connection) return;

    const handleConfirm = () => {
      dispatch(setLoader(true));
      dispatch(unfriendUser(connection.id));
      setFriends([...friends.filter((c) => c.id !== connection.id)]);
      dispatch(setLoader(false));
      dispatch(closeModal());
    };

    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={t("account.unfriendModalTitle", {
              username: connection.friend.username,
            })}
            description={t("account.unfriendModalText", {
              username: connection.friend.username,
            })}
            onConfirm={handleConfirm}
            onCancel={() => dispatch(closeModal())}
          />
        ),
      })
    );
  };

  const handleConnectionShare = (username) => {
    dispatch(
      setSnackbar({
        open: true,
        message: t("drops.copied"),
      })
    );

    const publicLink = `${window.origin}/public/user/${username}`;
    navigator.clipboard.writeText(publicLink);
  };

  const handleAcceptRequest = useCallback(
    (connectionId) => {
      if (!connectionId) return;

      dispatch(setLoader(true));
      dispatch(acceptFriendRequest(connectionId)).then(() => {
        setFriendRequests([
          ...friendRequests.filter((c) => c.id !== connectionId),
        ]);
        getFriendsData(friendsOffset + CONNECTIONS_LOAD_LIMIT);
      });
      dispatch(setLoader(false));
    },
    [friendRequests, friendsOffset, getFriendsData]
  );

  const handleRejectRequest = useCallback(
    (connectionId) => {
      if (!connectionId) return;

      dispatch(setLoader(true));
      dispatch(rejectFriendRequest(connectionId)).then(() => {
        setFriendRequests([
          ...friendRequests.filter((c) => c.id !== connectionId),
        ]);
        getFriendsData(friendsOffset + CONNECTIONS_LOAD_LIMIT);
      });
      dispatch(setLoader(false));
    },
    [friendRequests, friendsOffset, getFriendsData]
  );

  const handleChangeTab = (_event, newValue) => {
    setTabsValue(newValue);
  };

  const displayRequests = useCallback(() => {
    if (isMobile) {
      if (friendRequests.length > requestItemsLimit) {
        return (
          <Slider
            ref={requestsSliderRef}
            className={styles.slider}
            {...requestsSliderSettings}
          >
            {friendRequests.map((item) => (
              <FriendRequestItem
                key={item.id}
                request={item}
                handleAcceptRequest={() => handleAcceptRequest(item.id)}
                handleRejectRequest={() => handleRejectRequest(item.id)}
              />
            ))}
          </Slider>
        );
      } else {
        return friendRequests.map((item) => (
          <FriendRequestItem
            key={item.id}
            request={item}
            handleAcceptRequest={() => handleAcceptRequest(item.id)}
            handleRejectRequest={() => handleRejectRequest(item.id)}
          />
        ));
      }
    } else {
      return friendRequests
        .slice(0, requestItemsLimit)
        .map((item) => (
          <FriendRequestItem
            key={item.id}
            request={item}
            handleAcceptRequest={() => handleAcceptRequest(item.id)}
            handleRejectRequest={() => handleRejectRequest(item.id)}
          />
        ));
    }
  }, [
    friendRequests,
    handleAcceptRequest,
    handleRejectRequest,
    isMobile,
    requestItemsLimit,
    requestsSliderSettings,
  ]);

  return (
    <Box className={styles.page}>
      <Container className={styles.container}>
        <Box className={styles.header}>
          <BackBtn
            onClick={() => navigate(-1)}
            title={t("account.connections")}
          />
        </Box>
        {!isPublicUser && !!friendRequests?.length && (
          <Box className={styles.requestsBlock}>
            <Box className={styles.requestsBlockHeader}>
              <Box className={styles.requestsBlockTitleWrap}>
                <Typography
                  variant="h4"
                  component="h3"
                  className={styles.requestsBlockTitle}
                >
                  {t("account.friendRequests")}
                </Typography>
                {showFriendRequestsIndicator && (
                  <Box className={styles.requestsBlockIndicator} />
                )}
              </Box>
              <Button
                variant="outlined"
                onClick={() => navigate("/friend-requests")}
                size="small"
                disabled={friendRequests?.length <= requestItemsLimit}
              >
                {t("account.viewAll")}
              </Button>
            </Box>
            <Box className={styles.requestsContainer}>{displayRequests()}</Box>
          </Box>
        )}
        <Box className={styles.tabsWrapper}>
          <Tabs
            value={tabsValue}
            onChange={handleChangeTab}
            aria-label="tabs-label"
            className={styles.tabLinks}
          >
            <Tab
              className={styles.tabLink}
              label={t("account.friends")}
              {...a11yProps(0)}
            />
            <Tab
              className={styles.tabLink}
              label={t("account.following")}
              {...a11yProps(1)}
            />
          </Tabs>
          {tabsValue === 0 && (
            <Typography variant="body1" className={styles.connectionsTotalText}>
              {`${friends?.total || 0} ${t("account.connections")}`}
            </Typography>
          )}
          {tabsValue === 1 && (
            <Typography variant="body1" className={styles.connectionsTotalText}>
              {`${following?.length || 0} ${t("account.following")}`}
            </Typography>
          )}
        </Box>
        <TabPanel
          value={tabsValue}
          index={0}
          tabPanelClassName={styles.connectionsTabPanel}
        >
          {friends && !!friends.rows.length ? (
            <InfiniteScroll
              dataLength={friends.total}
              next={() =>
                getFriendsData(friendsOffset + CONNECTIONS_LOAD_LIMIT)
              }
              hasMore={!isLastPage}
              style={{ overflow: "hidden" }}
              className={styles.infiniteScroll}
              endMessage=""
              scrollThreshold={SCROLL_THRESHOLD}
            >
              <Box className={styles.connectionsContainer}>
                {friends.rows.map((item) => (
                  <ConnectionItem
                    key={item.id}
                    connection={item}
                    handleUnfriend={() => handleUnfriend(item)}
                    handleShare={() =>
                      handleConnectionShare(item.friend.username)
                    }
                  />
                ))}
              </Box>
            </InfiniteScroll>
          ) : (
            <Placeholder
              text={t("account.noFriendsYet")}
              buttonText={t("account.referYourFriends")}
              onBtnClick={() => navigate("/referrals")}
              icon={
                <NoFriendsIcon className={styles.placeholderNoFriendsIcon} />
              }
            />
          )}
        </TabPanel>
        <TabPanel
          value={tabsValue}
          index={1}
          tabPanelClassName={styles.connectionsTabPanel}
        >
          {!!following?.length || isFollowingLoading ? (
            <InfiniteScroll
              dataLength={following.length}
              next={() =>
                getFollowingData(followingOffset + CONNECTIONS_LOAD_LIMIT)
              }
              hasMore={!isLastPage}
              style={{ overflow: "hidden" }}
              className={styles.infiniteScroll}
              endMessage=""
              scrollThreshold={SCROLL_THRESHOLD}
            >
              <Box className={styles.connectionsContainer}>
                {following.map((item) => (
                  <ConnectionItem
                    key={item.id}
                    connection={item}
                    isInfluencer
                    isPublic={!isAuthenticated()}
                  />
                ))}
              </Box>
            </InfiniteScroll>
          ) : (
            <Placeholder
              text={t("account.noFollowingYet")}
              buttonText={t("account.findCelebrities")}
              onBtnClick={() => navigate("/discover")}
              icon={
                <NoFollowingIcon
                  className={styles.placeholderNoFollowingIcon}
                />
              }
            />
          )}
        </TabPanel>
      </Container>
    </Box>
  );
};

export default Connections;
