import { Box, Skeleton } from "@mui/material";
import { ReactComponent as NoFriendsIcon } from "assets/svg/no-connections-icon.svg";
import ConnectionItem from "components/ConnectionItem";
import { ShareModal } from "components/Modal/ShareModal/ShareModal";
import UnfriendModal from "components/Modal/UnfriendModal";
import {
  CONNECTIONS_LOAD_LIMIT,
  PATHS,
  SCROLL_THRESHOLD,
} from "constants/index";
import React, { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import { Placeholder } from "../Placeholder/Placeholder";
import styles from "./ClubsList.module.scss";

export const ClubsList = ({
  rows = [],
  total = 0,
  isLastPage,
  loader,
  setPage,
}) => {
  const isMobile = useIsMobile();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const next = () => {
    setPage((prevState) => prevState + 1);
  };

  const handleConnectionShare = (username) => {
    dispatch(
      openModal({
        children: (
          <ShareModal
            url={`${window.origin}/${username}`}
            title={t("default.profileFriendsEmptyStateAction")}
          />
        ),
        styles: {
          width: "100%",
          maxWidth: isMobile ? "280px" : "352px",
          padding: isMobile ? "16px 16px 16px 16px" : "44px 48px 48px 48px",
        },
      })
    );
  };

  return (
    <Box className={styles.list}>
      {!rows?.length && loader ? (
        <Box className={styles.connectionsContainer}>
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
        </Box>
      ) : null}
      {rows.length ? (
        <InfiniteScroll
          dataLength={total}
          next={next}
          hasMore={!isLastPage}
          style={{ overflow: "hidden" }}
          className={styles.infiniteScroll}
          scrollThreshold={SCROLL_THRESHOLD}
        >
          <Box className={styles.connectionsContainer}>
            {rows.map((item) => (
              <ConnectionItem
                key={item?.id}
                connection={item}
                isInfluencer={true}
                isClub={true}
                handleUnfriend={() => {}}
                handleShare={() =>
                  handleConnectionShare(item.influencer.handle)
                }
                showOptionsBtn
              />
            ))}
          </Box>
        </InfiniteScroll>
      ) : null}
      {!rows.length && !loader ? (
        <Placeholder
          text={t("default.profileClubsEmptyStateTitle")}
          buttonText={t("default.profileClubsEmptyStateAction")}
          onBtnClick={() => navigate(PATHS.DISCOVER)}
          icon={<NoFriendsIcon className={styles.placeholderNoFriendsIcon} />}
        />
      ) : null}
    </Box>
  );
};
