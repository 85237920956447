import { Box } from "@mui/material";
import classNames from "classnames/bind";
import sliderStyles from "components/CardsSlider/CardSlider.module.scss";
import { useLayoutEffect, useRef } from "react";
import Slider from "react-slick";

import { MEDIA_TYPES } from "../../constants";
import { Audio } from "../Audio/Audio";
import { VideoBlock } from "../VideoBlock/VideoBlock";
import styles from "./MediaSlider.module.scss";

export const MediaSlider = ({
  data,
  index = 0,
  alt,
  isFullScreen,
  overrideStyles,
  accessGranted = true,
  isMultipleType,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: index,
    accessibility: true,
    adaptiveHeight: true,
  };
  const cx = classNames.bind(styles);
  const sliderRef = useRef();
  const setSliderFocus = () => {
    sliderRef?.current.innerSlider.list.setAttribute("tabindex", 0);
    sliderRef?.current.innerSlider.list.focus();
  };
  useLayoutEffect(() => {
    setSliderFocus();
  }, [sliderRef, isFullScreen]);

  return (
    <Box
      className={cx(
        sliderStyles.slider,
        styles.slider,
        isFullScreen && styles.sliderFullScreen
      )}
    >
      {data && !isMultipleType ? (
        <Slider {...settings} ref={sliderRef} afterChange={setSliderFocus}>
          {data.map((image, index) => (
            <div
              className={cx(styles.sliderItem, overrideStyles?.sliderItem)}
              key={index}
            >
              <img
                src={image}
                alt={alt}
                className={!accessGranted ? styles.bluredImage : ""}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <Slider {...settings} ref={sliderRef} afterChange={setSliderFocus}>
          {data.map((item, index) => (
            <div
              className={cx(styles.sliderItem, overrideStyles?.sliderItem)}
              key={index}
            >
              {item.type === MEDIA_TYPES.IMAGE ? (
                <img
                  src={item.url}
                  alt={alt}
                  className={!accessGranted ? styles.bluredImage : ""}
                />
              ) : item.type === MEDIA_TYPES.VIDEO ? (
                <VideoBlock
                  videos={[item.url]}
                  postIndex={"-1"}
                  mediaIndex={index}
                />
              ) : item.type === MEDIA_TYPES.AUDIO ? (
                <Audio
                  src={item.url}
                  mediaIndex={index}
                  parentIndex={"-1"}
                  classNameWrapper={styles.audioWrapper}
                />
              ) : null}
            </div>
          ))}
        </Slider>
      )}
    </Box>
  );
};
