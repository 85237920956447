import { Box, Skeleton, Tab, Tabs } from "@mui/material";
import Post from "components/Feed/Post/Post";
import SystemNewCard from "components/SystemNewCard/SystemNewCard";
import { PATHS, POST_TYPES, SCROLL_THRESHOLD } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Outlet, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { getInfluencerProfileLink } from "../../utils";
import { useIsMobile } from "../../utils/hooks";
import { PageLayout } from "../PageLayout/PageLayout";
import { CollectionTab } from "./components/CollectionTab/CollectionTab";
import { EmptyState } from "./components/EmptyState/EmptyState";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { CollectionIcon, PostsIcon } from "./icons";
import styles from "./NewProfile.module.scss";

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const tabsIndex = {
  posts: 0,
  collection: 1,
};

export const NewProfile = ({
  title,
  me,
  loading,
  profile,
  postsData,
  editions,
  editionLoader,
  loadMorePosts,
  hasMorePosts,
  postsLoader,
}) => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();

  const [tabsValue, setTabsValue] = useState(tabsIndex.posts);

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
    const url = new URL(window.location.href);
    url.searchParams.set("tab", newValue);
    window.history.pushState({}, "", url);
  };

  const influencerProfileLink = (post) => {
    return getInfluencerProfileLink({
      id: post.influencer.id,
      handle: post.influencer.handle,
    });
  };

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    let queryParams = {};
    for (let [key, value] of params.entries()) {
      queryParams[key] = value;
    }

    if (queryParams.tab) {
      setTabsValue(Number(queryParams.tab));
    }
  }, []);

  return (
    <PageLayout title={title} headerClassName={styles.header}>
      {!me?.id && loading ? (
        <Box className={styles.wrapper}>
          <Box className={styles.sidebar}>
            <Skeleton
              variant="rectangular"
              className={styles.skeletonProfile}
            />
            <Skeleton
              variant="rectangular"
              className={styles.skeletonSidebarBox}
            />
            <Skeleton
              variant="rectangular"
              className={styles.skeletonSidebarBox}
            />
          </Box>
          <Box className={styles.mainContent}>
            <Box className={styles.tabsWrap}>
              <Skeleton
                className={styles.tabsWrapSkeleton}
                variant="rectangular"
              />
            </Box>
          </Box>
        </Box>
      ) : me?.id ? (
        <Box className={styles.wrapper}>
          <Box className={styles.sidebar}>
            <Sidebar
              level={me.level}
              profileImageUrl={profile.userPicCroppedUrl}
              username={profile.username}
              isInfluencer={profile.appUser?.isInfluencer}
              rewardPoints={me.rewardPoints}
              stats={me.stats}
            />
          </Box>
          <Box className={styles.mainContent}>
            <Box className={styles.tabsWrap}>
              <Tabs
                value={tabsValue}
                onChange={handleTabChange}
                className={`${styles.tabs} global-tabs-class`}
              >
                <Tab
                  label={
                    <Box className={styles.tabLabel}>
                      {t("default.posts")}{" "}
                      <Box
                        className={`${styles.tabSubLabel} ${
                          tabsValue === tabsIndex.posts
                            ? styles.tabSubLabelActive
                            : ""
                        }`}
                      >{`(${postsData.total})`}</Box>
                    </Box>
                  }
                  {...a11yProps(tabsIndex.posts)}
                />
                <Tab
                  label={
                    <Box className={styles.tabLabel}>
                      {t("barter.collection")}{" "}
                      <Box
                        className={`${styles.tabSubLabel} ${
                          tabsValue === tabsIndex.collection
                            ? styles.tabSubLabelActive
                            : ""
                        }`}
                      >{`(${editions?.length})`}</Box>
                    </Box>
                  }
                  {...a11yProps(tabsIndex.collection)}
                />
              </Tabs>
              <Box className={styles.tabsContent}>
                <Box
                  className={styles.tabsValue}
                  style={{
                    display: tabsValue === tabsIndex.posts ? "block" : "none",
                  }}
                >
                  {!postsData.posts.all.length && !postsLoader ? (
                    <EmptyState
                      icon={<PostsIcon />}
                      title={t("discover.noPosts")}
                      subTitle={t("default.emptyStateDescriptionPostsProfile")}
                      buttonText={t("default.emptyStateButtonPostsProfile")}
                      action={() => navigate(PATHS.DISCOVER_GROUPS)}
                    />
                  ) : null}
                  <InfiniteScroll
                    dataLength={
                      postsData.posts.left.length + postsData.posts.right.length
                    }
                    next={loadMorePosts}
                    hasMore={hasMorePosts}
                    // loader={scrollLoader}
                    scrollThreshold={SCROLL_THRESHOLD}
                    scrollableTarget="scrollableEl"
                    className={styles.postsList}
                  >
                    {isMobile && !postsData.posts.all.length && postsLoader ? (
                      <Skeleton
                        variant="rectangular"
                        style={{ height: "200px", width: "100%" }}
                      />
                    ) : null}
                    {isMobile ? (
                      <Box className={styles.scrollCol}>
                        {postsData.posts.all.map((item, index) =>
                          item?.type === POST_TYPES.SYSTEM_NEW_CARD ? (
                            <SystemNewCard
                              {...item.data}
                              key={item?.id}
                              className={styles.systemPost}
                            />
                          ) : (
                            <Post
                              {...item}
                              key={item?.id}
                              userName={item.influencer?.name}
                              postIndex={index}
                              originalMessage={item?.message}
                              level={me.level}
                              pathToProfile={influencerProfileLink(item)}
                              className={styles.post}
                              isAvatar={true}
                              isShowPostedOn={true}
                              isGlobal={true}
                              currentUser={{
                                ...profile,
                                username: t("default.you"),
                              }}
                              isShowCommentedOn={true}
                            />
                          )
                        )}
                      </Box>
                    ) : (
                      <>
                        <Box className={styles.scrollCol}>
                          {!postsData.posts.left.length && postsLoader ? (
                            <Skeleton
                              variant="rectangular"
                              style={{ height: "200px", width: "100%" }}
                            />
                          ) : null}
                          {postsData.posts.left.map((item, index) =>
                            item?.type === POST_TYPES.SYSTEM_NEW_CARD ? (
                              <SystemNewCard
                                {...item.data}
                                key={item?.id}
                                className={styles.systemPost}
                              />
                            ) : (
                              <Post
                                {...item}
                                key={item?.id}
                                userName={item.influencer?.name}
                                postIndex={index}
                                originalMessage={item?.message}
                                level={me.level}
                                pathToProfile={influencerProfileLink(item)}
                                className={styles.post}
                                isAvatar={true}
                                isShowPostedOn={true}
                                isGlobal={true}
                                currentUser={{
                                  ...profile,
                                  username: t("default.you"),
                                }}
                                isShowCommentedOn={true}
                              />
                            )
                          )}
                        </Box>
                        <Box className={styles.scrollCol}>
                          {!postsData.posts.right.length && postsLoader ? (
                            <Skeleton
                              variant="rectangular"
                              style={{ height: "200px", width: "100%" }}
                            />
                          ) : null}
                          {postsData.posts.right.map((item, index) =>
                            item?.type === POST_TYPES.SYSTEM_NEW_CARD ? (
                              <SystemNewCard
                                {...item.data}
                                key={item?.id}
                                className={styles.systemPost}
                              />
                            ) : (
                              <Post
                                {...item}
                                key={item?.id}
                                userName={item.influencer?.name}
                                postIndex={index}
                                originalMessage={item?.message}
                                level={me.level}
                                pathToProfile={influencerProfileLink(item)}
                                className={styles.post}
                                isAvatar={true}
                                isShowPostedOn={true}
                                isGlobal={true}
                                currentUser={{
                                  ...profile,
                                  username: t("default.you"),
                                }}
                                isShowCommentedOn={true}
                              />
                            )
                          )}
                        </Box>
                      </>
                    )}
                  </InfiniteScroll>
                </Box>
                <Box
                  className={styles.tabsValue}
                  style={{
                    display:
                      tabsValue === tabsIndex.collection ? "block" : "none",
                  }}
                >
                  {!editions.length && !editionLoader ? (
                    <EmptyState
                      icon={<CollectionIcon />}
                      title={t("default.profileNoCollection")}
                      subTitle={t(
                        "default.emptyStateDescriptionCollectionProfile"
                      )}
                      buttonText={t(
                        "default.emptyStateButtonCollectionProfile"
                      )}
                      action={() => navigate(PATHS.DROPS)}
                    />
                  ) : null}
                  <CollectionTab
                    isCompleted={profile.isCompleted}
                    trophyCardId={profile.trophyCardId}
                    editions={editions}
                    loader={editionLoader}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
      <Outlet />
    </PageLayout>
  );
};
