import { createTheme } from "@mui/material/styles";

const palette = {
  transparent: "transparent",
  dark: {
    main: "#111322",
    contrastText: "#FFFFFF",
    placeholder: "rgba(255, 255, 255, 0.5)",
  },
  surface: { main: "#21264E", border: "gba(80, 90, 169, 0.5)" },
  lihtGrey: { main: "#F0F1F5", opacity20: "rgba(240, 241, 245, 0.2)" },
  accent: {
    main: "#70E8FC",
    secondary: "#00D3F3",
    third: "rgba(112, 232, 252, 0.25)",
  },
  coral: { main: "#EE5261", hover: "#c63e4b" },
  tertiary: { main: "#27E7C4", progressBg: "#737696" },
  primary: {
    main: "#4A3FCB",
  },
  secondary: {
    main: "#FFFFFF",
  },
  error: {
    main: "#E65447",
  },
  lime: {
    main: "#f1fc70",
    progressBg: "#737696",
    secondary: "#DAE41F",
  },
  purple: {
    main: "#ABB3F3",
    secondary: "#505aa9",
  },
  borderColor: {
    main: "rgba(240, 241, 245, 0.1)",
    progress: "rgba(255, 255, 255, 0.2)",
  },
};
const theme = createTheme({
  typography: {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    /*-------Clash Display-------*/
    textNormal: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 12,
      fontWeight: 400,
      color: "#FFFFFF",
    },
    clash_display_12_500: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 12,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    textNormal_14: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.3,
      color: "#FFFFFF",
    },
    body1Display: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.25,
      color: "#FFFFFF",
    },
    h6: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1,
      color: "#FFFFFF",
    },
    h5: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1,
      color: "#FFFFFF",
    },
    h5_medium: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1,
      color: "#FFFFFF",
      "@media (max-width:480px)": {
        fontSize: 16,
      },
    },
    /*-------Clash Display 500-------*/
    textMediumBold: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 14,
      fontWeight: 500,
      color: "#FFFFFF",
    },
    textMediumBold1: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 15,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#FFFFFF",
    },
    textMediumBold2: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#FFFFFF",
    },
    body4: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.1,
      color: "#FFFFFF",
    },
    body6: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.2,
      color: "#FFFFFF",
    },
    body6_medium_dark: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#111322",
    },
    h4: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1,
      color: "#FFFFFF",
      // "@media (max-width:1280px)": {
      //   fontSize: 16,
      //   lineHeight: 1.2,
      // },
    },
    h4_24: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1,
      color: "#FFFFFF",
    },
    h3: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.43,
      color: "#FFFFFF",
      "@media (max-width:480)": {
        fontSize: 20,
      },
    },
    h2_32: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 32,
      fontWeight: 500,
      lineHeight: 1.14,
      color: "#FFFFFF",
    },
    h2: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 36,
      fontWeight: 500,
      lineHeight: 1.14,
      color: "#FFFFFF",
      // "@media (max-width:1280px)": {
      //   fontSize: 24,
      //   fontWeight: 500,
      //   lineHeight: "30px",
      // },
    },
    h1: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 54,
      fontWeight: 500,
      lineHeight: 1.22,
      letterSpacing: "-0.01em",
      color: "#FFFFFF",
    },
    lgTitle: {
      fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 80,
      fontWeight: 500,
      lineHeight: "72px",
      color: "#FFFFFF",
      "@media (max-width:1280px)": {
        fontSize: 40,
        lineHeight: "36px",
      },
    },
    /*-------ClashGrotesk 400-------*/
    body3: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.33,
      color: "#FFFFFF",
    },
    body2: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.14,
      color: "#FFFFFF",
    },
    body1: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#FFFFFF",
    },
    subtitle1: {
      fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.33,
      color: "#FFFFFF",
      "@media (max-width:480)": {
        fontSize: 16,
      },
    },
    /*-------ClashGrotesk 500-------*/
    body3_grotesk_medium: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.3,
      color: "#FFFFFF",
    },
    body5: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#FFFFFF",
    },
    body4_groteskMedium: {
      fontFamily: `"ClashGrotesk-Medium", "Noto Sans JP", "Noto Sans KR", sans-serif`,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 768,
      md: 1024,
      lg: 1260,
      xl: 1440,
    },
  },
  components: {
    MuiContainer: {
      // minHeight: "100vh",
      defaultProps: {
        maxWidth: "xl",
      },
      styleOverrides: {
        maxWidthLg: {
          paddingLeft: 24,
          paddingRight: 24,
        },
        maxWidthSm: {
          paddingLeft: 16,
          paddingRight: 16,
        },
        maxWidthXl: {
          paddingLeft: 48,
          paddingRight: 48,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: "#fff",
          fontSize: "16px",
          paddingBottom: "16px",
          fontFamily: `"ClashGrotesk-Regular", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
          "&:before": {
            borderBottom: "1px solid #FFFFFF !important",
            borderColor: "#fff",
          },
          "&:after": {
            borderColor: "#70E8FC",
          },
          "&.Mui-error": {
            fontSize: "16px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "20px",
          textTransform: "none",
          height: "54px",
          borderRadius: "10px",
          padding: "12px 29px",
          color: "#111322",
          background: "#70E8FC",
          border: "none",
          flexShrink: 0,
          "&.MuiButton-sizeSmall": {
            height: "44px",
          },
          "&.MuiButton-sizeXsmall": {
            height: "32px",
            fontSize: "18px",
            borderRadius: "6px",
            padding: "5px 8px",
            minWidth: "auto",
          },
          "&.MuiButton-text": {
            background: "none",
            padding: 0,
            height: "auto",
            color: "#00D3F3",
          },
          "&.MuiButton-contained": {
            background: "#70E8FC",
            color: "#111322",
            border: "none",
            fontWeight: 500,
            "&:hover": {
              background: "#00D3F3",
            },
            "&.MuiButton-containedDark": {
              background: "rgba(17, 19, 34, 0.7)",
              color: "#70E8FC",
              "&:hover": {
                background: "rgba(17, 19, 34, 0.4)",
              },
            },
            "&.MuiButton-containedSurface": {
              background: "#21264E",
              color: "white",
              "&:hover": {
                background: "#21264e80",
              },
              "&.MuiButton-sizeXsmall": {
                height: "27px",
                fontSize: "14px",
                borderRadius: "5px",
                color: "#70E8FC",
              },
            },
          },
          "&.MuiButton-verify": {
            background: palette.coral.main,
            color: "#111322",
            border: "none",
            fontWeight: 500,
          },
          "&.MuiButton-yellow": {
            background: "#F1FC70",
            color: "#111322",
            border: "none",
            fontWeight: 500,
          },
          "&.MuiButton-white": {
            background: "#fff",
            color: "#111322",
            border: "none",
            fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
            fontWeight: 500,
            fontSize: "14px",
            height: "40px",
          },
          "&.MuiButton-outlined": {
            background: "none",
            color: "#FFFFFF",
            border: "1px solid #FFFFFF",
            "&:hover": {
              color: "#70E8FC",
              borderColor: "#70E8FC",
            },
            ".MuiLoadingButton-loadingIndicator": {
              color: "#FFFFFF",
            },
          },
          "&.MuiButton-outlined-red": {
            background: "none",
            color: palette.coral.main,
            border: "1px solid #EE5261",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              opacity: 0.8,
            },
            ".MuiLoadingButton-loadingIndicator": {
              color: "#FFFFFF",
            },
          },
          "&.MuiButton-contained-danger": {
            background: palette.coral.main,
            color: "#111322",
            fontWeight: 500,
            "&:hover": {
              background: palette.coral.hover,
            },
          },
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
        containedPrimary: {
          "&:hover": {},
        },
        containedSecondary: {
          color: "#000646",
          "&:hover": {
            background: "#edfbff",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#111322",
            background: "#FFFFFF",
            "&:hover": {
              color: "#111322",
              background: "#edfbff",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          color: "#FFFFFF",
          ".MuiOutlinedInput-notchedOutline": {
            border: "1px solid #FFFFFF",
          },
          ".MuiOutlinedInput-notchedOutline span": {
            border: "1px solid #FFFFFF",
          },
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFFFFF",
            },
          },
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              border: "1px solid #FFFFFF",
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "none",
          boxShadow: "none",
          border: "1px solid rgba(240, 241, 245, 0.1)",
          margin: 0,
          "@media (max-width:480px)": {
            border: "none",
          },
          "&.MuiAccordion-root": {
            borderRadius: "12px",
          },
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0 28px",
          minHeight: "82px",
          "@media (max-width:480px)": {
            minHeight: "41px",
            padding: "0",
          },
          "&.Mui-expanded": {
            boxShadow: "0 1px 0 0 rgba(240, 241, 245, 0.1)",
            minHeight: "82px",
            "@media (max-width:480px)": {
              boxShadow: "none",
              minHeight: "41px",
            },
          },
        },
        content: {
          "&.Mui-expanded": {
            "@media (max-width:480px)": {
              margin: "0px",
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "20px 28px",
          "@media (max-width:480px)": {
            padding: "16px",
          },
        },
      },
    },
  },
  palette,
});

export default theme;
