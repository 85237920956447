import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as IconRedirect } from "assets/svg/icon-redirect.svg";
import PriceTitle from "components/PriceTitle";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { hederaLink } from "utils";

import styles from "./edition.module.scss";

const TopBlock = ({ editionData, totalHoldings, ownedCards }) => {
  const { t } = useContext(LocalizationContext);
  const handleRedirectBlockscaner = () => {
    window.open(`${hederaLink()}/${editionData.hederaTokenId}`, "_blank");
  };
  if (!editionData) {
    return (
      <Skeleton
        className={`${styles.priceBlock} ${styles.skeletonBg}`}
        variant="rectangular"
        sx={{ minHeight: 47 }}
      />
    );
  }

  return (
    <>
      <Box className={styles.block}>
        <Box className={styles.blockEl}>
          <Typography className={styles.blockTitle} variant="body2">
            {t("discover.editionPrice")}
          </Typography>
          <Box className={styles.blockData}>
            <PriceTitle {...editionData} />
          </Box>
        </Box>
        {ownedCards > 0 ? (
          <Box className={styles.blockEl}>
            <Typography className={styles.blockTitle} variant="body2">
              {t("edition.holdingsTitle")}
            </Typography>
            <Box className={styles.blockData}>
              <Typography variant="h4">{totalHoldings}</Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box className={styles.block}>
        <Box className={styles.blockEl}>
          <Typography className={styles.blockTitle} variant="body2">
            {t("edition.hederaIdLabel")}
          </Typography>
          <Box
            className={`${styles.blockData} ${styles.blockDataLink}`}
            onClick={() => handleRedirectBlockscaner(editionData.hederaTokenId)}
          >
            <Typography variant="h4">{editionData.hederaTokenId}</Typography>
            <IconRedirect className={styles.redirectIcon} />
          </Box>
        </Box>
        <Box className={styles.blockEl}>
          <Typography className={styles.blockTitle} variant="body2">
            {t("edition.cardsTotal")}
          </Typography>
          <Box className={styles.blockData}>
            <Typography variant="h4">
              {editionData.quantityAllocated}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default TopBlock;
