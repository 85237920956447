import { Box, Button, Tooltip, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";

import { socialNetworks } from "./config";
import { CloseIcon, LinkIcon } from "./icons";
import styles from "./ShareModal.module.scss";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement="top"
    PopperProps={{
      disablePortal: true,
    }}
    disableFocusListener
    disableHoverListener
    disableTouchListener
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.dark.main,
    color: "white",
    border: `1px solid white`,
    maxWidth: "300px",
    padding: "0 5px",
  },
  ".MuiTooltip-arrow": {
    color: "white",
  },
}));

export const ShareModal = ({ url = null, title }) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [open, setOpen] = useState(false);

  const handleClose = () => dispatch(closeModal());

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    navigator.clipboard.writeText(url);
  };

  return (
    <Box>
      <Button onClick={handleClose} className={styles.close}>
        <CloseIcon />
      </Button>
      <Typography variant="h4" className={styles.title}>
        {title}
      </Typography>
      <Box className={styles.row}>
        {socialNetworks.map(
          ({ icon, title, ActionButton, CustomActionButton, name, id }) =>
            CustomActionButton ? (
              <CustomActionButton key={id}>
                <Box className={styles.col}>
                  <Box className={styles.networkBtn}>{icon}</Box>
                  <Typography variant="body2">{name}</Typography>
                </Box>
              </CustomActionButton>
            ) : (
              <ActionButton url={url} title={title} key={id}>
                <Box className={styles.col}>
                  <Box className={styles.networkBtn}>{icon}</Box>
                  <Typography variant="body2">{name}</Typography>
                </Box>
              </ActionButton>
            )
        )}
      </Box>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box>
          <HtmlTooltip title={t("drops.copied")} open={open}>
            <Button
              variant="outlined"
              className={styles.linkBtn}
              onClick={handleTooltipOpen}
            >
              <LinkIcon />
              <Typography variant="textMediumBold2">
                {t("discover.copyLink")}
              </Typography>
            </Button>
          </HtmlTooltip>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};
