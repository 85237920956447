import { Box } from "@mui/material";
import cardSrc from "assets/img/card-bg.png";
import classNames from "classnames/bind";
import { CARD, CARD_VARIANTS } from "constants/card";
import { EDITION_STATUSES } from "constants/index";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./Card.module.scss";
import { FullCard } from "./Full/FullCard";
import { SmallCard } from "./Small/SmallCard";

const CONTENT = {
  [CARD_VARIANTS.SMALL]: {
    Main: SmallCard,
  },
  [CARD_VARIANTS.FULL]: {
    Main: FullCard,
  },
};

export const Card = ({
  variant = CARD_VARIANTS.SMALL,
  data,
  link,
  overrideStyles,
  isGrayedOut,
  isImg,
}) => {
  const cx = classNames.bind(styles);
  const location = useLocation();
  const navigate = useNavigate();
  const { status, imageUrl } = data || {};
  const { width, imgHeight } = CARD[variant];
  const hideImage = status === EDITION_STATUSES.phase0;
  const isSmall = variant === CARD_VARIANTS.SMALL;

  const { Main } = CONTENT[variant];

  const goToCard = (e) => {
    if (!e.target.classList.contains("global-path-link")) {
      navigate(link, { state: { from: location.pathname } });
    }
  };

  return (
    <Box
      className={cx(
        styles.card,
        overrideStyles?.root,
        isSmall && styles.cardSmall
      )}
      sx={{ width }}
      style={{ cursor: link ? "pointer" : "default" }}
      onClick={goToCard}
    >
      {isImg ? (
        <img
          alt="card"
          src={hideImage ? cardSrc : imageUrl}
          className={isGrayedOut ? styles.cardImgNotHolding : ""}
        />
      ) : (
        <Box
          className={cx(
            styles.cardImg,
            isGrayedOut && styles.cardImgNotHolding,
            overrideStyles?.img
          )}
          style={{
            backgroundImage: imageUrl && !hideImage ? `url(${imageUrl})` : null,
          }}
          sx={{ height: imgHeight }}
        />
      )}
      <Main data={data} className={styles.cardInfo} />
    </Box>
  );
};
