import { Box, Popover } from "@mui/material";
import { PATHS } from "constants/index";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deletePostReaction, sendPostReaction } from "services/api/feedApi";
import { isAuthenticated } from "services/auth.service";
import { useIsMobile } from "utils/hooks";
import styles from "views/Influencer/components/FeedTab/feed.module.scss";

import { PopoverContainer } from "./components/PopoverContainer/PopoverContainer";
import { ReactionsInfo } from "./components/ReactionsInfo/ReactionsInfo";

const Reactions = ({
  postId,
  userReaction,
  postSummary,
  accessGranted,
  post,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const isAuth = isAuthenticated();
  const handle = post?.influencer?.handle;
  const [anchorEl, setAnchorEl] = useState(null);
  const previousSelectedReactionRef = useRef();
  const [selectedReaction, setSelectedReaction] = useState(userReaction || []);
  const [allReactions, setAllReactions] = useState(
    postSummary.map((item) => {
      const selectedName = selectedReaction.find(
        (reaction) => reaction?.name === item.name
      );
      return {
        ...item,
        isSelected: selectedName,
      };
    })
  );

  const open = Boolean(anchorEl);
  const { entities: reactions, loading } = useSelector(
    (state) => state.feed.reactions
  );

  const openPopover = (event) => {
    if (isAuth && handle && !accessGranted) {
      handle && navigate(`/${handle}`);
      return;
    }
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleSelect = (e, value, isSelected) => {
    e.stopPropagation();
    if (isAuth && handle && !accessGranted) {
      handle && navigate(`/${handle}`);
      return;
    }
    let { id } = reactions.find((item) => item.name === value.name);
    if (isSelected) {
      deletePostReaction(postId, id).catch((e) => console.error(e));

      setSelectedReaction((prev) => {
        return prev.filter((reaction) => reaction.name !== value.name);
      });
    } else {
      sendPostReaction(postId, id).catch((e) => console.error(e));

      setSelectedReaction((prev) => {
        return [...prev, { ...value, isSelected: true }];
      });
    }

    closePopover(e);
  };

  const getDiff = (arr1, arr2) => {
    return arr1.find(
      (arr1Reaction) =>
        !arr2?.some((arr2Reaction) => arr2Reaction.name === arr1Reaction.name)
    );
  };

  const getDefaultValue = (selected) => {
    let res = 0;
    const postSummaryCount = postSummary.find(
      (reaction) => reaction.name === selected.name
    );
    const defaultSelection = userReaction.find(
      (reaction) => reaction.name === selected.name
    );
    if (postSummaryCount) {
      res += postSummaryCount.count;
      if (defaultSelection) {
        res -= 1;
      }
    }

    return res;
  };

  const updateReactionsList = useCallback(() => {
    const previousSelectedReaction = previousSelectedReactionRef.current;
    let newReactionsList = [...allReactions];

    if (previousSelectedReaction) {
      if (selectedReaction.length > previousSelectedReaction.length) {
        const selected = getDiff(selectedReaction, previousSelectedReaction);
        const isReactionInList = newReactionsList.find(
          (item) => item.name === selected.name
        );

        if (isReactionInList) {
          newReactionsList = newReactionsList.map((reaction) => {
            if (reaction.name === selected.name) {
              const count = getDefaultValue(selected);
              return {
                ...reaction,
                count: count + 1,
                isSelected: true,
              };
            }
            return reaction;
          });
        } else {
          newReactionsList.push({
            ...selected,
            count: 1,
            isSelected: true,
          });
        }
      } else {
        const selected = getDiff(previousSelectedReaction, selectedReaction);
        newReactionsList = newReactionsList
          .map((reaction) => {
            if (reaction?.name === selected?.name) {
              if (reaction.count > 1) {
                return {
                  ...reaction,
                  isSelected: false,
                  count: reaction.count - 1,
                };
              } else {
                return null;
              }
            }
            return reaction;
          })
          .filter((item) => item);
      }
    }

    previousSelectedReactionRef.current = selectedReaction;
    return newReactionsList;
  }, [selectedReaction]);

  useEffect(() => {
    setAllReactions(updateReactionsList());
  }, [updateReactionsList]);

  return (
    <>
      <Popover
        id={`${postId}-reactions`}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: isMobile ? -55 : -70,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={styles.reactionsPopoverWrapper}
        sx={{
          borderRadius: "12px",
        }}
      >
        <PopoverContainer
          handleSelect={handleSelect}
          selectedReaction={selectedReaction}
          data={reactions}
          loading={loading}
        />
      </Popover>
      <Box
        className={`${styles.postsBottomTitle} ${styles.reactionsList}`}
        aria-describedby={`${postId}-reactions`}
      >
        <ReactionsInfo
          handleSelect={handleSelect}
          openPopover={openPopover}
          selectedReaction={selectedReaction}
          allReactions={allReactions}
        />
      </Box>
    </>
  );
};

export default Reactions;
