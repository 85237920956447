import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { ReactComponent as Visibility } from "assets/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import AuthLeftSide from "components/AuthLeftSide";
import { AuthTypeSwitcher } from "components/AuthTypeSwitcher/AuthTypeSwitcher";
// import { ExternalAuthSection } from "components/ExternalAuthSection/ExternalAuthSection";
import { LanguageSelect } from "components/LanguageSelect/LanguageSelect";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import Analytics from "services/Analytics.service";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import {
  setAccessToken,
  setIdToken,
  setRefreshToken,
  setUUID,
} from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";
import styles from "styles/auth/Auth.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";
import { useIsMobile } from "utils/hooks";
import { useRedirectAfterAuth } from "utils/hooks";
import * as Yup from "yup";

import {
  ANALYTICS_EVENTS,
  AUTH_CELEBRITY_TYPE,
  AUTH_CREATOR,
  errorResponseMsgs,
  errorResponseTypes,
  PATHS,
} from "../../constants";
import { googleAuth, setPayloadToDashboard } from "../../utils/auth";

const Login = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location?.state || {};
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [serverError, setServerError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isFanAuth, setFanAuth] = useState(
    localStorage.getItem(AUTH_CELEBRITY_TYPE) === null
  );

  const loading = useSelector((state) => state.app.loader);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t("error.required")),
    email: Yup.string()
      .required(t("error.required"))
      .email(t("error.invalidEmail")),
  });

  const redirect = useRedirectAfterAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email,
    },
  });

  const setAuthUser = (data) => {
    if (isFanAuth) {
      setAccessToken(data.getAccessToken().getJwtToken());
      setIdToken(data.getIdToken().getJwtToken());
      setRefreshToken(data.getRefreshToken().getToken());
      setUUID();
      redirect();
      dispatch(setLoader(false));
    } else {
      setPayloadToDashboard(data);
    }
  };

  const onSubmit = (data) => {
    setServerError(false);
    dispatch(setLoader(true));
    const user = new CognitoUser({
      Username: data.email,
      Pool: UserPool(),
    });

    const authDetails = new AuthenticationDetails({
      Username: data.email,
      Password: data.password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (d) => {
        setAuthUser(d);
        Analytics.identify({ userId: data.email });
        Analytics.track(ANALYTICS_EVENTS.LOGIN);
      },
      mfaRequired: (codeDeliveryDetails, params) => {
        console.error("mfaRequired", codeDeliveryDetails, params);
        dispatch(setLoader(false));
      },
      onFailure: (error) => {
        console.error("onFailure", error);
        setTimeout(() => {
          dispatch(setLoader(false));
          setServerError(errorResponseMessages(error, t));
        }, 1500);
      },
      newPasswordRequired: function () {
        dispatch(setLoader(false));
        const path = isFanAuth
          ? PATHS.FORGOT_PASSWORD_FAN
          : PATHS.FORGOT_PASSWORD_CREATOR;
        navigate(path, {
          state: {
            email: watchEmail,
            passwordExpired: true,
            password: data.password,
          },
        });
        setServerError(
          errorResponseMessages(
            {
              code: errorResponseTypes.NotAuthorizedException,
              message: errorResponseMsgs.PasswordExpired,
            },
            t
          )
        );
      },
    });
  };

  const watchEmail = watch("email") || "";

  const setCelebrityType = () => {
    setFanAuth(false);
    localStorage.setItem(AUTH_CELEBRITY_TYPE, AUTH_CELEBRITY_TYPE);
  };

  useEffect(() => {
    googleAuth({ dispatch, navigate, isMainApp: isFanAuth });
    if (location.hash === AUTH_CREATOR) {
      setCelebrityType();
    }
  }, []);

  return (
    <>
      <Box className={`${styles.container} ${styles.signContainer}`}>
        {!isMobile && <AuthLeftSide className={styles.authLeftSide} />}
        <Box className={`${styles.rightSide} ${styles.signRightSide}`}>
          {!isMobile ? <LanguageSelect className={styles.langSelect} /> : null}
          <Box
            className={`${styles.rightSideContent} ${styles.signRightSideContent}`}
          >
            <Box className={styles.signRightSideContentBox}>
              <Typography
                variant="h3"
                component="h1"
                className={`${styles.title} ${styles.signTitle}`}
              >
                {t("auth.logInAs")}
              </Typography>
              {/*<ExternalAuthSection isFanAuth={isFanAuth} />*/}
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                className={`${styles.form} ${
                  !isFanAuth ? styles.formCeleb : ""
                } ${styles.formSign}`}
              >
                <FormControl>
                  <TextField
                    label={t("kyc.email")}
                    error={!!errors.email}
                    inputProps={{
                      onChange: (ev) =>
                        setData({ ...data, email: ev.target?.value }),
                    }}
                    className={`${roundedInputStyles.input} ${
                      roundedInputStyles.cyan
                    } ${watchEmail.length ? roundedInputStyles.filled : ""}`}
                    type="text"
                    autoComplete="off"
                    {...register("email")}
                  />
                  {!!errors.email ? (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {errors.email?.message}
                    </Typography>
                  ) : null}
                </FormControl>

                <FormControl>
                  <TextField
                    label={t("auth.password")}
                    error={!!errors.password}
                    className={`${roundedInputStyles.input} ${
                      roundedInputStyles.cyan
                    } ${
                      !!data.password.length ? roundedInputStyles.filled : null
                    }`}
                    inputProps={{
                      onChange: (ev) =>
                        setData({ ...data, password: ev.target?.value }),
                    }}
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    className={roundedInputStyles.showPasswordToggle}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  {!!errors.password ? (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {errors.password?.message}
                    </Typography>
                  ) : null}
                  {serverError ? (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {serverError}
                    </Typography>
                  ) : null}
                  <Link
                    to={
                      isFanAuth
                        ? PATHS.FORGOT_PASSWORD_FAN
                        : PATHS.FORGOT_PASSWORD_CREATOR
                    }
                    state={{ email: watchEmail }}
                    className={styles.forgotPasswordLink}
                  >
                    {t("auth.forgotYourPassword")}
                  </Link>
                </FormControl>
                <Box className={styles.signSubmitContainer}>
                  <FormControl>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      className={styles.submitBtn}
                      loading={loading}
                      disabled={!isValid}
                    >
                      {isFanAuth
                        ? t("auth.logInAsFan")
                        : t("auth.logInAsCelebrity")}
                    </LoadingButton>
                  </FormControl>
                  <Box className={styles.account}>
                    <Typography
                      sx={{
                        color: theme.palette.dark.contrastText,
                      }}
                      variant="body2"
                      className={styles.accountText}
                    >
                      {t("auth.noAccount")}&nbsp;&nbsp;
                      <Link to="/sign-up" state={{ email: watchEmail }}>
                        <Box
                          component="span"
                          sx={{
                            color: theme.palette.accent.main,
                          }}
                        >
                          {t("auth.signUp")}
                        </Box>
                      </Link>
                    </Typography>
                  </Box>
                  <AuthTypeSwitcher
                    isFanAuth={isFanAuth}
                    setFanAuth={setFanAuth}
                    text={
                      isFanAuth
                        ? t("auth.logInAsCelebrity")
                        : t("auth.logInAsFan")
                    }
                  />
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Login;
