import { Box } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames/bind";
import styles from "./ApplePay.module.scss";
import {
  validateApplePaySession,
  applePayCapture,
} from "services/api/paymentsApi";

import { setError } from "redux/appSlice";
import { LocalizationContext } from "services/localizationContext";
import { STATUS_FAILURE } from "constants";

let config = {
  payments: {
    acceptedCardSchemes: ["amex", "masterCard", "maestro", "visa", "mada"],
  },
  shop: {
    shop_name: "Zoop",
    shop_localisation: {
      currencyCode: "USD",
      countryCode: "US",
    },
  },
};
const isApplePayAvailable = function () {
  return window.ApplePaySession && window.ApplePaySession.canMakePayments();
};
var applePaySession = null;
const cx = classnames.bind(styles);

const ApplePay = ({
  onReady,
  onSuccess,
  onError,
  transferId,
  className,
  amount,
  locale,
}) => {
  const [showBtn, setShowBtn] = useState(false);

  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const init = () => {
    if (isApplePayAvailable()) {
      setShowBtn(true);
      onReady && onReady();
    } else {
      console.error("ApplePay is not available in this environment.");
      setShowBtn(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const performTransaction = async (details, callback) => {
    try {
      const response = await applePayCapture({
        transferId,
        tokenData: details.token,
      });
      callback(response);
    } catch (e) {
      console.error(e);
      onError();
    }
  };
  /**
   *
   * @param {object} Apple Pay Session (the one generate on the button click)
   *
   */
  const handleApplePayEvents = (appleSession) => {
    appleSession.oncancel = (event) => {
      onError();
    };
    appleSession.onvalidatemerchant = function (event) {
      onValidateApplePaySession(
        event.validationURL,
        function (merchantSession) {
          appleSession.completeMerchantValidation(merchantSession);
        }
      );
    };
    appleSession.onpaymentauthorized = function (event) {
      performTransaction(event.payment, function (outcome) {
        if (outcome?.status === STATUS_FAILURE) {
          appleSession.completePayment(applePaySession.STATUS_FAILURE);
          dispatch(
            setError({
              open: true,
              title: t("payments.applePayError"),
              subtitle: outcome.description,
            })
          );
          onError();
        } else {
          appleSession.completePayment(applePaySession.STATUS_SUCCESS);
          onSuccess(outcome);
        }
      });
    };
  };

  const startApplePaySession = (config) => {
    applePaySession = new window.ApplePaySession(6, config);
    handleApplePayEvents(applePaySession);
    applePaySession.begin();
  };

  /**
   *
   * @param {string} appleUrl The Apple Pay validation URL generated by Apple
   * @param {function} callback Callback function used to return the server call outcome
   *
   * @return {object} The session payload
   *
   */
  const onValidateApplePaySession = (appleUrl, callback) => {
    validateApplePaySession({ transferId, appleUrl })
      .then((response) => {
        callback(response);
      })
      .catch((e) => {
        console.error(e);
        onError();
        if (applePaySession) {
          applePaySession.abort();
          dispatch(
            setError({
              open: true,
              title: t("payments.applePayError"),
            })
          );
        }
      });
  };

  const onClick = () => {
    startApplePaySession({
      currencyCode: config.shop.shop_localisation.currencyCode,
      merchantCapabilities: [
        "supports3DS",
        "supportsEMV",
        "supportsCredit",
        "supportsDebit",
      ],
      supportedNetworks: config.payments.acceptedCardSchemes,
      countryCode: config.shop.shop_localisation.countryCode,

      total: {
        label: config.shop.shop_name,
        amount,
        type: "final",
      },
    });
  };

  return (
    <>
      {showBtn && (
        <Box
          locale={locale}
          className={cx(styles.applePayButton, className)}
          onClick={onClick}
        />
      )}
    </>
  );
};
export default ApplePay;
