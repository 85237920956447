import {
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import jwt_decode from "jwt-decode";
import up from "services/userPool";
import { v4 as uuidv4 } from "uuid";

import { EMULATE } from "../constants";
import { api } from "./api.service";
let userPool = up;

const API_URL = process.env.REACT_APP_API_HOST;

export const logout = () => {
  window.zE && window.zE("messenger", "logoutUser");
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(ZENDESK_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  removeEmulateId();
  removeInfluencerEmulateId();
};

const ACCESS_TOKEN = "dapple-access-token";
const REFRESH_TOKEN = "dapple-refresh-token";
const ID_TOKEN = "dapple-id-token";
export const UUID = "uuid";
export const INVITE_CODE = "invite-code";
export const ZENDESK_TOKEN = "zendesk-token";

const getParamsFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const emulateId = urlParams.get("emulateId");
  const username = urlParams.get("username");
  const refreshToken = urlParams.get("refreshToken");
  const idToken = urlParams.get("idToken");
  const accessToken = urlParams.get("accessToken");
  const userPoolId = urlParams.get("userPoolId");
  const clientId = urlParams.get("clientId");
  const emulateInfluencerId = urlParams.get("emulateInfluencerId");

  return {
    emulateId,
    username,
    refreshToken,
    idToken,
    accessToken,
    userPoolId,
    clientId,
    emulateInfluencerId,
  };
};

const handleEmulation = () => {
  const {
    emulateId,
    username,
    refreshToken,
    idToken,
    accessToken,
    userPoolId,
    clientId,
    emulateInfluencerId,
  } = getParamsFromURL();

  if (refreshToken && idToken && accessToken) {
    setAuth(
      {
        idToken,
        accessToken,
        refreshToken,
      },
      true
    );
    if (emulateInfluencerId) {
      setInfluencerEmulateId(emulateInfluencerId);
      removeEmulateId();
    } else if (emulateId) {
      setEmulateId(emulateId);
      removeInfluencerEmulateId();
    }

    if (username && userPoolId && clientId) {
      userPool = new CognitoUserPool({
        UserPoolId: userPoolId,
        ClientId: clientId,
      });
      const userData = {
        Username: username,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        (err, session) => {
          setAuth(session);
        }
      );
    }
  }
};

export const isAuthenticated = () => {
  handleEmulation();
  return getAccessToken() != null;
};

export const setAccessToken = (token) => {
  return localStorage.setItem(ACCESS_TOKEN, token);
};

export const setIdToken = (token) => {
  return localStorage.setItem(ID_TOKEN, token);
};

export const getIdToken = () => {
  return localStorage.getItem(ID_TOKEN);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const setRefreshToken = (token) => {
  return localStorage.setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const setUUID = () => {
  return localStorage.setItem(UUID, uuidv4());
};

export const setInviteCode = (code) => {
  return localStorage.setItem(INVITE_CODE, code);
};

export const getInviteCode = () => {
  return localStorage.getItem(INVITE_CODE);
};

export const removeInviteCode = () => {
  return localStorage.removeItem(INVITE_CODE);
};

export const setZendeskToken = (token) => {
  return localStorage.setItem(ZENDESK_TOKEN, token);
};

export const getZendeskToken = () => {
  return localStorage.getItem(ZENDESK_TOKEN);
};

export const setEmulateId = (emulateId) => {
  return localStorage.setItem(EMULATE.LOCAL_STORAGE_ID, emulateId);
};

export const getEmulateId = () => {
  return localStorage.getItem(EMULATE.LOCAL_STORAGE_ID);
};

export const removeEmulateId = () => {
  return localStorage.removeItem(EMULATE.LOCAL_STORAGE_ID);
};

export const setInfluencerEmulateId = (influencerEmulateId) => {
  return localStorage.setItem(
    EMULATE.INFLUENCER_EMULATE_ID,
    influencerEmulateId
  );
};

export const getInfluencerEmulateId = () => {
  return localStorage.getItem(EMULATE.INFLUENCER_EMULATE_ID);
};

export const removeInfluencerEmulateId = () => {
  return localStorage.removeItem(EMULATE.INFLUENCER_EMULATE_ID);
};

export const isEmulationMode = () => {
  return getEmulateId() || getInfluencerEmulateId();
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(UUID);
  localStorage.removeItem(INVITE_CODE);
  localStorage.removeItem(ZENDESK_TOKEN);
};

export const getTokens = () => {
  const accessToken = getAccessToken();
  const idToken = getIdToken();

  return (
    accessToken && {
      accessToken: idToken,
      expireAt: idToken && jwt_decode(accessToken)?.exp,
      refreshToken: getRefreshToken(),
      idToken: getIdToken(),
    }
  );
};

export const setAuth = (data, isCrossDomain = false) => {
  const accessToken = isCrossDomain
    ? data.accessToken
    : data.getAccessToken().getJwtToken();
  const idToken = isCrossDomain
    ? data.idToken
    : data.getIdToken().getJwtToken();
  const refreshToken = isCrossDomain
    ? data.refreshToken
    : data.getRefreshToken().getToken();
  accessToken && setAccessToken(accessToken);
  idToken && setIdToken(idToken);
  refreshToken && setRefreshToken(refreshToken);
  setUUID();
};

export const postPreSignupCheck = (params) => {
  return api
    .post(`${API_URL}/public/users/pre-signup-check`, params)
    .then((response) => response?.data);
};
