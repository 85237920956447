import { PAYMENT_TYPE } from "constants/index";
import { api } from "services/api.service";

const API_URL = process.env.REACT_APP_API_HOST;

export const getPaymentMethods = (params) => {
  let query = "";

  if (params && params.type === PAYMENT_TYPE.WITHDRAW) {
    query = `?filterFor=${PAYMENT_TYPE.WITHDRAW}`;
  }
  if (params && params.type === PAYMENT_TYPE.DEPOSIT) {
    query = `?filterFor=${PAYMENT_TYPE.DEPOSIT}`;
  }
  if (params && params.type === PAYMENT_TYPE.MARKET) {
    query = `?filterFor=${PAYMENT_TYPE.MARKET}`;
  }
  if (params && params.type === PAYMENT_TYPE.DROP) {
    query = `?filterFor=${PAYMENT_TYPE.DROP}`;
  }

  return api
    .get(`${API_URL}/app-users/me/payment-methods${query}`)
    .then((res) => res.data);
};

export const addFunds = (params) => {
  return api
    .post(`${API_URL}/payments/funds-add`, params)
    .then((res) => res.data);
};
export const withdrawFunds = async (params) => {
  try {
    const res = await api.post(`${API_URL}/payments/funds-withdraw`, params);
    if (res.error) {
      return res;
    }
    return res.data;
  } catch (e) {
    return e;
  }
};

export const getPGPKey = async () => {
  const res = await api.get(`${API_URL}/payments/circle-pgpkey`);
  return res.data;
};

export const addCard = async (params) => {
  const res = await api.post(`${API_URL}/payments/circle-add-card`, params);
  return res.data;
};

export const addBankAccount = async (params) => {
  const res = await api.post(`${API_URL}/payments/circle-add-bank`, params);
  return res.data;
};
export const getOnfidoToken = () => {
  return api.get(`${API_URL}/kyc/onfido-token`).then((res) => res.data);
};
export const onfidoCheck = () => {
  return api.post(`${API_URL}/kyc/onfido-check`).then((res) => res.data);
};
export const getWalletAddress = (params) => {
  return api
    .get(`${API_URL}/app-users/me/wallet-address`, { params })
    .then((res) => res.data);
};

export const paypalCapture = (trackingId) => {
  return api
    .post(`${API_URL}/payments/paypal-capture`, { trackingId })
    .then((response) => response.data);
};

export const validateApplePaySession = (params) => {
  return api
    .post(`${API_URL}/payments/applepay-validate`, params)
    .then((response) => response?.data || response);
};

export const applePayCapture = (params) => {
  return api
    .post(`${API_URL}/payments/applepay-capture`, params)
    .then((response) => response.data);
};
export const cancelOrder = (orderId) => {
  return api
    .delete(`${API_URL}/trading/orders/${orderId}/cancel`)
    .then((response) => response.data);
};

export const createStripeCheckoutSession = (influencerId) => {
  return api
    .post(`${API_URL}/payments/stripe-influencer-subscription`, {
      influencerId,
    })
    .then((response) => response.data);
};

export const cancelStripeCheckoutSession = (influencerId) => {
  return api
    .post(`${API_URL}/payments/stripe-influencer-unsubscribe`, {
      influencerId,
    })
    .then((response) => response.data);
};
export const renewStripeCheckoutSession = (influencerId) => {
  return api
    .post(`${API_URL}/payments/stripe-influencer-resubscribe`, {
      influencerId,
    })
    .then((response) => response.data);
};

export const stripeSetupPaymentMethod = () => {
  return api
    .post(`${API_URL}/payments/stripe-setup-payment-method`)
    .then((response) => response.data);
};

export const stripeSavePaymentMethod = (paymentMethodId) => {
  return api
    .post(`${API_URL}/payments/stripe-save-payment-method`, {
      paymentMethodId,
    })
    .then((response) => response.data);
};

export const getPaymentMethod = (params) => {
  return api
    .get(`${API_URL}/app-users/me/payment-methods`, {
      params,
    })
    .then((response) => response.data);
};

export const deletePaymentCard = (params) => {
  return api
    .post(`${API_URL}/payments/stripe-remove-payment-method`, params)
    .then((response) => response.data);
};

export const stripeInfluencerDonate = (params) => {
  return api
    .post(`${API_URL}/payments/stripe-influencer-donate`, params)
    .then((response) => response.data);
};
