export function filterByLastYear(data) {
  const yearAgo = new Date().setFullYear(new Date().getFullYear() - 1);
  return filterByPeriod(data, yearAgo);
}

export function filterByLastHalfYear(data) {
  const halfYear = new Date().setMonth(new Date().getMonth() - 6);
  return filterByPeriod(data, halfYear);
}

export function filterByLastMonth(data) {
  const monthAgo = new Date().setMonth(new Date().getMonth() - 1);
  return filterByPeriod(data, monthAgo);
}

export function filterByLastWeek(data) {
  const weekAgo = new Date().setDate(new Date().getDate() - 7);
  return filterByPeriod(data, weekAgo);
}

export function filterByPeriod(data, filterDate) {
  const now = new Date();
  return data.filter((d) => {
    const date = new Date(d.date).getTime();
    return date >= filterDate && date <= now.getTime();
  });
}
