import {
  Box,
  Button,
  Container,
  ToggleButton,
  Typography,
} from "@mui/material";
import { ReactComponent as AppleIcon } from "assets/svg/apple-icon.svg";
import { ReactComponent as CoinifyIcon } from "assets/svg/coinify-icon.svg";
import { ReactComponent as BtcIcon } from "assets/svg/payments/btc-icon.svg";
import { ReactComponent as PaypalIcon } from "assets/svg/payments/paypal-icon.svg";
import { ReactComponent as ZoopIcon } from "assets/svg/zoop-icon.svg";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import Placeholder from "components/Layout/EmptyBlock";
import {
  PATHS,
  PAYMENT_TYPE_APPLEPAY,
  PAYMENT_TYPE_BTC,
  PAYMENT_TYPE_COINIFY,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_ZOOP_WALLET,
} from "constants/index";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setError, setLoader } from "redux/appSlice";
import { getPaymentMethods } from "services/api/paymentsApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";

import { ANALYTICS_EVENTS } from "../../constants";
import Analytics from "../../services/Analytics.service";
import styles from "./Payments.module.scss";

const SelectPaymentMethod = () => {
  const location = useLocation();
  const prevRoute =
    location.state?.prevRoute || localStorage.getItem("prevRoute") || null;
  const nextRoute =
    location.state?.nextRoute ||
    location.state?.prevRoute ||
    localStorage.getItem("nextRoute") ||
    null;

  const amount = location.state?.amount;
  const paymentType =
    location.state?.paymentType || localStorage.getItem("paymentType") || null;
  const order = location.state.order;
  const edition = location.state.edition;

  const navigate = useNavigate();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);

  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const isLoaderShown = loading && !paymentMethods.length;
  const isApplePayAvailable = function () {
    return window.ApplePaySession && window.ApplePaySession.canMakePayments();
  };

  const sendAnalytics = () => {
    Analytics.page(location.pathname, {
      event: ANALYTICS_EVENTS.PAYMENT_PROGRESS,
      step: "1",
    });
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await getPaymentMethods({ type: paymentType });
      setPaymentMethods(res);
    } catch (e) {
      dispatch(setError({ open: true, subtitle: errorResponseMessages(e, t) }));
    } finally {
      setLoading(false);
    }
  };
  const PaymentTypeIcon = ({ type }) => {
    if (type === PAYMENT_TYPE_APPLEPAY) return <AppleIcon />;
    if (type === PAYMENT_TYPE_PAYPAL) return <PaypalIcon />;
    if (type === PAYMENT_TYPE_BTC) return <BtcIcon />;
    if (type === PAYMENT_TYPE_ZOOP_WALLET) return <ZoopIcon />;
    if (type === PAYMENT_TYPE_COINIFY) return <CoinifyIcon />;

    return "";
  };

  useEffect(() => {
    if (prevRoute) localStorage.setItem("prevRoute", prevRoute);
    if (nextRoute) localStorage.setItem("nextRoute", nextRoute);
    if (paymentType) localStorage.setItem("paymentType", paymentType);
    getData();
  }, []);

  useEffect(() => {
    sendAnalytics();
  }, []);

  useEffect(() => {
    dispatch(
      setLoader(
        isLoaderShown
          ? {
              title: t("default.loading"),
              subtitle: t("default.itMayTakeAFewSeconds"),
            }
          : false
      )
    );
  }, [loading, paymentMethods.length]);

  const onSelect = ({ id, name, type }) => {
    if (nextRoute) {
      navigate(nextRoute, {
        state: {
          paymentMethod: { id, name, type },
          amount,
          order,
          edition,
        },
        // replace: true,
      });
    } else {
      setSelected(id);
    }
    localStorage.removeItem("nextRoute");
    localStorage.removeItem("prevRoute");
  };

  if (isLoaderShown) return null;

  const isEmptyContent = !paymentMethods?.length && !loading;
  const handleClick = () => {
    navigate(PATHS.ADD_CARD, { state: location.state });
  };

  return (
    <Container className={styles.containerSm}>
      <Box className={styles.wrapper}>
        <BackBtn
          link={prevRoute}
          isPlain
          title={
            location.state?.title || t("payments.selectPaymentMethodTitle")
          }
        />
        <Box className={styles.mainTitle}>
          <Typography variant="h3">
            {location.state?.title || t("payments.selectPaymentMethodTitle")}
          </Typography>
        </Box>
      </Box>
      <Container className={`${styles.cardContainer} ${styles.innerContainer}`}>
        {isEmptyContent ? (
          <Placeholder text={t("empty.noConnectedAccounts")} />
        ) : (
          <Box className={styles.paymentMethodsWrap}>
            {paymentMethods.map((item) => {
              if (item.type === PAYMENT_TYPE_APPLEPAY && !isApplePayAvailable())
                return false;
              return (
                <ToggleButton
                  className={styles.toggleBtn}
                  key={item.id}
                  value={item.id}
                  selected={selected === item.id}
                  onChange={() => onSelect(item)}
                >
                  <Box className={styles.toggleBtnText}>
                    {item.name}
                    <PaymentTypeIcon type={item.type} />
                  </Box>
                </ToggleButton>
              );
            })}
          </Box>
        )}
        {/*<Box className={[styles.actions, styles.singleBtn]}>*/}
        {/*  <Button variant="contained" onClick={handleClick}>*/}
        {/*    {t("payments.addNewCard")}*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Container>
    </Container>
  );
};
export default SelectPaymentMethod;
