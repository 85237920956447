import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  FormControl,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { ReactComponent as ErrorIcon } from "assets/svg/payments/error.svg";
import mastercardIcon from "assets/svg/payments/mastercard.png";
import visaIcon from "assets/svg/payments/visa.png";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { ANALYTICS_EVENTS, CARD_TYPES } from "constants/";
import * as openpgp from "openpgp";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchPaymentKey } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { isValidCreditCardNumber } from "utils/stringHelper";
import * as Yup from "yup";

import Analytics from "../../../services/Analytics.service";
import styles from "./AddCard.module.scss";

const CurdNumberInput = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000 0000 0000 0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CardExpireDateInput = React.forwardRef(function TextMaskCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00/00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CurdCVVInput = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
Yup.addMethod(Yup.string, "validateLatinLetter", function (errorMessage) {
  return this.test(`is-valid-string`, errorMessage, function (value) {
    return /^[a-zA-Z\s]+$/i.test(value);
  });
});
const AddCard = () => {
  const { t } = useContext(LocalizationContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    cardHolderName: Yup.string()
      .trim()
      .validateLatinLetter(t("error.onlyEnglishLetter"))
      .test(
        "cardName",
        t("error.cardholderNameError"),
        (value) => !(value && value.split(" ").length < 2)
      )
      .required(t("error.required")),
    cardNumber: Yup.string()
      .trim()
      .required(t("error.required"))
      .test(
        "cardType",
        t("error.cardTypeError"),
        (value) => !(value && !isValidCreditCardNumber(value))
      ),
    expireDate: Yup.string()
      .trim()
      .required(t("error.required"))
      .test("expDate", t("error.expDateError"), (value) => {
        if (!value) return true;
        const exp = value?.split("/");
        const expMonth = parseInt(exp[0]);
        const expYear = parseInt(exp[1]) + 2000;
        const today = new Date();

        let expDate = new Date();
        expDate.setFullYear(expYear, expMonth, 1);
        return expDate >= today;
      }),
    cvv: Yup.string()
      .trim()
      .required(t("error.required"))
      .min(3, t("error.cvvError")),
  });
  const renderCardType = (value) => {
    const cardType = isValidCreditCardNumber(value);
    return (
      <>
        {(!value || cardType === CARD_TYPES.MASTERCARD) && (
          <img src={mastercardIcon} alt="mastercard" />
        )}
        {(!value || cardType === CARD_TYPES.VISA) && (
          <img src={visaIcon} alt="visa" className={styles.visaIcon} />
        )}
        {value && errors.cardNumber && <ErrorIcon />}
      </>
    );
  };
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    Analytics.track(location.pathname, {
      event: ANALYTICS_EVENTS.PAYMENT_PROGRESS,
      step: "2",
    });
  }, []);

  const handleFormSubmit = (data) => {
    setLoading(true);
    handlePayment(data);
  };

  const handlePayment = async (data) => {
    const key = await dispatch(fetchPaymentKey())
      .unwrap()
      .then((data) => {
        return data;
      });

    const cardDetails = {
      number: data.cardNumber.trim().replace(/\D/g, ""),
      cvv: data.cvv,
    };

    const publicKey = key.publicKey;
    const message = await openpgp.createMessage({
      text: JSON.stringify(cardDetails),
    });

    const decodedPublicKey = await openpgp.readKey({
      armoredKey: atob(publicKey),
    });

    const encryptedData = await openpgp.encrypt({
      message,
      encryptionKeys: decodedPublicKey,
    });
    setLoading(false);

    navigate("/add-card/billing-address", {
      state: {
        encryptedData: window.btoa(encryptedData),
        expire: data.expireDate,
        cardHolderName: data.cardHolderName,
        keyId: key.keyId,
      },
    });
  };
  const title = t("payments.addNewCard");
  return (
    <Container className={styles.container}>
      <Box className={styles.wrapper}>
        <Box className={styles.backBtnWrap}>
          <BackBtn className={styles.backBtn} isPlain title={title} />
        </Box>
        <Box className={styles.mainTitle}>
          <Typography variant="h3">{title}</Typography>
        </Box>
      </Box>
      <Container className={`${styles.cardContainer}`}>
        <Box className={`${styles.form} ${styles.addCardForm}`}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormControl className={styles.formControlInput}>
              <label className={styles.label}>
                {t("payments.cardHolderName")}
              </label>
              <Input
                placeholder={t("payments.cardHolderName")}
                {...register("cardHolderName")}
                error={!!errors.cardHolderName}
                autoComplete="cc-name"
                className={styles.input}
              />
              <Typography variant="error" className={styles.inputError}>
                {errors.cardHolderName?.message}
              </Typography>
            </FormControl>
            <FormControl className={styles.formControlInput}>
              <label className={styles.label}>{t("payments.cardNumber")}</label>
              <Controller
                control={control}
                name="cardNumber"
                rules={{ required: t("error.required") }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                }) => (
                  <Input
                    value={getValues("cardNumber")}
                    error={!!errors.cardNumber}
                    placeholder="XXXX XXXX XXXX XXXX"
                    onChange={onChange}
                    className={`${styles.input} ${styles.cardNumberInp}`}
                    autoComplete="cc-number"
                    inputComponent={CurdNumberInput}
                    endAdornment={
                      <InputAdornment position="end">
                        {renderCardType(value)}
                      </InputAdornment>
                    }
                  />
                )}
              />

              <Typography variant="error" className={styles.inputError}>
                {errors.cardNumber?.message}
              </Typography>
            </FormControl>

            <Box className={styles.row}>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("payments.expireDate")}
                </label>
                <Controller
                  control={control}
                  name="expireDate"
                  rules={{ required: t("error.required") }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <Input
                      value={getValues("expireDate")}
                      error={!!errors.expireDate}
                      placeholder={"MM/YY"}
                      autoComplete="cc-exp"
                      onChange={onChange}
                      className={styles.input}
                      inputComponent={CardExpireDateInput}
                    />
                  )}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.expireDate?.message}
                </Typography>
              </FormControl>
              <FormControl
                className={`${styles.formControlInput} ${styles.cvvInp}`}
              >
                <label className={styles.label}>{t("payments.cvv")}</label>
                <Controller
                  control={control}
                  name="cvv"
                  rules={{ required: t("error.required") }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <Input
                      value={getValues("cvv")}
                      autoComplete="cc-csc"
                      error={!!errors.cvv}
                      placeholder="XXX"
                      onChange={onChange}
                      className={styles.input}
                      inputComponent={CurdCVVInput}
                    />
                  )}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.cvv?.message}
                </Typography>
              </FormControl>
            </Box>

            <FormControl>
              <LoadingButton
                type="submit"
                variant="outlined"
                className={styles.btnContinue}
                loading={loading}
                disabled={!isValid}
              >
                {t("kyc.continueButton")}
              </LoadingButton>
            </FormControl>
          </form>
        </Box>
      </Container>
    </Container>
  );
};
export default AddCard;
