import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import CustomTooltip from "components/Tooltip";
import { MODAL_TYPE, PATHS } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSnackbar } from "redux/appSlice";
import {
  blockChatToggle,
  deleteChatSlice,
  fetchChat,
  postMessage,
  setActiveChat,
} from "redux/messengerSlice";
import { openModal } from "redux/modalSlice";
import { getActiveMessagesData } from "redux/selectors/messengerSelectors";
import { LocalizationContext } from "services/localizationContext";
import { blockUserById, unblockUserById } from "services/userApi";
import { goToInfluencer } from "utils/index";

import {
  ArrowLeftIcon,
  BlockProfileIcon,
  DeleteIcon,
  ProfileIcon,
  ReportIcon,
} from "../../../../icons";
import stylesMain from "../../../../Messenger.module.scss";
import stylesChatContainer from "../../ChatContainer.module.scss";
import { Messages } from "../Messages/Messages";
import { SearchContainer } from "../SearchContainer/SearchContainer";

export const ChatContainerDataWrapper = ({
  activeChat,
  chatsType,
  firstCreatedChatMessageTimestamp,
  setChatsType,
  chatCategory,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [loader, setLoader] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const messagesData = useSelector(getActiveMessagesData(activeChat?.id));

  const goToProfile = (e) => {
    e.stopPropagation();
    if (activeChat?.otherUser?.isInfluencer) {
      goToInfluencer({
        id: activeChat.otherUser.id,
        handle: activeChat.otherUser.username,
        navigate,
        location,
      })();
    } else {
      navigate(`/public/user/${activeChat?.otherUser.username}`);
    }
  };

  const reportHandler = () => {
    dispatch(
      openModal({
        type: MODAL_TYPE.REPORT_MODAL,
        recordId: activeChat.otherUser.id,
        contentType: "user",
      })
    );
  };

  const blockToggle = (e) => {
    e.stopPropagation();
    setLoader(true);
    if (activeChat?.otherUser?.id) {
      const handler = !activeChat?.chatBlocked
        ? blockUserById
        : unblockUserById;
      handler(activeChat.otherUser.id)
        .then(() => {
          dispatch(
            blockChatToggle({
              chatId: activeChat.id,
              chatBlocked: !activeChat?.chatBlocked,
            })
          );
          dispatch(
            setSnackbar({
              open: true,
              message: !activeChat?.chatBlocked
                ? t("messages.chatBlocked")
                : t("messages.chatUnblocked"),
            })
          );
        })
        .finally(() => setLoader(false));
    }
  };

  const loadMoreMessages = (prevId) => {
    if (prevId) {
      dispatch(fetchChat({ chatId: activeChat.id, prev: prevId, limit: 35 }));
    }
  };

  const resetActiveChat = (e) => {
    e.stopPropagation();
    dispatch(setActiveChat(null));
    navigate(PATHS.CHAT, { replace: true, state: window.history.state });
  };

  const clearChatHandler = (e) => {
    e.stopPropagation();
    setLoader(true);
    setTooltipOpen(false);
    dispatch(deleteChatSlice({ chatId: activeChat.id, type: chatCategory }))
      .then(() => {
        navigate(PATHS.CHAT);
        setTimeout(() => {
          dispatch(setActiveChat(null));
        }, 0);
      })
      .catch(() => setLoader(false));
  };

  useEffect(() => {
    // We don't need post first message because it has been already created with createChat
    // need to post the rest of messages if user has written more than one on createChat EP
    if (firstCreatedChatMessageTimestamp && messagesData?.messages) {
      const arr = Object.values(messagesData?.messages);
      if (arr.length) {
        arr.forEach((item) => {
          if (firstCreatedChatMessageTimestamp !== item.timestamp) {
            dispatch(
              postMessage({
                chatId: activeChat.id,
                messageContent: item.messageContent,
                timestamp: item.timestamp,
              })
            );
          }
        });
      }
    }
  }, [firstCreatedChatMessageTimestamp]);

  return (
    <>
      {activeChat?.id ? (
        <Box className={stylesChatContainer.containerTop}>
          <Button
            className={stylesChatContainer.containerTopArrow}
            onClick={resetActiveChat}
          >
            <ArrowLeftIcon />
          </Button>
          <img
            src={activeChat.otherUser?.profileImageUrl}
            alt="avatar"
            className={
              activeChat.otherUser?.isInfluencer
                ? stylesChatContainer.influencerAvatar
                : ""
            }
          />
          <Box className={stylesChatContainer.containerNameWrapper}>
            <Typography
              variant="body4"
              className={stylesChatContainer.containerName}
            >
              {activeChat.otherUser?.username}
            </Typography>
          </Box>
          <CustomTooltip
            isArrow={false}
            isClick={true}
            open={tooltipOpen}
            setOpen={setTooltipOpen}
            title={
              <Box>
                <Box className={stylesMain.tooltipRow} onClick={goToProfile}>
                  <ProfileIcon />
                  <Typography variant="body2">
                    {t("tradehistory.viewProfile")}
                  </Typography>
                </Box>
                {activeChat?.otherUser?.id ? (
                  <>
                    <Box
                      className={stylesMain.tooltipRow}
                      onClick={blockToggle}
                    >
                      <BlockProfileIcon />
                      <Typography variant="body2">
                        {!activeChat?.chatBlocked
                          ? t("account.block")
                          : t("account.unblock")}
                      </Typography>
                    </Box>
                    <Box
                      className={stylesMain.tooltipRow}
                      onClick={reportHandler}
                    >
                      <ReportIcon />
                      <Typography variant="body2">
                        {t("account.report")}
                      </Typography>
                    </Box>
                  </>
                ) : null}
                <Box
                  className={stylesMain.tooltipRow}
                  onClick={clearChatHandler}
                >
                  <DeleteIcon />
                  <Typography
                    variant="body5"
                    style={{ color: theme.palette.error.main }}
                  >
                    {t("messages.deleteChat")}
                  </Typography>
                </Box>
              </Box>
            }
          >
            <Button
              className={stylesChatContainer.tooltipButton}
              onClick={() => setTooltipOpen(true)}
            >
              <DotsEditIcon />
            </Button>
          </CustomTooltip>
        </Box>
      ) : activeChat?.isNewMessageTo ? (
        <SearchContainer
          classNameWrapper={stylesChatContainer.containerTop}
          setChatsType={setChatsType}
        />
      ) : null}
      <Messages
        loadMore={loadMoreMessages}
        activeChat={activeChat}
        chatsType={chatsType}
        loader={loader}
      />
    </>
  );
};
