import { Box, Button, Skeleton, Typography } from "@mui/material";
import { ReactComponent as GradeIcon } from "assets/svg/grade-icon.svg";
import classNames from "classnames/bind";
import { HeaderSection } from "components/HeaderSection/HeaderSection";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./SidebarBox.module.scss";

export const SidebarBox = ({
  title,
  amount,
  path,
  data,
  imgStyle,
  onItemClick,
  loading,
  emptyStateIcon,
  emptyStateTitle,
  emptyStateActionText,
  emptyStateAction,
  itemClass,
}) => {
  const { t } = useContext(LocalizationContext);

  const itemClickHandler = (data) => {
    if (onItemClick) {
      onItemClick(data);
    }
  };

  const itemClasses = classNames(styles.item, {
    [itemClass]: itemClass,
  });

  return (
    <Box className={styles.wrapper}>
      <HeaderSection
        title={
          <Typography className={styles.title} variant="body4">
            {title}{" "}
            <Typography className="opacity_07" variant="body1">
              ({amount})
            </Typography>
          </Typography>
        }
        path={amount > 3 ? path : null}
        buttonText={amount > 3 ? t("default.viewAll") : null}
        wrapperClass={styles.header}
      />
      {!data.length && !loading ? (
        <Box className={styles.empty}>
          {emptyStateIcon}
          <Box className={styles.emptyContent}>
            <Typography variant="body1Display">{emptyStateTitle}</Typography>
            <Box>
              <Button onClick={emptyStateAction}>{emptyStateActionText}</Button>
            </Box>
          </Box>
        </Box>
      ) : null}
      {loading && !data.length ? (
        <>
          <Skeleton className={styles.itemSkeleton} variant="rectangular" />
          <Skeleton className={styles.itemSkeleton} variant="rectangular" />
          <Skeleton className={styles.itemSkeleton} variant="rectangular" />
        </>
      ) : null}
      {data.slice(0, 3).map((item) => (
        <Box
          key={item.id}
          className={itemClasses}
          onClick={() => itemClickHandler(item)}
          style={{ cursor: onItemClick ? "pointer" : "initial" }}
        >
          <Box className={styles.itemImgWrapper}>
            <img
              src={item.picture}
              alt="picture"
              className={styles.itemImg}
              style={imgStyle}
            />
            {item.goalCount ? (
              <Box className={styles.badgeGoal}>{item.goalCount}</Box>
            ) : null}
          </Box>
          <Box className={styles.itemContent}>
            <Typography className={styles.itemTitle} variant="body1">
              {item.title}
            </Typography>
            {item.stageNum ? (
              <Typography
                className={`opacity_07 ${styles.itemStage}`}
                variant="body2"
              >
                <GradeIcon />
                {t("challenges.stage", { rank: item.stageNum })}
              </Typography>
            ) : null}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
