import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/arrow-down.svg";
import Proptypes from "prop-types";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { convertSecondsToDDHHMMSS, getFormattedDate } from "utils/dateHelper";

import styles from "./meta-statistics.module.scss";

export default function MetaStatistics({
  tradesTotal,
  holdersTotal,
  cardsTotal,
  dropTimeAvg,
  dropTime,
  topSalePrice,
  bidWinRatio,
  dropBiddersAvg,
  lastTradedAt,
  defaultExpanded,
}) {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={styles.accordionWrap}>
      <Accordion className={styles.accordion} defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="meta-statistics"
          id="meta-stat"
        >
          <Typography variant="h5_medium">
            {t("discover.metaStatistics")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.infoWrap}>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.numberOfTradesSinceDrop")}
            </Typography>
            <Typography variant="subtitle1" component="span">
              {tradesTotal}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.numberOfOwners")}
            </Typography>
            <Typography variant="subtitle1" component="span">
              {holdersTotal}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.dropTime")}
            </Typography>
            <Typography variant="subtitle1" component="span">
              {convertSecondsToDDHHMMSS(dropTimeAvg || dropTime)}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.topSales")}
            </Typography>
            <Typography variant="subtitle1" component="span">
              ${topSalePrice}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.lastTraded")}
            </Typography>
            <Typography variant="subtitle1" component="span">
              {getFormattedDate(lastTradedAt)}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

MetaStatistics.defaultProps = {
  tradesTotal: 0,
  holdersTotal: 0,
  cardsTotal: 0,
  dropTimeAvg: 0,
  topSalePrice: 0,
  bidWinRatio: 0,
  lastTradedAt: null,
};

MetaStatistics.propTypes = {
  tradesTotal: Proptypes.number,
  holdersTotal: Proptypes.number,
  cardsTotal: Proptypes.number,
  dropTimeAvg: Proptypes.number,
  topSalePrice: Proptypes.number,
  bidWinRatio: Proptypes.number,
  lastTradedAt: Proptypes.string,
};
