import { CognitoUserPool } from "amazon-cognito-identity-js";

import { AUTH_CELEBRITY_TYPE } from "../constants";

const userPoolId = process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID;
const clientId = process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID;

const celebrityPoolId = process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID_CELEBRITY;
const celebrityClientId =
  process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID_CELEBRITY;

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};

const celebrityData = {
  UserPoolId: celebrityPoolId,
  ClientId: celebrityClientId,
};

const getCognitoUserPool = (isCelebType) => {
  if (localStorage.getItem(AUTH_CELEBRITY_TYPE) || isCelebType) {
    return new CognitoUserPool(celebrityData);
  } else {
    return new CognitoUserPool(poolData);
  }
};

export default getCognitoUserPool;
