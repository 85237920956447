import { Box, Skeleton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CountdownDate from "components/CountdownDate/CountdownDate";
import CountdownProgress from "components/CountdownProgress";
import { DescriptionCard } from "components/DescriptionCard/DescriptionCard";
import { CHANNELS, EVENTS, ORDER_TYPES } from "constants/index";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateDrop } from "redux/dropsSlice";
import { getInfluencerSeason } from "services/influencerApi";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";

import { fetchEditionMarketStatus } from "../../../redux/editionSlice";
import { CardsList } from "./components/CardsLIst/CardsList";
import styles from "./DropDetail.module.scss";

const seasonInitialState = {
  influencer: {},
  season: {
    title: "",
    description: "",
    seasonNum: 1,
  },
  seasonCards: [],
};

const Phase0DropDetail = ({ card, className, buyAlertHandler }) => {
  const params = useParams();
  const id = params.id;
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [seasonLoader, setSeasonLoader] = useState(false);
  const [seasonData, setSeasonData] = useState(seasonInitialState);

  const seasonCards = seasonData?.seasonCards.filter(
    (item) => item.id !== card.id
  );

  useEffect(() => {
    const { id } = card.influencer;
    setSeasonLoader(true);
    getInfluencerSeason({ id })
      .then((data) => setSeasonData(data))
      .finally(() => setSeasonLoader(false));
  }, [card.influencer.id]);

  useEffect(() => {
    const channelDrop = PusherService.subscribe(CHANNELS.DROPS);

    channelDrop.bind(EVENTS.STATUS_CHANGED, (data) => {
      dispatch(updateDrop(data));
    });

    return () => {
      PusherService.unsubscribe(CHANNELS.DROPS);
    };
  }, []);

  dispatch(fetchEditionMarketStatus({ id, type: ORDER_TYPES.sell }));

  return (
    <Box className={className}>
      <Box className={styles.framedBox}>
        <Box className="App-flexCenter App-flexCenter-between" mb={1}>
          <Typography variant="textMediumBold" className={styles.title}>
            {t("drops.droppingIn")}
          </Typography>
          <Typography
            variant="textLime"
            className={`${styles.timer} ${styles.timerPhase0}`}
          >
            <CountdownDate endDate={card.dropStartDate} />
          </Typography>
        </Box>
        <CountdownProgress
          startDate={card.createdAt}
          endDate={card.dropStartDate}
          color={theme.palette.lime}
        />

        <Box className={styles.description}>
          {t("drops.remainingDropZeroSubTitle", {
            dropAllocation: card.quantityAllocated,
          })}
        </Box>
      </Box>
      {seasonLoader ? (
        <Skeleton
          className={styles.seasonDescriptionSkeleton}
          variant="rectangular"
        />
      ) : null}
      {card.type === "custom" ? (
        <>
          <DescriptionCard
            className={styles.seasonDescription}
            buyAlertHandler={buyAlertHandler}
            {...card}
            title={card.name}
          />
          <CardsList
            seasonCards={card.otherCards}
            title={`${t("drops.otherCardsFrom")} ${card.influencer.name}`}
          />
        </>
      ) : null}
      {card.type !== "custom" ? (
        <>
          <DescriptionCard
            title={seasonData?.season.title}
            description={seasonData?.season.description}
            seasonNum={seasonData?.season.seasonNum}
            className={styles.seasonDescription}
          />
          {seasonLoader ? (
            <Skeleton
              className={styles.cardsListSkeleton}
              variant="rectangular"
            />
          ) : null}
          <CardsList
            seasonCards={seasonCards}
            seasonNum={seasonData?.season.seasonNum}
          />
        </>
      ) : null}
    </Box>
  );
};
export default Phase0DropDetail;
