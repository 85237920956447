import { Box, Skeleton, Typography } from "@mui/material";
import { ViewAll } from "components/ViewAll/ViewAll";

import styles from "./SlideBox.module.scss";

export const SlideBox = ({
  title,
  children,
  isLoading,
  isShow,
  path,
  buttonText,
}) => {
  if (isLoading) {
    return (
      <Skeleton variant="rectangular" className={styles.containerSkeleton} />
    );
  }

  if (!isShow && !isLoading) {
    return null;
  }

  return (
    <Box className={styles.container}>
      <Typography variant="h4" className={styles.containerTitle}>
        {title}
      </Typography>
      {children}
      {path ? (
        <Box className={styles.containerFooter}>
          <ViewAll text={buttonText} path={path} isArrow={true} />
        </Box>
      ) : null}
    </Box>
  );
};
