import { Box } from "@mui/material";
import React, { useContext, useLayoutEffect, useRef } from "react";
import { LocalizationContext } from "services/localizationContext";

import { AllStars } from "../../../../InfluencersRows/AllStars/AllStars";
import { EmptyState } from "../../../../InfluencersRows/EmptyState/EmptyState";
import { Groups } from "../../../../InfluencersRows/Groups/Groups";
import { RisingStars } from "../../../../InfluencersRows/RisingStars/RisingStars";
import styles from "./SearchResultList.module.scss";

export const SearchResultList = ({ data, loaders }) => {
  const { t } = useContext(LocalizationContext);
  const refWrapper = useRef();

  const isEmpty =
    !data.allStars?.length &&
    !data.risingStars?.length &&
    !data.groups?.length &&
    !loaders.allStarsLoader &&
    !loaders.risingStarsLoader &&
    !loaders.groupsLoader;

  useLayoutEffect(() => {
    if (refWrapper?.current) {
      const { top } = refWrapper.current.getBoundingClientRect();
      refWrapper.current.style.height = `${window.innerHeight - top}px`;
    }
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return (
    <Box ref={refWrapper} className={styles.wrapper}>
      {isEmpty ? (
        <EmptyState />
      ) : (
        <>
          <AllStars
            data={data.allStars}
            loading={loaders.allStarsLoader}
            className={styles.allStars}
            isHideViewAll
            subHeaderTitle={t("discover.creators")}
          />
          <RisingStars
            data={data.risingStars}
            loading={loaders.risingStarsLoader}
            isHideHeader={data.allStars?.length}
            isHideViewAll
          />
          <Groups
            data={data.groups}
            loading={loaders.groupsLoader}
            isHideViewAll
          />
        </>
      )}
    </Box>
  );
};
