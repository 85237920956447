import { Box, Skeleton, Typography } from "@mui/material";
import { DescriptionCard } from "components/DescriptionCard/DescriptionCard";
import OrderHistory from "components/Drops/OrderHistory";
import Statistics from "components/Drops/Statistics";
import PriceChart from "components/PriceChart";
import { CARD_TYPES } from "constants/card";
import { CHANNELS, EVENTS } from "constants/index";
import { useContext, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchDropHistory, isDropFinished, updateDrop } from "redux/dropsSlice";
import { fetchUser } from "redux/usersSlice";
import { fetchTransfers } from "redux/usersSlice";
import { isAuthenticated } from "services/auth.service";
import { getInfluencerSeason } from "services/influencerApi";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";

import { CardsList } from "./components/CardsLIst/CardsList";
import styles from "./DropDetail.module.scss";

const seasonInitialState = {
  influencer: {},
  season: {
    title: "",
    description: "",
    seasonNum: 1,
  },
  seasonCards: [],
};

const Phase2DropDetail = ({ className, card, buyAlertHandler }) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const history = useSelector((state) => state.drops.history.entities);

  const [seasonLoader, setSeasonLoader] = useState(false);
  const [seasonData, setSeasonData] = useState(seasonInitialState);

  const { id } = useParams();

  const { lastOrders = [] } = card;

  const seasonCards = seasonData?.seasonCards.filter(
    (item) => item.id !== card.id
  );

  useEffect(() => {
    const channelDrop = PusherService.subscribe(CHANNELS.DROPS);

    channelDrop.bind(EVENTS.STATUS_CHANGED, (data) => {
      dispatch(updateDrop(data));
    });
    channelDrop.bind(EVENTS.PRICE_CHANGED, (data) => {
      dispatch(updateDrop(data));
    });
    channelDrop.bind(EVENTS.BUY, (data) => {
      dispatch(updateDrop(data));
    });
    return () => {
      PusherService.unsubscribe(CHANNELS.DROPS);
    };
  }, []);

  useEffect(() => {
    const { id } = card.influencer;
    setSeasonLoader(true);
    getInfluencerSeason({ id })
      .then((data) => {
        data && setSeasonData(data);
      })
      .finally(() => setSeasonLoader(false));
  }, [card.influencer.id]);

  const orders = lastOrders?.map((order) => ({
    ...order,
    date: order.createdAt,
  }));
  const isFinished = useSelector((state) => isDropFinished(state));
  const [userLocale, setUserLocale] = useState(null);
  const phaseDetailsRef = useRef(null);

  useEffect(() => {
    let unmounted = false;

    dispatch(fetchDropHistory({ cardId: id }));

    if (isAuthenticated()) {
      dispatch(fetchTransfers({ cardId: id }));
      dispatch(fetchUser())
        .unwrap()
        .then((d) => !unmounted && setUserLocale(d.language));
      return () => (unmounted = true);
    }
  }, [id]);

  const StatusBlock = () => (
    <Box>
      <Box className={styles.statuses}>
        {isFinished ? (
          <Box className={styles.labelText}>{t("drops.dropFinished")}</Box>
        ) : (
          <Box className={styles.labelText}>
            {t("drops.editionsLeft", {
              count: card.saleTokensLeft,
              useSingular: true,
            })}
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <>
      <Box ref={phaseDetailsRef} className={className}>
        <Box className={`${styles.phase2DetailsHeader} ${styles.framedBox}`}>
          <Box className={styles.priceWrap}>
            <Typography variant="body1" className={styles.priceTitleLabel}>
              {card.type === "custom"
                ? t("discover.editionPrice")
                : t("tradehistory.currentPrice")}
            </Typography>
            <Typography variant="h1" className={styles.priceTitle}>
              <NumberFormat
                value={card.currentSalePrice}
                displayType={"text"}
                thousandSeparator={true}
                prefix={card.currencySymbol}
              />
            </Typography>
          </Box>
          <StatusBlock />
        </Box>
        {history?.length && card.type !== "custom" ? (
          <Box className={styles.chart}>
            <PriceChart
              className={styles.dropChart}
              data={[
                ...history,
                {
                  createdAt: card.createdAt,
                  price: card.currentSalePrice.toString(),
                },
              ]}
              currencySymbol={card.currencySymbol}
            />
          </Box>
        ) : null}
        <Box>
          {seasonLoader ? (
            <Skeleton
              className={styles.seasonDescriptionSkeleton}
              variant="rectangular"
            />
          ) : null}
          {card.type === "custom" ? (
            <>
              <DescriptionCard
                className={styles.seasonDescription}
                buyAlertHandler={buyAlertHandler}
                {...card}
                title={card.name}
              />
              <CardsList
                seasonCards={card.otherCards}
                title={`${t("drops.otherCardsFrom")} ${card.influencer.name}`}
              />
            </>
          ) : null}
          {card.type !== "custom" ? (
            <>
              <DescriptionCard
                title={seasonData?.season.title}
                description={seasonData?.season.description}
                seasonNum={seasonData?.season.seasonNum}
                className={styles.seasonDescription}
              />
              {seasonLoader ? (
                <Skeleton
                  className={styles.cardsListSkeleton}
                  variant="rectangular"
                />
              ) : null}
              <CardsList
                seasonCards={seasonCards}
                seasonNum={seasonData?.season.seasonNum}
              />
            </>
          ) : null}
        </Box>
        <OrderHistory data={orders} locale={userLocale} />
        {card?.type !== CARD_TYPES.CUSTOM ? (
          <Statistics data={card} locale={userLocale} />
        ) : null}
      </Box>
    </>
  );
};
export default Phase2DropDetail;
