import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ReactComponent as IconPoll } from "assets/svg/icon-poll-indicator.svg";
import { ReactComponent as IconPollSelected } from "assets/svg/icon-poll-indicator-selected.svg";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onPostPollVote } from "redux/feedSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import TimeAgo from "../TimeAgo";
import styles from "./Poll.module.scss";

const Poll = ({
  post,
  locale,
  shouldShowTime = false,
  accessGranted = true,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = isAuthenticated();
  const handle = post?.influencer?.handle;
  const { poll, pollVote, id, createdAt } = post;
  const { t } = useContext(LocalizationContext);

  if (!poll) return null;

  const { options, totalVotes, status, loading } = poll;
  const isFinilized = status === "finalized";
  const shouldShowResults = isFinilized || pollVote;

  const calculatePercentage = (votes) => {
    return votes && totalVotes > 0 ? Math.round((votes / totalVotes) * 100) : 0;
  };

  const handleVote = (option) => {
    if (!isAuth) {
      navigate(PATHS.SIGN_UP);
      return;
    } else if (!accessGranted && handle) {
      handle && navigate(`/${handle}`);
      return;
    }

    dispatch(
      onPostPollVote({
        id,
        params: {
          option,
        },
      })
    );
  };

  const Loader = () =>
    loading ? (
      <Box className={styles.loader}>
        <CircularProgress color="secondary" size={24} />
      </Box>
    ) : null;

  return (
    <Box
      className={`${styles.pollContainer} ${
        accessGranted ? "" : styles.pollBockAccess
      }`}
    >
      <Loader />
      {options.map((option, index) => (
        <Box
          key={index}
          className={`${styles.pollOption} ${
            shouldShowResults ? "" : styles.noVote
          }`}
        >
          {option.option === pollVote?.option ? (
            <IconPollSelected
              className={`${styles.iconPoll} ${styles.iconPollSelected}`}
              width={24}
              height={24}
            />
          ) : (
            <IconPoll
              className={`${styles.iconPoll} ${styles.iconPollNonSelected}`}
              width={24}
              height={24}
              onClick={() =>
                !pollVote && !isFinilized && handleVote(option.option)
              }
            />
          )}

          <Box className={styles.optionVotes}>
            <Box className={styles.optionData}>
              <Typography variant="body2" className={styles.optionText}>
                {option.option}
              </Typography>
              {shouldShowResults ? (
                <Typography variant="body2" className={styles.percentageText}>
                  {`${calculatePercentage(option.votes)}%`}
                </Typography>
              ) : null}
            </Box>
            {shouldShowResults ? (
              <LinearProgress
                className={`${styles.progress} ${
                  option.votes > 0
                    ? option.option === pollVote?.option
                      ? styles.progressUserVoted
                      : styles.progressVoted
                    : styles.progressRegular
                }`}
                variant="determinate"
                value={calculatePercentage(option.votes)}
              />
            ) : null}
          </Box>
        </Box>
      ))}
      <Box className={styles.bottomInfo}>
        <Box className={styles.pollInfo}>
          <Typography valiant="body3" className={styles.pollInfoItem}>
            {poll?.totalVotes === 1
              ? t("default.pollVotesSingular", {
                  number: poll?.totalVotes,
                })
              : t("default.pollVotes", { number: poll?.totalVotes })}
          </Typography>
          {poll?.deadline && new Date(poll.deadline).getTime() < Date.now() ? (
            <>
              <span className={styles.durationSpacing}></span>
              <Typography valiant="body3" className={styles.pollInfoItem}>
                {t("default.pollIsClosed")}
              </Typography>
            </>
          ) : poll?.duration ? (
            <Typography valiant="body3" className={styles.pollInfoItem}>
              <span className={styles.durationSpacing}></span>
              {poll?.duration === 1
                ? t("default.pollVotingDurationSingular", {
                    duration: poll?.duration,
                  })
                : t("default.pollVotingDuration", {
                    duration: poll?.duration,
                  })}
            </Typography>
          ) : null}
        </Box>
        {shouldShowTime ? (
          <Typography variant="body3" className={styles.pollInfoItem}>
            <TimeAgo value={createdAt} locale={locale} />
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default Poll;
