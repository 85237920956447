import { Box, Button, Typography } from "@mui/material";
import AuthLeftSide from "components/AuthLeftSide";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOnfidoToken, setServerError } from "redux/appSlice";
import { setLoader } from "redux/appSlice";
import { logout } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { LocalizationContext } from "services/localizationContext";
import styles from "styles/auth/Auth.module.scss";
import { useIsMobile } from "utils/hooks";
import { CheckKycStatus } from "utils/hooks/kycCheck";
import stylesKyc from "views/KYC/KYC.module.scss";

import {
  ANALYTICS_EVENTS,
  BLOCK_REASON_KYC,
  PATHS,
  USER_BLOCKED,
  USER_STATUS_BLOCKED,
} from "../../constants";

const UserBlocked = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sdkToken, setSdkToken] = useState(null);
  const [title, setTitle] = useState(null);
  // const [body, setBody] = useState(null);
  const checkStatus = CheckKycStatus();
  const unmounted = useRef(false);
  const isMobile = useIsMobile();
  const serverError = useSelector((state) => state.app.serverError);
  const { status, blockReason } = useSelector(
    (state) => state.users.me.entities
  );
  const { error, message } = useMemo(() => {
    if (serverError) return serverError;
    if (status === USER_STATUS_BLOCKED) {
      return { error: status, message: blockReason };
    }
    return {};
  }, [status, blockReason, serverError]);
  const profile = useSelector((state) => state.users.entities);
  const loader = useSelector((state) => state.app.loader);

  useEffect(() => {
    if (![USER_BLOCKED, USER_STATUS_BLOCKED].includes(error))
      return navigate("/");
    if (message === BLOCK_REASON_KYC) {
      getKycStatus();
    } else {
      setTitle(t("kyc.accSuspendedTitle"));
      // message && setBody(`${t("kyc.accSuspendedDesc")} ${message}`);
    }
    return () => {
      unmounted.current = true;
      dispatch(setServerError(null));
    };
  }, [error, message]);

  const handleLogout = () => {
    dispatch(logout());
    Analytics.track(ANALYTICS_EVENTS.LOGOUT, { user: profile.id });
    Analytics.reset();
    navigate(PATHS.LOGIN, { replace: true });
  };
  const launchOnfidoKyc = async () => {
    try {
      await getKycStatus();
      dispatch(
        setOnfidoToken({
          sdkToken: sdkToken,
          onfidoRedirectComplete: PATHS.HOME,
        })
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  let button = () => {
    return sdkToken ? (
      <Box className={styles.buttonContainer} mb={3}>
        <Button variant="contained" onClick={launchOnfidoKyc}>
          {t("kyc.continueVerification")}
        </Button>
      </Box>
    ) : null;
  };

  const getKycStatus = async () => {
    try {
      dispatch(
        setLoader({
          title: t("default.loading"),
          subtitle: t("default.itMayTakeAFewSeconds"),
        })
      );
      const res = await checkStatus({ hideModals: true });
      dispatch(setLoader(false));
      if (res === 1) return navigate("/");
      if (!unmounted.current) {
        setTitle(res?.title || t("kyc.verifyIdTitle"));
        // setBody(res?.body || t("kyc.verifyIdDesc"));
        res?.sdkToken && setSdkToken(res?.sdkToken);
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return (
    <Box className={styles.container}>
      {!isMobile && <AuthLeftSide />}

      <Box className={styles.rightSide}>
        <Box
          className={[
            stylesKyc.containerCenter,
            stylesKyc.step0,
            styles.containerCenter,
          ]}
        >
          <Box>
            <Typography
              variant="h3"
              component="h1"
              className={[styles.title, styles.titleBlock]}
            >
              {title}
            </Typography>
            {/*<Typography variant="textNormal" className={stylesKyc.description}>*/}
            {/*  {body}*/}
            {/*</Typography>*/}
          </Box>
          <Box>
            {button()}
            {!loader && (
              <Box className={stylesKyc.buttonContainer}>
                <Button variant="outlined" onClick={handleLogout} size="small">
                  <Typography variant="body1">{t("default.logOut")}</Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default UserBlocked;
