import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PATHS } from "../../constants";
import { isAuthenticated } from "../../services/auth.service";
import { LocalizationContext } from "../../services/localizationContext";
import styles from "./SystemNewCard.module.scss";

const SystemNewCard = ({ cardId, imageUrl, className }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleBuy = () => {
    if (isAuthenticated()) {
      navigate(PATHS.DROPS_ID_BUY.replace(":id", cardId), {
        state: { from: location.pathname },
      });
    } else {
      navigate(PATHS.SIGN_UP);
    }
  };

  return (
    <Box
      className={`${styles.systemNewCardContainer} ${
        className ? className : ""
      }`}
    >
      <Typography variant="h4" className={styles.systemNewCardTitle}>
        {t("drops.systemNewCardTitle")}
      </Typography>
      <img src={imageUrl} alt="system-card-img" />
      <Button
        variant="contained"
        size="small"
        className={styles.systemNewCardBtn}
        onClick={handleBuy}
      >
        {t("drops.buyNow")}
      </Button>
    </Box>
  );
};

export default SystemNewCard;
