import React, { useContext, useEffect, useMemo } from "react";
import { LocalizationContext } from "services/localizationContext";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Container } from "@mui/material";
import ReferralCard from "../ReferralCard";
import { fetchUserReferralsInfluencers } from "redux/usersSlice";
import styles from "./ReferralsInfluencers.module.scss";
import { roundToTwo } from "utils/math";

export const ReferralsInfluencers = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const referralsInfluencers = useSelector(
    (state) => state.users.referrals.influencers.entities
  );
  const user = useSelector((state) => state.users.entities);

  const totalEarnings = useMemo(
    () =>
      roundToTwo(
        referralsInfluencers.reduce((a, b) => a + (b?.capitalization || 0), 0)
      ),
    [referralsInfluencers]
  );

  useEffect(() => {
    dispatch(fetchUserReferralsInfluencers());
  }, []);

  return (
    <Container className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h2" className={styles.title}>
          {t("referral.myInfluencersRefferrals")}
        </Typography>
        <Box className={styles.subtitle}>
          <Typography variant="h5" className={styles.earningsTitle}>
            {t("referral.totalEarnings")}:
          </Typography>
          <Typography variant="h5" className={styles.earnings}>
            ${totalEarnings}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.content}>
        {!!referralsInfluencers.length ? (
          <Box className={styles.cardsWrap}>
            {referralsInfluencers.map((item, i) => (
              <ReferralCard
                key={item.name}
                className={styles.card}
                name={item.name}
                earnings={item.capitalization}
                joinedAt={item.joinedAt}
                imageUrl={item.profileImageUrl}
                locale={user.language}
                isLarge
                isInfluencer={true}
              />
            ))}
          </Box>
        ) : (
          <Box className={styles.placeholder}>
            <Typography className={styles.placeholderTitle} variant="h4">
              {t("referral.invitedCelebritiesWillAppearHere")}
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ReferralsInfluencers;
