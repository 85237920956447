import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { getMyConnections } from "redux/selectors/usersSelectors";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import { ConnectionsRequestsWrapper } from "./ConnectionsRequests/ConnectionsRequestsWrapper";
import { FriendsList } from "./FriendsList/FriendsList";
import styles from "./NewConnections.module.scss";

export const NewConnections = () => {
  const { t } = useContext(LocalizationContext);

  const connections = useSelector(getMyConnections);

  return (
    <PageLayout
      title={
        <Box className={styles.header}>
          {t("account.connections")}
          <Typography className={`opacity_07 ${styles.headerSubTitle}`}>
            ({connections.total})
          </Typography>
        </Box>
      }
      isBack={true}
    >
      <Box className={styles.main}>
        <ConnectionsRequestsWrapper />
        <FriendsList />
      </Box>
    </PageLayout>
  );
};
