import { api } from "services/api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getInfluencer = (id) => {
  return api
    .get(`${API_URL}/public/discover/influencer/${id}`, {
      params: { period: "all" },
    })
    .then((res) => res.data);
};

export const getUserByInviteCode = async (inviteCode) => {
  return api
    .get(
      `${API_URL}/public/users/search?searchBy=inviteCode&value=${inviteCode}`
    )
    .then((res) => res.data);
};

export const sendInfluencerReferralData = async (params) => {
  try {
    return api.post(`${API_URL}/public/leads`, params);
  } catch (error) {
    console.error(error);
  }
};

export const getEditionOwners = ({ cardId, userId }) => {
  return api
    .get(
      `${API_URL}/public/discover/card/${cardId}/owners?swap=1&userId=${userId}`
    )
    .then((res) => res.data);
};
