import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { GLOBAL_HEADER_CLASSNAME, PATHS } from "constants/index";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toggleMobileMenu } from "redux/appSlice";
import { fetchNotificationsStatus } from "redux/notificationsSlice";
import { getGlobalCountUnreadMessages } from "redux/selectors/messengerSelectors";
import { useIsMobile } from "utils/hooks";

import {
  getFilteredInfluencers,
  initialLoaders,
} from "../../../utils/getFilteredInfluencers";
import { SearchBar } from "../../SearchBar/SearchBar";
// import { InstallApp } from "../InstallApp/InstallApp";
import { MessagesIcon } from "./components/MessagesIcon";
import { NavMenu } from "./components/NavMenu/NavMenu";
import { NotificationsIcon } from "./components/NotificationsIcon";
import { ProfileNav } from "./components/ProfileNav/ProfileNav";
import { SearchResultList } from "./components/SearchResultList/SearchResultList";
import styles from "./Header.module.scss";

export default function Header(props) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const notifications = useSelector(
    (state) => state.notifications.numberUnreadNotifications
  );
  const messages = useSelector(getGlobalCountUnreadMessages);

  const [loaders, setLoaders] = useState(initialLoaders);
  const [data, setData] = useState(initialLoaders);
  const [isResultOpened, setResultOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchNotificationsStatus());
  }, []);

  const handleOpenMobileMenu = () => {
    document.body.classList.add("mobile-menu-open");
    dispatch(toggleMobileMenu());
  };

  const handleSearch = (searchTerm) => {
    getFilteredInfluencers({ params: { searchTerm }, setLoaders, setData });
    setResultOpen(true);
  };

  if (
    !isMobile &&
    (window.location.href.includes(PATHS.SIGN_UP) ||
      window.location.href.includes(PATHS.LOGIN) ||
      window.location.href.includes(PATHS.FORGOT_PASSWORD_FAN) ||
      window.location.href.includes(PATHS.FORGOT_PASSWORD_CREATOR))
  ) {
    return null;
  }

  return (
    <Box
      className={`${styles.header} ${GLOBAL_HEADER_CLASSNAME}`}
      ref={props?.innerRef}
    >
      <AppBar
        className={styles.headerAppBar}
        position="static"
        color="dark"
        elevation={0}
      >
        <Toolbar className={styles.headerToolbar}>
          <Link to="/" className={styles.logo}>
            <Logo />
          </Link>
          {isMobile ? (
            <IconButton
              onClick={handleOpenMobileMenu}
              className={styles.hamburgerMenu}
            >
              <Box className={styles.line} />
              <Box className={styles.line} />
              <Box className={styles.line} />
              {!!notifications || !!messages ? (
                <Box className={styles.badge} />
              ) : null}
            </IconButton>
          ) : (
            <Box className={styles.headerMainSection}>
              <SearchBar
                onSeacrhChanged={handleSearch}
                classNameWrapper={styles.search}
                isAnimate
                isResultOpened={isResultOpened}
                setResultOpen={setResultOpen}
                classNameResult={styles.searchResult}
              >
                <SearchResultList data={data} loaders={loaders} />
              </SearchBar>
              <NavMenu>
                {/*<InstallApp />*/}
                <Box className={styles.iconsWrapper}>
                  <MessagesIcon className={styles.messagesIcon} />
                  <NotificationsIcon className={styles.notificationIcon} />
                </Box>
                <ProfileNav />
              </NavMenu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
