import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as ZoopIcon } from "assets/svg/zoop-icon.svg";
import React from "react";

import styles from "./HomeBanner.module.scss";

const HomeBanner = ({
  condition,
  title,
  text,
  buttonText,
  onClick,
  isVerify,
  loading,
}) => {
  const theme = useTheme();

  const sx = {
    border: `1px solid ${
      isVerify ? theme.palette.surface.main : theme.palette.coral.main
    }`,
  };

  if (!condition || loading) {
    return null;
  }
  return (
    <Box className={styles.kycContainer} sx={sx}>
      <Box className={styles.wrapper}>
        <Box className={styles.iconWrapper}>
          <ZoopIcon />
        </Box>
        <Box>
          <Typography className={styles.kycTitle} variant="h5" component="h5">
            {title}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={styles.kycSubtitle}
          >
            {text}
          </Typography>
        </Box>
      </Box>
      <Button
        variant={isVerify ? "contained" : "verify"}
        size="small"
        onClick={onClick}
        className={styles.kycButton}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default HomeBanner;
