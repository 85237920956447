import React, { useContext } from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./Nomatch.module.scss";
import { ReactComponent as Icon404 } from "assets/svg/404.svg";
import { LocalizationContext } from "services/localizationContext";

const NoMatch = () => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  return (
    <Container className={styles.container}>
      <Box className={styles.block}>
        <Typography variant="h1" className={styles.title}>
          {t("error.noMatchErrorTitle")}
        </Typography>
        <Typography variant="textNormal" className={styles.description}>
          {t("error.noMatchErrorDesc")}
        </Typography>
        <Button
          variant="yellow"
          className={styles.btn}
          onClick={() => navigate("/home")}
        >
          {t("error.backHomeBtn")}
        </Button>
      </Box>
      <Box className={styles.block}>
        <Icon404 />
      </Box>
      <Button
        variant="yellow"
        className={`${styles.btnMobile} ${styles.btn}`}
        onClick={() => navigate("/home")}
      >
        {t("error.backHomeBtn")}
      </Button>
    </Container>
  );
};

export default NoMatch;
