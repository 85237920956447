import { Box, Button, Container, Typography } from "@mui/material";
import OrderDetails from "components/Drops/OrderDetails";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchDrop } from "redux/dropsSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import stylesMain from "../../DropDetail/DropDetail.module.scss";
import styles from "./Success.module.scss";

const BuySuccess = () => {
  const isAuth = isAuthenticated();

  const { t } = useContext(LocalizationContext);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const paymentMethod = location.state?.paymentMethod;
  const currentDrop = useSelector((state) => state.drops.drop);

  const card = location.state?.card || currentDrop;

  useEffect(() => {
    if (!location.state?.card) dispatch(fetchDrop(params.id, isAuth));
  }, [params.id]);

  return (
    <Container className={`${stylesMain.container} ${styles.container}`}>
      <Box className={[stylesMain.wrapper, styles.wrapper]}>
        <Box className={`${styles.content}`}>
          <Typography variant="h1">
            {paymentMethod?.type === "wallet"
              ? t("default.success")
              : t("orderdetails.submitted")}
          </Typography>
          <Typography variant="body2">
            {paymentMethod?.type === "wallet"
              ? t("drops.buyWalletSuccessInfo")
              : t("drops.buySuccessInfo")}
          </Typography>
          <Box mt="50px">
            <OrderDetails
              {...{
                influencerName: card?.influencer.name,
                series: card?.series,
                currencySymbol: card?.currencySymbol,
                currentSalePrice: card?.currentSalePrice,
                paymentMethodName: paymentMethod?.name || "Bitcoin",
              }}
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          className={styles.submitBtn}
          onClick={() => navigate(`/pass/${params.id}`, { replace: true })}
        >
          {t("default.done")}
        </Button>
      </Box>
    </Container>
  );
};
export default BuySuccess;
