import React, { useContext, useMemo } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import Proptypes from "prop-types";
import styles from "./activity.module.scss";
import { LocalizationContext } from "services/localizationContext";
import { getFormattedDate } from "utils/dateHelper";

export default function ActivityStatistics({ data, isEditionColumnEnabled }) {
  const { t } = useContext(LocalizationContext);

  const columns = useMemo(
    () => [
      { id: "type", label: t("discover.type") },
      { id: "price", label: t("default.price") },
      { id: "edition", label: t("discover.edition") },
      { id: "username", label: t("settings.username") },
      { id: "date", label: t("discover.date") },
    ],
    []
  );

  function twoDecimalPlaces(amount) {
    return amount % 1 !== 0 ? amount.toFixed(2) : amount;
  }

  return (
    <Box className={styles.accordionWrap}>
      <Box id="activity" mb={2}>
        <Typography className={styles.accordionTitle} variant="h6">
          {t("discover.activity")}
        </Typography>
      </Box>
      <Box>
        <TableContainer
          className={styles.tableContainer}
          sx={{ maxHeight: 289 }}
        >
          <Table
            className={`${styles.table} ${styles.tableBlock}`}
            component="div"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead className={styles.tableHead} component="div">
              <TableRow className={styles.tableHeadRow} component="div">
                {columns.map((column) => {
                  if (!isEditionColumnEnabled && column.id === "edition")
                    return null;
                  return (
                    <TableCell
                      className={styles.tableHeadCell}
                      component="div"
                      key={column.id}
                      align={"left"}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className={styles.tableBody} component="div">
              {!!data.length &&
                data.map((row, i) => {
                  return (
                    <TableRow
                      className={styles.tableBodyRow}
                      component="div"
                      hover
                      tabIndex={-1}
                      key={`${row.price}_${i}`}
                    >
                      {columns.map((column) => {
                        if (!isEditionColumnEnabled && column.id === "edition")
                          return null;
                        const value = row[column.id];
                        const currencySymbol = row["currencySymbol"];
                        const isPrice = column.id === "price";
                        const isDate = column.id === "date";
                        const isUsername = column.id === "username";
                        const usernameLengthLimit = 11;

                        const cellValue = (() => {
                          if (isDate) {
                            return getFormattedDate(value);
                          }
                          if (isPrice) {
                            return (
                              <>
                                {currencySymbol}
                                {twoDecimalPlaces(value)}
                              </>
                            );
                          }
                          if (isUsername) {
                            return `${value?.substring(
                              0,
                              usernameLengthLimit
                            )}${
                              value?.length > usernameLengthLimit ? "..." : ""
                            }`;
                          }
                          if (!!value) {
                            return value;
                          }
                          return "--/--/--";
                        })();

                        return (
                          <TableCell
                            component="div"
                            className={styles.tableBodyCell}
                            key={column.id}
                            align={"left"}
                          >
                            {cellValue}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

ActivityStatistics.defaultProps = {
  data: [],
  isEditionColumnEnabled: true,
};

ActivityStatistics.propTypes = {
  items: Proptypes.arrayOf(
    Proptypes.shape({
      currencySymbol: Proptypes.string,
      date: Proptypes.string,
      edition: Proptypes.number,
      price: Proptypes.number,
      type: Proptypes.string,
      username: Proptypes.string,
    })
  ),
};
