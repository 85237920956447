import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Slider from "react-slick";
import UserSwapCard from "components/UserSwapCard";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import { useWindowSize } from "utils/hooks";
import styles from "./edition.module.scss";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import sliderCss from "styles/slider/ResponsiveSlider.module.scss";

const cardWidth = 180;
const mobileCardWidth = 160;
const BarterEdition = ({ editionId, holders, isLoading }) => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const [sliderSettings, setSliderSettings] = useState({});
  const ws = useWindowSize();
  const sliderContainer = useRef(null);
  const slider = useRef(null);

  useLayoutEffect(() => {
    if (!sliderContainer.current) return;
    const containerWidth = sliderContainer.current.clientWidth;
    let slidesToShow = containerWidth / cardWidth;

    if (window.innerWidth <= 1260) {
      slidesToShow = containerWidth / mobileCardWidth;
    }
    slidesToShow = Math.round((slidesToShow + Number.EPSILON) * 100) / 100;

    setSliderSettings({
      dots: false,
      slidesToShow,
      slidesToScroll: 1,
      infinite: false,
    });
  }, [ws.width, sliderContainer.current]);

  if (!isLoading && !holders?.length) return null;
  return (
    <Box className={styles.usersSwapBlock}>
      <Typography variant="h5" className={styles.title}>
        {t("discover.barterThisEdition")}
      </Typography>
      <Box className={styles.userSwapCardsContainer} ref={sliderContainer}>
        {isLoading ? (
          Array.from(new Array(2)).map((_, i) => (
            <Skeleton
              key={i}
              className={styles.userSwapCardSkeleton}
              variant="rectangular"
            />
          ))
        ) : (
          <Slider ref={slider} {...sliderSettings} className={sliderCss.slider}>
            {holders.map((holder, i) => (
              <UserSwapCard
                key={`swap-${holder.id}`}
                className={styles.userSwapCard}
                name={holder.username}
                imageUrl={holder.profileImageUrl}
                swap={holder.swap}
                createPayload={{ cardId: editionId, userId: holder.id }}
                onClick={() => navigate(`/public/user/${holder.username}`)}
              />
            ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
};
export default BarterEdition;
