import { Container } from "@mui/material";
import { PhaseBody } from "components/PhaseBody/PhaseBody";
import { PhaseHeader } from "components/PhaseHeader/PhaseHeader";
import { INFLUENCERS_LOAD_LIMIT } from "constants/index";
import { orderBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLimitedEdition } from "redux/dropsSlice";
import {
  getLimitedEditionCards,
  getLimitedEditionCardsTotal,
} from "redux/selectors/dropsSelectors";
import { LocalizationContext } from "services/localizationContext";

import styles from "./LimitedEdition.module.scss";

export const LimitedEdition = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const cards = useSelector(getLimitedEditionCards);
  const cardsTotal = useSelector(getLimitedEditionCardsTotal);

  const [filter, setFilter] = useState({
    page: 1,
    orderBy: "-dropStartDate",
    limit: INFLUENCERS_LOAD_LIMIT,
  });
  const [isLoading, setLoading] = useState(true);

  const isLastPage = cards.length === cardsTotal;

  const loadMore = () =>
    setFilter((prevState) => ({ ...prevState, page: prevState.page + 1 }));

  useEffect(() => {
    dispatch(fetchLimitedEdition({ ...filter, type: "custom" })).finally(() =>
      setLoading(false)
    );
  }, [filter]);

  return (
    <Container className={styles.viewPhaseContainer}>
      <PhaseHeader
        title={t("drops.limitedEditionCardsTitle")}
        orderByList={[
          { value: "name", text: t("drops.az") },
          { value: "-dropStartDate", text: t("drops.filterNew") },
        ]}
        orderBy={filter.orderBy}
        setOrderBy={(value) => {
          setFilter((prevState) => ({
            ...prevState,
            page: 1,
            orderBy: prevState.orderBy === value ? null : value,
          }));
        }}
        isLoading={isLoading}
      />
      <PhaseBody
        cards={cards}
        isLoading={isLoading}
        next={loadMore}
        isLastPage={isLastPage}
      />
    </Container>
  );
};
