import { Box, Typography } from "@mui/material";
import TimeAgo from "components/TimeAgo";
import { DROP_INITIAL_PRICE, TRANSFER_STATUSES } from "constants/index";
import { useContext } from "react";
import NumberFormat from "react-number-format";
import { LocalizationContext } from "services/localizationContext";

import { DropDown } from "../DropDown/DropDown";
import styles from "./Statistics.module.scss";

const Statistics = ({ data, locale }) => {
  const { t } = useContext(LocalizationContext);
  const auctionStarted = data.dropPhase2StartDate ? (
    <TimeAgo value={data.dropPhase2StartDate} locale={locale} />
  ) : (
    "-"
  );
  const isInCollection = !!data.transfer?.amount;
  const status = () => {
    if (
      data.transferStatus === TRANSFER_STATUSES.pending ||
      data.transferStatus === TRANSFER_STATUSES.unpaid
    ) {
      return t("orderdetails.pending");
    }

    if (isInCollection) return t("drops.statusInCollection");
    return null;
  };

  const TransferAmount = () =>
    data.transfer?.amount ? (
      <Box className={styles.gridItem}>
        <Typography variant="h6" component="span">
          {t("drops.purchasedFor")}
        </Typography>
        <Typography variant="h6" component="span">
          <NumberFormat
            value={data.transfer?.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={data.currencySymbol}
          />
        </Typography>
      </Box>
    ) : null;

  const OrderStatus = () =>
    status() ? (
      <Box className={styles.gridItem}>
        <Typography variant="h6" component="span">
          {t("orderdetails.status")}
        </Typography>
        <Typography variant="h6" component="span">
          {status()}
        </Typography>
      </Box>
    ) : null;

  return (
    <DropDown title={t("drops.statistics")}>
      <Box>
        <Box className={styles.gridItem}>
          <Typography variant="h6" component="span">
            {t("drops.auctionStarted")}
          </Typography>
          <Typography variant="h6" component="span">
            {auctionStarted}
          </Typography>
        </Box>
        <Box className={styles.gridItem}>
          <Typography variant="h6" component="span">
            {t("drops.initialPrice")}
          </Typography>
          <Typography variant="h6" component="span">
            <NumberFormat
              value={DROP_INITIAL_PRICE}
              displayType={"text"}
              thousandSeparator={true}
              prefix={data.currencySymbol}
            />
          </Typography>
        </Box>
        <TransferAmount />
        <OrderStatus />
      </Box>
    </DropDown>
  );
};
export default Statistics;
