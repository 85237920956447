import { Box, Typography } from "@mui/material";
import { ReactComponent as CardsIcon } from "assets/svg/cards-icon.svg";
import { ReactComponent as FireIcon } from "assets/svg/fire-icon-yellow.svg";
import classNames from "classnames/bind";
import Tooltip from "components/Tooltip";
import CustomTooltip from "components/Tooltip";
import { EDITION_STATUSES } from "constants/index";
import { Fragment, useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { truncateText } from "utils/stringHelper";

import { getRewardsActionType } from "../../../utils";
import { LiveDrop } from "../components/LiveDrop";
import { Price } from "../components/Price/Price";
import { SellFooter } from "../components/SellFooter/SellFooter";
import { UpcomingDrop } from "../components/UpcomingDrop";
import styles from "./FullCard.module.scss";

const CONTENT = {
  [EDITION_STATUSES.phase0]: {
    FooterComponent: UpcomingDrop,
  },
  [EDITION_STATUSES.phase2]: {
    FooterComponent: LiveDrop,
    showAllocation: true,
  },
  [EDITION_STATUSES.complete]: {
    FooterComponent: Price,
    showAllocation: true,
  },
  // custom status, need to discuss it
  resale: {
    FooterComponent: SellFooter,
    showAllocation: true,
  },
  defaultCmpnt: {
    FooterComponent: Price,
  },
};

const formatTooltipTitle = (parts = []) => {
  return (
    <>
      {parts.filter(Boolean).map((part, index) => (
        <Fragment key={index}>
          {index !== 0 && <span className={styles.separator}>|</span>}
          {part}
        </Fragment>
      ))}
    </>
  );
};

export const FullCard = ({ className, data }) => {
  const cx = classNames.bind(styles);
  const {
    status,
    holdings = 0,
    cardNum,
    name: cardName,
    influencer,
    seasonNum,
    type,
    ordersCount,
    rewardType,
  } = data;

  const contentStatus = ordersCount ? "resale" : status;

  const { showAllocation, FooterComponent } =
    CONTENT[contentStatus] || CONTENT.defaultCmpnt;

  const { name, isHot } = influencer || {};
  const { t } = useContext(LocalizationContext);
  return (
    <Box className={cx(className, styles.card)}>
      <Box className={styles.cardHeader}>
        <Typography
          variant="textMediumBold2"
          className={styles.cardHeaderTitle}
        >
          <CustomTooltip title={name} isArrow={true}>
            <Box className={styles.cardHeaderTitleText}>{name}</Box>
          </CustomTooltip>
          {isHot ? <FireIcon /> : null}
        </Typography>
        {cardName ? (
          <CustomTooltip title={cardName} isArrow={true}>
            <Typography
              variant="textNormal"
              className={styles.cardHeaderSubtitle}
            >
              {truncateText(cardName, 40)}
            </Typography>
          </CustomTooltip>
        ) : null}
      </Box>
      <Box className={styles.content}>
        {(seasonNum || cardNum) && type !== "custom" ? (
          <Tooltip
            title={formatTooltipTitle([
              seasonNum && t("edition.seasonNum", { season: seasonNum }),
              cardNum && t("edition.cardSeries", { series: cardNum }),
            ])}
          >
            <Box className={styles.season}>
              {seasonNum ? (
                <>
                  <Typography
                    variant="textMediumBold"
                    style={{ display: "block", whiteSpace: "nowrap" }}
                  >
                    {t("edition.seasonNumShort", { season: seasonNum })}
                  </Typography>

                  <Box className={styles.divider} />
                </>
              ) : null}
              {cardNum ? (
                <Typography
                  variant="textMediumBold"
                  style={{ display: "block", whiteSpace: "nowrap" }}
                >
                  {t("edition.cardSeriesShort", { series: cardNum })}
                </Typography>
              ) : null}
            </Box>
          </Tooltip>
        ) : null}
        {type === "custom" ? (
          <Box className={styles.season}>
            <Typography
              variant="textMediumBold"
              style={{ display: "block", whiteSpace: "nowrap" }}
            >
              {rewardType
                ? getRewardsActionType({ type: rewardType, t })
                : t("default.limitedEdition")}
            </Typography>
          </Box>
        ) : null}
        {showAllocation && (
          <Box className={styles.holdings}>
            <CardsIcon />
            <Typography
              variant="textMediumBold"
              style={{ display: "block", whiteSpace: "nowrap" }}
            >
              {holdings} {t("edition.owned")}
            </Typography>
          </Box>
        )}
      </Box>
      <FooterComponent className={styles.block} {...data} />
    </Box>
  );
};
