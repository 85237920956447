import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { ReactComponent as Visibility } from "assets/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import AuthLeftSide from "components/AuthLeftSide";
import { AuthTypeSwitcher } from "components/AuthTypeSwitcher/AuthTypeSwitcher";
// import { ExternalAuthSection } from "components/ExternalAuthSection/ExternalAuthSection";
import { LanguageSelect } from "components/LanguageSelect/LanguageSelect";
import PrivacyPolicyModal from "components/Modal/PrivacyPolicyModal.js";
import {
  AUTH_CELEBRITY_TYPE,
  AUTH_CREATOR,
  PATHS,
  zoopPolicyHost,
} from "constants/index";
import DOMPurify from "dompurify";
import pdf from "pdf/Terms and Conditions-Zoop Giveaway_Wild.pdf";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoader, setPixel } from "redux/appSlice";
import {
  fetchUser,
  followInfluencer,
  updateUserProfile,
} from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import {
  isAuthenticated,
  setAccessToken,
  setIdToken,
  setInviteCode,
  setRefreshToken,
} from "services/auth.service";
import { postPreSignupCheck } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";
import styles from "styles/auth/Auth.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";
import {
  useIsMobile,
  usePolicyHostUrl,
  useRedirectAfterAuth,
} from "utils/hooks";
import { nameValidation } from "utils/validators";
import * as Yup from "yup";

import { getInfluencerProfileLink } from "../../utils";
import { googleAuth, setPayloadToDashboard } from "../../utils/auth";

Yup.addMethod(Yup.string, "validateLatinLetter", nameValidation);

const SignUp = () => {
  const location = useLocation();
  const { email } = location?.state || {};
  const isMobile = useIsMobile();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { code } = useParams();
  const getDynamicUrl = usePolicyHostUrl();

  const isGiveaway = location.pathname === PATHS.SIGN_UP_GIVEAWAY;

  useEffect(() => {
    setInviteCode(code);
  }, [code]);

  const loading = useSelector((state) => state.app.loader);

  const [data, setData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isFanAuth, setFanAuth] = useState(
    !localStorage.getItem(AUTH_CELEBRITY_TYPE)
  );

  const { t } = useContext(LocalizationContext);
  const [openAgreements, setOpenAgreements] = useState(false);
  const influencerData = useSelector((state) => state.influencer.entities);
  const { id, handle, community } = influencerData || {};

  useEffect(() => {
    if (isGiveaway) {
      localStorage.removeItem(AUTH_CELEBRITY_TYPE);
    }
  }, [isGiveaway]);

  const validationSchema = Yup.object().shape({
    ...(isFanAuth && {
      firstName: Yup.string()
        .trim()
        .required(t("error.required"))
        .validateLatinLetter(t("error.onlyEnglishLetter")),
    }),
    ...(isFanAuth && {
      lastName: Yup.string()
        .trim()
        .required(t("error.required"))
        .validateLatinLetter(t("error.onlyEnglishLetter")),
    }),
    email: Yup.string()
      .required(t("error.required"))
      .email(t("error.invalidEmail")),
    password: Yup.string()
      .required(t("error.required"))
      .min(8, t("error.shortText"))
      .matches(/\d/, t("error.shouldContainNumbers"))
      .matches(/^(?=(.*[a-z]){1})/, t("error.shouldContainLowercaseLetter"))
      .matches(/^(?=(.*[A-Z]){1})/, t("error.shouldContainUppercaseLetter"))
      .matches(
        /^(?=(.*[!@#$%^&*()_+\-=\[\]{};~`':"\\|,.<>\/?]){1})/,
        t("error.shouldContainSpecialCharacter")
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      email,
    },
  });

  const redirect = useRedirectAfterAuth();

  //ToDO: need refactor this func in future (SOLID)
  const redirectTo = () => {
    if (isAuthenticated() && (id || handle)) {
      if (!community?.subscriptionPrice) {
        dispatch(followInfluencer({ influencerId: id }))
          .then(() => {
            const influencerProfileLink = getInfluencerProfileLink({
              id,
              handle,
            });
            dispatch(setPixel(true));
            navigate(influencerProfileLink);
          })
          .finally(() => dispatch(setLoader(false)));
      }
    } else {
      dispatch(setLoader(false));
      dispatch(setPixel(true));
      redirect();
    }
  };

  const doSignUp = (data) => {
    const setAuthUser = (data) => {
      setAccessToken(data.getAccessToken().getJwtToken());
      setIdToken(data.getIdToken().getJwtToken());
      setRefreshToken(data.getRefreshToken().getToken());
      return dispatch(fetchUser());
    };

    const authDetails = new AuthenticationDetails({
      Username: data.email,
      Password: data.password,
    });

    const fanParams = isFanAuth
      ? [
          {
            Name: "given_name",
            Value: data.firstName,
          },
          {
            Name: "family_name",
            Value: data.lastName,
          },
        ]
      : [];
    UserPool().signUp(data.email, data.password, fanParams, [], (err) => {
      if (err) {
        dispatch(setLoader(false));
        setServerError(errorResponseMessages(err, t));
      } else {
        const cognitoUser = new CognitoUser({
          Username: data.email,
          Pool: UserPool(),
        });
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: (data) => {
            if (isFanAuth) {
              setAuthUser(data).then(() => {
                Analytics.identify({ userId: data.email });
                Analytics.track("sign_up");
                if (code && !isGiveaway && location.hash !== AUTH_CREATOR) {
                  dispatch(updateUserProfile({ referralCode: code }));
                }
                if (isGiveaway) {
                  dispatch(updateUserProfile({ referralCode: "ref41817607" }));
                }
                redirectTo();
              });
            } else {
              setPayloadToDashboard(data);
            }
          },
          onFailure: (error) => {
            dispatch(setLoader(false));
            setServerError(errorResponseMessages(error, t));
          },
        });
      }
    });
  };

  const onSubmit = (data) => {
    setServerError(null);
    dispatch(setLoader(true));

    postPreSignupCheck({ email: data.email })
      .then((res) => {
        if (!res?.isSupportedCountry || !res?.isSupportedEmailDomain) {
          dispatch(setLoader(false));
          return setServerError(t("auth.preSignCheckErrorCountry"));
        }

        doSignUp(data);
      })
      .catch((e) => {
        setServerError(errorResponseMessages(e, t));
        dispatch(setLoader(false));
      });
  };

  const watchEmail = watch("email") || "";

  const setCelebrityType = () => {
    setFanAuth(false);
    localStorage.setItem(AUTH_CELEBRITY_TYPE, AUTH_CELEBRITY_TYPE);
  };

  useEffect(() => {
    googleAuth({ dispatch, navigate, isMainApp: isFanAuth });
    if (location.hash === AUTH_CREATOR) {
      setCelebrityType();
    }
  }, []);

  return (
    <>
      <Box
        className={`${styles.container} ${styles.signContainer} ${
          isGiveaway ? styles.giveawayContainer : ""
        }`}
      >
        {!isMobile && (
          <AuthLeftSide
            className={styles.authLeftSide}
            isGiveaway={isGiveaway}
          />
        )}
        <Box className={`${styles.rightSide} ${styles.signRightSide}`}>
          {!isMobile ? <LanguageSelect className={styles.langSelect} /> : null}
          <Box
            className={`${styles.rightSideContent} ${styles.signRightSideContent}`}
          >
            <Box className={styles.signRightSideContentBox}>
              <Typography
                variant="h3"
                component="h1"
                className={`${styles.title} ${styles.titleGiveaway}`}
              >
                {!isGiveaway ? t("auth.joinZoopAs") : t("auth.welcome")}
              </Typography>
              {isGiveaway && !isMobile ? (
                <Typography variant="subtitle1" className={styles.subtitle}>
                  {t("auth.giveawayDescription")}
                </Typography>
              ) : null}
              {isMobile && isGiveaway ? (
                <Box className={styles.giveawayInfo}>
                  <Typography
                    variant="body4"
                    className={styles.subtitleMain}
                    component="p"
                  >
                    {t("auth.giveawayMainTitle")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={styles.additionaltext}
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        t("auth.giveawayMainDescriptionSmall"),
                        {
                          USE_PROFILES: { html: true },
                        }
                      ),
                    }}
                  />
                </Box>
              ) : null}
              {!isGiveaway ? (
                <>
                  <Typography variant="subtitle1" className={styles.subtitle}>
                    {isFanAuth
                      ? t("auth.fanDescription")
                      : t("auth.celebrityDescription")}
                  </Typography>
                </>
              ) : null}
              {/*<ExternalAuthSection isFanAuth={isFanAuth} />*/}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`${styles.form} ${
                  !isFanAuth ? styles.formCeleb : ""
                } ${styles.formSign}`}
                autoComplete="off"
              >
                {isFanAuth ? (
                  <Box className={styles.nameWrap}>
                    <FormControl>
                      <TextField
                        label={t("default.firstName")}
                        error={!!errors.firstName}
                        className={`${roundedInputStyles.input} ${
                          roundedInputStyles.cyan
                        } ${
                          !!data.firstName.length
                            ? roundedInputStyles.filled
                            : ""
                        }`}
                        inputProps={{
                          onChange: (ev) =>
                            setData({ ...data, firstName: ev.target?.value }),
                        }}
                        type="text"
                        autoComplete="off"
                        {...register("firstName")}
                      />
                      {!!errors.firstName ? (
                        <Typography
                          variant="error"
                          className={roundedInputStyles.error}
                        >
                          {errors.firstName?.message}
                        </Typography>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <TextField
                        label={t("default.lastName")}
                        error={!!errors.lastName}
                        className={`${roundedInputStyles.input} ${
                          roundedInputStyles.cyan
                        } ${
                          !!data.lastName.length
                            ? roundedInputStyles.filled
                            : ""
                        }`}
                        inputProps={{
                          onChange: (ev) =>
                            setData({ ...data, lastName: ev.target?.value }),
                        }}
                        type="text"
                        autoComplete="off"
                        {...register("lastName")}
                      />
                      {!!errors.lastName ? (
                        <Typography
                          variant="error"
                          className={roundedInputStyles.error}
                        >
                          {errors.lastName?.message}
                        </Typography>
                      ) : null}
                    </FormControl>
                  </Box>
                ) : null}

                <FormControl>
                  <TextField
                    label={t("kyc.email")}
                    error={!!errors.email}
                    inputProps={{
                      onChange: (ev) =>
                        setData({ ...data, email: ev.target?.value }),
                    }}
                    className={`${roundedInputStyles.input} ${
                      roundedInputStyles.cyan
                    } ${watchEmail.length ? roundedInputStyles.filled : ""}`}
                    type="text"
                    autoComplete="off"
                    {...register("email")}
                  />
                  {!!errors.email ? (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {errors.email?.message}
                    </Typography>
                  ) : null}
                  {serverError && (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {serverError}
                    </Typography>
                  )}
                </FormControl>

                <FormControl>
                  <TextField
                    label={t("auth.password")}
                    error={!!errors.password}
                    className={`${roundedInputStyles.input} ${
                      roundedInputStyles.cyan
                    } ${
                      !!data.password.length ? roundedInputStyles.filled : null
                    }`}
                    inputProps={{
                      onChange: (ev) =>
                        setData({ ...data, password: ev.target?.value }),
                    }}
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    className={roundedInputStyles.showPasswordToggle}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  {!!errors.password ? (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {errors.password?.message}
                    </Typography>
                  ) : null}
                </FormControl>

                <FormControl>
                  <Typography
                    variant="body2"
                    component="p"
                    className={styles.authTerms}
                  >
                    {t("auth.agreementText")}{" "}
                    <a
                      href={getDynamicUrl("terms-of-service")}
                      target="_blank"
                      className={`${styles.authTermsLink} ${
                        !isFanAuth ? styles.authTermsLinkCreator : ""
                      }`}
                      rel="noreferrer"
                    >
                      {t("auth.termsAndConditions")}
                    </a>
                    {", "}
                    <Box
                      component="span"
                      onClick={() => setOpenAgreements(true)}
                      className={`${styles.authTermsLink} ${
                        !isFanAuth ? styles.authTermsLinkCreator : ""
                      }`}
                    >
                      {t("auth.thirdPartyServiceAgreements")}
                    </Box>
                    {", "}
                    <a
                      href={getDynamicUrl("privacy-policy")}
                      target="_blank"
                      className={`${styles.authTermsLink} ${
                        !isFanAuth ? styles.authTermsLinkCreator : ""
                      }`}
                      rel="noreferrer"
                    >
                      {t("auth.tosCBLabelPrivacyPolicy")}
                    </a>{" "}
                    {t("auth.tosLabelAndOur")}{" "}
                    <a
                      href={`${zoopPolicyHost}/guidelines/community-guidelines`}
                      target="_blank"
                      className={`${styles.authTermsLink} ${
                        !isFanAuth ? styles.authTermsLinkCreator : ""
                      }`}
                      rel="noreferrer"
                    >
                      {t("auth.communityGuidelines")}
                    </a>
                    {"."}
                  </Typography>
                  {isGiveaway ? (
                    <Typography
                      variant="body2"
                      component="p"
                      className={styles.authTerms}
                    >
                      <a
                        href={pdf}
                        target="_blank"
                        className={`${styles.authTermsLink} ${
                          !isFanAuth ? styles.authTermsLinkCreator : ""
                        }`}
                        rel="noreferrer"
                      >
                        {t("settings.termsAndConditions")}
                      </a>{" "}
                      {t("auth.ofTheGiveaway")}
                    </Typography>
                  ) : null}
                </FormControl>
                <Box className={styles.signSubmitContainer}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    className={styles.submitBtn}
                    disabled={!isValid}
                  >
                    {isFanAuth
                      ? t("auth.startConnectingOnZoop")
                      : t("auth.createClubZoop")}
                  </LoadingButton>
                  <Box className={styles.account}>
                    <Typography
                      sx={{
                        color: theme.palette.dark.contrastText,
                      }}
                      variant="body2"
                      className={styles.accountText}
                    >
                      {t("auth.haveAccount")}{" "}
                      <Link
                        to={PATHS.LOGIN}
                        className={`${styles.authTermsLink} ${
                          !isFanAuth ? styles.authTermsLinkCreator : ""
                        }`}
                        state={{ email: watchEmail }}
                      >
                        {t("auth.logIn")}
                      </Link>
                    </Typography>
                  </Box>
                  <AuthTypeSwitcher
                    isFanAuth={isFanAuth}
                    setFanAuth={setFanAuth}
                    text={
                      isFanAuth
                        ? t("auth.signUpAsCelebrity")
                        : t("auth.signUpAsFan")
                    }
                  />
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
      <PrivacyPolicyModal
        privacyPolicyLink={getDynamicUrl("privacy-policy")}
        open={openAgreements}
        onClose={() => setOpenAgreements(false)}
      />
    </>
  );
};

export default SignUp;
