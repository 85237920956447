import {
  Box,
  Button,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import classnames from "classnames/bind";
import ApplePay from "components/ApplePay";
import Card from "components/CardId/Card";
import Countdown from "components/Countdown";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { BanxaDisclaimer } from "components/Modal/BanxaDisclaimer/BanxaDisclaimer";
import {
  ANALYTICS_EVENTS,
  DROP_INITIAL_PRICE,
  errorResponseTypes,
  PATHS,
  PAYMENT_METHODS,
  PAYMENT_TYPE,
  PAYMENT_TYPE_APPLEPAY,
  PAYMENT_TYPE_BTC,
  PAYMENT_TYPE_COINIFY,
  PAYMENT_TYPE_PAYPAL,
  TRANSFER_STATUSES,
} from "constants/index";
import { useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setError, setLoader } from "redux/appSlice";
import { fetchDrop, resetDrop, setCheckoutUrl } from "redux/dropsSlice";
import { isDropFinished } from "redux/dropsSlice";
import { getCheckoutUrl } from "redux/selectors/dropsSelectors";
import { defaultPaymentMethod, fetchUserMe } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { cancelOrder } from "services/api/paymentsApi";
import { TradingErrorResponseMessages } from "services/apiErrorHelper.tsx";
import { isAuthenticated } from "services/auth.service";
import { buyCard, getCard } from "services/dropsApi";
import { LocalizationContext } from "services/localizationContext";
import PaypalButton from "views/Drops/DropBuy/PaypalButton";

import BuyCardActions from "../../../components/Modal/ErrorModal/Actions/BuyCardActions";
import stylesMain from "../DropDetail/DropDetail.module.scss";
import styles from "./DropBuy.module.scss";

const PAYMENT_COUNTDOWN_TIME = 90;

const DropBuy = () => {
  const isAuth = isAuthenticated();
  const cx = classnames.bind(styles);
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const params = useParams();
  const card = useSelector((state) => state.drops.drop);
  const isFinished = useSelector((state) => isDropFinished(state));
  const stateStatus = useSelector((state) => state.drops.dropStatus);
  const checkoutUrl = useSelector(getCheckoutUrl);

  const navigate = useNavigate();
  const location = useLocation();

  const [redirectUrl, setRedirectUrl] = useState("");
  const [transfer, setTransfer] = useState(null);
  const [paymentCountdown, setPaymentCountdown] = useState(-1);
  const [localCheckoutUrl, setLocalCheckoutUrl] = useState(null);
  const [cardName, setCardName] = useState("");
  const [banxaLoader, setBanxaLoader] = useState(false);
  const backRoute = location.state?.from;

  const myDefaultPaymentMethod = useSelector((state) =>
    defaultPaymentMethod(state)
  );
  const paymentMethod = location.state?.paymentMethod || myDefaultPaymentMethod;

  const paymentCountdownProgress =
    (paymentCountdown * 100) / PAYMENT_COUNTDOWN_TIME;
  const isUserLoading = useSelector((state) => state.users.me.loading);
  const user = useSelector((state) => state.users.entities);

  const isBought = [
    TRANSFER_STATUSES.processed,
    TRANSFER_STATUSES.pending,
    TRANSFER_STATUSES.succeeded,
  ].includes(card?.transferStatus);

  const goToSelectPayment = () => {
    const {
      currentSalePrice,
      id,
      influencer: { name },
      series,
    } = card || {};
    Analytics.track(ANALYTICS_EVENTS.ADD_PAYMENT_INFO, {
      value: currentSalePrice,
      items: [
        {
          name: t("default.editionNumber", { number: series }),
          id,
          price: currentSalePrice,
          celebrity: name,
          initial_price: DROP_INITIAL_PRICE,
          quantity: 1,
        },
      ],
    });

    navigate("/payment/accounts", {
      state: {
        prevRoute: location.pathname,
        paymentType: PAYMENT_TYPE.DROP,
      },
    });
  };
  const errorsHandler = TradingErrorResponseMessages();
  const onCancelOrder = () => {
    setTransfer(null);
    if (transfer?.orderId) {
      cancelOrder(transfer?.orderId).catch((e) => console.error(e));
    }
  };

  useEffect(() => {
    dispatch(fetchDrop(params.id, isAuth));
    if (params.id) {
      getCard(params.id).then((data) => {
        if (data?.type !== "custom") {
          setCardName(
            t("drops.seasonTitleAbbr", {
              curdNumber: data.series,
              seasonNumber: data.seasonNum,
            })
          );
        } else {
          setCardName(data?.name);
        }
      });
    }
    if (!location.state?.paymentMethod && !isUserLoading)
      dispatch(fetchUserMe());

    return () => {
      dispatch(resetDrop());
      dispatch(setCheckoutUrl(null));
    };
  }, []);

  useEffect(() => {
    if (isFinished) navigate(`/pass/${params.id}`, { replace: true });
  }, [isBought, isFinished, params?.id]);

  useEffect(() => {
    if (stateStatus === "failed") {
      dispatch(setError({ open: true }));
      navigate(PATHS.DROPS);
    }
  }, [stateStatus, paymentMethod]);

  const onPaymentSuccess = () => {
    Analytics.track("Buy Drop", {
      paymentMethod: paymentMethod.id,
      cardId: card.id,
    });
    dispatch(
      setLoader({
        title: t("drops.waitingPaymentConfirmation"),
      })
    );
    navigate(`success`, { replace: true, state: { paymentMethod, card } });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(
        setLoader({
          title: t("trading.submittingOrder"),
          subtitle: t("default.itMayTakeAFewSeconds"),
        })
      );
      let params = {
        appUserPaymentMethodId: paymentMethod.id,
      };
      const PAYMENT_METHOD_WITH_ORDER = PAYMENT_METHODS.find(
        (item) => item.type === paymentMethod.type
      );
      if (PAYMENT_METHOD_WITH_ORDER) {
        params.paymentMethodId = PAYMENT_METHOD_WITH_ORDER.id;
      }
      const res = await buyCard(card.id, params);
      const details = JSON.parse(res.details);
      dispatch(setLoader(false));

      if (details.data?.order?.checkout_url) {
        setLocalCheckoutUrl(details.data.order.checkout_url);
        return;
      }
      if (PAYMENT_METHOD_WITH_ORDER) {
        if (paymentMethod?.type === PAYMENT_TYPE_BTC) {
          setRedirectUrl(details?.hosted_checkout_url);
        } else if (paymentMethod?.type === PAYMENT_TYPE_COINIFY) {
          setRedirectUrl(details?.paymentWindowUrl);
        } else {
          setPaymentCountdown(PAYMENT_COUNTDOWN_TIME);
        }
        setTransfer(res);
      } else {
        onPaymentSuccess();
      }
    } catch (err) {
      //TODO::test errors
      if (err?.data.error === errorResponseTypes.ProfileNotCompleted) {
        dispatch(
          setError({
            open: true,
            ...errorsHandler(err?.data || err),
            isNotCompleted: true,
            actions: "BuyCardActions",
            title: t("kyc.completeKyc"),
            subtitle: t("kyc.pleaseCompleteTheWholeKyc"),
          })
        );
      } else {
        dispatch(
          setError({
            open: true,
            ...errorsHandler(err?.data || err),
          })
        );
      }
      dispatch(setLoader(false));
    }
  };

  const handleRedirect = ({ subtitleError }) => {
    if (!redirectUrl) {
      dispatch(
        setError({
          open: true,
          title: t("trading.dang"),
          subtitle: subtitleError,
        })
      );
      return;
    }
    window.open(redirectUrl);
  };

  window.closeBanxaIframe = () => {
    setLocalCheckoutUrl(null);
    dispatch(setCheckoutUrl({ checkoutUrl: null }));
    navigate(PATHS.DROPS_ID_BUY_SUCCESS.replace(":id", card?.id), {
      replace: true,
      state: { paymentMethod, card },
    });
  };

  return (
    <Container className={`${stylesMain.container} ${styles.buyDetails}`}>
      {(stateStatus === "loading" && !card) || isUserLoading ? (
        <Box className={stylesMain.wrapper}>
          <Container
            className={`${stylesMain.cardContainer} ${styles.cardContainer}`}
          >
            <Skeleton
              variant="rectangular"
              className={`${stylesMain.card} ${styles.detailsCard}`}
            />
            <Box className={`${styles.details}`}>
              <Skeleton
                variant="rectangular"
                className={styles.skeletonDetails}
              />
              <Skeleton
                variant="rectangular"
                className={styles.skeletonMethod}
              />
              <Box className={[styles.dropActions]}>
                <Skeleton
                  variant="rectangular"
                  className={styles.skeletonBtn}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box className={`${stylesMain.wrapper} ${styles.wrapper}`}>
          <BackBtn
            isPlain
            link={backRoute || `/pass/${params.id}`}
            title={
              transfer ? t("payments.completeOrder") : t("drops.reviewDetails")
            }
          />
          <Box className={styles.mainTitle}>
            <Typography variant="h3">
              {transfer
                ? t("payments.completeOrder")
                : t("drops.reviewDetails")}
            </Typography>
          </Box>
          {card && (
            <Container
              className={`${stylesMain.cardContainer} ${styles.cardContainer}`}
            >
              <Card
                card={card}
                className={`${stylesMain.card} ${styles.detailsCard}`}
              />
              <form onSubmit={onSubmit} className={styles.details}>
                <Box mb="30px">
                  <Box className={cx(stylesMain.gridItem, styles.gridItem)}>
                    <Typography
                      variant="lightText"
                      className={stylesMain.gridText}
                    >
                      {t("drops.bidInfluencer")}
                    </Typography>
                    <Typography
                      variant="acentTextLighter"
                      className={cx(
                        stylesMain.acentText,
                        stylesMain.acentTextLighter,
                        stylesMain.acentTextLarge
                      )}
                    >
                      {card.influencer.name}
                    </Typography>
                  </Box>
                  <Box className={cx(stylesMain.gridItem, styles.gridItem)}>
                    <Typography
                      variant="lightText"
                      className={stylesMain.gridText}
                    >
                      {t("default.edition")}
                    </Typography>
                    <Typography
                      variant="acentTextLighter"
                      className={cx(
                        stylesMain.acentText,
                        stylesMain.acentTextLighter,
                        stylesMain.acentTextLarge
                      )}
                    >
                      {card.name}
                    </Typography>
                  </Box>
                  <Box className={styles.divider} />
                  <Box className={stylesMain.gridItem}>
                    <Typography
                      variant="lightText"
                      className={cx(
                        stylesMain.gridText,
                        stylesMain.gridTextLarge
                      )}
                    >
                      {t("drops.total")}
                    </Typography>
                    <Typography
                      variant="acentTextLighter"
                      className={cx(
                        stylesMain.acentText,
                        stylesMain.acentTextLarge
                      )}
                    >
                      <NumberFormat
                        value={card.currentSalePrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={card.currencySymbol}
                      />
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={cx(styles.info, styles.infoMain)}
                  sx={{
                    justifyContent: paymentMethod?.id
                      ? "space-between"
                      : "center",
                  }}
                >
                  {paymentMethod?.id && (
                    <Typography variant="infoMain">
                      {t("payments.payingWith")} {paymentMethod.name}
                    </Typography>
                  )}
                  <Button
                    className={styles.changeBtn}
                    variant="contained"
                    size="xsmall"
                    color="dark"
                    onClick={goToSelectPayment}
                  >
                    {paymentMethod?.id
                      ? t("payments.change")
                      : t("payments.selectPaymentMethod")}
                  </Button>
                </Box>

                <Box className={styles.submitBtn}>
                  {paymentCountdown >= 0 && transfer && (
                    <Box className={styles.paymentCountdownWrap}>
                      <Typography variant="body1">
                        {t("payments.completePaymentTimerText")}
                      </Typography>
                      <Box className={styles.paymentCountdown}>
                        <Countdown
                          value={paymentCountdown}
                          onSetValue={setPaymentCountdown}
                          onEnd={onCancelOrder}
                        />
                        <Typography
                          variant="body2"
                          className={styles.paymentCountdownText}
                        >
                          {paymentCountdown}
                        </Typography>
                        <CircularProgress
                          color="lime"
                          variant="determinate"
                          value={paymentCountdownProgress}
                        />
                      </Box>
                    </Box>
                  )}

                  {transfer && paymentMethod?.type === PAYMENT_TYPE_BTC && (
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleRedirect({
                          subtitleError: t("trading.urlPayWithBtcError"),
                        })
                      }
                      disabled={!paymentMethod?.id}
                    >
                      {t("trading.payWithBtcBtn")}
                    </Button>
                  )}
                  {transfer && paymentMethod?.type === PAYMENT_TYPE_COINIFY && (
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleRedirect({
                          subtitleError: t("trading.urlPayWithCoinifyError"),
                        })
                      }
                      disabled={!paymentMethod?.id}
                    >
                      {t("trading.payWithCoinifyBtn")}
                    </Button>
                  )}
                  {transfer && paymentMethod?.type === PAYMENT_TYPE_PAYPAL && (
                    <PaypalButton
                      trackingId={transfer.trackingId}
                      onSuccess={onPaymentSuccess}
                      onCancel={onCancelOrder}
                    />
                  )}
                  {transfer &&
                    paymentMethod?.type === PAYMENT_TYPE_APPLEPAY && (
                      <ApplePay
                        className={styles.applePayBtn}
                        onSuccess={onPaymentSuccess}
                        onError={onCancelOrder}
                        transferId={transfer?.id}
                        amount={transfer.amount}
                        locale={user?.language}
                      />
                    )}
                  {!transfer && (
                    <Button
                      variant="outlined"
                      onClick={onSubmit}
                      disabled={!paymentMethod?.id}
                    >
                      {t("trading.submitOrder")}
                    </Button>
                  )}
                </Box>
              </form>
            </Container>
          )}
        </Box>
      )}
      <BanxaDisclaimer
        cardName={cardName}
        isOpen={localCheckoutUrl}
        setOpen={setLocalCheckoutUrl}
        submitHandler={() => {
          dispatch(setCheckoutUrl({ checkoutUrl: localCheckoutUrl }));
          setBanxaLoader(true);
        }}
      />
      {checkoutUrl ? (
        <Box className={styles.banxa}>
          <Box
            className={styles.banxaOverlay}
            onClick={() => {
              dispatch(setCheckoutUrl({ checkoutUrl: null }));
              setBanxaLoader(false);
            }}
          />
          {banxaLoader ? (
            <CircularProgress className={styles.banxaLoader} />
          ) : null}
          <iframe
            id="banxaCheckoutIframe"
            src={checkoutUrl}
            onLoad={() => setBanxaLoader(false)}
          />
        </Box>
      ) : null}
    </Container>
  );
};
export default DropBuy;
