import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { ReactComponent as ArrowBack } from "assets/svg/arrow-left.svg";
import AuthLeftSide from "components/AuthLeftSide";
import AppPublicHeader from "components/Layout/PublicHeader/PublicHeader";
import { errorResponseTypes, forgotPasswordSteps, PATHS } from "constants/";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Analytics from "services/Analytics.service";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";
import styles from "styles/auth/Auth.module.scss";
import { useIsMobile } from "utils/hooks";

import { celebrityDomain } from "../../constants";
import { setLoader } from "../../redux/appSlice";
import Email from "./Email";
import Password from "./Password";
import Success from "./Success";
import Code from "./VerificationCode";

const ForgotPassword = ({ isCelebType }) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [step, setStep] = useState(forgotPasswordSteps.email);
  const [email, setEmail] = useState(location?.state?.email || "");
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [resendCountdown, setResendCountdown] = useState(0);

  const isExpiredPassword = location?.state?.passwordExpired;
  const oldPassword = location.state?.password;

  const goBack = () => {
    step > 1 ? setStep(step - 1) : navigate(PATHS.LOGIN, { state: { email } });
  };

  const sendCode = (email, isResend) => {
    setLoading(true);
    setServerError(false);

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool(isCelebType),
    });

    user.forgotPassword({
      onSuccess: function (data) {
        setLoading(false);
        if (!isResend) {
          setStep(forgotPasswordSteps.code);
          setEmail(email);
        } else {
          setResendCountdown(15);
        }
      },
      onFailure: function (err) {
        setServerError(errorResponseMessages(err, t));
        setLoading(false);
      },
    });
  };

  const onCodeSubmit = (code) => {
    setCode(code);
    setStep(forgotPasswordSteps.password);
  };

  const setAuthCreator = (data) => {
    const lang = localStorage.getItem("selected-language");
    let url = `${celebrityDomain}/signin?refreshToken=${data.refreshToken.token}&username=${data.accessToken.payload.username}`;
    if (lang) {
      url += `&lang=${lang}`;
    }
    window.location.href = url;
  };

  const onSubmit = (password) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool(),
    });

    setLoading(true);
    setServerError(false);
    if (isExpiredPassword) {
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: oldPassword,
      });
      user.authenticateUser(authDetails, {
        onSuccess: (d) => {},
        onFailure: (error) => {
          console.error("onFailure", error);
          setTimeout(() => {
            dispatch(setLoader(false));
            setServerError(errorResponseMessages(error, t));
          }, 1500);
        },
        newPasswordRequired() {
          user.completeNewPasswordChallenge(
            password,
            {},
            {
              onSuccess: (result) => {
                dispatch(setLoader(false));
                setAuthCreator(result);
              },
              onFailure(err) {
                dispatch(setLoader(false));
                setServerError(errorResponseMessages(err, t));
              },
            }
          );
        },
      });
    } else {
      user.confirmPassword(code, password, {
        onSuccess() {
          setLoading(false);
          setStep(forgotPasswordSteps.success);
          Analytics.track("Forgot Password", { email });
        },
        onFailure(err) {
          setLoading(false);
          setServerError(errorResponseMessages(err, t));
          if (
            err.code === errorResponseTypes.CodeMismatchException ||
            err.code === errorResponseTypes.CodeExpiredException
          ) {
            setStep(forgotPasswordSteps.code);
          }
        },
      });
    }
  };
  const saveEmail = (email) => {
    setEmail(email);
  };

  return (
    <>
      {isMobile && <AppPublicHeader />}
      <Box>
        {step === forgotPasswordSteps.success ? (
          <Success />
        ) : (
          <Box
            className={`${styles.container} ${styles.forgotPasswordForm} ${
              isCelebType ? styles.forgotPasswordFormCreator : ""
            }`}
          >
            {!isMobile && <AuthLeftSide step={0} />}
            <Box className={styles.rightSide}>
              <Box className={styles.rightSideContent}>
                {!isMobile && (
                  <IconButton
                    onClick={goBack}
                    className={styles.backBtn}
                    sx={{ bgcolor: theme.palette.surface.main }}
                  >
                    <ArrowBack width={40} height={40} />
                  </IconButton>
                )}
                {step === forgotPasswordSteps.email && !isExpiredPassword ? (
                  <Email
                    onSubmit={sendCode}
                    mainServerError={serverError}
                    isMainLoading={loading}
                    onEmailChanged={saveEmail}
                  />
                ) : null}
                {step === forgotPasswordSteps.code && (
                  <Code
                    email={email}
                    onResend={sendCode}
                    onSubmit={onCodeSubmit}
                    mainServerError={serverError}
                    isMainLoading={loading}
                    resendCountdown={resendCountdown}
                    setResendCountdown={setResendCountdown}
                  />
                )}
                {step === forgotPasswordSteps.password || isExpiredPassword ? (
                  <Password onSubmit={onSubmit} />
                ) : null}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ForgotPassword;
