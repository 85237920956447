import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./meta-statistics.module.scss";
import Proptypes from "prop-types";
import { LocalizationContext } from "services/localizationContext";
import { convertSecondsToDDHHMMSS, getFormattedDate } from "utils/dateHelper";

export default function MetaStatistics({
  tradesTotal,
  holdersTotal,
  cardsTotal,
  dropTimeAvg,
  dropTime,
  topSalePrice,
  bidWinRatio,
  dropBiddersAvg,
  lastTradedAt,
}) {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={`${styles.accordionWrap} ${styles.blockWrap}`}>
      <Box className={styles.accordion}>
        <Box id="meta-stat" mb={2}>
          <Typography variant="h6">{t("discover.metaStatistics")}</Typography>
        </Box>
        <Box className={styles.infoWrap}>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.numberOfTradesSinceDrop")}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.accent}
            >
              {tradesTotal}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.numberOfOwners")}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.accent}
            >
              {holdersTotal}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.dropTime")}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.accent}
            >
              {convertSecondsToDDHHMMSS(dropTimeAvg || dropTime)}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.topSales")}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.accent}
            >
              ${topSalePrice}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.lastTraded")}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              className={styles.accent}
            >
              {getFormattedDate(lastTradedAt)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

MetaStatistics.defaultProps = {
  tradesTotal: 0,
  holdersTotal: 0,
  cardsTotal: 0,
  dropTimeAvg: 0,
  topSalePrice: 0,
  bidWinRatio: 0,
  lastTradedAt: null,
};

MetaStatistics.propTypes = {
  tradesTotal: Proptypes.number,
  holdersTotal: Proptypes.number,
  cardsTotal: Proptypes.number,
  dropTimeAvg: Proptypes.number,
  topSalePrice: Proptypes.number,
  bidWinRatio: Proptypes.number,
  lastTradedAt: Proptypes.string,
};
