import { Box } from "@mui/material";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { BUY_EDITION, ORDER_TYPES } from "constants/index";
import React, { useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setError, setLoader } from "redux/appSlice";
import {
  createBuyEditionOrder,
  fetchEditionMarketStatus,
  fetchEditionOrders,
} from "redux/editionSlice";
import { defaultPaymentMethod, fetchUserMe } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { TradingErrorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import styles from "./buy-edition.module.scss";
import BuyCard from "./BuyCard";
import ReviewDetails from "./ReviewDetails";
import Submitted from "./Submitted";

export const orderSteps = {
  buyCard: 0,
  reviewDetails: 2,
  submitted: 3,
};

const BuyEdition = () => {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editionData, setEditionData] = useState(null);
  const [orderStep, setOrderStep] = useState(orderSteps.buyCard);

  const [orders, setOrders] = useState([]);
  const [platformFee, setPlatformFee] = useState(roundToTwo(0));
  const [priceValue, setPriceValue] = useState(roundToTwo(0));
  const errorsHandler = TradingErrorResponseMessages();
  const myDefaultPaymentMethod = useSelector((state) =>
    defaultPaymentMethod(state)
  );
  const paymentMethod = location.state?.paymentMethod || myDefaultPaymentMethod;
  const order = location.state?.order;
  const edition = editionData || location.state?.edition;
  const tokenNumber = order?.tokenSerial?.split("-")[1];

  const isUserLoading = useSelector((state) => state.users.me.loading);
  const isEditionDataLoading = useSelector(
    (state) =>
      state.edition.marketStatus.loading || state.edition.editionOrders.loading
  );

  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    let unmounted = false;

    dispatch(fetchEditionMarketStatus({ id: params.id, type: ORDER_TYPES.buy }))
      .unwrap()
      .then((d) => !unmounted && setEditionData(d));

    dispatch(fetchEditionOrders({ id: params.id, type: ORDER_TYPES.sell }))
      .unwrap()
      .then((d) => !unmounted && setOrders(d.rows));

    return () => {
      unmounted = true;
    };
  }, [params.id]);

  useEffect(() => {
    if (!edition) {
      return;
    }

    if (order) {
      const price = +order?.price || 0;
      setPlatformFee(price * +edition.feePercentage);
      setPriceValue(price);
    } else {
      setPlatformFee(+edition.defaultPrice * +edition.feePercentage);
      setPriceValue(+edition.defaultPrice);
    }
  }, [edition, order?.price]);

  useEffect(() => {
    if (!location.state?.paymentMethod && !isUserLoading)
      dispatch(fetchUserMe());
  }, [location.state?.paymentMethod]);

  useEffect(() => {
    const loaderText = {
      title: t("default.loading"),
      subtitle: t("default.itMayTakeAFewSeconds"),
    };
    const loader = isUserLoading || isEditionDataLoading ? loaderText : false;
    dispatch(setLoader(loader));
  }, [isUserLoading, isEditionDataLoading]);

  const onBackClick = () => {
    if (orderStep === orderSteps.buyCard) {
      navigate(`/pass/${params.id}`, params.id);
    } else {
      setOrderStep(orderSteps.buyCard);
    }
  };

  const submitOrder = async () => {
    setLoading(true);
    dispatch(
      createBuyEditionOrder({
        id: +params.id,
        maximumPrice: +priceValue,
        quantity: 1,
        appUserPaymentMethodId: +paymentMethod.id,
        counterOrderId: +order?.orderId || +order?.id || undefined,
      })
    )
      .unwrap()
      .then(() => {
        Analytics.track("Buy Edition", {
          id: +params.id,
          maximumPrice: +priceValue,
          quantity: 1,
          appUserPaymentMethodId: +paymentMethod.id,
          counterOrderId: +order?.orderId || +order?.id || undefined,
        });
        setOrderStep(orderSteps.submitted);
        if (paymentMethod?.type === "card" && order?.id) {
          dispatch(
            setLoader({
              title: t("drops.waitingPaymentConfirmation"),
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setError({
            open: true,
            ...errorsHandler(err, BUY_EDITION),
          })
        );
      })
      .finally(() => setLoading(false));
  };
  const title = useMemo(() => {
    switch (orderStep) {
      case orderSteps.buyCard:
        return t("edition.buyCard");
      case orderSteps.reviewDetails:
        return t("discover.reviewDetails");
      default:
        return "";
    }
  }, [orderStep]);

  return (
    <>
      <Box className={styles.container}>
        {orderStep !== orderSteps.submitted && (
          <BackBtn
            onClick={onBackClick}
            isPlain={orderStep !== orderSteps.buyCard}
            title={title}
          />
        )}
        <>
          {orderStep === orderSteps.buyCard && (
            <BuyCard
              title={title}
              setOrderStep={setOrderStep}
              paymentMethod={paymentMethod}
              ordersCount={orders.length}
              edition={edition}
              order={order}
              setPlatformFee={setPlatformFee}
              setPriceValue={setPriceValue}
              priceValue={priceValue}
              platformFee={platformFee}
            />
          )}
          {orderStep === orderSteps.reviewDetails && (
            <ReviewDetails
              title={title}
              edition={edition}
              priceValue={priceValue}
              platformFee={platformFee}
              submitOrder={submitOrder}
              loading={loading}
              buttonText={t("edition.buyCard")}
              tokenNumber={tokenNumber}
            />
          )}
          {orderStep === orderSteps.submitted && (
            <Submitted
              edition={edition}
              priceValue={priceValue}
              platformFee={platformFee}
              paymentMethod={paymentMethod}
            />
          )}
        </>
      </Box>
    </>
  );
};

export default BuyEdition;
