import {
  Box,
  Typography,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Skeleton,
} from "@mui/material";
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import WatchListItem from "components/WatchListItem";
import { LocalizationContext } from "services/localizationContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserWatchList,
  removeEditionFromWatchList,
} from "redux/usersSlice";
import { useIsMobile } from "utils/hooks";
import { getFormattedDate, formatAMPM } from "utils/dateHelper";
import Toaster from "components/Toaster";
import styles from "./watchList.module.scss";
import { ReactComponent as PlaceholderIcon } from "assets/svg/no-watch-list-icon.svg";
import { ReactComponent as ArrowDown } from "assets/svg/arrow-down.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import { WATCHLIST_FILTER_TYPES } from "constants/";

const Placeholder = ({ text }) => {
  return (
    <Box className={styles.placeholder}>
      <PlaceholderIcon
        width={73}
        height={88}
        className={styles.placeholderIcon}
      />
      <Typography
        variant="h4"
        component="h4"
        className={styles.placeholderText}
      >
        {text}
      </Typography>
    </Box>
  );
};

const Skeletons = () => (
  <Box className={styles.watchListItemsContainer}>
    {Array.from(new Array(6)).map((_item, i) => (
      <Skeleton key={i} variant="rectangular" className={styles.skeletonRect} />
    ))}
  </Box>
);

const POPOVER_LIFETIME = 4000;

const WatchList = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();
  const watchList = useSelector((state) => state.users.watchList);
  const [watchListFiltered, setWatchListFiltered] = useState([]);
  const [filter, setFilter] = useState(WATCHLIST_FILTER_TYPES.none);
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [editionToRemove, setEditionToRemove] = useState(null);
  const timer = useRef(null);

  useEffect(() => {
    let unmounted = false;

    !unmounted && setAnchorEl(containerRef.current);

    dispatch(fetchUserWatchList())
      .unwrap()
      .then((d) => !unmounted && setWatchListFiltered(d));

    return () => {
      unmounted = true;
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (editionToRemove) {
        const id = editionToRemove.card.id;
        dispatch(removeEditionFromWatchList(id));
      }
    };
  }, [editionToRemove]);

  const handleSort = useCallback(() => {
    if (!watchListFiltered) {
      return;
    }

    switch (filter) {
      case WATCHLIST_FILTER_TYPES.none:
        setWatchListFiltered(watchListFiltered);
        break;

      case WATCHLIST_FILTER_TYPES.recent:
        const watchListSortedByRecent = [...watchListFiltered].sort((a, b) => {
          const aTime = new Date(a.createdAt).getTime();
          const bTime = new Date(b.createdAt).getTime();

          return aTime - bTime;
        });

        setWatchListFiltered(watchListSortedByRecent);
        break;

      case WATCHLIST_FILTER_TYPES.price:
        const watchListSortedByPrice = [...watchListFiltered].sort((a, b) => {
          const aPrice = a.card.latestPrice;
          const bPrice = b.card.latestPrice;

          return aPrice - bPrice;
        });

        setWatchListFiltered(watchListSortedByPrice);
        break;

      default:
        setWatchListFiltered(watchListFiltered);
    }
  }, [filter, watchListFiltered]);

  useEffect(() => {
    handleSort();
  }, [filter]);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const handleDelete = useCallback(
    (editionId) => {
      const items = watchListFiltered.filter(
        (item) => item.card.id !== editionId
      );
      setWatchListFiltered(items);

      const removedEdition = watchListFiltered.find(
        (item) => item.card.id === editionId
      );
      setEditionToRemove(removedEdition);

      setPopoverIsOpen(true);

      timer.current = setTimeout(() => {
        dispatch(removeEditionFromWatchList(removedEdition.cardId)).then(() => {
          setPopoverIsOpen(false);
          setEditionToRemove(null);
        });
      }, POPOVER_LIFETIME);
    },
    [watchListFiltered]
  );

  const getFilterBtnTitle = () => {
    if (filter === WATCHLIST_FILTER_TYPES.recent) {
      return t("default.mostRecent");
    }
    if (filter === WATCHLIST_FILTER_TYPES.price) {
      return t("default.price");
    }
    return "";
  };

  const onUndoPress = () => {
    setWatchListFiltered([editionToRemove, ...watchListFiltered]);
    clearTimeout(timer.current);
    setPopoverIsOpen(false);
  };

  const displayTimeUpdated = () => {
    const d = new Date();
    const date = getFormattedDate(d);
    const time = formatAMPM(d);

    return `${t("discover.updated")}: ${date} at ${time}`;
  };

  return (
    <Box className={styles.page}>
      <Container ref={containerRef} className={styles.container}>
        <Typography variant="h2" component="h1" className={styles.title}>
          {t("home.watchlist")}
        </Typography>
        {isMobile && !!watchList.entities.length && (
          <Box className={styles.headerInfo}>
            <Typography
              variant="body1"
              component="span"
              className={styles.headerInfoQuantity}
            >
              {`${watchListFiltered.length} edition${
                watchListFiltered.length > 1 ? "s" : ""
              }`}
            </Typography>
            <Box className={styles.headerInfoBullet} component="span"></Box>
            <Typography
              variant="body1"
              component="span"
              className={styles.headerInfoUpdated}
            >
              {displayTimeUpdated()}
            </Typography>
          </Box>
        )}
        <Box className={styles.header}>
          <FormControl className={styles.selectWrap}>
            <InputLabel className={styles.selectLabel} id="filter-select-label">
              {t("default.sortBy")}
            </InputLabel>
            <Select
              onChange={handleChange}
              value={filter}
              label={t("default.sortBy")}
              labelId="filter-select-label"
              IconComponent={ArrowDown}
              className={styles.select}
              MenuProps={{
                classes: { paper: styles.filterDropdownMenu },
              }}
              notched={false}
              renderValue={() => null}
            >
              <MenuItem value={"recent"}>{t("default.mostRecent")}</MenuItem>
              <MenuItem value={"price"}>{t("default.price")}</MenuItem>
            </Select>
            {filter !== WATCHLIST_FILTER_TYPES.none && (
              <Button
                variant="contained"
                endIcon={<CloseIcon />}
                onClick={() => setFilter(WATCHLIST_FILTER_TYPES.none)}
                className={styles.filterBtn}
              >
                {getFilterBtnTitle()}
              </Button>
            )}
          </FormControl>
          {!isMobile && !!watchListFiltered.length && (
            <Box className={styles.headerInfo}>
              <Typography
                variant="body1"
                component="span"
                className={styles.headerInfoQuantity}
              >
                {`${watchListFiltered.length} edition${
                  watchListFiltered.length > 1 ? "s" : ""
                }`}
              </Typography>
              <Box className={styles.headerInfoBullet} component="span"></Box>
              <Typography
                variant="body1"
                component="span"
                className={styles.headerInfoUpdated}
              >
                {displayTimeUpdated()}
              </Typography>
            </Box>
          )}
        </Box>
        {watchList.loading ? (
          <Skeletons />
        ) : !!watchListFiltered.length ? (
          <Box className={styles.watchListItemsContainer}>
            {watchListFiltered.map((item) => {
              return (
                <WatchListItem
                  key={item.id}
                  item={item}
                  handleDelete={() => handleDelete(item.card.id)}
                />
              );
            })}
          </Box>
        ) : (
          <Placeholder text={t("home.emptyWatchlist")} />
        )}
      </Container>
      <Toaster
        name="delete-popover"
        isOpen={popoverIsOpen}
        anchorEl={anchorEl}
        onUndoPress={onUndoPress}
        message={t("home.removedFromWatchlist")}
        anchorOrigin={{ horizontal: 70 }}
      />
    </Box>
  );
};

export default WatchList;
