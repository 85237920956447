import "styles/slider/index.scss";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ActivityStatistics from "components/ActivityStatistics";
import Tokens from "components/Card/Tokens";
import { DescriptionCard } from "components/DescriptionCard/DescriptionCard";
import { InfluencerLink } from "components/InfluencerLink/InfluencerLink";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import MarketStatistics from "components/MarketStatistics";
import MetaStatistics from "components/MetaStatistics";
import { SeasonSlider } from "components/SeasonSlider/SeasonSlider";
import { PATHS } from "constants/index";
import { CHANNELS, EVENTS, ORDER_TYPES } from "constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { updateCardTokens } from "redux/cardSlice";
import {
  fetchEditionActivity,
  fetchEditionData,
  fetchEditionOrders,
  fetchEditionStats,
} from "redux/editionSlice";
import { getCardSelector, getCardTokens } from "redux/selectors/cardSelectors";
import { fetchUserEditions } from "redux/usersSlice";
import { getEditionOwners } from "services/api/publicApi";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";
import { getUserCard } from "services/userApi";
import { formatEditionOrders, getInfluencerProfileLink } from "utils";

import { CARD_TYPES } from "../../constants/card";
import BarterEdition from "./BarterEdition";
import styles from "./edition.module.scss";
import CardSeason from "./Season";
import { SellCards } from "./SellCards";
import TopBlock from "./TopBlock";

const Edition = ({
  handleBuyBtnClick,
  onSell,
  checking,
  status,
  setBuyAlert,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useContext(LocalizationContext);
  const [editionOrders, setEditionOrders] = useState({
    tokens: [],
    amountCards: 0,
  });
  const [sellCardsLoader, setSellCardsLoader] = useState(false);
  const influencer = status?.card?.influencer;
  const [currencySymbol, setCurrensySymbol] = useState(null);
  const [seasonNum, setSeasonNum] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOwnersLoading, setOwnersLoading] = useState(false);
  const [statistic, setStatistic] = useState(null);
  const [activity, setActivity] = useState(null);
  const editionId = +params.id;
  const [editionOwners, setEditionOwners] = useState([]);
  const isMobile = useMediaQuery("(max-width:840px)");
  const isInCollection = status?.assetHoldings && status?.assetHoldings > 0;

  const userMe = useSelector((state) => state.users.me.entities);
  const card = useSelector(getCardSelector);
  const inCollectionCard = useSelector(getCardTokens);

  const backRoute = location.state?.backRoute || PATHS.DROPS;
  const unmounted = useRef(false);

  useEffect(() => {
    if (status) {
      const { card } = status;
      setSellCardsLoader(true);
      dispatch(fetchEditionOrders({ id: editionId, type: ORDER_TYPES.sell }))
        .then((res) => {
          if (res?.payload) {
            const amount = card?.quantityAllocated || 0;
            const formatRes = formatEditionOrders(res.payload, amount);
            setEditionOrders({
              amountCards: formatRes.amountCards,
              tokens: formatRes.tokens,
            });
          }
        })
        .finally(() => setSellCardsLoader(false));
    }
  }, [editionId, status]);

  //TODO::?????
  useEffect(() => {
    const channelCards = PusherService.subscribe(CHANNELS.CARDS);
    channelCards.bind(EVENTS.PRICE_CHANGED, (data) => {
      dispatch(fetchUserEditions());
    });
    channelCards.bind(EVENTS.BUY, (data) => {
      dispatch(fetchUserEditions());
    });
  }, []);

  useEffect(() => {
    if (!editionId) return;
    setIsLoading(true);
    dispatch(fetchEditionData(editionId))
      .unwrap()
      .then((d) => {
        if (unmounted.current) return;
        setSeasonNum(d.seasonNum);
        setCurrensySymbol(d?.influencer?.currencySymbol);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        !unmounted.current && setIsLoading(false);
      });

    return () => {
      unmounted.current = true;
    };
  }, [editionId]);

  useEffect(() => {
    const getMyCardData = async () => {
      try {
        const res = await getUserCard({ params: { cardId: editionId } });
        const formatRes = formatEditionOrders(
          res?.data,
          status?.card?.quantityAllocated
        );
        dispatch(updateCardTokens(formatRes));
      } catch (e) {
        console.error(e);
      }
    };
    isInCollection ? getMyCardData() : dispatch(updateCardTokens(null));
  }, [isInCollection, editionId]);

  useEffect(() => {
    dispatch(fetchEditionStats({ id: editionId }))
      .unwrap()
      .then((d) => {
        !unmounted.current && setStatistic(d);
      });

    dispatch(fetchEditionActivity({ id: editionId }))
      .unwrap()
      .then((d) => {
        !unmounted.current && setActivity(d);
      });
    getOwners(editionId);
  }, [editionId, userMe?.id]);

  const cardIsNotComplete = status?.card?.status !== "complete";
  // const isSellBtnDisabled = !isInCollection || !status?.assetHoldings;
  const totalHoldings =
    currencySymbol && inCollectionCard
      ? `${currencySymbol}${inCollectionCard?.overallValue}`
      : "";

  const onBuy = () => {
    if (editionOrders && editionOrders.tokens && editionOrders.tokens[0]) {
      const token = editionOrders.tokens[0];
      handleBuyBtnClick({
        ...token,
        price: token.purchasePrice || token.price,
        total: editionOrders?.amountCards,
        currencySymbol,
        allTokens: editionOrders.tokens,
      });
    } else {
      handleBuyBtnClick();
    }
  };

  const getOwners = async (cardId) => {
    try {
      setOwnersLoading(true);
      const res = await getEditionOwners({ cardId, userId: userMe?.id });
      if (!unmounted.current) {
        const owners = !!userMe?.id
          ? res.filter(
              (owner) => owner.id !== userMe?.id && owner.availableForBarter
            )
          : [];
        setEditionOwners(owners);
      }
    } catch (e) {
      console.error(e);
    } finally {
      !unmounted.current && setOwnersLoading(false);
    }
  };

  const MainInfo = () => {
    return !status?.card ? (
      <Skeleton
        className={`${styles.mainInfo} ${styles.skeletonBg}`}
        variant="rectangular"
      />
    ) : (
      <Box className={styles.mainInfo}>
        <img
          className={styles.mainImage}
          src={status?.card?.imageUrl}
          alt={influencer?.name}
        />
      </Box>
    );
  };

  const MyCards = () =>
    inCollectionCard && isInCollection ? (
      <Box className={styles.myCards}>
        <Tokens
          data={inCollectionCard}
          currencySymbol={currencySymbol}
          onSubmit={onSell}
          loading={checking}
          subTitle={
            card?.type === CARD_TYPES.CUSTOM
              ? t("discover.editionNumber")
              : null
          }
        />
      </Box>
    ) : null;

  const influencerProfileLink = getInfluencerProfileLink({
    id: influencer?.id,
    handle: influencer?.handle,
  });

  const handleSell = () => {
    if (
      inCollectionCard &&
      inCollectionCard.tokens &&
      inCollectionCard.tokens[0]
    ) {
      const token = inCollectionCard.tokens[0];
      onSell({
        ...token,
        price: token.purchasePrice || token.price,
        total: inCollectionCard?.amountCards,
        currencySymbol,
        allTokens: inCollectionCard.tokens,
      });
    } else {
      onSell();
    }
  };

  return (
    <>
      <Box className={styles.container}>
        <BackBtn link={backRoute} />
        <>
          <Box className={styles.content}>
            <Box className={styles.leftSide}>
              {<MainInfo />}
              {influencer ? (
                <Box className={styles.influencerLink}>
                  <InfluencerLink
                    className={styles.influencerLinkEl}
                    influencer={influencer}
                    path={influencerProfileLink}
                  />
                </Box>
              ) : null}
              {!status?.card || isLoading ? (
                <Skeleton
                  className={`${styles.buttonsBlock} ${styles.skeletonBg}`}
                  variant="rectangular"
                  sx={{
                    height: 54,
                  }}
                />
              ) : (
                <Box className={styles.buttonsBlock}>
                  {/*{!status?.assetHoldings && !editionOrders?.tokens?.length ? (*/}
                  {/*  <Button*/}
                  {/*    variant="outlined"*/}
                  {/*    className={styles.buttonsBlockBtn}*/}
                  {/*    style={{ pointerEvents: "none" }}*/}
                  {/*  >*/}
                  {/*    {t("discover.noCardsAvailableForSale")}*/}
                  {/*  </Button>*/}
                  {/*) : null}*/}
                  {/*{status?.assetHoldings ? (*/}
                  {/*  <LoadingButton*/}
                  {/*    loading={checking}*/}
                  {/*    variant="outlined"*/}
                  {/*    // disabled={isSellBtnDisabled}*/}
                  {/*    className={styles.buttonsBlockBtn}*/}
                  {/*    onClick={handleSell}*/}
                  {/*  >*/}
                  {/*    {t("discover.sell")}*/}
                  {/*  </LoadingButton>*/}
                  {/*) : null}*/}
                  {editionOrders?.tokens?.length ? (
                    <Button
                      variant="contained"
                      disabled={cardIsNotComplete}
                      className={styles.buttonsBlockBtn}
                      onClick={onBuy}
                    >
                      {t("discover.buy")}
                    </Button>
                  ) : null}
                </Box>
              )}
              {!isMobile ? <MyCards /> : null}
              {!isMobile ? (
                <SellCards
                  id={editionId}
                  handleBuyBtnClick={handleBuyBtnClick}
                  data={editionOrders}
                  loading={sellCardsLoader}
                  currencySymbol={currencySymbol}
                  defaultExpanded={inCollectionCard?.tokens?.length}
                />
              ) : null}
            </Box>

            <Box className={styles.rightSide}>
              <Box className={styles.top}>
                <TopBlock
                  ownedCards={inCollectionCard?.ownedCards}
                  editionData={status?.card}
                  totalHoldings={totalHoldings}
                />
              </Box>
              {isMobile ? <MyCards /> : null}
              {card?.type === "custom" ? (
                <>
                  <DescriptionCard
                    className={styles.seasonDescription}
                    buyAlertHandler={() => setBuyAlert(true)}
                    {...card}
                    title={card.name}
                    marketTokens={editionOrders}
                  />
                  {card.otherCards?.length ? (
                    <Box className={styles.usersSwapBlock}>
                      <Typography
                        variant="h5_medium"
                        className={styles.title}
                        component="h2"
                      >
                        {`${t("drops.otherCardsFrom")} ${card.influencer.name}`}
                      </Typography>
                      <SeasonSlider
                        seasonCards={card.otherCards}
                        cardProps={{ description: null }}
                      />
                    </Box>
                  ) : null}
                </>
              ) : null}
              {card?.type !== "custom" ? (
                <CardSeason
                  seasonNum={seasonNum}
                  influencerId={influencer?.id}
                  editionId={editionId}
                />
              ) : null}
              {!isInCollection ? (
                <BarterEdition
                  editionId={editionId}
                  holders={editionOwners}
                  isLoading={isOwnersLoading}
                />
              ) : null}
              {!statistic ? (
                <Skeleton
                  className={`${styles.accordionWrap} ${styles.skeletonBg}`}
                  variant="rectangular"
                  sx={{
                    height: 83,
                    mb: "20px",
                  }}
                />
              ) : (
                <MarketStatistics {...statistic} />
              )}
              {!statistic ? (
                <Skeleton
                  className={`${styles.accordionWrap} ${styles.skeletonBg}`}
                  variant="rectangular"
                  sx={{
                    height: 83,
                    mb: "20px",
                  }}
                />
              ) : (
                <MetaStatistics {...statistic} />
              )}
              {!activity ? (
                <Skeleton
                  className={`${styles.accordionWrap} ${styles.skeletonBg}`}
                  variant="rectangular"
                  sx={{
                    height: 83,
                  }}
                />
              ) : (
                <ActivityStatistics
                  isEditionColumnEnabled={false}
                  data={activity}
                />
              )}
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default Edition;
