import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { EDITION_STATUSES, PATHS } from "constants/index";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { LinearProgressStyled } from "../../LinearProgress";
import CustomTooltip from "../../Tooltip";
import { NotReleased } from "../components/NotReleased";
import { Price } from "../components/Price/Price";
import { UpcomingDrop } from "../components/UpcomingDrop";
import { PlusIcon, SearchIcon } from "../icons";
import styles from "./SmallCard.module.scss";

const CONTENT = {
  [EDITION_STATUSES.phase0]: {
    ContentCmpnt: UpcomingDrop,
  },
  [EDITION_STATUSES.phase2]: {
    ContentCmpnt: Price,
    showAllocation: true,
    showHoldings: true,
  },
  [EDITION_STATUSES.complete]: {
    ContentCmpnt: Price,
    showHoldings: true,
  },
  [EDITION_STATUSES.notReleased]: {
    ContentCmpnt: NotReleased,
  },
  defaultContent: {
    ContentCmpnt: Box,
  },
};

export const SmallCard = ({ className, data }) => {
  const cx = classNames.bind(styles);
  const {
    status,
    type,
    holdings = 0,
    cardNum,
    quantityAllocated = 0,
    title,
    name,
    saleTokensLeft = 0,
    id,
    description,
    dropStartDateFormat,
  } = data;
  const { showAllocation, showHoldings, ContentCmpnt } =
    CONTENT[status] || CONTENT.defaultContent;

  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const goToBuy = () => {
    navigate(PATHS.DROPS_ID_BUY.replace(":id", id));
  };

  return (
    <Box className={cx(className, styles.card)}>
      <Box className={`${styles.cardContent} global-small-card-content`}>
        <ContentCmpnt
          {...data}
          className={styles.cardContentBlock}
          overrideStyles={styles}
          dropStartDateFormat={dropStartDateFormat}
        />
        {showHoldings && (
          <Box className={styles.cardContentBlock}>
            <Typography variant="textNormal_14">
              {holdings ? t("edition.collected") : t("edition.collect")}
            </Typography>
            <Typography
              variant="textMediumBold"
              color={theme.palette.accent.secondary}
            >
              {holdings ? (
                <Box style={{ textTransform: "lowercase" }}>
                  {holdings > 1
                    ? `${holdings} ${t("default.cards")}`
                    : `${holdings} ${t("default.card")}`}
                </Box>
              ) : !+saleTokensLeft ? (
                <Button className={styles.cardContentBlockBtn}>
                  <SearchIcon />
                  {t("drops.resaleCardsTitle")}
                </Button>
              ) : (
                <Button
                  className={`${styles.cardContentBlockBtn} global-path-link`}
                  onClick={goToBuy}
                >
                  <PlusIcon />
                  {t("default.buyNow")}
                </Button>
              )}
            </Typography>
          </Box>
        )}
        {showAllocation && (
          <Box className={styles.cardContentBlock}>
            <Typography variant="textNormal_14">
              {t("edition.availability")}
            </Typography>
            <Box className={styles.cardProgressBarWrapper}>
              <LinearProgressStyled
                color={theme.palette.lime}
                value={(saleTokensLeft / quantityAllocated) * 100}
              />
            </Box>
          </Box>
        )}
      </Box>
      {title || name || description ? (
        <Box className={styles.cardFooter}>
          {/*{cardNum && type !== "custom" ? (*/}
          {/*  <Typography*/}
          {/*    noWrap*/}
          {/*    variant="body1"*/}
          {/*    color={theme.palette.accent.main}*/}
          {/*    component="span"*/}
          {/*  >*/}
          {/*    #{cardNum}{" "}*/}
          {/*  </Typography>*/}
          {/*) : null}*/}
          {/*{type === "custom" ? (*/}
          {/*  <CustomTooltip*/}
          {/*    title={t("drops.limitedEditionCardsTitle")}*/}
          {/*    enterDelay={1000}*/}
          {/*  >*/}
          {/*    <Typography*/}
          {/*      noWrap*/}
          {/*      variant="body1"*/}
          {/*      color={theme.palette.accent.main}*/}
          {/*      component="span"*/}
          {/*      style={{ cursor: "default" }}*/}
          {/*    >*/}
          {/*      LE{" "}*/}
          {/*    </Typography>*/}
          {/*  </CustomTooltip>*/}
          {/*) : null}*/}
          {title || name ? (
            <CustomTooltip title={title || name}>
              <Typography variant="body1" component="span" noWrap>
                {title || name}
              </Typography>
            </CustomTooltip>
          ) : null}
          {description ? (
            <CustomTooltip title={description}>
              <Typography
                variant="body2"
                component="span"
                noWrap
                className={styles.cardFooterDescription}
              >
                {description}
              </Typography>
            </CustomTooltip>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};
