import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyFollowersLoaderSelector,
  getMyFollowersSelector,
} from "redux/selectors/usersSelectors";
import { fetchMyFollows } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Clubs.module.scss";
import { ClubsList } from "./components/ClubsList/ClubsList";

export const Clubs = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const clubs = useSelector(getMyFollowersSelector);
  const clubsLoading = useSelector(getMyFollowersLoaderSelector);

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchMyFollows());
  }, []);

  return (
    <PageLayout
      title={
        <Box className={styles.header}>
          {t("default.clubs")}
          <Typography className={`opacity_07 ${styles.headerSubTitle}`}>
            ({clubs.length})
          </Typography>
        </Box>
      }
      isBack={true}
    >
      <Box className={styles.main}>
        <ClubsList
          rows={clubs}
          loader={clubsLoading}
          total={clubs.length}
          setPage={setPage}
          isLastPage={true}
        />
      </Box>
    </PageLayout>
  );
};
