import { CARD_TYPES } from "constants/index";

export const truncateText = (input, limit) => {
  if (input.length <= limit) {
    return input;
  }
  const truncatedText = input.substr(0, limit);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");
  if (lastSpaceIndex === -1) {
    return `${truncatedText}...`;
  }
  return `${truncatedText.substr(0, lastSpaceIndex)}...`;
};

export const isValidCreditCardNumber = (cc) => {
  const str = cc?.replace(/\s/g, "");
  let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  let mastercard = new RegExp("^5[1-5][0-9]{14}$");
  let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");
  if (visa.test(str)) {
    return CARD_TYPES.VISA;
  }
  if (mastercard.test(str) || mastercard2.test(str)) {
    return CARD_TYPES.MASTERCARD;
  }
  return false;
};
