import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, IconButton } from "@mui/material";
import { ReactComponent as Icon } from "assets/svg/barter-icon-v2.svg";
import { LocalizationContext } from "services/localizationContext";
import styles from "./UserSwapCard.module.scss";

const UserSwapCard = ({
  className,
  name,
  imageUrl,
  swap,
  createPayload,
  onClick,
}) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const handleSeeRequest = () =>
    navigate(`/swaps/${swap.id}`, {
      state: { id: swap.id },
    });

  const handleCreateBarter = () =>
    navigate("/swaps/create", {
      state: createPayload,
    });

  return (
    <Box className={`${styles.card} ${className}`}>
      <Box className={styles.cardContainer}>
        <img
          className={styles.image}
          src={imageUrl}
          alt={name}
          onClick={onClick && onClick}
          style={{ cursor: !!onClick ? "pointer" : "default" }}
        />
        <Typography
          className={styles.title}
          variant="body1"
          onClick={onClick && onClick}
          style={{ cursor: !!onClick ? "pointer" : "default" }}
        >
          {name}
        </Typography>
        {swap?.status === "pending" ? (
          <IconButton
            className={`${styles.btn} ${styles.pending}`}
            onClick={handleSeeRequest}
          >
            {t("swap.requestSent")}
          </IconButton>
        ) : (
          <IconButton className={styles.btn} onClick={handleCreateBarter}>
            <Icon className={styles.icon} />
            {t("swap.barterNow")}
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default UserSwapCard;
