export const EmptyStateIcon = () => (
  <svg
    width="45"
    height="43"
    viewBox="0 0 45 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2943 13.4832L4.26324 11.0707C2.47804 10.347 0.620477 11.9151 1.00647 13.7968L3.46714 25.69C3.9255 27.9336 5.90369 29.5258 8.19549 29.5258H14.1059"
      stroke="white"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M35.0451 13.5144L41.0762 11.102C42.8614 10.3783 44.719 11.9463 44.333 13.828L41.8723 25.7213C41.4139 27.9648 39.4358 29.557 37.144 29.557H31.8125"
      stroke="white"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M14.3945 41.5H30.5095"
      stroke="white"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M13.333 29.3152C11.5961 27.2164 10.5346 24.5386 10.5346 21.5955V12.9108C10.5346 11.2944 9.71438 9.75049 8.36343 8.88202C8.12218 8.73727 7.90507 8.5684 7.68795 8.35128C6.96422 7.62756 6.50586 6.61434 6.50586 5.50462C6.50586 4.39491 6.96422 3.38169 7.68795 2.65796C8.4358 1.95836 9.42489 1.5 10.5346 1.5H34.6347C35.7444 1.5 36.7577 1.95836 37.4814 2.68209C38.2051 3.40582 38.6635 4.41903 38.6635 5.52875C38.6635 6.63846 38.2051 7.65168 37.4814 8.37541C37.2884 8.5684 37.0471 8.7614 36.83 8.90614C35.4549 9.79874 34.6588 11.3186 34.6588 12.9349V21.2819C34.6588 27.916 29.2309 33.6094 22.5967 33.6576V41.498"
      stroke="white"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
