import { Box, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getPublicUser,
  getPublicUserLoading,
} from "redux/selectors/usersSelectors";

import { fetchPublicUser, searchUser } from "../../redux/usersSlice";
import { LocalizationContext } from "../../services/localizationContext";
import { getUsernameFromPathname } from "../../utils/usernameHelper";
import { NewProfile } from "./NewProfile";
import styles from "./NewProfileWrapper.module.scss";

export const NewUserProfile = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const publicUser = useSelector(getPublicUser);
  const publicUserIsLoading = useSelector(getPublicUserLoading);

  const username = params?.id || getUsernameFromPathname(location.pathname);

  useEffect(() => {
    if (!username) {
      return;
    }

    dispatch(searchUser(username))
      .unwrap()
      .then((user) => {
        dispatch(fetchPublicUser(user?.id));
      })
      .catch((err) => {
        console.log("Cannot get public user", err);
      });
  }, [username]);

  return (
    <NewProfile
      title={
        <Box className={styles.header}>
          <Typography variant="h2">
            {t("default.userProfileTitle", { name: username })}
          </Typography>
        </Box>
      }
      // me={me}
      // profile={profile}
      // loading={meLoader}
      // postsData={postsData}
      // editions={editions}
      // editionLoader={editionLoader}
      // loadMorePosts={loadMorePosts}
      // hasMorePosts={hasMorePosts}
      // postsLoader={postsLoader}
    />
  );
};
