import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as NoFriendsIcon } from "assets/svg/no-connections-icon.svg";
import ConnectionItem from "components/ConnectionItem";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import {
  CONNECTION_REQUEST_TYPE,
  CONNECTIONS_LOAD_LIMIT,
  SCROLL_THRESHOLD,
} from "constants/";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import {
  acceptFriendRequest,
  fetchUserFriendRequests,
  hideFriendRequestsIndicator,
  rejectFriendRequest,
} from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./friend-requests.module.scss";

const Placeholder = ({ text }) => {
  return (
    <Box className={styles.placeholder}>
      <NoFriendsIcon className={styles.placeholderNoFriendsIcon} />
      <Typography
        variant="h5"
        component="h5"
        className={styles.placeholderText}
      >
        {text}
      </Typography>
    </Box>
  );
};

const FriendRequests = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLastPage = useSelector(
    (state) => state.users.connections.requests.isLastPage
  );
  const [friendRequests, setFriendRequests] = useState([]);
  const [friendRequestsOffset, setFriendRequestsOffset] = useState(0);

  const getFriendRequestsData = async (offsetParam) => {
    dispatch(setLoader(true));
    setFriendRequestsOffset(offsetParam);

    const payload = {
      offset: 0,
      limit: offsetParam + CONNECTIONS_LOAD_LIMIT,
      type: CONNECTION_REQUEST_TYPE.incoming,
    };

    await dispatch(fetchUserFriendRequests(payload))
      .unwrap()
      .then((d) => setFriendRequests(d))
      .finally(() => dispatch(setLoader(false)));
  };

  useEffect(() => {
    let unmounted = false;

    !unmounted && getFriendRequestsData(0);
    dispatch(hideFriendRequestsIndicator());

    return () => {
      unmounted = true;
    };
  }, []);

  const handleAcceptRequest = (connectionId) => {
    dispatch(setLoader(true));
    dispatch(acceptFriendRequest(connectionId));
    setFriendRequests([...friendRequests.filter((c) => c.id !== connectionId)]);
    dispatch(setLoader(false));
  };

  const handleRejectRequest = (connectionId) => {
    dispatch(setLoader(true));
    dispatch(rejectFriendRequest(connectionId));
    setFriendRequests([...friendRequests.filter((c) => c.id !== connectionId)]);
    dispatch(setLoader(false));
  };

  return (
    <Box className={styles.page}>
      <Container className={styles.container}>
        <Box className={styles.header}>
          <BackBtn
            title={t("account.friendRequests")}
            onClick={() => navigate(-1)}
          />
        </Box>
        {!!friendRequests.length ? (
          <InfiniteScroll
            dataLength={friendRequests.length}
            next={() =>
              getFriendRequestsData(
                friendRequestsOffset + CONNECTIONS_LOAD_LIMIT
              )
            }
            hasMore={!isLastPage}
            style={{ overflow: "hidden" }}
            className={styles.infiniteScroll}
            endMessage=""
            scrollThreshold={SCROLL_THRESHOLD}
          >
            <Box className={styles.friendRequestsContainer}>
              {friendRequests.map((item) => (
                <ConnectionItem
                  key={item.id}
                  connection={item}
                  handleAccept={() => handleAcceptRequest(item.id)}
                  handleReject={() => handleRejectRequest(item.id)}
                  isRequest
                  textBlockClass={styles.connectionName}
                />
              ))}
            </Box>
          </InfiniteScroll>
        ) : (
          <Placeholder text={t("account.noFriendRequestsYet")} />
        )}
      </Container>
    </Box>
  );
};

export default FriendRequests;
