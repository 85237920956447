import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import ShareBtn from "components/Buttons/ShareBtn/ShareBtn";
import DOMPurify from "dompurify";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { CardRewards } from "../CardRewards/CardRewards";
import styles from "./DescriptionCard.module.scss";

const cx = classNames.bind(styles);

export const DescriptionCard = ({
  title,
  seasonNum,
  description,
  className,
  rewardType,
  media,
  mediaSummary,
  buyAlertHandler,
  holdings,
  influencer,
  marketTokens,
}) => {
  const { t } = useContext(LocalizationContext);

  const shareLink = window.location.href;

  if (!description) {
    return null;
  }

  return (
    <Box className={cx(className, styles.framedBox)}>
      <Box className={styles.framedBoxHeader}>
        {seasonNum ? (
          <Typography variant="h5_medium" component="h2">
            {t("drops.seasonTitleAbbr", {
              curdNumber: 1,
              seasonNumber: seasonNum,
            })}
            : {title}
          </Typography>
        ) : (
          <Typography variant="h5_medium" component="h2">
            {title}
          </Typography>
        )}
        <ShareBtn shareLink={shareLink} />
      </Box>
      <Typography
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(description, {
            USE_PROFILES: { html: true },
          }),
        }}
        className={styles.description}
        variant="body1"
      />
      {rewardType ? (
        <>
          <Typography
            variant="body4"
            style={{ margin: "24px 0 8px" }}
            component="p"
            className={styles.rewardTitle}
          >
            {t("default.cardRewardSectionTitle")}
          </Typography>
          <Typography className={styles.description} variant="body1">
            {t("default.cardRewardSectionDescription")}
          </Typography>
        </>
      ) : null}
      <CardRewards
        media={media || []}
        rewardType={rewardType}
        mediaSummary={mediaSummary}
        holdings={holdings}
        buyAlertHandler={buyAlertHandler}
        influencer={influencer}
        marketTokens={marketTokens}
      />
    </Box>
  );
};
