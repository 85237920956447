import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Proptypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import { roundToTwo } from "utils/math";

import styles from "./price-title.module.scss";
import { PriceMovementIndicator } from "./PriceMovementIndicator";

export default function PriceTitle({
  currencySymbol,
  latestPrice,
  priceMovement,
  isPercentage,
  priceMovementPct,
}) {
  const theme = useTheme();

  const calculatePercentage = () => {
    if (priceMovementPct !== null) return `(${priceMovementPct}%)`;
    return !isPercentage
      ? ` (${roundToTwo((priceMovement / latestPrice) * 100)}%)`
      : ` (${roundToTwo(priceMovement)}%)`;
  };

  return (
    <>
      <Typography className={styles.price} variant="h4">
        <NumberFormat
          value={latestPrice}
          displayType={"text"}
          thousandSeparator={true}
          prefix={currencySymbol}
        />
      </Typography>

      {!!priceMovement && (
        <Typography
          style={{
            color:
              priceMovement > 0
                ? theme.palette.tertiary.main
                : theme.palette.coral.main,
          }}
          variant="body2"
          className={styles.priceMovement}
        >
          {!isPercentage
            ? priceMovement > 0
              ? `+${priceMovement}`
              : priceMovement
            : roundToTwo((latestPrice / 100) * priceMovement)}
          {calculatePercentage()}
          <PriceMovementIndicator priceMovement={priceMovement} />
        </Typography>
      )}
    </>
  );
}

PriceTitle.defaultProps = {
  currencySymbol: "$",
  latestPrice: 0,
  priceMovement: 0,
  priceMovementPct: null,
  isPercentage: false,
};

PriceTitle.propTypes = {
  currencySymbol: Proptypes.string,
  latestPrice: Proptypes.number,
  priceMovement: Proptypes.number,
  priceMovementPct: Proptypes.number,
  isPercentage: Proptypes.bool,
};
