import React from "react";
import { Box, Typography, Container } from "@mui/material";

import styles from "./Error500.module.scss";
import { ReactComponent as Icon404 } from "assets/svg/icon-404.svg";

const NoMatch = () => {
  return (
    <Container className={styles.container}>
      <Box className={styles.block}>
        <Icon404 />
      </Box>
      <Box className={styles.block}>
        <Typography variant="h1" className={styles.title}>
          Sorry, the Zoop server is down for maintenance
        </Typography>
        <Typography variant="textNormal" className={styles.description}>
          For help, contact support@zoop.com. Please try again later
        </Typography>
      </Box>
    </Container>
  );
};

export default NoMatch;
