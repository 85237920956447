import { useTheme } from "@emotion/react";
import classNames from "classnames/bind";

import styles from "./price-title.module.scss";

export const PriceMovementIndicator = ({
  priceMovement,
  className,
  width = 4,
  height = 8,
}) => {
  const theme = useTheme();
  const cx = classNames.bind(styles);
  if (!priceMovement) return null;
  return (
    <span
      className={cx(className, styles.diffTriangle)}
      style={{
        borderWidth:
          priceMovement > 0
            ? `0 ${width / 2}px ${height}px`
            : `${height}px ${width / 2}px 0`,
        borderColor:
          priceMovement >= 0
            ? `transparent transparent ${theme.palette.tertiary.main} transparent`
            : `${theme.palette.coral.main} transparent transparent transparent`,
      }}
    />
  );
};
