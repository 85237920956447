import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./EmptyState.module.scss";
import { EmptyStateIcon } from "./icons";

export const EmptyState = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={styles.wrapper}>
      <EmptyStateIcon />
      <Box className={styles.content}>
        <Typography className={styles.title} variant="h5">
          {t("badges.emptyStateTitle")}
        </Typography>
        <Typography variant="body2" className={"opacity_07"}>
          {t("badges.emptyStateSubTitle")}
        </Typography>
      </Box>
    </Box>
  );
};
