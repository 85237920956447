import { Box } from "@mui/material";
import { CardsSlider } from "components/CardsSlider/CardsSlider";
import HomeBanner from "components/HomeBanner";
import { Card } from "components/NewCard";
import { CARD_VARIANTS } from "constants/card";
import { CHALLENGE_CATEGORIES, PATHS, SECURITY_STATES } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchChallenges } from "redux/challengesSlice";
import {
  clubChallengesByIdLoader,
  getChallengesCategorySelector,
} from "redux/selectors/challengesSelector";
import { getMarketplaceCards } from "services/discoverApi";
import { LocalizationContext } from "services/localizationContext";

// import { OnboardingTutorial } from "./components";
import { BannersCarousel } from "./components/BannersCarousel/BannersCarousel";
import { Main } from "./components/Main/Main";
import { SimpleChallenge } from "./components/SimpleChallenge/SimpleChallenge";
import { SlideBox } from "./components/SlideBox/SlideBox";
import styles from "./Home.module.scss";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userProfile = useSelector((state) => state.users.entities);
  const userProfileLoading = useSelector((state) => state.users.loading);
  const is2faEnabled = useSelector(
    (state) => state.users.entities.is2faEnabled
  );
  const challenges = useSelector(
    getChallengesCategorySelector(CHALLENGE_CATEGORIES.SOCIAL)
  );
  const challengesLoader = useSelector(clubChallengesByIdLoader);

  const [cards, setCards] = useState([]);
  const [cardsLoader, setCardsLoader] = useState(true);

  const goToEmailVerification = () => {
    localStorage.setItem("completeProfileBackRoute", location.pathname);
    navigate(PATHS.EMAIL_VERIFICATION);
  };

  const handleActivateMFA = () => {
    navigate(PATHS.SETTINGS_SECURITY, {
      state: { settingsState: SECURITY_STATES.mfa },
    });
  };

  const isConditionProfile = !userProfile?.emailVerified;
  const isConditionMFA = userProfile?.isCompleted && !is2faEnabled;

  useEffect(() => {
    getMarketplaceCards({ type: "followings", limit: 12 })
      .then((res) => {
        if (res?.cards) {
          setCards(res.cards);
        }
      })
      .finally(() => setCardsLoader(false));
    dispatch(fetchChallenges({ category: CHALLENGE_CATEGORIES.SOCIAL }));
  }, []);

  return (
    <Box className={"home"}>
      <Box className={styles.topContainer}>
        <HomeBanner
          isVerify={true}
          condition={isConditionProfile}
          title={t("home.verifyKYC")}
          text={t("home.verifyKYCText")}
          buttonText={t("default.verifyNow")}
          onClick={goToEmailVerification}
          loading={userProfileLoading && !userProfile?.id}
        />
        <HomeBanner
          condition={isConditionMFA}
          title={t("home.home2FABannerTitle")}
          text={t("home.home2FABannerText")}
          buttonText={t("home.activate2FA")}
          onClick={handleActivateMFA}
          loading={userProfileLoading && !userProfile?.id}
        />
        <BannersCarousel />
      </Box>
      <Box className={styles.container}>
        <Box className={styles.containerLeft}>
          <SlideBox
            title={t("home.newCardsTitle")}
            isLoading={cardsLoader}
            isShow={cards.length > 0}
            path={PATHS.DROPS}
            buttonText={t("home.viewCards")}
          >
            <CardsSlider
              spaceBetweenSlides={1}
              spaceBetweenSlidesMobile={0}
              cardWidth={274}
            >
              {cards.map((item) => (
                <Card variant={CARD_VARIANTS.FULL} data={item} key={item.id} />
              ))}
            </CardsSlider>
          </SlideBox>
          <SlideBox
            isShow={challenges?.length > 0}
            title={t("home.challenges")}
            path={PATHS.CHALLENGES}
            isLoading={challengesLoader}
            buttonText={t("home.viewChallenges")}
          >
            <Box className={styles.challengesList}>
              {challenges?.slice(0, 5)?.map((item) => (
                <SimpleChallenge {...item} key={item.id} />
              ))}
            </Box>
          </SlideBox>
        </Box>
        <Box className={styles.containerRight}>
          <Main />
        </Box>
      </Box>
      <Outlet />
      {/*<OnboardingTutorial />*/}
    </Box>
  );
};

export default Home;
