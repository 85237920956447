import { useContext, useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { openModal } from "redux/modalSlice";
import { MODAL_TYPE } from "constants";
import { LocalizationContext } from "services/localizationContext";
import { setError, setLoader } from "redux/appSlice";

const PAYMENT_INTENT_STATUSES = {
  SUCCEEDED: "succeeded",
  PROCESSING: "processing",
  REQUIRES_PAYMENT_METHOD: "requires_payment_method",
};
const PaymentStatus = ({ onClose, clientSecret, influencerData }) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [paymentIntent, setPaymentIntent] = useState(null);

  const showSuccesModal = (description) => {
    dispatch(
      openModal({
        type: MODAL_TYPE.SUCCESS_MODAL,
        payload: {
          description,
          influencer: influencerData,
          data: [
            {
              text: t("default.influencer"),
              value: influencerData?.name,
            },
            {
              text: t("subscription.renewal"),
              value: t("subscription.renewEachMonth"),
            },
            {
              text: t("subscription.price"),
              value: `${influencerData?.currencySymbol}${influencerData?.community?.subscriptionPrice}`,
            },
          ],
        },
      })
    );
  };
  const handleIntent = () => {
    let sucessModalDesc = null;

    switch (paymentIntent.status) {
      case PAYMENT_INTENT_STATUSES.SUCCEEDED:
        sucessModalDesc = t("subscription.subscribeSuccessDesc", {
          name: influencerData?.name,
        });
        break;

      case PAYMENT_INTENT_STATUSES.PROCESSING:
        sucessModalDesc = t("subscription.subscribeProcessingDesc");
        break;

      case PAYMENT_INTENT_STATUSES.REQUIRES_PAYMENT_METHOD:
        dispatch(
          setError({
            open: true,
            subtitle: t("subscription.paymentFailed"),
          })
        );
        break;

      default:
        dispatch(setError({ open: true }));
        break;
    }

    if (sucessModalDesc) showSuccesModal(sucessModalDesc);
    onClose();
  };

  const getIntentStatus = () => {
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        setPaymentIntent(paymentIntent);
        dispatch(setLoader(false));
      })
      .catch((e) => {
        dispatch(setLoader(false));
      });
  };
  useEffect(() => {
    if (paymentIntent && influencerData) handleIntent();
  }, [paymentIntent, influencerData]);

  useEffect(() => {
    if (stripe && clientSecret) getIntentStatus();
  }, [clientSecret, stripe]);

  return null;
};

export default PaymentStatus;
