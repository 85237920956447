import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { CARD, CARD_VARIANTS } from "constants/card";
import React, { useLayoutEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useWindowSize } from "utils/hooks";

import styles from "./CardSlider.module.scss";
const { width, slidesGap, padding, mobileSlidesGap } =
  CARD[CARD_VARIANTS.SMALL];
export const CardsSlider = ({
  spaceBetweenSlides = slidesGap,
  spaceBetweenSlidesMobile = mobileSlidesGap,
  cardWidth = width + padding,
  children,
  wrapperClassname,
}) => {
  const theme = useTheme();
  const slider = useRef(null);
  const sliderContainer = useRef(null);
  const [sliderSettings, setSliderSettings] = useState({});
  const MOBILE_BREAKPOINT = theme.breakpoints.values.xs;

  const ws = useWindowSize();
  const getSliderSettings = () => {
    const containerWidth = sliderContainer.current?.clientWidth;
    const gap =
      window.innerWidth <= MOBILE_BREAKPOINT
        ? spaceBetweenSlidesMobile
        : spaceBetweenSlides;

    let slidesToShow = containerWidth / (cardWidth + gap);
    slidesToShow = Math.round((slidesToShow + Number.EPSILON) * 100) / 100;

    setSliderSettings({
      dots: false,
      slidesToShow: slidesToShow || 1,
      slidesToScroll: 1,
      infinite: false,
      swipeToSlide: true,
    });
  };
  useLayoutEffect(() => {
    setTimeout(() => {
      getSliderSettings();
    }, 100);
  }, [ws.width]);

  return (
    <Box
      ref={sliderContainer}
      className={wrapperClassname ? wrapperClassname : ""}
    >
      <Slider ref={slider} {...sliderSettings} className={styles.slider}>
        {children}
      </Slider>
    </Box>
  );
};
