import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg";
import ActiveLink from "components/ActiveLink";
import { PATHS } from "constants/";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import styles from "./Settings.module.scss";

const Settings = () => {
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const isMobile = useIsMobile();

  const Aside = () => (
    <Box className={styles.aside}>
      <ActiveLink
        to={PATHS.SETTINGS_ACCOUNTS}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.account")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      <ActiveLink
        to={PATHS.SETTINGS_SECURITY}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.security")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      {/* temporary commented */}
      {/* <ActiveLink
        to="/settings/subscription"
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.subscription")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink> */}
      <ActiveLink
        to={PATHS.SETTINGS_EMAIL_NOTIFICATIONS}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.emailNotifications")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      <ActiveLink
        to={PATHS.SETTINGS_TRANSFERS}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
      >
        <Typography variant="h6">{t("settings.transfers")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      <ActiveLink
        to={PATHS.SETTINGS_HELP}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
        matchEnd={false}
      >
        <Typography variant="h6">{t("settings.help")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
      <ActiveLink
        to={PATHS.SETTINGS_POLICIES}
        className={styles.menuItem}
        activeClass={styles.menuItemActive}
        matchEnd={false}
      >
        <Typography variant="h6">{t("settings.policies")}</Typography>
        <IconRight className={styles.icon} />
      </ActiveLink>
    </Box>
  );

  const isNavVisible = location.pathname === PATHS.SETTINGS;

  return (
    <Container className={styles.mainContainer}>
      {!isMobile || isNavVisible ? (
        <Typography className={styles.mainTitle} variant="h2">
          {t("default.settings")}
        </Typography>
      ) : null}
      <Box className={styles.container}>
        {isMobile && !isNavVisible ? null : <Aside />}
        {!isMobile || !isNavVisible ? (
          <Box className={styles.settingsContent}>
            <Outlet />
          </Box>
        ) : null}
      </Box>
    </Container>
  );
};

export default Settings;
