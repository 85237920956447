import { Box, Button } from "@mui/material";
import { ReactComponent as ArrowRight } from "assets/svg/arrow-right.svg";
import { AUTH_CELEBRITY_TYPE } from "constants/index";

import styles from "./AuthTypeSwitcher.module.scss";

export const AuthTypeSwitcher = ({
  setFanAuth,
  isFanAuth,
  text,
  classNameWrapper,
}) => {
  const setFanType = () => {
    setFanAuth(true);
    localStorage.removeItem(AUTH_CELEBRITY_TYPE);
  };

  const setCelebrityType = () => {
    setFanAuth(false);
    localStorage.setItem(AUTH_CELEBRITY_TYPE, AUTH_CELEBRITY_TYPE);
  };

  return (
    <Box
      className={`${styles.switcher} ${
        classNameWrapper ? classNameWrapper : ""
      }`}
    >
      <Button
        onClick={isFanAuth ? setCelebrityType : setFanType}
        variant="contained"
        className={styles.switcherBtn}
      >
        {text}
        <ArrowRight />
      </Button>
    </Box>
  );
};
