import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";

import { EmailIcon, FacebookIcon, TwitterIcon } from "./icons";
export const socialNetworks = [
  {
    id: 1,
    icon: <TwitterIcon />,
    name: "Twitter",
    title: "",
    ActionButton: TwitterShareButton,
  },
  {
    id: 2,
    icon: <FacebookIcon />,
    name: "Facebook",
    title: "",
    ActionButton: FacebookShareButton,
  },
  {
    id: 3,
    icon: <EmailIcon />,
    name: "Email",
    title: "",
    ActionButton: EmailShareButton,
  },
];
