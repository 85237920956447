import { Box, CircularProgress, Skeleton } from "@mui/material";
import { ReactComponent as NoFriendsIcon } from "assets/svg/no-connections-icon.svg";
import ConnectionItem from "components/ConnectionItem";
import UnfriendModal from "components/Modal/UnfriendModal";
import {
  CONNECTIONS_LOAD_LIMIT,
  PATHS,
  SCROLL_THRESHOLD,
} from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader, setSnackbar } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import {
  getMyConnections,
  getMyConnectionsIsLastPage,
  getMyConnectionsLoaderSelector,
} from "redux/selectors/usersSelectors";
import { fetchUserConnections, unfriendUser } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./FriendsList.module.scss";
import { Placeholder } from "./Placeholder";

export const FriendsList = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { rows } = useSelector(getMyConnections);
  const isLastPage = useSelector(getMyConnectionsIsLastPage);
  const loader = useSelector(getMyConnectionsLoaderSelector);

  const [page, setPage] = useState(1);

  const next = () => {
    setPage((prevState) => prevState + 1);
  };

  const handleUnfriend = ({ id, username }) => {
    const handleConfirm = () => {
      dispatch(setLoader(true));
      dispatch(unfriendUser(id)).finally(() => dispatch(setLoader(false)));
      dispatch(closeModal());
    };

    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={t("account.unfriendModalTitle", {
              username: username,
            })}
            description={t("account.unfriendModalText", {
              username: username,
            })}
            onConfirm={handleConfirm}
            onCancel={() => dispatch(closeModal())}
          />
        ),
      })
    );
  };

  const handleConnectionShare = (username) => {
    dispatch(
      setSnackbar({
        open: true,
        message: t("drops.copied"),
      })
    );

    const publicLink = `${window.origin}/public/user/${username}`;
    navigator.clipboard.writeText(publicLink);
  };

  useEffect(() => {
    if (page > 1) {
      dispatch(
        fetchUserConnections({
          limit: CONNECTIONS_LOAD_LIMIT,
          page,
        })
      );
    }
  }, [page]);

  useEffect(() => {
    dispatch(
      fetchUserConnections({
        limit: CONNECTIONS_LOAD_LIMIT,
        page: 1,
      })
    );
  }, []);

  return (
    <Box className={styles.list}>
      {!rows?.length && loader ? (
        <Box className={styles.connectionsContainer}>
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
        </Box>
      ) : null}
      {rows.length ? (
        <InfiniteScroll
          dataLength={rows.length}
          next={next}
          hasMore={!isLastPage}
          style={{ overflow: "hidden" }}
          className={styles.infiniteScroll}
          scrollThreshold={SCROLL_THRESHOLD}
          loader={
            <Box
              style={{
                justifyContent: "center",
                display: "flex",
                padding: "16px",
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          <Box className={styles.connectionsContainer}>
            {rows.map((item) => (
              <ConnectionItem
                key={item?.id}
                connection={item}
                handleUnfriend={() =>
                  handleUnfriend({
                    id: item?.id,
                    username: item.friend.username,
                  })
                }
                handleShare={() => handleConnectionShare(item.friend.username)}
                showOptionsBtn
              />
            ))}
          </Box>
        </InfiniteScroll>
      ) : null}
      {!rows.length && !loader ? (
        <Placeholder
          text={t("account.noFriendsYet")}
          buttonText={t("account.referYourFriends")}
          onBtnClick={() => navigate(PATHS.REFERRALS)}
          icon={<NoFriendsIcon className={styles.placeholderNoFriendsIcon} />}
        />
      ) : null}
    </Box>
  );
};
