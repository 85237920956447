import { Box, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import { ReactComponent as ExitFullScreenIcon } from "assets/svg/feed/exit-fullscreen.svg";
import { ReactComponent as FullScreenIcon } from "assets/svg/feed/full-screen.svg";

import styles from "./MediaControls.module.scss";
export const MediaControls = ({
  onClose,
  isFullScreen,
  onSwicthFullScreen,
  showFullScreenBtn,
}) => {
  const close = () => {
    onClose && onClose();
  };
  return (
    <Box className={styles.controls}>
      {onSwicthFullScreen && showFullScreenBtn && (
        <IconButton className={styles.btn} onClick={onSwicthFullScreen}>
          {isFullScreen ? (
            <ExitFullScreenIcon className={styles.btnIcon} />
          ) : (
            <FullScreenIcon className={styles.btnIcon} />
          )}
        </IconButton>
      )}
      <IconButton className={styles.btn} onClick={close}>
        <CloseIcon className={styles.btnIcon} />
      </IconButton>
    </Box>
  );
};
