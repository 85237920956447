import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { ReactComponent as PlaceholderIcon } from "assets/svg/swaps-placeholder-icon.svg";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import SwapRequestCard from "components/SwapRequestCard";
import {
  PATHS,
  SCROLL_THRESHOLD,
  SWAP_STATUSES,
  SWAPS_LOAD_LIMIT,
  SWAPS_TYPE,
} from "constants/";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchSwaps } from "redux/swapsSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "../swaps.module.scss";

const Placeholder = ({ text }) => {
  return (
    <Box className={styles.placeholder}>
      <PlaceholderIcon
        width={79}
        height={88}
        className={styles.placeholderIcon}
      />
      <Typography
        variant="h5"
        component="h4"
        className={styles.placeholderText}
      >
        {text}
      </Typography>
    </Box>
  );
};

const IncomingSwaps = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [swaps, setSwaps] = useState([]);
  const [swapsPending, setSwapsPending] = useState([]);
  const isLastPage = useSelector((state) => state.swaps.swapsList);
  const loader = <CircularProgress className={styles.loader} />;

  const getData = async (offsetParam) => {
    setLoading(true);
    setOffset(offsetParam);

    const payload = {
      type: SWAPS_TYPE.incoming,
      offset: 0,
      limit: offsetParam + SWAPS_LOAD_LIMIT,
    };

    await dispatch(fetchSwaps(payload))
      .unwrap()
      .then((d) => {
        const swaps = [...d].sort((a, b) => b.id - a.id);

        const pendingSwaps = swaps.filter(
          (item) => item.status === SWAP_STATUSES.pending
        );
        const otherSwaps = swaps.filter(
          (item) => item.status !== SWAP_STATUSES.pending
        );

        setSwapsPending(pendingSwaps);
        setSwaps(otherSwaps);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    let unmounted = false;

    !unmounted && getData(0);

    return () => {
      unmounted = true;
    };
  }, []);

  const handleSwapItemClick = (id) => {
    navigate(`/swaps/${id}`, {
      state: { id: id },
    });
  };
  const title = t("swap.incomingSwaps");
  return (
    <Box className={styles.page}>
      <Container className={styles.container}>
        <Box className={styles.header}>
          <BackBtn link={PATHS.PROFILE} title={title} />
          <Box className={styles.headerBlock}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate("/swaps/sent")}
              className={styles.headerBtn}
            >
              {t("swap.goToSentSwaps")}
            </Button>
          </Box>
          {loading && loader}
        </Box>
        {!!swapsPending.length || !!swaps.length ? (
          <Box className={styles.swapsContainer}>
            {!!swapsPending.length && (
              <Box className={styles.row}>
                <Typography
                  variant="h6"
                  component="h4"
                  className={styles.rowTitle}
                >
                  {t("swap.pending")}
                </Typography>
                <Box className={styles.swapsBlock}>
                  {swapsPending.map((item) => (
                    <SwapRequestCard
                      key={item.id}
                      id={item.id}
                      status={item.status}
                      sender={item.fromAppUser.username}
                      cards={item.offering}
                      onClick={() => handleSwapItemClick(item.id)}
                      outlined
                    />
                  ))}
                </Box>
              </Box>
            )}
            {!!swaps.length && (
              <Box className={styles.row}>
                <Typography
                  variant="h6"
                  component="h4"
                  className={styles.rowTitle}
                >
                  {t("swap.earlier")}
                </Typography>
                <InfiniteScroll
                  dataLength={swaps.length}
                  next={() => getData(offset + SWAPS_LOAD_LIMIT)}
                  hasMore={!isLastPage}
                  style={{ overflow: "hidden" }}
                  className={styles.infiniteScroll}
                  loader={loader}
                  endMessage=""
                  scrollThreshold={SCROLL_THRESHOLD}
                >
                  <Box className={styles.swapsBlock}>
                    {swaps.map((item) => (
                      <SwapRequestCard
                        key={item.id}
                        id={item.id}
                        status={item.status}
                        sender={item.fromAppUser.username}
                        cards={item.offering}
                        onClick={() => handleSwapItemClick(item.id)}
                        outlined
                      />
                    ))}
                  </Box>
                </InfiniteScroll>
              </Box>
            )}
          </Box>
        ) : (
          <Placeholder text={t("swap.noSwapRequests")} />
        )}
      </Container>
    </Box>
  );
};

export default IncomingSwaps;
