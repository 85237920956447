import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { LocalizationContext } from "services/localizationContext";
import * as Yup from "yup";

import { ReactComponent as CloseIcon } from "../../../assets/svg/close-icon.svg";
import { FULL_COMMENT_TEXT_LIMIT } from "../../../constants";
import { setError, setSnackbar } from "../../../redux/appSlice";
import { closeModal } from "../../../redux/modalSlice";
import { errorResponseMessages } from "../../../services/apiErrorHelper.tsx";
import { StripeCard } from "./components/Stripe/StripeCard";
import styles from "./DonationModal.module.scss";
import { PaymentCardsList } from "./PaymentCardsList";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const DonationModal = ({
  message,
  name,
  onSubmit,
  isMessageRequired,
  isTipIsHiddenField = true,
  snackbarMessage,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [isShowCard, setShowCard] = useState(false);
  const [activeCardId, setActiveCardId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const validationSchema = Yup.object().shape({
    ...(isMessageRequired
      ? {
          message: Yup.string().trim().required(t("error.required")),
        }
      : { message: Yup.string().trim().notRequired() }),
    tipAmount: Yup.number().required(t("error.required")),
    tipIsHidden: Yup.boolean().notRequired(),
  });

  const {
    register,
    setValue,
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleClose = () => dispatch(closeModal());

  const showErrorModal = (err) => {
    if (err?.status !== 401) {
      dispatch(
        setError({
          open: true,
          title: "Error",
          subtitle: errorResponseMessages(err.data || err, t),
        })
      );
    }
  };

  const onSubmitHandler = async (data) => {
    setLoading(true);
    onSubmit({ ...data, paymentMethodId: activeCardId })
      .then((data) => {
        if (data?.status === "ignore") {
          return;
        }
        dispatch(
          setSnackbar({
            open: true,
            message: snackbarMessage || t("payments.successfulTipMessage"),
          })
        );
        handleClose();
      })
      .catch((e) => {
        showErrorModal(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showCardHandler = () => setShowCard(true);

  const stripeCb = (data) => {
    setShowCard(false);
    if (data) {
      const { id, name } = data;
      if (id) {
        if (!paymentMethods.length) {
          setActiveCardId(id);
        }
        setPaymentMethods((prevState) => [...prevState, { id, name }]);
      }
    }
  };

  const isDisabled =
    !paymentMethods.length || !isValid || loading || !activeCardId;

  useEffect(() => {
    if (message?.trim()) {
      setValue("message", message);
    } else {
      setValue("message", t("payments.donationDefaultMessage"));
    }
  }, [message]);

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.close} onClick={handleClose}>
        <CloseIcon />
      </Box>
      <Typography variant="h4" className={styles.title}>
        {t("discover.donationTitle", { name })}
      </Typography>
      <Typography variant="body2" className={styles.subtitle}>
        {t("discover.donationSubTitle", { name })}
      </Typography>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
        <Box className={styles.sendMsgInput}>
          <TextField
            {...register("message")}
            name="message"
            variant="outlined"
            multiline
            minRows={1}
            maxRows={3}
            inputProps={{ maxLength: FULL_COMMENT_TEXT_LIMIT }}
            placeholder={t("discover.message")}
            autoComplete="off"
            disabled={loading}
          />
          {!!errors.message ? (
            <Typography variant="error">{errors.message?.message}</Typography>
          ) : null}
        </Box>
        <FormControl className={styles.formControl}>
          <Controller
            control={control}
            name="tipAmount"
            defaultValue={5}
            rules={{ required: true }}
            render={({ field }) => (
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                className={styles.buttonList}
                {...field}
              >
                <FormControlLabel
                  value={5}
                  label="$5"
                  control={<Radio className={styles.radio} />}
                />
                <FormControlLabel
                  value={10}
                  label="$10"
                  control={<Radio className={styles.radio} />}
                />
                <FormControlLabel
                  value={25}
                  label="$25"
                  control={<Radio className={styles.radio} />}
                />
                <FormControlLabel
                  value={50}
                  label="$50"
                  control={<Radio className={styles.radio} />}
                />
                <FormControlLabel
                  value={100}
                  label="$100"
                  control={<Radio className={styles.radio} />}
                />
              </RadioGroup>
            )}
          />
          {!!errors.tipAmount ? (
            <Typography variant="error">{errors.tipAmount?.message}</Typography>
          ) : null}
        </FormControl>
        {isTipIsHiddenField ? (
          <FormControl className={styles.tipLabelWrapper}>
            <FormControlLabel
              className={styles.tipLabel}
              control={
                <Checkbox {...register("tipIsHidden")} name="tipIsHidden" />
              }
              label={
                <Typography variant="subtitle1">
                  {t("discover.hideTheTipAmount")}
                </Typography>
              }
            />
          </FormControl>
        ) : null}
        <PaymentCardsList
          activeCardId={activeCardId}
          setActiveCardId={setActiveCardId}
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
        />
        {isShowCard ? (
          <Elements stripe={stripePromise}>
            <StripeCard callback={stripeCb} name={name} />
          </Elements>
        ) : null}
        {!isShowCard ? (
          <Box className={styles.newCardBtnWrapper}>
            <Button variant="contained" size="small" onClick={showCardHandler}>
              {t("payments.addCard")}
            </Button>
          </Box>
        ) : null}
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={isDisabled}
          className={styles.submitButton}
          loading={loading}
        >
          {t("default.continueBtn")}
        </LoadingButton>
      </form>
    </Box>
  );
};
