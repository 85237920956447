import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { PATHS } from "../../../constants";
import styles from "./BottomMenu.module.scss";
import {
  ChallengesIcon,
  DiscoverIcon,
  HomeIcon,
  MarketIcon,
  ProfileIcon,
} from "./icons";

const menuPaths = {
  home: PATHS.HOME,
  discover: PATHS.DISCOVER,
  market: PATHS.DROPS,
  challenges: PATHS.CHALLENGES,
  profile: PATHS.PROFILE,
};

export const BottomMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const isXs = useMediaQuery("(max-width:480px)");

  const [activeMenuItem, setActiveMenuItem] = useState(menuPaths.home);

  const getActiveClass = (value) => {
    return value === activeMenuItem ? styles.menuItemActive : "opacity_05";
  };

  const goTo = (path) => () => navigate(path);

  useEffect(() => {
    const paths = Object.values(menuPaths);
    const isPath = paths.includes(location.pathname);
    if (isPath) {
      setActiveMenuItem(location.pathname);
    }
  }, [location]);

  if (
    !isXs ||
    location.pathname.includes(PATHS.LOGIN) ||
    location.pathname.includes(PATHS.SIGN_UP) ||
    location.pathname.includes(PATHS.FORGOT_PASSWORD_CREATOR) ||
    location.pathname.includes(PATHS.FORGOT_PASSWORD_FAN)
  ) {
    return null;
  }

  return (
    <Box className={styles.menu}>
      <Button
        className={`${styles.menuItem} ${getActiveClass(menuPaths.home)}`}
        onClick={goTo(PATHS.HOME)}
      >
        <HomeIcon />
        <Typography
          className={styles.menuItemText}
          variant="clash_display_12_500"
        >
          {t("home.home")}
        </Typography>
      </Button>
      <Button
        className={`${styles.menuItem} ${getActiveClass(menuPaths.discover)}`}
        onClick={goTo(PATHS.DISCOVER)}
      >
        <DiscoverIcon />
        <Typography
          className={styles.menuItemText}
          variant="clash_display_12_500"
        >
          {t("discover.discover")}
        </Typography>
      </Button>
      <Button
        className={`${styles.menuItem} ${getActiveClass(menuPaths.market)}`}
        onClick={goTo(PATHS.DROPS)}
      >
        <MarketIcon />
        <Typography
          className={styles.menuItemText}
          variant="clash_display_12_500"
        >
          {t("default.bottomMenuMarket")}
        </Typography>
      </Button>
      <Button
        className={`${styles.menuItem} ${getActiveClass(menuPaths.challenges)}`}
        onClick={goTo(PATHS.CHALLENGES)}
      >
        <ChallengesIcon />
        <Typography
          className={styles.menuItemText}
          variant="clash_display_12_500"
        >
          {t("home.challenges")}
        </Typography>
      </Button>
      <Button
        className={`${styles.menuItem} ${getActiveClass(menuPaths.profile)}`}
        onClick={goTo(PATHS.PROFILE)}
      >
        <ProfileIcon />
        <Typography
          className={styles.menuItemText}
          variant="clash_display_12_500"
        >
          {t("account.profile")}
        </Typography>
      </Button>
    </Box>
  );
};
