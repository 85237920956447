import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { LocalizationContext } from "services/localizationContext";
import styles from "./swap-request-card.module.scss";
import { SWAP_STATUSES } from "constants/index";

const SwapRequestCard = ({ id, cards, onClick, status, sender, outlined }) => {
  const { t } = useContext(LocalizationContext);

  const isMultipleCards = cards?.length > 1;

  return (
    <Box
      className={`${styles.swapCard} ${outlined ? styles.outlined : null}`}
      onClick={onClick && onClick}
    >
      <Box className={styles.container}>
        <Box className={styles.imageWrap}>
          {isMultipleCards ? (
            cards.map((card, i) => (
              <img
                key={card.id}
                className={styles.image}
                src={card.imageUrl}
                alt={card.influencerName}
                style={{
                  position: i > 0 ? "absolute" : "relative",
                  opacity: 1 / (0.5 + 1 * i),
                  top: 4 * i,
                  left: 5 * i,
                  zIndex: -`${i}`,
                }}
              />
            ))
          ) : (
            <img
              className={styles.image}
              src={cards[0]?.imageUrl}
              alt={cards[0]?.influencerName}
            />
          )}
        </Box>
        <Box className={styles.textBlock}>
          {!isMultipleCards && (
            <Typography
              variant="body3"
              component="span"
              className={styles.series}
            >
              {t("default.editionNumber", { number: cards[0]?.series })}
            </Typography>
          )}
          {isMultipleCards ? (
            <Box className={styles.nameWrap}>
              <Typography variant="h6" className={styles.name}>
                {t("swap.multipleEditions")}
              </Typography>
              <Typography variant="body3" className={styles.cardsAmount}>
                {cards?.length}
              </Typography>
            </Box>
          ) : (
            <Typography variant="h6" className={styles.name}>
              {cards[0]?.influencerName}
            </Typography>
          )}
          {sender && (
            <Typography
              variant="body3"
              component="span"
              className={styles.sender}
            >
              {sender}
            </Typography>
          )}
          <Typography variant="body3" component="span" className={styles.id}>
            {`#${id}`}
          </Typography>
        </Box>
        {status === SWAP_STATUSES.pending && (
          <Box className={styles.label}>
            <Typography
              variant="body3"
              component="span"
              className={styles.labelText}
            >
              {t("swap.pending")}
            </Typography>
          </Box>
        )}
        {status !== SWAP_STATUSES.pending && (
          <Typography
            variant="body3"
            component="span"
            className={styles.status}
          >
            {`${t("swap.offer")} ${status}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SwapRequestCard;
