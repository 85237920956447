import { Box, Container, Skeleton, Typography } from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/arrow-down.svg";
import Card from "components/CardId/Card.js";
import DropDetailSkeleton from "components/Drops/Skeletons/DropDetailSkeleton";
import { InfluencerLink } from "components/InfluencerLink/InfluencerLink";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import {
  EDITION_STATUSES,
  ORDER_TYPES,
  PATHS,
  REWARDS_ACTION_TYPE,
} from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setError } from "redux/appSlice";
import { updateCardTokens } from "redux/cardSlice";
import { fetchEditionOrders } from "redux/editionSlice";
import { getCardSelector, getCardTokens } from "redux/selectors/cardSelectors";
import { LocalizationContext } from "services/localizationContext";
import { getUserCard } from "services/userApi";
import { formatEditionOrders, getInfluencerProfileLink } from "utils";

import stylesCard from "../../../components/Card/card.module.scss";
import Tokens from "../../../components/Card/Tokens";
import { CARD_TYPES } from "../../../constants/card";
import { MyTokens } from "./components/MyTokens/MyTokens";
import { SidebarActions } from "./components/SidebarActions/SidebarActions";
import styles from "./DropDetail.module.scss";
import Phase0Detail from "./Phase0DropDetail";
import Phase2Detail from "./Phase2DropDetail";

const STATE_STATUS = {
  failed: "failed",
  loading: "loading",
};

const DropDetail = ({
  status,
  handleBuyBtnClick,
  checking,
  onSell,
  setBuyAlert,
}) => {
  const { id } = useParams();
  const { t } = useContext(LocalizationContext);

  const dispatch = useDispatch();

  const [editionOrders, setEditionOrders] = useState({
    tokens: [],
    amountCards: 0,
  });
  const [sellCardsLoader, setSellCardsLoader] = useState(false);

  const stateStatus = useSelector((state) => state.drops.dropStatus);
  const drop = useSelector((state) => state.drops.drop);
  const collectionCard = useSelector(getCardTokens);
  const card = useSelector(getCardSelector);

  const { influencer } = drop || {};

  const navigate = useNavigate();
  const location = useLocation();
  const backRoute = location.state?.from;
  const influencerProfileLink = getInfluencerProfileLink({
    id: influencer?.id,
    handle: influencer?.handle,
  });

  const handleSell = () => {
    if (collectionCard && collectionCard.tokens && collectionCard.tokens[0]) {
      const token = collectionCard.tokens[0];
      onSell({
        ...token,
        price: token.purchasePrice || token.price,
        total: collectionCard?.amountCards,
        currencySymbol: drop.influencer.currencySymbol,
        allTokens: collectionCard.tokens,
      });
    } else {
      onSell();
    }
  };

  const getMyCardData = async () => {
    try {
      const res = await getUserCard({ params: { cardId: id } });
      const formatRes = formatEditionOrders(
        res?.data,
        status?.card?.quantityAllocated
      );
      dispatch(updateCardTokens(formatRes));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    status?.assetHoldings > 0
      ? getMyCardData()
      : dispatch(updateCardTokens(null));
  }, [status?.assetHoldings, id]);

  useEffect(() => {
    if (status) {
      const { card } = status;
      setSellCardsLoader(true);
      dispatch(fetchEditionOrders({ id, type: ORDER_TYPES.sell }))
        .then((res) => {
          if (res?.payload) {
            const amount = card?.quantityAllocated || 0;
            const formatRes = formatEditionOrders(res.payload, amount);
            setEditionOrders({
              amountCards: formatRes.amountCards,
              tokens: formatRes.tokens,
            });
          }
        })
        .finally(() => setSellCardsLoader(false));
    }
  }, [id, status]);

  useEffect(() => {
    if (stateStatus === STATE_STATUS.failed) {
      dispatch(setError({ open: true }));
      navigate(PATHS.DROPS);
    }
  }, [stateStatus]);

  return (
    <Container
      className={`${styles.container} ${styles.hasStickyBottomMobile}`}
    >
      <Box className={styles.wrapper}>
        <BackBtn className={styles.backBtn} link={backRoute} />
        {stateStatus === STATE_STATUS.loading && !drop && (
          <DropDetailSkeleton className={styles.cardContainer} />
        )}
        {drop && (
          <Container className={styles.cardContainer}>
            <Box className={styles.cardSidebar}>
              <Card
                className={styles.card}
                card={drop}
                link={influencerProfileLink}
              />
              <InfluencerLink
                influencer={drop?.influencer}
                path={influencerProfileLink}
                className={styles.cardInfluencer}
              />
              <SidebarActions
                drop={drop}
                checking={checking}
                assetHoldings={status?.assetHoldings || 0}
                handleBuyBtnClick={
                  card?.rewardType &&
                  card?.rewardType !== REWARDS_ACTION_TYPE.HIDDEN_CONTENT &&
                  !card?.holdings
                    ? setBuyAlert
                    : handleBuyBtnClick
                }
                onSell={handleSell}
              />
              <MyTokens
                currencySymbol={drop.influencer.currencySymbol}
                assetHoldings={status?.assetHoldings || 0}
                onSubmit={onSell}
                loading={checking}
                collectionCard={collectionCard}
                subTitle={
                  card?.type === CARD_TYPES.CUSTOM
                    ? t("discover.editionNumber")
                    : null
                }
              />
              {sellCardsLoader ? (
                <Skeleton
                  variant="rectangular"
                  style={{ height: "250px", marginTop: "24px" }}
                />
              ) : editionOrders?.tokens?.length ? (
                <Box sx={{ marginTop: "24px" }} key={status?.assetHoldings}>
                  <Tokens
                    data={editionOrders}
                    cardsCount={editionOrders.tokens.length}
                    currencySymbol={drop.influencer.currencySymbol}
                    title={t("discover.editionsForSale")}
                    subTitle={t("discover.editionNumber")}
                    onSubmit={handleBuyBtnClick}
                    defaultExpanded={!status?.assetHoldings}
                    expandButton={
                      <Box className={stylesCard.collectionDetailsBottom}>
                        <Typography
                          variant="body2"
                          className={`${stylesCard.collectionDetailsBottomTitleLink}`}
                          onClick={() => navigate(`/pass/${id}/pass-for-sale`)}
                        >
                          {t("discover.viewAllCards")}
                          <ExpandMoreIcon />
                        </Typography>
                      </Box>
                    }
                    actionTitle={t("edition.buyCard")}
                  />
                </Box>
              ) : null}
            </Box>
            {drop?.status === EDITION_STATUSES.phase0 ? (
              <Phase0Detail
                card={{ ...drop, ...card }}
                className={styles.details}
                buyAlertHandler={setBuyAlert}
              />
            ) : null}
            {drop?.status === EDITION_STATUSES.phase2 ||
            drop?.status === EDITION_STATUSES.complete ? (
              <Phase2Detail
                card={{ ...drop, ...card }}
                className={styles.details}
                buyAlertHandler={setBuyAlert}
              />
            ) : null}
          </Container>
        )}
      </Box>
    </Container>
  );
};
export default DropDetail;
