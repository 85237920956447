import { Button } from "@mui/material";
import { PATHS } from "constants/index";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import {
  getAlertErrorCardId,
  getAlertErrorIsNotCompleted,
} from "redux/selectors/appSelectors";
import { LocalizationContext } from "services/localizationContext";

import styles from "./BuyCardActions.module.scss";

const BuyCardActions = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cardId = useSelector(getAlertErrorCardId);
  const isNotCompleted = useSelector(getAlertErrorIsNotCompleted);

  const close = () => {
    dispatch(setError({ open: false }));
  };
  const buyCardHandler = () => {
    navigate(PATHS.DROPS_ID_BUY.replace(":id", cardId));
    close();
  };

  const handler = () => {
    if (isNotCompleted) {
      navigate(PATHS.COMPLETE_PROFILE);
      close();
    } else {
      buyCardHandler();
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={close} className={styles.button}>
        {isNotCompleted ? t("default.goBack") : t("default.cancel")}
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={handler}
        className={styles.button}
      >
        {isNotCompleted ? t("kyc.complete") : t("edition.buyCard")}
      </Button>
    </>
  );
};
export default BuyCardActions;
