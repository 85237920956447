import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import React from "react";

import styles from "./ActionTooltip.module.scss";

export const ActionTooltip = ({
  isLoading,
  children,
  open,
  setOpen,
  disabled,
}) => {
  const Loader = () =>
    isLoading ? (
      <Box className={styles.loader}>
        <CircularProgress color="inherit" size={16} />
      </Box>
    ) : null;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box>
        <Tooltip
          open={open}
          title={
            <Box className={styles.tooltip}>
              <Loader />
              {children}
            </Box>
          }
        >
          <Button
            variant="text"
            className={styles.button}
            onClick={() => setOpen(true)}
            disabled={disabled}
          >
            <DotsEditIcon />
          </Button>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};
