import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./ProfileConnection.module.scss";

const ProfileConnection = ({ connection }) => {
  const navigate = useNavigate();

  const name = connection.friend.username;

  return (
    <Box
      key={connection.id}
      className={styles.friend}
      onClick={() => navigate(`/public/user/${connection.friend.username}`)}
    >
      <Box className={styles.friendImageWrap}>
        <img
          className={styles.friendImage}
          src={connection.friend.userPicCroppedUrl}
          alt={name}
        />
      </Box>
      <Typography className={styles.friendName} variant="body1">
        {name}
      </Typography>
    </Box>
  );
};

export default ProfileConnection;
