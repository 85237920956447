import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import blurredPostAudio from "assets/img/blured-post-audio.png";
import blurredPostImg from "assets/img/blured-post-img.png";
import blurredPostText from "assets/img/blured-post-text.png";
import { ReactComponent as Crown } from "assets/svg/feed/crown.svg";
import { ReactComponent as Pin } from "assets/svg/pin-icon.svg";
import classnames from "classnames/bind";
import { ActionTooltipPost } from "components/ActionTooltipPost/ActionTooltipPost";
import Gallery from "components/Gallery/Gallery";
import { ModalPostCreator } from "components/ModalPostCreator/ModalPostCreator";
import Poll from "components/Poll/Poll";
import { ShareDataBox } from "components/ShareDataBox/ShareDataBox";
import {
  INFLUENCER_ACTIVE_ID_STORAGE_KEY,
  PATHS,
  POST_TYPES,
} from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import { setPost, updatePostSlice } from "redux/feedSlice";
import { setActiveMedia } from "redux/mediaSlice";
import { deletePost } from "services/api/feedApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import { LEVEL_GROUP } from "utils/getFilteredInfluencers";
import { useIsMobile } from "utils/hooks";
import { getStatus } from "utils/post";
import { truncateText } from "utils/stringHelper";

import { getProcessingSubscription } from "../../../redux/selectors/influencerSelectors";
import { getInfluencerProfileLink } from "../../../utils";
import { useInfluencerSubscribe } from "../../../utils/hooks/useInfluencerSubscribe";
import { handleSharingPostLink } from "../../../utils/sharingHelper";
import SubscribeInfluencer from "../../SubscribeInfluencer";
import { VideoBlock } from "../../VideoBlock/VideoBlock";
import { YouTubeVideoBlock } from "../../VideoBlock/YouTubeVideoBlock";
import { AudioBlock } from "../Comment/AudioBlock/AudioBlock";
import CommentsCount from "../Comment/CommentsCount";
import Reactions from "../Reactions/Reactions";
import { InfoIcon } from "./icons";
import styles from "./Post.module.scss";
import { PostText } from "./PostText";

const Post = (postData) => {
  const {
    className,
    userName,
    message,
    createdAt,
    commentsCount,
    children,
    id,
    images,
    videos,
    audios,
    appUserReactions,
    allowComments,
    pinned,
    postSummary,
    pathToProfile,
    influencer,
    pathToPost,
    data,
    postIndex,
    originalMessage,
    inReview,
    stylesWrapper,
    status,
    callbackDeleteFunc,
    level,
    appUser,
    isShowPostedOn,
    accessGranted,
    isGroupOwner,
    type,
    isGlobal,
    currentUser,
    isShowCommentedOn,
  } = postData;
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const profile = useSelector((state) => state.users.entities);
  const appUserID = useSelector((state) => state.users.entities?.appUserID);
  const postsPendingReview = useSelector(
    (state) => state.influencer.entities?.community?.postsPendingReview
  );
  const isProcessingSubscription = useSelector(getProcessingSubscription);
  const theme = useTheme();

  const cx = classnames.bind(styles);
  const classNames = cx(styles.postsItem, className, "global-class-post-feed", {
    [styles.pinned]: pinned,
    [styles.postBlurred]: !accessGranted,
  });
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState(null);

  const { onResubscribe, onSubscribe } = useInfluencerSubscribe({
    influencerData: influencer,
    influencerId: influencer?.id,
    setClientSecret,
  });

  const influencerProfileLink = getInfluencerProfileLink({
    id: influencer?.id,
    handle: influencer?.handle,
  });

  const handleOnSubscribe = () => {
    if (isGlobal) {
      navigate(influencerProfileLink);
    } else if (influencer?.isSubscribed) {
      if (influencer?.isCancelled) {
        onResubscribe();
      }
    } else {
      onSubscribe();
    }
  };

  const isMobile = useIsMobile();
  // const isShortened = postSummary?.length > 1 && isMobile;
  const isUserPost =
    !isGroupOwner &&
    appUser?.username &&
    appUser?.username !== influencer?.handle &&
    isShowPostedOn &&
    currentUser
      ? !isGroupOwner || isGroupOwner
      : !isGroupOwner;

  const isUserCommented = !isUserPost && isShowCommentedOn;

  const [isLoading, setLoading] = useState(false);
  const [isPostHide, setPostHide] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenPostTooltip, setOpenPostTooltip] = useState(false);

  const RenderPinnedBlock = () =>
    pinned ? (
      <Box className={styles.postsItemTitlePinnedWrapper}>
        <Pin />
        <span className={styles.postsItemPinned}>{t("discover.pinned")}</span>
      </Box>
    ) : null;
  const location = useLocation();
  const openPostImage = (e, img) => {
    const imgIdx = images.findIndex((i) => i === img);
    openPost(e, imgIdx);
  };

  const openPost = (e, imgIdx) => {
    e.stopPropagation();
    const path = pathToPost || PATHS.POST_ID.replace(":postId", id);
    navigate(path, {
      state: { from: location.pathname, imgIdx },
    });
    dispatch(setPost(postData));
    dispatch(setActiveMedia({ postIndex: null, mediaIndex: null }));
  };

  const openProfile = (e) => {
    if (pathToProfile) {
      e.stopPropagation();
      navigate(pathToProfile);
    }
  };

  const openUserProfile = (e) => {
    const username = appUser?.username;
    if (username) {
      e.stopPropagation();
      navigate(`/public/user/${username}`);
    }
  };

  const handleUsername = (username1, username2) => {
    const isLengthLimit =
      (username1 ? username1 : null + username2 ? username2 : null).length > 18;
    return isUserPost && isMobile && isLengthLimit
      ? truncateText(username1, 9)
      : username1;
  };

  const statusData = getStatus({ value: status, theme, t });

  const deletePostHandler = () => {
    setLoading(true);
    deletePost({ postId: id })
      .then(() => {
        setPostHide(true);
        if (callbackDeleteFunc) {
          callbackDeleteFunc();
        }
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      })
      .finally(() => setLoading(false));
  };

  const updatePostHandler = (payload) => {
    return dispatch(updatePostSlice({ ...payload, inReview }))
      .then(() => setOpenModal(false))
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      });
  };

  const isShowActionTooltip =
    inReview || (!postsPendingReview && level === LEVEL_GROUP)
      ? appUserID === postData?.appUserId
      : false;

  const postOwnerUsername = currentUser
    ? currentUser?.username
    : postData?.isGroupOwner
    ? userName
    : postData?.appUser?.username || userName;

  if (!accessGranted) {
    const imgSrc =
      type === POST_TYPES.AUDIO
        ? blurredPostAudio
        : type === POST_TYPES.IMAGE || type === POST_TYPES.VIDEO
        ? blurredPostImg
        : blurredPostText;

    return (
      <Box className={classNames} style={stylesWrapper}>
        {isGlobal ? (
          <Box className={styles.postsItemTitleWrapper}>
            <Box className={styles.postsItemTitle}>
              <Box
                className={styles.postsItemTitleMain}
                onClick={isUserPost ? openUserProfile : openProfile}
              >
                {currentUser?.userPicCroppedUrl ? (
                  <img
                    src={currentUser.userPicCroppedUrl}
                    alt="influencer-avatar"
                    className={styles.influencerAvatar}
                  />
                ) : postData?.appUser?.profileImageUrl &&
                  !postData?.isGroupOwner ? (
                  <img
                    src={postData.appUser.profileImageUrl}
                    alt="influencer-avatar"
                    className={`${styles.influencerAvatar} ${
                      level === LEVEL_GROUP || isUserPost
                        ? styles.influencerAvatarCircle
                        : ""
                    }`}
                  />
                ) : (
                  <img
                    src={influencer?.profileImageUrl}
                    alt="influencer-avatar"
                    className={styles.influencerAvatar}
                  />
                )}
                <Typography
                  variant="body5"
                  className={styles.postsItemUsername}
                >
                  {handleUsername(postOwnerUsername, influencer?.name)}
                </Typography>
              </Box>
              {isUserPost || isUserCommented ? (
                <Box
                  className={styles.postsItemTitleMain}
                  onClick={openProfile}
                >
                  <Typography
                    variant="body2"
                    className={`${styles.postsItemUsername} ${styles.postItemOn}`}
                  >
                    {isUserCommented
                      ? t("default.commentedOn")
                      : t("discover.postedOn")}
                  </Typography>
                  {influencer?.profileImageUrl ? (
                    <img
                      src={influencer?.profileImageUrl}
                      alt="influencer-avatar"
                      className={`${styles.influencerAvatar} ${styles.activeCommunityAvatar}`}
                    />
                  ) : null}
                  <Typography
                    variant="body5"
                    className={styles.postsItemUsername}
                  >
                    {handleUsername(influencer?.name, postOwnerUsername)}
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : null}
        <Box className={styles.postBlurredWrapperImg}>
          <img src={imgSrc} alt="post" className={styles.postBlurredImg} />
          <Box className={styles.bluredPostContent}>
            <Typography variant="body1">
              {t("discover.paidPostTitle")}
            </Typography>
            <Box>
              <LoadingButton
                variant="outlined"
                onClick={handleOnSubscribe}
                loading={isProcessingSubscription}
              >
                {t("discover.subscribe")}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
        <SubscribeInfluencer
          clientSecret={clientSecret}
          influencerData={influencer}
          onCloseSubscribeModal={() => {
            setClientSecret(null);
            sessionStorage.removeItem(INFLUENCER_ACTIVE_ID_STORAGE_KEY);
          }}
        />
      </Box>
    );
  }

  return (
    <>
      {!isPostHide ? (
        <Box className={classNames} style={stylesWrapper}>
          {isLoading ? (
            <Box className={styles.loaderWrapper}>
              <CircularProgress size={24} />
            </Box>
          ) : null}
          <Box className={styles.postsItemTitleWrapper}>
            <Box className={styles.postsItemTitle}>
              <Box
                className={styles.postsItemTitleMain}
                onClick={isUserPost ? openUserProfile : openProfile}
              >
                {currentUser?.userPicCroppedUrl ? (
                  <img
                    src={currentUser.userPicCroppedUrl}
                    alt="influencer-avatar"
                    className={styles.influencerAvatar}
                  />
                ) : postData?.appUser?.profileImageUrl &&
                  !postData?.isGroupOwner ? (
                  <img
                    src={postData.appUser.profileImageUrl}
                    alt="influencer-avatar"
                    className={`${styles.influencerAvatar} ${
                      level === LEVEL_GROUP || isUserPost
                        ? styles.influencerAvatarCircle
                        : ""
                    }`}
                  />
                ) : (
                  <img
                    src={influencer?.profileImageUrl}
                    alt="influencer-avatar"
                    className={styles.influencerAvatar}
                  />
                )}
                <Typography
                  variant="body5"
                  className={styles.postsItemUsername}
                >
                  {handleUsername(postOwnerUsername, influencer?.name)}
                  {postData?.isGroupOwner && level === LEVEL_GROUP ? (
                    <Box className={styles.crownIcon} component="span">
                      <Tooltip
                        title={
                          <Typography variant="body3_grotesk_medium">
                            {t("default.groupOwner")}
                          </Typography>
                        }
                        placement="top"
                        arrow
                        classes={{
                          tooltipArrow: styles.crownTooltip,
                          arrow: styles.crownTooltipArrow,
                        }}
                      >
                        <Crown />
                      </Tooltip>
                    </Box>
                  ) : null}
                </Typography>
              </Box>
              {isUserPost || isUserCommented ? (
                <Box
                  className={styles.postsItemTitleMain}
                  onClick={openProfile}
                >
                  <Typography
                    variant="body2"
                    className={`${styles.postsItemUsername} ${styles.postItemOn}`}
                  >
                    {isUserCommented
                      ? t("default.commentedOn")
                      : t("discover.postedOn")}
                  </Typography>
                  {influencer?.profileImageUrl ? (
                    <img
                      src={influencer?.profileImageUrl}
                      alt="influencer-avatar"
                      className={`${styles.influencerAvatar} ${styles.activeCommunityAvatar}`}
                    />
                  ) : null}
                  <Typography
                    variant="body5"
                    className={styles.postsItemUsername}
                  >
                    {handleUsername(influencer?.name, postOwnerUsername)}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <RenderPinnedBlock />
            <Box className={styles.statusBlock}>
              {inReview ? (
                <Button
                  style={{
                    background: statusData.background,
                    color: statusData.statusColor,
                  }}
                  className={styles.statusBlockButton}
                  onClick={openPost}
                >
                  {statusData.title}
                  <InfoIcon svgStrokeColor={statusData.svgStrokeColor} />
                </Button>
              ) : null}
              {isShowActionTooltip ? (
                <ActionTooltipPost
                  deleteHandler={deletePostHandler}
                  isLoading={isLoading}
                  editHandler={() => {
                    setOpenModal(true);
                    setOpenPostTooltip(false);
                  }}
                  status={status}
                  open={isOpenPostTooltip}
                  setOpen={setOpenPostTooltip}
                />
              ) : null}
            </Box>
          </Box>
          {images?.length ? (
            <Box className={styles.galleryWrapper}>
              <Gallery postId={id} images={images} onImgClick={openPostImage} />
            </Box>
          ) : null}
          {videos?.length ? (
            <VideoBlock videos={videos} postIndex={postIndex} mediaIndex={0} />
          ) : null}
          <AudioBlock
            audios={audios}
            postIndex={postIndex}
            // accessGranted={accessGranted}
            handle={influencer?.handle}
          />
          {data?.youtubeUrl ? (
            <Box key={data?.youtubeUrl} className={styles.youtubeVideoWrapper}>
              <YouTubeVideoBlock
                youtubeUrl={data.youtubeUrl}
                className={styles.youtubeVideo}
                parentIndex={postIndex}
              />
            </Box>
          ) : null}
          <Box key={message} style={{ width: "100%" }}>
            <PostText
              message={message}
              question={postData?.poll?.question}
              postId={id}
              originalMessage={originalMessage}
              createdAt={createdAt}
              locale={profile?.language}
              // shouldShowTime={!postData?.poll}
              shouldShowTime={false}
            />
          </Box>
          <Box className={styles.pollWrapper}>
            <Poll
              post={postData}
              locale={profile?.language}
              shouldShowTime={true}
            />
          </Box>
          {children}
          {!inReview ? (
            <Box className={styles.postsBottomFooter}>
              <Reactions
                postId={id}
                userReaction={appUserReactions}
                postSummary={postSummary}
              />
              <Box className={styles.postsBottomFooterRight} onClick={openPost}>
                <ShareDataBox
                  isText={false}
                  url={handleSharingPostLink({
                    url: window.location.href,
                    postId: id,
                    handle: influencer?.handle,
                  })}
                />
                <CommentsCount
                  count={commentsCount}
                  allowComments={allowComments}
                  isShortened={true}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <ModalPostCreator
        isOpen={isOpenModal}
        setOpenModal={setOpenModal}
        title={t("default.editPost")}
        onSubmitHandler={(payload) => updatePostHandler(payload)}
        influencerId={influencer?.id}
        post={postData}
        isUpdate={true}
      />
    </>
  );
};
export default Post;
