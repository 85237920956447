import {
  Box,
  CircularProgress,
  Container,
  Skeleton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "components/Card";
import { TutorialTooltip } from "components/TutorialTooltip/TutorialTooltip";
import { INFLUENCERS_LOAD_LIMIT, TUTORIALS_PROPS } from "constants/index";
import React, { useContext, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserSeenTutorials } from "redux/selectors/usersSelectors";
import { getInfluencers } from "services/discoverApi";
import { LocalizationContext } from "services/localizationContext";
import { LEVEL_BIGGER, LEVEL_SMALLER } from "utils/getFilteredInfluencers";

import { updateUserEntities, updateUserProfile } from "../../redux/usersSlice";
import { getScrollThreshold, goToInfluencer } from "../../utils";
import { CategoriesList } from "./components/CategoriesList/CategoriesList";
import { MainHeader } from "./components/MainHeader/MainHeader";
import styles from "./discover.module.scss";
import { EmptyStateIcon } from "./icons";

const sortCreators = (
  smallElements,
  bigElements,
  offset1,
  offset2,
  startNextBigPosition = 0
) => {
  const resultArray = [];

  let bigIndex = 0;
  let nextBigPosition = startNextBigPosition;
  let offset = offset1;

  smallElements.forEach((element, index) => {
    while (index === nextBigPosition && bigIndex < bigElements.length) {
      resultArray.push(bigElements[bigIndex]);
      bigIndex++;
      nextBigPosition += offset;
      offset = offset === offset1 ? offset2 : offset1;
    }
    resultArray.push(element);
  });

  while (bigIndex < bigElements.length) {
    resultArray.push(bigElements[bigIndex]);
    bigIndex++;
  }

  return resultArray;
};

const LIMIT_BIG_CARDS = 7;

const Discover = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery("(max-width:570px)");

  const seenTutorials = useSelector(getUserSeenTutorials);

  const [offset, setOffset] = useState(0);
  const [category, setCategory] = useState(null);
  const [notFeaturedData, setNotFeaturedData] = useState([]);
  const [featuredData, setFeaturedData] = useState([]);
  const [notFeaturedDataLoader, setNotFeaturedDataLoader] = useState(true);
  const [featuredDataLoader, setFeaturedDataLoader] = useState(true);
  const [hasMoreNotFeature, setHasMoreNotFeature] = useState(true);
  const [hasMoreFeature, setHasMoreFeature] = useState(true);

  const isEmpty =
    !notFeaturedData.length &&
    !featuredData.length &&
    !notFeaturedDataLoader &&
    !featuredDataLoader;

  const isSkeleton =
    !notFeaturedData.length &&
    !featuredData.length &&
    (notFeaturedDataLoader || featuredDataLoader);

  const loadMore = () => setOffset((prevState) => prevState + 1);

  const allData = useMemo(() => {
    const offset1 = isSm ? 4 : isMd ? 6 : 12;
    const offset2 = isSm ? 4 : isMd ? 2 : 4;
    const startNextBigPosition = isSm ? 4 : 0;
    return sortCreators(
      notFeaturedData,
      featuredData,
      offset1,
      offset2,
      startNextBigPosition
    );
  }, [featuredData, isMd, isSm, notFeaturedData]);

  const setCategoryHandler = (value) => {
    setCategory(value);
    setOffset(0);
  };

  const closeTutorialTooltip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.CREATORS_TUTORIAL_1]: true,
      },
    };
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  useEffect(() => {
    setNotFeaturedDataLoader(true);
    setFeaturedDataLoader(true);
    getInfluencers({
      level: `${LEVEL_BIGGER},${LEVEL_SMALLER}`,
      featured: 0,
      ...(offset && { offset: INFLUENCERS_LOAD_LIMIT * offset }),
      // orderBy: "random",
      limit: INFLUENCERS_LOAD_LIMIT,
      category,
    })
      .then((res) => {
        setNotFeaturedData((prevState) =>
          offset === 0 ? res.data : prevState.concat(res.data)
        );
        if (!res.data?.length) {
          setHasMoreNotFeature(false);
        } else {
          setHasMoreNotFeature(res.data.length % INFLUENCERS_LOAD_LIMIT === 0);
        }
      })
      .finally(() => setNotFeaturedDataLoader(false));
    getInfluencers({
      level: `${LEVEL_BIGGER},${LEVEL_SMALLER}`,
      featured: 1,
      ...(offset && { offset: LIMIT_BIG_CARDS * offset }),
      // orderBy: "random",
      limit: LIMIT_BIG_CARDS,
      category,
    })
      .then((res) => {
        setFeaturedData((prevState) =>
          offset === 0 ? res.data : prevState.concat(res.data)
        );
        if (!res.data?.length) {
          setHasMoreFeature(false);
        } else {
          setHasMoreFeature(res.data.length % LIMIT_BIG_CARDS === 0);
        }
      })
      .finally(() => setFeaturedDataLoader(false));
  }, [offset, category]);

  return (
    <Container className="page-container">
      <MainHeader
        title={
          <TutorialTooltip
            isOpen={!seenTutorials?.[TUTORIALS_PROPS.CREATORS_TUTORIAL_1]}
            setOpen={closeTutorialTooltip}
            placement={isMd ? "bottom" : "right"}
            title={t("default.creatorsTutorial1Title")}
            subTitle={t("default.creatorsTutorial1SubTitle")}
          >
            {t("discover.creators")}
          </TutorialTooltip>
        }
        isControls={false}
        wrapperClass={styles.header}
      />
      <CategoriesList
        setCategory={setCategoryHandler}
        category={category}
        loader={notFeaturedDataLoader || featuredDataLoader}
      />
      {isEmpty ? (
        <Box className={styles.emptyState}>
          <EmptyStateIcon />
          <Typography>{t("discover.discoverEmptyState")}</Typography>
        </Box>
      ) : isSkeleton ? (
        <Box className={styles.grid}>
          <Skeleton
            variant="rectangular"
            className={`${styles.card} ${styles.cardBig}`}
          />
          <Skeleton variant="rectangular" className={styles.card} />
          <Skeleton variant="rectangular" className={styles.card} />
          <Skeleton variant="rectangular" className={styles.card} />
          <Skeleton variant="rectangular" className={styles.card} />
          <Skeleton variant="rectangular" className={styles.card} />
          <Skeleton variant="rectangular" className={styles.card} />
          <Skeleton variant="rectangular" className={styles.card} />
          <Skeleton variant="rectangular" className={styles.card} />
        </Box>
      ) : (
        <InfiniteScroll
          dataLength={allData.length}
          next={loadMore}
          hasMore={hasMoreNotFeature || hasMoreFeature}
          scrollThreshold={getScrollThreshold()}
          scrollableTarget="scrollableEl"
          className={styles.grid}
          loader={<CircularProgress />}
        >
          {allData.map((card) => (
            <Card
              className={`${styles.card} ${
                card.featured ? styles.cardBig : ""
              }`}
              key={card.id}
              name={card.name}
              isHot={card.isHot}
              imageUrl={card.profileImageUrl}
              isWatchlisted={card.isWatchlisted}
              onClick={goToInfluencer({
                id: card.id,
                handle: card.handle,
                navigate,
                location,
              })}
            />
          ))}
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default Discover;
