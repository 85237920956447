import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { SWAP_MODAL_TYPES } from "constants/";
import { useDispatch } from "react-redux";
import { closeModal } from "redux/modalSlice";
import styles from "./SwapModal.module.scss";
import { ReactComponent as SuccessIcon } from "assets/svg/zoop-icon.svg";
import { ReactComponent as ErrorIcon } from "assets/svg/error-icon.svg";

export const SwapModal = ({
  title,
  description,
  btnTitle,
  type,
  onBtnClick,
}) => {
  const dispatch = useDispatch();
  return (
    <Box className={styles.modal}>
      <Box className={styles.modalIconWrap}>
        {type === SWAP_MODAL_TYPES.error ? <ErrorIcon /> : <SuccessIcon />}
      </Box>
      <Typography className={styles.modalTitle} variant="h6">
        {title}
      </Typography>
      <Typography className={styles.modalDesc} variant="body1">
        {description}
      </Typography>
      <Button
        className={`${styles.modalBtn} ${
          type === SWAP_MODAL_TYPES.error ? styles.errorBtn : ""
        }`}
        variant="contained"
        onClick={() => {
          dispatch(closeModal());
          onBtnClick();
        }}
      >
        {btnTitle}
      </Button>
    </Box>
  );
};

export default SwapModal;
