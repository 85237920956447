import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as DownloadIcon } from "assets/svg/download-icon.svg";
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg";
import MenuTitle from "components/MenuTitle";
import UnfriendModal from "components/Modal/UnfriendModal";
import { ACCOUNT_STATES, LANGUAGES_LIST, zoopEmail } from "constants/";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import { fetchUser, rejectDeleteYourAccount } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import Address from "./account/Address";
import BlockedAccounts from "./account/BlockedAccounts";
import Language from "./account/Language";
import PersonalInformation from "./account/PersonalInformation";
import Username from "./account/Username";
import { ExclamationMarkIcon } from "./icons";
import DeleteAccountInfo from "./modals/DeleteAccountInfo";
import DeleteAccountRejectInfo from "./modals/DeleteAccountRejectInfo";
import styles from "./Settings.module.scss";

const Account = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state) => state.users.entities);
  const loading = useSelector((state) => state.app.loader);
  const [accountState, setAccountState] = useState(
    location?.state?.accountState || ACCOUNT_STATES.default
  );
  const [langLabel, setLangLabel] = useState(null);

  useEffect(() => {
    if (!user?.language) {
      return;
    }

    const l = Object.entries(LANGUAGES_LIST).find(
      ([_, v]) => user?.language === v
    );
    if (l) {
      localStorage.setItem("selected-language", l[1]);
      setLangLabel(t(`account.${l[0]}`));
    }
  }, [t, user?.language]);

  const handleSendEmail = () => {
    // window.zE("messenger:set", "zIndex", 99999);
    // window.zE("messenger", "open");
    window.zE("messenger", "show");
    dispatch(closeModal());
  };

  const handleOpenEmailModal = () => {
    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={t("settings.sendEmailTitle")}
            description={t("settings.sendEmailDescription")}
            onConfirm={handleSendEmail}
            onCancel={() => dispatch(closeModal())}
            confirmBtnText={t("settings.sendEmail")}
            cancelBtnText={t("default.cancel")}
            isWarn
            icon={<ExclamationMarkIcon />}
          />
        ),
      })
    );
  };

  const handleDeleteAccount = () => {
    dispatch(openModal({ children: <DeleteAccountInfo /> }));
  };

  const handleRejectDeleteAccount = () => {
    dispatch(setLoader(true));
    dispatch(rejectDeleteYourAccount()).then(() => {
      dispatch(fetchUser()).then(() => {
        dispatch(openModal({ children: <DeleteAccountRejectInfo /> }));
        dispatch(setLoader(false));
      });
    });
  };

  const getDeleteYourAccount = () => {
    if (user.underDeletion) {
      return (
        <Box className={styles.deleteAccountWrapper}>
          <LoadingButton
            variant="outlined-red"
            onClick={handleRejectDeleteAccount}
            loading={loading}
            className={styles.deleteAccountBtn}
          >
            {t("settings.rejectDeleteAccount")}
          </LoadingButton>
          <Typography variant="error" className={styles.deleteAccountText}>
            {t("settings.yourAccountWillBeDeleted")}
          </Typography>
        </Box>
      );
    }
    return (
      <Box className={styles.deleteAccountWrapper}>
        <LoadingButton
          variant="outlined-red"
          onClick={handleDeleteAccount}
          loading={loading}
          className={styles.deleteAccountBtn}
        >
          {t("settings.deleteYourAccount")}
        </LoadingButton>
      </Box>
    );
  };

  const successLangChange = (newLang) => {
    setLangLabel(newLang);
    setAccountState(ACCOUNT_STATES.default);
  };
  const getOptions = () => (
    <>
      <Box className={styles.tabs}>
        {Object.keys(ACCOUNT_STATES).map((option) => {
          if (option === Object.keys(ACCOUNT_STATES).at(0)) {
            return null;
          }

          return (
            <Box
              key={option}
              onClick={() => setAccountState(ACCOUNT_STATES[option])}
              className={styles.tabOption}
            >
              <Typography variant="h6">
                {t(`settings.${option.toLowerCase()}`)}
              </Typography>
              {ACCOUNT_STATES[option] === ACCOUNT_STATES.language ? (
                <Box className={styles.iconWrap}>
                  <Typography
                    variant="h6"
                    className={styles.tabOptionLabel}
                    textTransform="capitalize"
                  >
                    {langLabel}
                  </Typography>
                  <IconRight className={styles.icon} />
                </Box>
              ) : (
                <IconRight className={styles.icon} />
              )}
            </Box>
          );
        })}
      </Box>
      <Box className={styles.tabsBtnBlock}>
        <Button
          className={styles.downloadDataBtn}
          endIcon={<DownloadIcon className={styles.downloadDataBtnIcon} />}
          onClick={handleOpenEmailModal}
        >
          {t("settings.downloadYourData")}
        </Button>
        {getDeleteYourAccount()}
      </Box>
    </>
  );

  const getContent = () => {
    switch (accountState) {
      case ACCOUNT_STATES.default:
        return getOptions();
      case ACCOUNT_STATES.username:
        return <Username />;
      case ACCOUNT_STATES.personalInformation:
        return <PersonalInformation />;
      case ACCOUNT_STATES.address:
        return <Address />;
      case ACCOUNT_STATES.language:
        return <Language successChangeCb={successLangChange} />;
      case ACCOUNT_STATES.blockedAccounts:
        return <BlockedAccounts />;
      default:
        return getOptions();
    }
  };
  const goBack = () => {
    location.state?.from
      ? navigate(location.state?.from, { replace: true })
      : setAccountState(ACCOUNT_STATES.default);
  };
  return (
    <Box className={styles.tabContainer}>
      <MenuTitle
        states={ACCOUNT_STATES}
        currentState={accountState}
        onBack={goBack}
      />
      {getContent()}
    </Box>
  );
};

export default Account;
