import { Box, Container } from "@mui/material";
import { AllStars } from "components/InfluencersRows/AllStars/AllStars";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { PATHS, SCROLL_THRESHOLD } from "constants/index";
import React, { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { LEVEL_BIGGER } from "utils/getFilteredInfluencers";

import styles from "./styles.module.scss";
import { useData } from "./useData";

export const DiscoverAllStars = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const { data, loadMore, scrollLoader, loader, hasMore } = useData({
    level: LEVEL_BIGGER,
    featured: 0,
  });

  return (
    <Container className={styles.container}>
      <Box className={styles.backHeadline}>
        <BackBtn
          onClick={() => navigate(PATHS.DISCOVER)}
          title={t("discover.allStars")}
        />
      </Box>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        hasMore={hasMore}
        loader={scrollLoader}
        scrollThreshold={SCROLL_THRESHOLD}
        scrollableTarget="scrollableEl"
      >
        <AllStars data={data} loading={loader} isHideHeader={true} />
      </InfiniteScroll>
    </Container>
  );
};
