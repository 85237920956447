import { Avatar, Box, Container, Typography } from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/arrow-down.svg";
import { ReactComponent as LockedIcon } from "assets/svg/locked-icon.svg";
import { ReactComponent as UnlockedIcon } from "assets/svg/unlocked-icon.svg";
import { ORDER_TYPES, REWARD_STATUS } from "constants/index";
import DOMPurify from "dompurify";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import { fetchEditionMarketStatus } from "redux/editionSlice";
import { fetchEditionOrders } from "redux/editionSlice";
import { LocalizationContext } from "services/localizationContext";
import { formatEditionOrders } from "utils";
import { useIsMobile } from "utils/hooks";

import BackBtn from "../../components/Layout/BackBtn/BackBtn";
import styles from "./cards-for-sale.module.scss";

const CardsForSale = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [status, setStatus] = useState();
  const [editionOrders, setEditionOrders] = useState([]);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useContext(LocalizationContext);

  const handleBuyBtnClick = (token) => {
    if (token?.orderId) {
      navigate(`/discover/pass/${id}/buy`, {
        state: {
          order: {
            ...token,
            id: token.orderId,
            price: token.purchasePrice,
          },
          edition: status,
          ...(location?.state?.paymentMethod && {
            paymentMethod: location?.state?.paymentMethod,
          }),
        },
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      setLoader({
        title: t("default.loading"),
        subtitle: t("default.itMayTakeAFewSeconds"),
      })
    );
    dispatch(fetchEditionMarketStatus({ id, type: ORDER_TYPES.sell }))
      .unwrap()
      .then((res) => {
        const amount = res?.card?.quantityAllocated;
        setStatus(res);
        dispatch(fetchEditionOrders({ id, type: ORDER_TYPES.sell }))
          .then((res) => {
            if (res?.payload) {
              setEditionOrders(formatEditionOrders(res.payload, amount));
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            dispatch(setLoader(false));
            setLoading(false);
          });
      })
      .catch((e) => {
        dispatch(setLoader(false));
        setLoading(false);
        console.error(e);
      });
  }, [id]);

  if (loading) return null;

  return (
    <Container className={styles.container}>
      <Box className={styles.header}>
        <BackBtn
          link={location?.state?.backRoute || `/pass/${id}`}
          state={location?.state}
          title={t("edition.cardsForSale")}
        />
      </Box>
      <Box className={styles.tokenList}>
        {editionOrders?.tokens?.map((token) => (
          <Box
            className={`${styles.tokenItem} ${styles.tokenItemList}`}
            key={token.id}
            onClick={() => handleBuyBtnClick(token)}
          >
            {!isMobile && (
              <Box className={styles.user}>
                <Avatar src={token.userPictureURL} className={styles.avatar} />
                <Typography
                  variant="h4"
                  component="span"
                  className={styles.userName}
                >
                  {token.username}
                </Typography>
              </Box>
            )}
            <Box className={styles.tokenTitle}>
              <Typography
                variant="subtitle1"
                component="span"
                className={styles.tokenText}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    t("edition.numberOfTotal", {
                      number: token.id,
                      total: editionOrders?.amountCards,
                    }),
                    {
                      USE_PROFILES: { html: true },
                    }
                  ),
                }}
              />
              {token.rewardStatus === REWARD_STATUS.LOCKED ? (
                <LockedIcon />
              ) : token.rewardStatus === REWARD_STATUS.UNLOCKED ? (
                <UnlockedIcon />
              ) : null}
            </Box>
            <Box className={styles.goTo}>
              <Typography
                variant="subtitle1"
                component="span"
                className={styles.tokenText}
              >
                {token.currencySymbol} {token.purchasePrice}
              </Typography>
              <ExpandMoreIcon className={styles.goToIcon} />
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};
export default CardsForSale;
