import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import styles from "styles/auth/Auth.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";
import * as Yup from "yup";

import { getAppUsersStatus } from "../../services/api.service";
import { errorResponseMessages } from "../../services/apiErrorHelper.tsx";
import { LocalizationContext } from "../../services/localizationContext";

const Email = ({
  onSubmit,
  isMainLoading,
  mainServerError,
  onEmailChanged,
}) => {
  const { t } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const location = useLocation();
  const { email } = location?.state || {};
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("error.required"))
      .email(t("error.invalidEmail")),
  });

  const {
    register,
    handleSubmit: handleEmailSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email,
    },
  });

  const onEmailSubmit = (data) => {
    setServerError(false);
    setLoading(true);
    getAppUsersStatus({ email: data.email })
      .then((res) => {
        setLoading(false);
        if (res.usernameExists) {
          onSubmit(data.email);
        } else {
          setServerError(t("auth.emailDoesntExist"));
        }
      })
      .catch((e) => {
        setLoading(false);
        setServerError(errorResponseMessages(e, t));
      });
  };
  const watchEmail = watch("email") || "";
  const handleEmailChange = (ev) => {
    setEmailValue(ev.target?.value);
    onEmailChanged(ev.target?.value);
  };
  return (
    <Box>
      <Typography variant="h3" component="h1" className={styles.title}>
        {t("auth.forgotYourPassword")}
      </Typography>
      <form
        onSubmit={handleEmailSubmit(onEmailSubmit)}
        className="auth-forgotPasswordForm"
      >
        <FormControl>
          <TextField
            label={t("kyc.email")}
            error={!!errors.email}
            inputProps={{
              onChange: handleEmailChange,
            }}
            className={`${roundedInputStyles.input} ${
              roundedInputStyles.cyan
            } ${watchEmail.length ? roundedInputStyles.filled : ""}`}
            type="text"
            autoComplete="off"
            {...register("email")}
          />
          {!!errors.email ? (
            <Typography variant="error" className={roundedInputStyles.error}>
              {errors.email?.message}
            </Typography>
          ) : null}
          {serverError || mainServerError ? (
            <Typography variant="error" className={roundedInputStyles.error}>
              {serverError || mainServerError}
            </Typography>
          ) : null}
        </FormControl>
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!isValid}
          loading={loading || isMainLoading}
          className={styles.submitBtn}
        >
          {t("auth.sendCode")}
        </LoadingButton>
      </form>
    </Box>
  );
};
export default Email;
