import { INFLUENCER_ACTIVE_ID_STORAGE_KEY, MODAL_TYPE } from "constants/index";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { setError } from "redux/appSlice";
import { resubscribe, subscribe } from "redux/influencerSlice";
import { openModal } from "redux/modalSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";

export const useInfluencerSubscribe = ({
  influencerId,
  setClientSecret,
  influencerData,
  setLoader,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const showErrorModal = (e) => {
    dispatch(
      setError({
        open: true,
        subtitle: errorResponseMessages(e.data || e, t),
      })
    );
  };
  const loaderHandler = (value) => {
    if (setLoader) {
      setLoader(value);
    }
  };
  const onSubscribe = (revertStateCb) => {
    loaderHandler(true);
    dispatch(subscribe(influencerId))
      .unwrap()
      .then((res) => {
        if (res?.clientSecret) {
          setClientSecret(res.clientSecret);
          sessionStorage.setItem(
            INFLUENCER_ACTIVE_ID_STORAGE_KEY,
            influencerId
          );
        }
        if (revertStateCb) {
          revertStateCb((prevState) => !prevState);
        }
      })
      .catch((e) => {
        showErrorModal(e);
      })
      .finally(() => loaderHandler(false));
  };

  const onResubscribe = (revertStateCb) => {
    loaderHandler(true);
    dispatch(resubscribe(influencerId))
      .unwrap()
      .then(() => {
        if (revertStateCb) {
          revertStateCb((prevState) => !prevState);
        }
        dispatch(
          openModal({
            type: MODAL_TYPE.SUCCESS_MODAL,
            payload: {
              description: t("subscription.subscribeSuccessDesc", {
                name: influencerData?.name,
              }),
              influencer: influencerData,
              data: [
                {
                  text: t("default.influencer"),
                  value: influencerData?.name,
                },
                {
                  text: t("subscription.renewal"),
                  value: t("subscription.renewEachMonth"),
                },
                {
                  text: t("subscription.price"),
                  value: `${influencerData?.currencySymbol}${influencerData?.community?.subscriptionPrice}`,
                },
              ],
            },
          })
        );
      })
      .catch((e) => {
        showErrorModal(e);
      })
      .finally(() => loaderHandler(false));
  };

  return { onSubscribe, onResubscribe };
};
