import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import NotificationCard from "components/NotificationCard";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMarkAsRead,
  fetchNotificationsData,
  removeNotifications,
} from "redux/notificationsSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Notification.module.scss";

const Notifications = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [hasMore, setHasMore] = useState(true);
  const [sortBy, setSortBy] = useState("ALL");
  const [loading, setLoading] = useState(false);
  const notifications = useSelector((state) => state.notifications.entities);
  const [filtredNotifications, setFiltredNotifications] = useState([]);
  const numberUnreadNotifications = useSelector(
    (state) => state.notifications.numberUnreadNotifications
  );
  const user = useSelector((state) => state.users.entities);

  const fetchNotifications = () => {
    setLoading(true);
    dispatch(fetchNotificationsData({ offset: offset, limit: limit }))
      .unwrap()
      .then((res) => {
        setOffset(offset + 1);
        if (res.length === 0) {
          setHasMore(false);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchNotifications();
    return () => {
      dispatch(removeNotifications());
    };
  }, []);

  useEffect(() => {
    if (sortBy === "ALL") {
      setFiltredNotifications(notifications);
    } else if (sortBy === "NEW") {
      const filtredNotifications = notifications.filter(
        (n) => n.status === "NEW"
      );
      setFiltredNotifications(filtredNotifications);
    }
  }, [sortBy, notifications, numberUnreadNotifications]);

  const handleMarkAsRead = () => {
    setLoading(true);
    setOffset(0);
    dispatch(fetchMarkAsRead()).then(() => {
      dispatch(removeNotifications());
      dispatch(
        fetchNotificationsData({
          offset: offset - limit > 0 ? offset - limit : 0,
          limit: limit,
        })
      );
    });
  };

  const isHasMore = () => {
    if (filtredNotifications.length === 0) {
      return false;
    }
    return hasMore;
  };

  return (
    <Container className={styles.mainContainer}>
      <Typography variant="h2" className={styles.title}>
        {t("notifications.notifications")}
      </Typography>
      <Box>
        <Box className={styles.buttonContainer}>
          <Button
            variant={sortBy === "ALL" ? "contained" : "outlined"}
            onClick={() => setSortBy("ALL")}
            className={styles.filterBtn}
          >
            {t("home.entire")}
          </Button>
          <Button
            variant={sortBy === "NEW" ? "contained" : "outlined"}
            onClick={() => setSortBy("NEW")}
            className={styles.filterBtn}
          >
            {t("notifications.unread")}
          </Button>
        </Box>
        <Box className={styles.buttonSubWrapper}>
          <Typography variant="h6" className={styles.secondaryTitle}>
            {t("notifications.latest")}
          </Typography>
          <Button
            onClick={() => handleMarkAsRead()}
            className={styles.markAsRead}
          >
            {t("notifications.markAllAsRead")}
          </Button>
        </Box>
        <InfiniteScroll
          dataLength={filtredNotifications.length}
          next={fetchNotifications}
          hasMore={isHasMore()}
          className={styles.container}
          scrollThreshold="200px"
          loader={
            <>
              <Grid item xs={4}>
                <Skeleton
                  variant="rectangular"
                  sx={{ bgcolor: "#21264e", height: 87, borderRadius: "10px" }}
                />
              </Grid>
              <Grid item xs={4}>
                <Skeleton
                  variant="rectangular"
                  sx={{ bgcolor: "#21264e", height: 87, borderRadius: "10px" }}
                />
              </Grid>
            </>
          }
          endMessage={""}
        >
          {filtredNotifications.length > 0 ? (
            filtredNotifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                {...notification}
                locale={user.language}
              />
            ))
          ) : (
            <>
              {!loading && (
                <Typography variant="h6">
                  {t("notifications.noNotifications")}
                </Typography>
              )}
            </>
          )}
        </InfiniteScroll>
      </Box>
    </Container>
  );
};
export default Notifications;
