import { ShareModal } from "components/Modal/ShareModal/ShareModal";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import { EmptyStateFriendsIcon } from "../icons";
import { SidebarBox } from "./SidebarBox/SidebarBox";

export const FriendsWrapper = ({ connections, userInviteCode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useContext(LocalizationContext);

  const onItemClick = (data) => {
    if (data?.friend?.username) {
      navigate(PATHS.PUBLIC_USER.replace(":id", data.friend.username));
    }
  };

  const friendInviteLink = `${window.origin}${PATHS.SIGN_UP}/${userInviteCode}`;

  const emptyStateHandler = () => {
    dispatch(
      openModal({
        children: (
          <ShareModal
            url={friendInviteLink}
            title={t("default.profileFriendsEmptyStateAction")}
          />
        ),
        styles: {
          width: "100%",
          maxWidth: isMobile ? "280px" : "352px",
          padding: isMobile ? "16px 16px 16px 16px" : "44px 48px 48px 48px",
        },
      })
    );
  };

  return (
    <SidebarBox
      title={t("default.friends")}
      amount={connections?.total}
      path={PATHS.CONNECTIONS}
      data={connections?.rows?.map((item) => ({
        ...item,
        picture: item?.friend?.userPicCroppedUrl,
        title: item?.friend?.username,
        id: item?.id,
      }))}
      onItemClick={onItemClick}
      emptyStateIcon={<EmptyStateFriendsIcon />}
      emptyStateTitle={t("default.profileFriendsEmptyStateTitle")}
      emptyStateActionText={t("default.profileFriendsEmptyStateAction")}
      emptyStateAction={emptyStateHandler}
    />
  );
};
