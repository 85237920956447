import { Box } from "@mui/material";
import { ReactComponent as IconRefferals } from "assets/svg/add-friend-icon.svg";
import { ReactComponent as IconChallenges } from "assets/svg/challenges.svg";
import { ReactComponent as IconAdminClub } from "assets/svg/icon-admin.svg";
import { ReactComponent as IconSettings } from "assets/svg/icon-settings.svg";
import { ReactComponent as IconTransactions } from "assets/svg/icon-transactions.svg";
import { ReactComponent as IconLogout } from "assets/svg/logout-icon.svg";
import ActiveLink from "components/ActiveLink";
import {
  ANALYTICS_EVENTS,
  celebrityDomain,
  EMULATE,
  PATHS,
} from "constants/index";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import { logout } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { isEmulationMode } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";
import { useIsMobile } from "utils/hooks";

import defaultStyles from "./ProfileNav.module.scss";

export const ProfileNavMenu = ({ overrideStyles, handleClose, children }) => {
  const isMobile = useIsMobile();
  const profile = useSelector((state) => state.users.entities);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = { ...defaultStyles, ...(overrideStyles || {}) };
  const handleLogout = () => {
    Analytics.track(ANALYTICS_EVENTS.LOGOUT, { user: profile.id });
    Analytics.reset();
    const cognitoUser = UserPool()?.getCurrentUser();
    if (cognitoUser) {
      if (isEmulationMode()) {
        console.error(EMULATE.ACCESS_ERROR);
      } else {
        cognitoUser.signOut();
      }
    }
    dispatch(logout());
    handleClose && handleClose();
    navigate(PATHS.LOGIN, { replace: true });
  };
  const { t } = useContext(LocalizationContext);
  const handleReferralsClick = (e) => {
    e.preventDefault();
    handleClose && handleClose();
    if (profile.isCompleted) {
      navigate(PATHS.REFERRALS);
    } else {
      localStorage.setItem("completeProfileBackRoute", "/referrals");
      dispatch(
        setError({
          open: true,
          title: t("kyc.completeKyc"),
          subtitle: t("kyc.pleaseCompleteTheWholeKyc"),
          actions: "ErrorKycDlgActions",
        })
      );
    }
  };

  const goToClub = () => {
    window.location.href = celebrityDomain;
  };

  return (
    <>
      <Box>
        {children}
        <ActiveLink
          to={PATHS.CHALLENGES}
          onClick={handleClose}
          className={styles.popoverMenuItem}
          activeClass={styles.menuItemActive}
        >
          <IconChallenges />
          {t("home.challenges")}
        </ActiveLink>
        <ActiveLink
          to={PATHS.TRANSACTIONS}
          onClick={handleClose}
          className={styles.popoverMenuItem}
          activeClass={styles.menuItemActive}
        >
          <IconTransactions />
          {t("home.transactions")}
        </ActiveLink>

        <ActiveLink
          to={PATHS.REFERRALS}
          onClick={handleReferralsClick}
          className={styles.popoverMenuItem}
          activeClass={styles.menuItemActive}
        >
          <IconRefferals className={styles.popoverMenuItemIconRefferals} />
          {t("home.referrals")}
        </ActiveLink>

        {profile?.appUser?.isInfluencer ? (
          <ActiveLink
            isExternal
            onClick={goToClub}
            className={styles.popoverMenuItem}
            activeClass={styles.menuItemActive}
          >
            <IconAdminClub />
            {t("default.clubAdmin")}
          </ActiveLink>
        ) : null}

        <ActiveLink
          to={isMobile ? PATHS.SETTINGS : PATHS.SETTINGS_ACCOUNTS}
          onClick={handleClose}
          className={styles.popoverMenuItem}
          activeClass={styles.menuItemActive}
        >
          <IconSettings />
          {t("default.settings")}
        </ActiveLink>
      </Box>

      <Box
        onClick={handleLogout}
        className={`${styles.popoverMenuItem} ${styles.logOutItem}`}
      >
        <IconLogout />
        {t("default.logOut")}
      </Box>
    </>
  );
};
