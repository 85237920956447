import { Box, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Badge } from "components/Badge/Badge";
import { CardsSlider } from "components/CardsSlider/CardsSlider";
import { HeaderSection } from "components/HeaderSection/HeaderSection";
import { ViewAll } from "components/ViewAll/ViewAll";
import { PATHS } from "constants/index";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChallenges } from "redux/challengesSlice";
import {
  getChallengesLoadingSelector,
  getChallengesSelector,
} from "redux/selectors/challengesSelector";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Badges.module.scss";
import { EmptyState } from "./components/EmptyState/EmptyState";

export const Badges = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:768px)");

  const challenges = useSelector(getChallengesSelector);
  const loading = useSelector(getChallengesLoadingSelector);
  const challengesArray = Object.entries(challenges);

  const badges = challengesArray.reduce((acc, current) => {
    current[1].forEach((item) => {
      const badge = item?.appUserTrack?.challengeStage?.badge;
      const currentBadge = item?.currentBadge;
      if (badge && currentBadge) {
        acc.push({
          ...badge,
          challengeId: item.id,
          stageNum: currentBadge.stageNum,
          goalCount: currentBadge?.goalCount,
        });
      }
    });
    return acc;
  }, []);

  useEffect(() => {
    if (!challenges.length) {
      dispatch(fetchChallenges({ offset: 0, limit: 50, init: true }));
    }
  }, []);

  if (loading && !badges?.length) {
    return (
      <Box>
        <Skeleton variant="rectangular" className={styles.badgeSkeletonTitle} />
        <Box className={styles.badgesSkeleton}>
          <Skeleton variant="rectangular" className={styles.badgeSkeleton} />
          <Skeleton variant="rectangular" className={styles.badgeSkeleton} />
          <Skeleton variant="rectangular" className={styles.badgeSkeleton} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <HeaderSection
        customTitle={
          <Typography variant="h4" className={styles.headerTitle}>
            {t("badges.sliderTitle")}{" "}
            <Typography className={styles.headerTitleValue}>
              ({badges.length})
            </Typography>
          </Typography>
        }
        customButton={
          (isMobile && badges.length >= 3) ||
          (!isMobile && badges.length > 5) ? (
            <ViewAll path={PATHS.BADGES} />
          ) : null
        }
        wrapperClass={styles.header}
      />
      {!loading && !badges?.length ? <EmptyState /> : null}
      <CardsSlider
        cardWidth={isMobile ? 124 : 145}
        spaceBetweenSlides={isMobile ? 8 : 16}
        wrapperClassname={styles.cardsSlider}
      >
        {badges.map((item) => (
          <Badge
            key={item.id}
            {...item}
            imageUrl={item.imageUrl}
            stageNum={item?.stageNum}
            title={t(`badges.${item?.code}`)}
            goalCount={item?.goalCount}
            wrapperClassName={styles.badge}
          />
        ))}
      </CardsSlider>
    </Box>
  );
};
