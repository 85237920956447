import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as RedirectIcon } from "assets/svg/redirect-icon.svg";
import classnames from "classnames/bind";
import { getCategories } from "constants/index";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./InfluencerLink.module.scss";

export const InfluencerLink = ({ influencer, path, className }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const cx = classnames.bind(styles);
  const location = useLocation();

  const categories = getCategories({ t });

  const toInfluencerId = () =>
    navigate(path, {
      state: { from: location.pathname },
    });

  if (!influencer) {
    return null;
  }

  return (
    <Box className={cx(styles.user, className)} onClick={toInfluencerId}>
      <Box className={styles.userAvatarWrapper}>
        <img
          src={influencer.profileImageUrl}
          alt={t("default.influencerAvatar")}
        />
      </Box>
      <Box className={styles.userInfo}>
        <Typography className={styles.userName} variant="body4" component="p">
          {influencer.name}
          <Button variant="text" className={styles.userInfoButton}>
            <RedirectIcon />
          </Button>
        </Typography>
        <Typography className={`${styles.userRole} opacity_07`} variant="body1">
          {categories[influencer.category]}
        </Typography>
      </Box>
    </Box>
  );
};
