import React, { useContext } from "react";
import { Box, Typography, Container } from "@mui/material";

import styles from "./Error503.module.scss";
import { ReactComponent as Icon503 } from "assets/svg/503.svg";
import { LocalizationContext } from "services/localizationContext";

const Error503 = () => {
  const { t } = useContext(LocalizationContext);
  return (
    <Container className={styles.container}>
      <Box className={styles.block}>
        <Icon503 className={styles.iconError} />
      </Box>
      <Box className={styles.block}>
        <Typography variant="h1" className={styles.title}>
          {t("error.maintenanceTitle")}
        </Typography>
        <Typography variant="textNormal" className={styles.description}>
          {t("error.maintenanceDesc")}
        </Typography>
      </Box>
    </Container>
  );
};

export default Error503;
