import { Box, Container } from "@mui/material";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { getUsernameFromPathname } from "utils/usernameHelper";

import styles from "./public-collections.module.scss";

const PublicCollections = () => {
  const { t } = useContext(LocalizationContext);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const username =
    params?.username || getUsernameFromPathname(location.pathname);

  const handleBack = () => {
    navigate(`/public/user/${username}`);
  };
  const title = t("account.collections", { username });
  return (
    <Container maxWidth={false}>
      <Box className={styles.container}>
        <Box className={styles.headerContainer}>
          <BackBtn onClick={handleBack} title={title} />
        </Box>
        <Box className={styles.collectionContainer}>
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
};

export default PublicCollections;
