import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChallenges } from "redux/challengesSlice";
import { getChallengesSelector } from "redux/selectors/challengesSelector";
import {
  getMyConnections,
  getMyFollowersLoaderSelector,
  getMyFollowersSelector,
  getUserInviteCode,
} from "redux/selectors/usersSelectors";
import { fetchMyFollows, fetchUserConnections } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import { CONNECTIONS_LOAD_LIMIT } from "../../../../constants";
import { BadgesWrapper } from "../BadgesWrapper";
import { ClubsWrapper } from "../ClubsWrapper";
import { FriendsWrapper } from "../FriendsWrapper";
import { ProfileInfo } from "../ProfileInfo/ProfileInfo";

export const Sidebar = ({
  level,
  profileImageUrl,
  username,
  isInfluencer,
  rewardPoints,
  stats,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isS = useMediaQuery("(max-width:960px)");

  const clubsCommunity = useSelector(getMyFollowersSelector);
  const clubsLoading = useSelector(getMyFollowersLoaderSelector);
  const connections = useSelector(getMyConnections);
  const userInviteCode = useSelector(getUserInviteCode);

  const challenges = useSelector(getChallengesSelector);

  const challengesArray = Object.entries(challenges);

  const convertedData = challengesArray.reduce((acc, current) => {
    current[1].forEach((item) => {
      const badge = item?.appUserTrack?.challengeStage?.badge;
      const currentBadge = item?.currentBadge;
      if (badge && currentBadge) {
        acc.push({
          picture: badge.imageUrl,
          title: t(`badges.${badge.code}`),
          id: badge.id,
          stageNum: currentBadge.stageNum,
          goalCount: currentBadge.goalCount,
        });
      }
      return acc;
    });
    return acc;
  }, []);

  useEffect(() => {
    dispatch(fetchMyFollows());
    dispatch(
      fetchUserConnections({
        limit: CONNECTIONS_LOAD_LIMIT,
        page: 1,
      })
    );
    dispatch(fetchChallenges());
  }, []);

  return (
    <>
      <ProfileInfo
        data={{
          level,
          profileImageUrl,
          username,
          appUser: {
            isInfluencer,
          },
          rewardPoints,
          badgesAmount: convertedData?.length,
          clubsAmount: clubsCommunity?.length,
          friendsAmount: connections?.total,
          stats,
          userInviteCode,
        }}
      />
      {!isS ? (
        <>
          <ClubsWrapper
            clubsLoading={clubsLoading}
            clubsCommunity={clubsCommunity}
          />
          <FriendsWrapper
            connections={connections}
            userInviteCode={userInviteCode}
          />
          <BadgesWrapper convertedData={convertedData} />
        </>
      ) : null}
    </>
  );
};
