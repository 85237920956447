import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { ReactComponent as Icon } from "assets/svg/warn-icon.svg";
import { MODAL_TYPE } from "constants";
import { format } from "date-fns";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoader } from "redux/appSlice";
import { cancelSubscription } from "redux/influencerSlice";
import { openModal } from "redux/modalSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import { useLocale } from "utils/hooks";

import styles from "./ConfirmDlg.module.scss";

const ConfirmUnsubscribeDlg = ({
  influencerId,
  open,
  onClose,
  influencerData,
  currentPeriodEndAt,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isProcessing = useSelector(
    (state) => state.influencer.processingSubscription
  );
  const locale = useLocale();
  const endDateFormatted = currentPeriodEndAt
    ? format(new Date(currentPeriodEndAt), "dd MMM yyyy", {
        locale,
      })
    : null;
  const onSubmit = () => {
    dispatch(
      setLoader({
        title: t("trading.submittingOrder"),
        subtitle: t("default.itMayTakeAFewSeconds"),
      })
    );
    dispatch(cancelSubscription(influencerId))
      .unwrap()
      .then(() => {
        dispatch(
          openModal({
            type: MODAL_TYPE.SUCCESS_MODAL,
            payload: {
              description: t("subscription.unsubscribeSuccessDesc", {
                endDate: endDateFormatted,
              }),
              influencer: influencerData,
              data: [
                {
                  text: t("default.influencer"),
                  value: influencerData?.name,
                },
                {
                  text: t("subscription.endDate"),
                  value: t("subscription.noRenew"),
                },
                {
                  text: t("subscription.renewal"),
                  value: endDateFormatted,
                },
              ],
            },
          })
        );
        onClose(true);
      })
      .catch((e) => {
        onClose();
        dispatch(
          setError({
            open: true,
            subtitle: errorResponseMessages(e.data || e, t),
          })
        );
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        classes={{
          paper: styles.dlgPaper,
          root: styles.modal,
        }}
      >
        <Box className={styles.container}>
          <Box className={styles.iconWrapper}>
            <Icon />
          </Box>
          <Box className={styles.content}>
            <Typography className={styles.title} variant="h5" component="h5">
              {t("subscription.unsubscribeConfirmTitle")}
            </Typography>
            <Typography
              className={styles.subtitle}
              variant="subtitle1"
              component="p"
            >
              {t("subscription.unsubscribeConfirmDesc", {
                currentPeriodEndAt: endDateFormatted,
                name: influencerData?.name,
              })}
            </Typography>
          </Box>
          <Box className={styles.actions}>
            <Button size="small" variant="outlined" onClick={onClose}>
              {t("default.cancel")}
            </Button>
            <LoadingButton
              loading={isProcessing}
              size="small"
              variant="contained-danger"
              onClick={onSubmit}
            >
              {t("subscription.unsubscribeBtn")}
            </LoadingButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ConfirmUnsubscribeDlg;
