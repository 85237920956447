import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/arrow-down.svg";
import Proptypes from "prop-types";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./market-statistics.module.scss";

export default function MarketStatistics({
  capitalization,
  volume,
  volumeChange,
  buys,
  sells,
  defaultExpanded,
}) {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  return (
    <Box className={styles.accordionWrap}>
      <Accordion className={styles.accordion} defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="market-statistics"
          id="market-stat"
        >
          <Typography variant="h5_medium">
            {t("discover.marketStatistics")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.infoWrap}>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.totalMarketValue")}
            </Typography>
            <Typography variant="subtitle1" component="span">
              ${capitalization}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography variant="subtitle1" component="span">
              {t("discover.sevenDayVolume")}
            </Typography>
            <Box className={styles.volumeWrap}>
              <Typography
                variant="subtitle1"
                component="span"
                className={styles.volumeChange}
                style={{
                  color:
                    volumeChange >= 0
                      ? theme.palette.tertiary.main
                      : theme.palette.coral.main,
                }}
              >
                {volumeChange}%
                <span
                  className={styles.diffTriangle}
                  style={{
                    borderWidth:
                      volumeChange > 0 ? "0 2px 8px 2px" : "8px 2px 0 2px",
                    borderColor:
                      volumeChange >= 0
                        ? `transparent transparent ${theme.palette.tertiary.main} transparent`
                        : `${theme.palette.coral.main} transparent transparent transparent`,
                  }}
                ></span>
              </Typography>
              <Typography variant="subtitle1" component="span">
                ${volume}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.infoItem}>
            <Typography
              className={styles.subText}
              variant="subtitle1"
              component="span"
            >
              {t("discover.buy")}
            </Typography>
            <Typography variant="subtitle1" component="span">
              {buys || "0"}%
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography
              className={styles.subText}
              variant="subtitle1"
              component="span"
            >
              {t("discover.sell")}
            </Typography>
            <Typography variant="subtitle1" component="span">
              {sells || "0"}%
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

MarketStatistics.defaultProps = {
  capitalization: 0,
  volume: 0,
  volumeChange: 0,
  buys: 0,
  sells: 0,
};

MarketStatistics.propTypes = {
  capitalization: Proptypes.number,
  volume: Proptypes.number,
  volumeChange: Proptypes.number,
  buys: Proptypes.number,
  sells: Proptypes.number,
};
