import React, { useMemo, useState, useContext } from "react";
import {
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { Button, Box } from "@mui/material";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";
import {
  filterByLastYear,
  filterByLastHalfYear,
  filterByLastMonth,
  filterByLastWeek,
} from "./filterHelper";
import styles from "./pricechart.module.scss";

const PERIODS_TYPES = {
  week: "week",
  month: "month",
  half: "half",
  year: "year",
  all: "all",
};

const DESKTOP_WIDTH = 660;

export default function PriceChart({ data, currencySymbol }) {
  const { t } = useContext(LocalizationContext);
  const [activePeriod, setActivePeriod] = useState(PERIODS_TYPES.all);
  const isMobile = useIsMobile();
  const cleanupData = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        ...{ price: parseFloat(item.price) },
      })),
    [data]
  );
  const longestLabelLength = useMemo(() => {
    const longest = data
      .map((c) => c.price.toString().split(".")[0])
      .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0);
    return longest + currencySymbol.length;
  }, [data, currencySymbol]);

  const PERIODS = [
    {
      title: t("default.allStr"),
      type: PERIODS_TYPES.all,
    },
    {
      title: t("default.week"),
      type: PERIODS_TYPES.week,
    },
    {
      title: t("default.month"),
      type: PERIODS_TYPES.month,
    },
    {
      title: t("default.sixMonths"),
      type: PERIODS_TYPES.half,
    },
    {
      title: t("default.year"),
      type: PERIODS_TYPES.year,
    },
  ];

  const getDataByPeriod = useMemo(() => {
    switch (activePeriod) {
      case PERIODS_TYPES.all:
        return cleanupData;
      case PERIODS_TYPES.year:
        return filterByLastYear(cleanupData);
      case PERIODS_TYPES.half:
        return filterByLastHalfYear(cleanupData);
      case PERIODS_TYPES.month:
        return filterByLastMonth(cleanupData);
      case PERIODS_TYPES.week:
        return filterByLastWeek(cleanupData);
      default:
        return cleanupData;
    }
  }, [activePeriod, cleanupData]);

  return (
    <>
      <Box className={styles.graphWrap}>
        <ResponsiveContainer
          margin={0}
          minWidth="100%"
          padding={0}
          width={isMobile ? "100%" : DESKTOP_WIDTH}
          height="100%"
        >
          <LineChart
            margin={{ top: 10, right: 0, left: 2, bottom: 0 }}
            data={getDataByPeriod}
          >
            <CartesianGrid
              stroke="rgba(171, 179, 243, 0.1)"
              strokeWidth={1}
              vertical={false}
              strokeLinecap="round"
            />

            <YAxis
              stroke="white"
              opacity={0.76}
              fontSize={isMobile ? "10px" : "13px"}
              dx={0}
              dy={0}
              interval={0}
              tickMargin={0}
              tickLine={false}
              axisLine={false}
              orientation="left"
              tickCount={5}
              width={longestLabelLength * (isMobile ? 8 : 10)}
              tickFormatter={(value) => {
                return `${currencySymbol}${value}`;
              }}
            />
            <Line
              type="monotone"
              dot={false}
              dataKey="price"
              stroke="#70E8FC"
              strokeWidth={1.5}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box className={styles.buttonsWrap}>
        {PERIODS.map((p) => {
          const isActive = p.type === activePeriod;
          return (
            <Button
              onClick={() => setActivePeriod(p.type)}
              key={p.type}
              className={`${styles.btn} ${isActive ? styles.active : ""}`}
              size="small"
            >
              {p.title}
            </Button>
          );
        })}
      </Box>
    </>
  );
}
