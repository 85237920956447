import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import { getCategories } from "constants/index";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./CategoriesList.module.scss";
export const CategoriesList = ({ category, setCategory, loader }) => {
  const { t } = useContext(LocalizationContext);

  const categories = getCategories({ t });

  const clickHandler = (value) => () => {
    if (category === value) {
      setCategory(null);
    } else {
      setCategory(value);
    }
  };

  return (
    <Box className={styles.list}>
      {Object.entries(categories).map((item) => (
        <LoadingButton
          variant="outlined"
          onClick={clickHandler(item[0])}
          key={item[0]}
          className={category === item[0] ? styles.buttonActive : ""}
          loading={loader && category === item[0]}
        >
          {item[1]}
        </LoadingButton>
      ))}
    </Box>
  );
};
