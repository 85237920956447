import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchUserZendeskJwt } from "redux/usersSlice";
import { isAuthenticated, setZendeskToken } from "services/auth.service";

const ZENDESK_AVAILABLE_PAGE = ["/settings/help"];

const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;

function loadScript(src, position, id, onLoaded) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("id", id);
  script.src = src;
  script.addEventListener("load", onLoaded);
  position.appendChild(script);
}

export default function ZendeskWidget() {
  const dispatch = useDispatch();
  const loaded = React.useRef(false);
  let location = useLocation();

  useEffect(() => {
    if (!loaded.current) return;

    if (!isAuthenticated()) {
      disableZendesk();
    } else {
      enableZendesk();
    }
  }, [isAuthenticated()]);

  useEffect(() => {
    hideWidgetButton();
  }, [window.zE, location.pathname, isAuthenticated(), loaded.current]);

  const hideWidgetButton = () => {
    if (window.zE) {
      if (ZENDESK_AVAILABLE_PAGE.includes(location.pathname)) {
        // window.zE("messenger:set", "zIndex", 99999);
        window.zE("messenger", "show");
      } else {
        // window.zE("messenger:set", "zIndex", -99999);
        window.zE("messenger", "hide");
      }
    }
  };

  const initZendesk = () => {
    hideWidget();
    window.zE("messenger:set", "cookies", true);
    dispatch(fetchUserZendeskJwt())
      .unwrap()
      .then((data) => {
        setZendeskToken(data?.token);
        loginUser(data?.token);
        hideWidgetButton();
      })
      .catch((err) => {
        console.log("Cannot get Zendesk jwt", err);
      });
  };

  const hideWidget = () => {
    window.zE("messenger", "close");
  };

  const showWidget = () => {
    window.zE("messenger", "open");
  };

  const loginUser = async (jwtToken) => {
    try {
      window.zE("messenger", "loginUser", function (callback) {
        callback(jwtToken);
      });
    } catch (e) {
      console.log("Cannot loogin into Zendesk", e);
    }
  };

  const disableZendesk = () => {
    if (document.querySelector("#ze-snippet")) {
      window.zE("messenger:set", "cookies", false);
    }
  };

  const enableZendesk = () => {
    if (typeof window !== "undefined" && loaded.current && window.zE) {
      if (document.querySelector("#ze-snippet")) {
        initZendesk();
      }
    }
  };

  if (!isAuthenticated()) return null;

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#ze-snippet")) {
      loadScript(
        `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`,
        document.head,
        "ze-snippet",
        initZendesk
      );
    }

    loaded.current = true;
  }

  return <></>;
}
