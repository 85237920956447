import React, { useContext, useEffect, useState, useRef } from "react";
import { Box, Typography, Container, Button, Skeleton } from "@mui/material";
import { LocalizationContext } from "services/localizationContext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "redux/appSlice";
import { fetchUserEditions } from "redux/usersSlice";
import EditionCard from "components/Card/Edition/EditionCard";
import { TOOLTIP_SHOWN } from "constants/";
import Toaster from "components/Toaster";

import styles from "./barter.module.scss";

import barterTeaserUrl from "assets/img/barter-teaser.png";
import { ReactComponent as ArrowRight } from "assets/svg/arrow-right.svg";
import { ReactComponent as PlaceholderIcon } from "assets/svg/swiping-placeholder-icon.svg";

const Skeletons = () => (
  <>
    {Array.from(new Array(8)).map((_item, i) => (
      <Box className={styles.skeleton} key={i}>
        <Skeleton
          variant="rectangular"
          className={styles.skeletonRect}
          sx={{ width: "100%", height: 196, mb: 2 }}
        />
        <Skeleton
          variant="rectangular"
          className={styles.skeletonRect}
          sx={{ width: "50%", height: 18, mb: 0.5 }}
        />
        <Skeleton
          variant="rectangular"
          className={styles.skeletonRect}
          sx={{ width: "90%", height: 18 }}
        />
      </Box>
    ))}
  </>
);

const Barter = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.loader);

  const [editions, setEditions] = useState([]);
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);
  const matchesBtnRef = useRef(null);

  const matchesBtnTooltip = localStorage.getItem("matchesBtnTooltip");

  useEffect(() => {
    dispatch(setLoader(true));
    dispatch(fetchUserEditions())
      .unwrap()
      .then((d) => {
        const filteredEditions = d.filter(
          (edition) =>
            edition.isSwappable &&
            edition.availableForBarter &&
            edition.status === "complete"
        );

        setEditions(filteredEditions);
      })
      .finally(() => dispatch(setLoader(false)));
  }, []);

  useEffect(() => {
    if (matchesBtnTooltip !== TOOLTIP_SHOWN) {
      setTooltipAnchorEl(matchesBtnRef.current);
    }
  }, []);

  const handleCloseTooltip = () => {
    localStorage.setItem("matchesBtnTooltip", TOOLTIP_SHOWN);
    setTooltipAnchorEl(null);
  };

  const Placeholder = () => (
    <Box className={styles.placeholder}>
      <PlaceholderIcon className={styles.placeholderIcon} />
      <Typography variant="h5">{t("barter.barterPlaceholderText")}</Typography>
    </Box>
  );

  // prevent showing barter game on prod
  if (process.env.REACT_APP_ENV === "production") {
    return (
      <Box className={styles.page}>
        <Container className={styles.teaserContainer}>
          <Box className={styles.sliderWrap}>
            <img
              src={barterTeaserUrl}
              className={styles.sliderImage}
              alt={t("barter.barterComingSoon")}
            />
          </Box>
          <Typography
            variant="h2"
            component="h1"
            className={styles.teaserTitle}
          >
            {t("barter.barterComingSoon")}
          </Typography>
        </Container>
      </Box>
    );
  }

  return (
    <Box className={styles.page}>
      <Container className={styles.container}>
        <Box className={styles.header}>
          <Typography variant="h2" component="h1" className={styles.title}>
            {t("barter.barter")}
          </Typography>
          {!!editions?.length ? (
            <Typography variant="h5" component="p" className={styles.subtitle}>
              {t("barter.barterHeaderSubtitle")}
            </Typography>
          ) : null}
          <Button
            ref={matchesBtnRef}
            onClick={() => navigate("/barter/matches")}
            className={styles.headerButton}
            endIcon={<ArrowRight className={styles.arrowRight} />}
          >
            {t("barter.seeMatches")}
          </Button>
        </Box>
        <Box className={styles.collectionContainer}>
          <Typography
            variant="h4"
            component="h2"
            className={styles.collectionTitle}
          >
            {t("barter.collection")}
          </Typography>
          <Box
            className={`${styles.collectionWrap} ${
              !editions?.length ? styles.empty : ""
            }`}
          >
            {loading ? (
              <Skeletons />
            ) : !!editions?.length ? (
              editions.map((card) => (
                <EditionCard
                  key={`edition-${card.id}`}
                  id={card.id}
                  className={styles.editionCard}
                  wrapClassName={styles.editionCardWrap}
                  textBoxClassName={styles.editionCardTextBox}
                  cardImage={card.imageUrl}
                  editionsDropped={card.series}
                  influencerName={card.influencerName}
                  inBarter={card.inBarter}
                  onClick={() =>
                    navigate(`/barter/${card.id}`, { state: { card } })
                  }
                />
              ))
            ) : (
              <Placeholder />
            )}
          </Box>
        </Box>
      </Container>
      <Toaster
        name="tooltip-popover"
        isOpen={!!tooltipAnchorEl}
        anchorEl={tooltipAnchorEl}
        message={t("barter.matchesBtnTooltip")}
        handleClose={handleCloseTooltip}
        isTooltip
      />
    </Box>
  );
};

export default Barter;
