import { useTheme } from "@emotion/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentStatus from "components/SubscribeInfluencer/PaymentStatus";
import SubscribeInfluencerModal from "components/SubscribeInfluencer/SubscribeInfluencerModal";
import { INFLUENCER_ACTIVE_ID_STORAGE_KEY } from "constants/index";
import { useLocation, useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SubscribeInfluencer = ({
  clientSecret,
  influencerData,
  onCloseSubscribeModal,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const showSubscribeModal = !!clientSecret;
  const clientSecretFromUrl = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  const clearPaymentStatusFromUrl = () => {
    navigate(location.pathname, { replace: true });
    sessionStorage.removeItem(INFLUENCER_ACTIVE_ID_STORAGE_KEY);
  };
  const secretKey = clientSecret || clientSecretFromUrl;
  if (!secretKey) return null;

  const stripeOptions = {
    clientSecret: secretKey,
    appearance: {
      theme: "night",
      variables: {
        colorPrimary: theme.palette.dark.main,
        colorBackground: theme.palette.surface.main,
        colorText: theme.palette.dark.main.contrastText,
        colorDanger: theme.palette.coral.main,
        fontFamily: theme.typography.fontFamily,
        spacingUnit: "4px",
        borderRadius: "8px",
      },
    },
  };
  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <SubscribeInfluencerModal
        open={showSubscribeModal}
        onClose={onCloseSubscribeModal}
      />
      {clientSecretFromUrl &&
      sessionStorage.getItem(INFLUENCER_ACTIVE_ID_STORAGE_KEY) ===
        `${influencerData?.id}` ? (
        <PaymentStatus
          onClose={clearPaymentStatusFromUrl}
          clientSecret={clientSecretFromUrl}
          influencerData={influencerData}
        />
      ) : null}
    </Elements>
  );
};
export default SubscribeInfluencer;
