import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  FormControl,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { ANALYTICS_EVENTS, WITHDRAW_TYPE } from "constants/";
import CountriesJson from "constants/Countries.json";
import { errorResponseTypes } from "constants/index";
import { STATE_KEYS } from "constants/states";
import { isArray } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addPaymentBankAccount, addPaymentCard } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import Analytics from "../../../services/Analytics.service";
import PlaceAutocomplete from "./../../KYC/PlaceAutocomplete";
import styles from "./AddCard.module.scss";

const BillingAddress = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.entities);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const countries = CountriesJson.Countries;

  const cardData = location.state;
  const amount = location.state?.amount;

  useEffect(() => {
    Analytics.track(location.pathname, {
      event: ANALYTICS_EVENTS.PAYMENT_PROGRESS,
      step: "3",
    });
  }, []);

  useEffect(() => {
    if (!cardData) {
      console.error("Incorrect data  passed to the location state", cardData);
      navigate("/", { replace: true });
    }
  }, [cardData]);

  const validationSchema = Yup.object().shape({
    addressLine1: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
    city: Yup.string().trim().required(t("error.required")),
    country: Yup.string().trim().required(t("error.required")),
    county: Yup.string()
      .trim()
      .when(["country"], {
        is: (country) => {
          return country === "US" || country === "United States";
        },
        then: (schema) => {
          return Yup.string().oneOf(STATE_KEYS, t("payments.stateError"));
        },
        otherwise: Yup.string().required(t("error.required")),
      })
      .isAlphanumeric(t("error.isAlphanumeric")),
    zipPostcode: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
  });
  const {
    clearErrors,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleChangeCountry = (value) => {
    if (!value) {
      setValue("country", "");
      return;
    }
    setValue("country", value.name);
  };

  const handleFormSubmit = (billingAddress) => {
    setLoading(true);

    const country = CountriesJson.Countries.find(
      (c) =>
        c.name == billingAddress.country || c.code == billingAddress.country
    );

    if (cardData.type === "WIRE") {
      let payload = {};

      if (cardData.subtype === WITHDRAW_TYPE.IBAN_SUPPORTED) {
        payload = {
          type: cardData.type,
          iban: cardData.iban,
          billingDetails: {
            name: cardData.cardHolderName,
            city: billingAddress.city,
            country: country?.code,
            line1: billingAddress.addressLine1,
            district: billingAddress.county,
            postalCode: billingAddress.zipPostcode,
          },
          bankAddress: {
            bankName: cardData.bankName,
            city: cardData.bankAddress.city,
            country: cardData.bankAddress.country.code,
            line1: cardData.bankAddress.addressLine1,
            district: cardData.bankAddress.county,
            postalCode: cardData.bankAddress.zipPostcode,
          },
        };
      } else if (cardData.subtype === WITHDRAW_TYPE.IBAN_NOT_SUPPORTED) {
        payload = {
          type: cardData.type,
          idempotencyKey: uuidv4(),
          accountNumber: cardData.accountNumber,
          routingNumber: cardData.routingNumber,
          billingDetails: {
            name: cardData.cardHolderName,
            city: billingAddress.city,
            country: country?.code,
            line1: billingAddress.addressLine1,
            district: billingAddress.county,
            postalCode: billingAddress.zipPostcode,
          },
          bankAddress: {
            bankName: cardData.bankName,
            city: cardData.bankAddress.city,
            country: cardData.bankAddress.country.code,
            line1: cardData.bankAddress.addressLine1,
            district: cardData.bankAddress.county,
            postalCode: cardData.bankAddress.zipPostcode,
          },
        };
      } else {
        payload = {
          type: cardData.type,
          accountNumber: cardData.accountNumber,
          routingNumber: cardData.routingNumber,
          billingDetails: {
            name: cardData.cardHolderName,
            city: billingAddress.city,
            country: country?.code,
            line1: billingAddress.addressLine1,
            district: billingAddress.county,
            postalCode: billingAddress.zipPostcode,
          },
          bankAddress: {
            bankName: cardData.bankName,
            city: cardData.bankAddress.city,
            country: cardData.bankAddress.country.code,
            line1: cardData.bankAddress.addressLine1,
            district: cardData.bankAddress.county,
            postalCode: cardData.bankAddress.zipPostcode,
          },
        };
      }

      dispatch(addPaymentBankAccount(payload))
        .unwrap()
        .then(() => {
          setLoading(false);
          navigate("/wallet/withdraw/type/wire", {
            replace: true,
            state: { amount },
          });
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          if (e?.error) {
            handleError(e);
          } else if (e?.errors && isArray(e?.errors)) {
            handleError(e?.errors[0]);
          }
        });
      return;
    } else {
      const exp = cardData.expire.split("/");
      const payload = {
        idempotencyKey: uuidv4(),
        expMonth: parseInt(exp[0]),
        expYear: parseInt(`20${exp[1]}`),
        keyId: cardData.keyId,
        encryptedData: cardData.encryptedData,
        firstName: user.firstName,
        lastName: user.lastName,
        billingDetails: {
          name: cardData.cardHolderName,
          city: billingAddress.city,
          country: country?.code,
          line1: billingAddress.addressLine1,
          district: billingAddress.county,
          postalCode: billingAddress.zipPostcode,
        },
        metadata: {
          email: user.email,
          phoneNumber: user.appUser?.phoneNumber,
        },
      };

      dispatch(addPaymentCard(payload))
        .unwrap()
        .then(() => {
          setLoading(false);
          navigate("/payment/accounts", { replace: true, state: { amount } });
        })
        .catch((e) => {
          setLoading(false);
          console.error("ERROR", e);
          if (e?.error === errorResponseTypes.ProfileNotCompleted) {
            localStorage.setItem("completeProfileBackRoute", location.pathname);
            navigate("/complete-profile");
          } else {
            handleError(e);
          }
        });
    }
  };

  const handleError = (error) => {
    if (error?.error === "ACCOUNT_DETAILS_NOT_PROVIDED")
      return setError({
        message: t("payments.accountDetailsNotProvided"),
      });
    if (error?.message?.includes("bankAddress.country")) {
      setError({
        message: t("payments.bankCountryError"),
      });
    } else if (error?.message?.includes("routingNumber")) {
      setError({
        message: t("payments.routingNumberError"),
      });
    } else if (error?.message?.includes("billingAddress.country")) {
      setError({
        message: t("payments.notValidCountry"),
      });
    } else {
      setError(error);
    }
  };
  const onAdressChange = (adressDetails) => {
    clearErrors();
    if (!adressDetails) return;
    const { city, region, streetNumber, streetAddress } = adressDetails;
    setValue("city", city);
    setValue("county", region);
    if (streetNumber || streetAddress) {
      setValue("addressLine1", `${streetNumber || ""} ${streetAddress || ""}`);
    } else {
      setValue("addressLine1", " ");
    }
  };
  const title = t("payments.billingAddressTitle");
  return (
    <Container className={styles.container}>
      <Box className={styles.wrapper}>
        <Box className={`${styles.backBtnWrap} ${styles.detailsContainer}`}>
          <BackBtn className={styles.backBtn} isPlain title={title} />
        </Box>
        <Box className={styles.mainTitle}>
          <Typography variant="h3">{title}</Typography>
        </Box>
      </Box>
      <Box className={styles.detailsContainer}>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          autoComplete="off"
          className={styles.paymentsForm}
        >
          <Box className={`${styles.formContainer} ${styles.form}`}>
            <Box className={styles.formWrapper}>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("kyc.streetAddressField")}
                </label>
                <Controller
                  control={control}
                  name="addressLine1"
                  rules={{ required: t("error.required") }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <Box className={styles.placeInput}>
                      <PlaceAutocomplete
                        placeholder={t("kyc.streetAddressField")}
                        onChange={onChange}
                        onOptionPress={onAdressChange}
                        error={!!errors.addressLine1}
                        errorText={errors.addressLine1?.message}
                        defaultValue={value}
                      />
                    </Box>
                  )}
                />
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("kyc.streetAddress2Field")}
                </label>
                <Input
                  placeholder={t("kyc.streetAddress2Field")}
                  {...register("addressLine2")}
                  error={!!errors.addressLine2}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.addressLine2?.message}
                </Typography>
              </FormControl>

              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>{t("kyc.cityField")}</label>
                <Input
                  placeholder={t("kyc.cityField")}
                  {...register("city")}
                  error={!!errors.city}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.city?.message}
                </Typography>
              </FormControl>
            </Box>

            <Box className={styles.formWrapper}>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("kyc.stateRegionField")}
                </label>
                <Input
                  placeholder={t("kyc.stateRegionField")}
                  {...register("county")}
                  error={!!errors.county}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.county?.message}
                </Typography>
              </FormControl>

              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("kyc.postalCodeField")}
                </label>
                <Input
                  placeholder={t("kyc.postalCodeField")}
                  {...register("zipPostcode")}
                  error={!!errors.zipPostcode}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.zipPostcode?.message}
                </Typography>
              </FormControl>

              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>{t("kyc.countryField")}</label>
                <Box className={styles.countrySelect}>
                  <Autocomplete
                    id="countries-input"
                    className={styles.inputWrap}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    onChange={(_ev, option) => {
                      handleChangeCountry(option);
                    }}
                    popupIcon={<></>}
                    componentsProps={{
                      paper: {
                        sx: {
                          backgroundColor: "#21264e",
                          border: "none",
                          padding: "15px",
                        },
                        variant: "outlined",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={""}
                        placeholder={t("kyc.countryField")}
                        variant="standard"
                        autoComplete="off"
                        className={styles.input}
                        error={!!errors.country}
                        {...register("country")}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        className={styles.inputOption}
                      >
                        {option.name}
                      </Box>
                    )}
                  />
                </Box>
                <Typography variant="error" className={styles.inputError}>
                  {errors.country?.message}
                </Typography>
              </FormControl>
            </Box>
          </Box>
          {error && (
            <Typography
              variant="error"
              className={`${styles.center} ${styles.inputError}`}
            >
              {error?.message}
            </Typography>
          )}
          <FormControl>
            <LoadingButton
              type="submit"
              variant="outlined"
              className={styles.btnContinue}
              loading={loading}
            >
              {t("kyc.continueButton")}
            </LoadingButton>
          </FormControl>
        </form>
      </Box>
    </Container>
  );
};
export default BillingAddress;
