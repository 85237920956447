import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { ReactComponent as AlertIcon } from "assets/svg/alert-icon.svg";
import { ReactComponent as Icon } from "assets/svg/error-icon.svg";
import { lazy, Suspense, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "redux/appSlice";
import { getAlertError } from "redux/selectors/appSelectors";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ErrorModal.module.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => {
  return {
    "& .MuiDialog-paper": {
      background: theme.palette.dark.main,
      borderRadius: "10px",
      maxWidth: "420px",
    },
  };
});
const ErrorModal = ({ ...rest }) => {
  const { t } = useContext(LocalizationContext);
  const error = useSelector(getAlertError);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setError({ open: false }));
  };
  const Actions = lazy(() => {
    return import(
      `components/Modal/ErrorModal/Actions/${error.actions}.jsx`
    ).catch(() => import("./Actions/DefaultDlgAction"));
  });
  if (!error?.open) return null;

  return (
    <BootstrapDialog
      onClose={onClose}
      open={error?.open}
      {...rest}
      classes={{
        paper: `errorDlgPaper ${
          error?.isNonMobileStyles ? "errorDlgPaper_desktop" : ""
        }`,
        container: `errorDlgContainer ${
          error?.isNonMobileStyles ? "errorDlgContainer_desktop" : ""
        }`,
        root: "errorDlg",
      }}
    >
      <Box
        className={`${styles.container} ${
          error?.classNameContainer ? error.classNameContainer : ""
        } ${error?.isNonMobileStyles ? styles.containerDesktop : ""} ${
          error?.isAlertIcon ? styles.containerAlert : ""
        }`}
      >
        <Box className={styles.iconWrapper}>
          {error?.isAlertIcon ? <AlertIcon /> : <Icon />}
        </Box>
        <Box>
          <Typography className={styles.title} variant="h5" component="h5">
            {error.title || t("error.errorTitle")}
          </Typography>
          <Typography className={styles.subtitle} variant="body1" component="p">
            {error.subtitle || t("error.unknownError")}
          </Typography>
        </Box>
        <Box
          className={`${styles.actions} ${
            error?.isNonMobileStyles ? styles.actionsDesktop : ""
          }`}
        >
          <Suspense fallback={<></>}>
            <Actions
              defaultActionClass={error?.isInfoAlert ? styles.actionsInfo : ""}
            />
          </Suspense>
        </Box>
      </Box>
    </BootstrapDialog>
  );
};

export default ErrorModal;
