import ActivityStatistics from "components/ActivityStatistics/block";
import MarketStatistics from "components/MarketStatistics/block";
import MetaStatistics from "components/MetaStatistics/block";
import { ORDER_TYPES } from "constants/";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { GraphBlock } from "../GraphBlock/GraphBlock";

export const StatsTab = ({ statistic, activity, influencerData }) => {
  const { t } = useContext(LocalizationContext);
  const localizedActivity = activity.map((a) => {
    let b = { ...a };
    if (b?.type === ORDER_TYPES.buy) {
      b.type = t("discover.buy");
    }
    if (a?.type === ORDER_TYPES.sell) {
      b.type = t("discover.sell");
    }

    return b;
  });
  return (
    <>
      <MarketStatistics {...statistic} />
      <MetaStatistics {...statistic} />
      <GraphBlock influencerData={influencerData} />
      <ActivityStatistics data={localizedActivity} />
    </>
  );
};
